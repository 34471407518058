import React from 'react';
import { useNavigate } from 'react-router-dom';
import { members } from '../../data/members';

interface TrainerConnectionsProps {
  trainerId: string;
  limit?: number;
}

const TrainerConnections: React.FC<TrainerConnectionsProps> = ({ trainerId, limit }) => {
  const navigate = useNavigate();
  
  // Simulate connections who trained with this trainer
  const connections = members
    .filter(m => !m.isTrainer && Math.random() > 0.7) // Random selection for demo
    .slice(0, limit || 5);

  if (connections.length === 0) return null;

  return (
    <div className="mt-4">
      <p className="text-dark-800 text-sm mb-2">Trained with:</p>
      <div className="flex -space-x-2 overflow-hidden">
        {connections.map((connection, index) => (
          <button
            key={connection.id}
            onClick={() => navigate(`/dashboard/members/${connection.id}`)}
            className="relative inline-block"
            title={connection.name}
          >
            <img
              src={connection.image}
              alt={connection.name}
              className="w-8 h-8 rounded-full border-2 border-dark-200 hover:z-10 transition-transform hover:scale-110"
            />
          </button>
        ))}
        {connections.length > 5 && (
          <div className="w-8 h-8 rounded-full bg-dark-300 border-2 border-dark-200 flex items-center justify-center text-xs text-white">
            +{connections.length - 5}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainerConnections;