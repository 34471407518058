// Mock Twilio service for frontend demo
export async function sendVerificationCode(phoneNumber: string): Promise<boolean> {
  // Simulate API call delay
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  // For demo purposes, always return success
  return true;
}

export async function verifyCode(phoneNumber: string, code: string): Promise<boolean> {
  // Simulate API call delay
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  // For demo purposes, accept any 6-digit code
  return code.length === 6 && /^\d+$/.test(code);
}