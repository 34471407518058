import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import TrainingDashboard from '../components/training/TrainingDashboard';

const Training: React.FC = () => {
  return (
    <DashboardLayout>
      <TrainingDashboard />
    </DashboardLayout>
  );
};

export default Training;