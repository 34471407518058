import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../../ui/dialog';
import { Button } from '../../ui/button';

interface DeclineRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  activity: {
    id: string;
    sport: string;
    opponent: {
      name: string;
    };
  };
}

const DeclineRequestModal: React.FC<DeclineRequestModalProps> = ({
  isOpen,
  onClose,
  activity
}) => {
  const [reason, setReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleDecline = async () => {
    setIsSubmitting(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to decline request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showSuccess) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px] bg-white dark:bg-dark-200 text-gray-900 dark:text-white border-gray-200 dark:border-dark-300">
          <DialogHeader>
            <div className="mx-auto w-12 h-12 rounded-full bg-green-100 dark:bg-green-400/20 flex items-center justify-center mb-4">
              <AlertCircle className="h-6 w-6 text-green-600 dark:text-green-400" />
            </div>
            <DialogTitle>Request Declined</DialogTitle>
            <DialogDescription className="text-gray-600 dark:text-dark-800">
              {activity.opponent.name} has been notified that you can't make it.
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button
              onClick={onClose}
              className="w-full bg-gold-500 hover:bg-gold-600 dark:bg-gold-400 dark:hover:bg-gold-500 text-white dark:text-dark-50"
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-white dark:bg-dark-200 text-gray-900 dark:text-white border-gray-200 dark:border-dark-300">
        <DialogHeader>
          <div className="mx-auto w-12 h-12 rounded-full bg-red-100 dark:bg-red-400/20 flex items-center justify-center mb-4">
            <AlertCircle className="h-6 w-6 text-red-600 dark:text-red-400" />
          </div>
          <DialogTitle>Decline Request</DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-dark-800">
            Let {activity.opponent.name} know why you're declining their {activity.sport} request
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Add a message (optional)"
            className="w-full h-32 bg-gray-50 dark:bg-dark-300 border border-gray-300 dark:border-dark-400 rounded-xl px-4 py-2 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-dark-800 resize-none"
          />
        </div>

        <DialogFooter className="sm:justify-center">
          <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              className="sm:order-1 border-gray-300 dark:border-dark-400"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleDecline}
              className="bg-red-600 hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-600 text-white sm:order-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Declining...' : 'Decline Request'}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeclineRequestModal;