import React from 'react';
import { Trophy, Star, Award, Target, TrendingUp } from 'lucide-react';
import { useBadges } from '../../../hooks/useBadges';
import { Badge } from '../../../types/badge';

interface AchievementsTabProps {
  userId: string;
}

const AchievementsTab: React.FC<AchievementsTabProps> = ({ userId }) => {
  const { badges, loading, getBadgeProgress } = useBadges(userId);

  const getTierColor = (tier: Badge['tier']) => {
    switch (tier) {
      case 'bronze': return 'text-orange-400';
      case 'silver': return 'text-gray-400';
      case 'gold': return 'text-yellow-400';
      case 'platinum': return 'text-blue-400';
      case 'elite': return 'text-purple-400';
      default: return 'text-white';
    }
  };

  const recentMilestones = [
    { title: 'First Tournament Win', date: 'March 1, 2024' },
    { title: '50 Matches Played', date: 'February 15, 2024' },
    { title: '90% Serve Accuracy', date: 'February 1, 2024' }
  ];

  const activities = {
    weekly: [65, 72, 78, 75, 82, 88, 92],
    sports: [
      { name: 'Tennis', score: 92, improvement: '+15%' },
      { name: 'Golf', score: 85, improvement: '+8%' },
      { name: 'Swimming', score: 78, improvement: '+12%' }
    ],
    goals: [
      { name: 'Weekly Training', target: 5, current: 4 },
      { name: 'Monthly Matches', target: 12, current: 8 },
      { name: 'Performance Score', target: 95, current: 88 }
    ]
  };

  if (loading) {
    return <div className="text-dark-800">Loading achievements...</div>;
  }

  const unlockedBadges = badges.filter(badge => badge.unlocked);
  const inProgressBadges = badges.filter(badge => !badge.unlocked && badge.progress! > 0);

  return (
    <div className="space-y-6">
      {/* Level Progress */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h3 className="text-lg font-semibold text-white">Level Progress</h3>
            <p className="text-dark-800 mt-1">Level 24 • Elite Player</p>
          </div>
          <div className="flex items-center space-x-2">
            <TrendingUp className="h-5 w-5 text-gold-400" />
            <span className="text-white font-medium">12,450 XP</span>
          </div>
        </div>
        <div className="w-full h-3 bg-dark-400 rounded-full">
          <div
            className="h-3 bg-gradient-to-r from-gold-400 to-gold-500 rounded-full"
            style={{ width: '75%' }}
          />
        </div>
        <p className="text-dark-800 text-sm mt-2">1,550 XP until Level 25</p>
      </div>

      {/* Unlocked Badges */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
          <Trophy className="h-5 w-5 text-gold-400 mr-2" />
          Achievements
        </h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {unlockedBadges.map((badge) => (
            <div
              key={badge.id}
              className="bg-dark-300 rounded-xl p-4 flex flex-col items-center text-center group hover:bg-dark-400 transition-colors"
            >
              <div className="text-3xl mb-2">{badge.icon}</div>
              <h4 className={`text-base font-medium mb-1 ${getTierColor(badge.tier)}`}>
                {badge.name}
              </h4>
              <p className="text-dark-800 text-xs">{badge.description}</p>
              {badge.unlockedAt && (
                <p className="text-dark-800 text-xs mt-2">
                  Unlocked {new Date(badge.unlockedAt).toLocaleDateString()}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Badges in Progress */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
          <Target className="h-5 w-5 text-gold-400 mr-2" />
          Next Achievements
        </h3>
        <div className="space-y-4">
          {inProgressBadges.slice(0, 3).map((badge) => (
            <div key={badge.id} className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center space-x-4 mb-2">
                <div className="text-2xl">{badge.icon}</div>
                <div className="flex-1">
                  <h4 className={`text-base font-medium ${getTierColor(badge.tier)}`}>
                    {badge.name}
                  </h4>
                  <p className="text-dark-800 text-xs">{badge.description}</p>
                </div>
                <div className="text-right">
                  <span className="text-white">{badge.progress}</span>
                  <span className="text-dark-800">/{badge.requirement}</span>
                </div>
              </div>
              <div className="w-full h-2 bg-dark-400 rounded-full">
                <div
                  className="h-2 bg-gold-400 rounded-full transition-all duration-300"
                  style={{ width: `${getBadgeProgress(badge)}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Recent Milestones */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
          <Award className="h-5 w-5 text-gold-400 mr-2" />
          Recent Milestones
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {recentMilestones.map((milestone, index) => (
            <div
              key={index}
              className="bg-dark-300 rounded-xl p-4 flex items-center space-x-4"
            >
              <div className="bg-gold-400 p-3 rounded-lg">
                <Trophy className="h-6 w-6 text-dark-50" />
              </div>
              <div>
                <h4 className="text-base font-medium text-white">{milestone.title}</h4>
                <p className="text-dark-800 text-xs">{milestone.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Activity Progress */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h3 className="text-lg font-semibold text-white mb-4">Activity Progress</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {activities.sports.map((sport, index) => (
            <div key={index} className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between mb-2">
                <span className="text-white">{sport.name}</span>
                <div className="flex items-center space-x-2">
                  <span className="text-white">{sport.score}</span>
                  <span className="text-green-400 text-sm">{sport.improvement}</span>
                </div>
              </div>
              <div className="w-full h-2 bg-dark-400 rounded-full">
                <div 
                  className="h-2 bg-gold-400 rounded-full transition-all duration-300"
                  style={{ width: `${sport.score}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Goals Progress */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h3 className="text-lg font-semibold text-white mb-4">Goals Progress</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {activities.goals.map((goal, index) => (
            <div key={index} className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between mb-4">
                <span className="text-white">{goal.name}</span>
                <span className="text-gold-400">
                  {goal.current}/{goal.target}
                </span>
              </div>
              <div className="w-full h-2 bg-dark-400 rounded-full">
                <div 
                  className="h-2 bg-gold-400 rounded-full transition-all duration-300"
                  style={{ width: `${(goal.current / goal.target) * 100}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Badge Stats */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="text-dark-800 mb-1">Total Badges</div>
          <div className="text-2xl font-bold text-white">{unlockedBadges.length}</div>
        </div>
        {['bronze', 'silver', 'gold', 'elite'].map((tier) => (
          <div key={tier} className="bg-dark-200 rounded-xl p-4 border border-dark-300">
            <div className="text-dark-800 mb-1 capitalize">{tier}</div>
            <div className={`text-2xl font-bold ${getTierColor(tier as Badge['tier'])}`}>
              {unlockedBadges.filter(b => b.tier === tier).length}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AchievementsTab;