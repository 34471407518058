import React, { useState, useEffect } from 'react';
import { X, Calendar, Clock, MapPin, AlertCircle, Send, Edit2 } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../../ui/dialog';
import { Button } from '../../ui/button';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import { facilities } from '../../../data/facilities';

interface ManageActivityModalProps {
  isOpen: boolean;
  onClose: () => void;
  activity: {
    id: string;
    sport: string;
    opponent: {
      id: string;
      name: string;
      image: string;
    };
    date: string;
    time: string;
    venue: {
      name: string;
      city: string;
    };
    status: string;
  };
}

const ManageActivityModal: React.FC<ManageActivityModalProps> = ({
  isOpen,
  onClose,
  activity
}) => {
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(activity.date);
  const [selectedTime, setSelectedTime] = useState(activity.time);
  const [selectedVenue, setSelectedVenue] = useState(activity.venue.name);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const cityVenues = facilities.filter(
    facility => facility.location === activity.venue.city
  );

  const timeSlots = [
    '09:00', '10:00', '11:00', '14:00', '15:00', '16:00', '17:00'
  ];

  const hasChanges = selectedDate !== activity.date || 
                    selectedTime !== activity.time || 
                    selectedVenue !== activity.venue.name;

  useEffect(() => {
    if (activity.status === 'pending') {
      setSelectedDate(activity.date);
      setSelectedTime(activity.time);
      setSelectedVenue(activity.venue.name);
    }
  }, [activity]);

  const handleAccept = async () => {
    setIsSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setSuccessMessage(`Match request accepted! You're all set to play with ${activity.opponent.name}.`);
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to accept request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePropose = async () => {
    setIsSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setSuccessMessage(`Alternative proposal sent to ${activity.opponent.name}. You'll be notified once they respond.`);
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to propose alternative:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => {
    setIsSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      onClose();
    } catch (error) {
      console.error('Failed to cancel:', error);
    } finally {
      setIsSubmitting(false);
      setShowCancelConfirm(false);
    }
  };

  if (showSuccess) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px] bg-white dark:bg-dark-200 text-gray-900 dark:text-white border-gray-200 dark:border-dark-300">
          <DialogHeader>
            <div className="mx-auto w-12 h-12 rounded-full bg-green-100 dark:bg-green-400/20 flex items-center justify-center mb-4">
              <Send className="h-6 w-6 text-green-600 dark:text-green-400" />
            </div>
            <DialogTitle>Success!</DialogTitle>
            <DialogDescription className="text-gray-600 dark:text-dark-800">
              {successMessage}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button
              onClick={onClose}
              className="w-full bg-gold-500 hover:bg-gold-600 dark:bg-gold-400 dark:hover:bg-gold-500 text-white dark:text-dark-50"
            >
              Done
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  if (showCancelConfirm) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px] bg-white dark:bg-dark-200 text-gray-900 dark:text-white border-gray-200 dark:border-dark-300">
          <DialogHeader>
            <div className="mx-auto w-12 h-12 rounded-full bg-red-100 dark:bg-red-400/20 flex items-center justify-center mb-4">
              <AlertCircle className="h-6 w-6 text-red-600 dark:text-red-400" />
            </div>
            <DialogTitle>Cancel Activity</DialogTitle>
            <DialogDescription className="text-gray-600 dark:text-dark-800">
              Are you sure you want to cancel your {activity.sport} session with {activity.opponent.name}?
              This action cannot be undone.
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="sm:justify-center">
            <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowCancelConfirm(false)}
                className="sm:order-1 border-gray-300 dark:border-dark-400"
                disabled={isSubmitting}
              >
                Keep Activity
              </Button>
              <Button
                type="button"
                onClick={handleCancel}
                className="bg-red-600 hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-600 text-white sm:order-2"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Cancelling...' : 'Yes, Cancel Activity'}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-white dark:bg-dark-200 text-gray-900 dark:text-white border-gray-200 dark:border-dark-300">
        <DialogHeader>
          <DialogTitle>Manage Activity</DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-dark-800">
            {activity.status === 'pending' 
              ? `Review match request from ${activity.opponent.name}`
              : `Manage your ${activity.sport} session with ${activity.opponent.name}`}
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-4">
          {!isEditing ? (
            <div className="space-y-4">
              <div className="bg-gray-50 dark:bg-dark-300 rounded-xl p-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center text-gray-600 dark:text-dark-800">
                    <Calendar className="h-4 w-4 mr-2" />
                    {new Date(activity.date).toLocaleDateString()}
                  </div>
                  <div className="flex items-center text-gray-600 dark:text-dark-800">
                    <Clock className="h-4 w-4 mr-2" />
                    {activity.time}
                  </div>
                  <div className="flex items-center text-gray-600 dark:text-dark-800 col-span-2">
                    <MapPin className="h-4 w-4 mr-2" />
                    {activity.venue.name}
                  </div>
                </div>
              </div>
              {(activity.status === 'pending' || activity.status === 'confirmed') && (
                <Button
                  onClick={() => setIsEditing(true)}
                  className="w-full bg-gray-100 hover:bg-gray-200 dark:bg-dark-300 dark:hover:bg-dark-400 text-gray-900 dark:text-white"
                >
                  <Edit2 className="h-4 w-4 mr-2" />
                  Edit Details
                </Button>
              )}
            </div>
          ) : (
            <>
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 dark:text-dark-800">Date</label>
                <input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  min={new Date().toISOString().split('T')[0]}
                  className="w-full bg-gray-50 dark:bg-dark-300 border border-gray-300 dark:border-dark-400 rounded-lg px-3 py-2 text-gray-900 dark:text-white"
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 dark:text-dark-800">Time</label>
                <Select value={selectedTime} onValueChange={setSelectedTime}>
                  <SelectTrigger className="w-full bg-gray-50 dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
                    <SelectValue placeholder="Select time" />
                  </SelectTrigger>
                  <SelectContent>
                    {timeSlots.map((time) => (
                      <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700 dark:text-dark-800">Venue</label>
                <Select value={selectedVenue} onValueChange={setSelectedVenue}>
                  <SelectTrigger className="w-full bg-gray-50 dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
                    <SelectValue placeholder="Select venue" />
                  </SelectTrigger>
                  <SelectContent>
                    {cityVenues.map((venue) => (
                      <SelectItem key={venue.id} value={venue.name}>
                        {venue.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {hasChanges && (
                <div className="bg-gray-50 dark:bg-dark-300 rounded-lg p-3 text-sm text-gray-600 dark:text-dark-800">
                  <p>
                    Your changes will be sent as a new proposal to {activity.opponent.name} for approval.
                  </p>
                </div>
              )}
            </>
          )}
        </div>

        <DialogFooter>
          <div className="flex flex-col sm:flex-row gap-3 w-full">
            <Button
              type="button"
              variant="outline"
              onClick={() => setShowCancelConfirm(true)}
              className="sm:order-1 border-gray-300 dark:border-dark-400"
              disabled={isSubmitting}
            >
              Cancel Activity
            </Button>
            {activity.status === 'pending' && (
              <Button
                type="button"
                onClick={hasChanges ? handlePropose : handleAccept}
                className={`${
                  hasChanges 
                    ? 'bg-gold-500 hover:bg-gold-600 dark:bg-gold-400 dark:hover:bg-gold-500' 
                    : 'bg-green-600 hover:bg-green-700 dark:bg-green-500 dark:hover:bg-green-600'
                } text-white dark:text-dark-50 sm:order-2 flex-1`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Processing...' : hasChanges ? 'Propose Alternative' : 'Accept Match'}
              </Button>
            )}
            {activity.status === 'confirmed' && hasChanges && (
              <Button
                type="button"
                onClick={handlePropose}
                className="bg-gold-500 hover:bg-gold-600 dark:bg-gold-400 dark:hover:bg-gold-500 text-white dark:text-dark-50 sm:order-2 flex-1"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Processing...' : 'Propose Changes'}
              </Button>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ManageActivityModal;