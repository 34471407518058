import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import ActivitiesTab from '../components/members/tabs/ActivitiesTab';
import TrainingActivitiesTab from '../components/members/tabs/TrainingActivitiesTab';

const Activities = () => {
  const [activeTab, setActiveTab] = useState<'matches' | 'training'>('matches');

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-bold text-white">Activities</h1>
              <p className="text-dark-800 mt-1">Track your sports activities and matches</p>
            </div>
          </div>

          {/* Tabs */}
          <div className="flex items-center space-x-4 mt-6 border-b border-dark-300">
            <button
              onClick={() => setActiveTab('matches')}
              className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
                activeTab === 'matches'
                  ? 'border-gold-400 text-gold-400'
                  : 'border-transparent text-dark-800 hover:text-white'
              }`}
            >
              My Matches
            </button>
            <button
              onClick={() => setActiveTab('training')}
              className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
                activeTab === 'training'
                  ? 'border-gold-400 text-gold-400'
                  : 'border-transparent text-dark-800 hover:text-white'
              }`}
            >
              My Trainings
            </button>
          </div>
        </div>

        {/* Tab Content */}
        {activeTab === 'matches' ? (
          <ActivitiesTab isOwnProfile={true} />
        ) : (
          <TrainingActivitiesTab />
        )}
      </div>
    </DashboardLayout>
  );
};

export default Activities;