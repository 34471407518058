import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { Hero } from '../components/Hero';
import { Features } from '../components/Features';
import { Stats } from '../components/Stats';
import { JoinClub } from '../components/JoinClub';
import { FloatingCTA } from '../components/FloatingCTA';
import { useAuth } from '../contexts/AuthContext';

const LandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [memberCount, setMemberCount] = useState(0);
  const [facilityCount, setFacilityCount] = useState(0);
  const [cityCount, setCityCount] = useState(0);
  const counterRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in');
          if (entry.target === counterRef.current) {
            animateCounter(9500, setMemberCount);
            animateCounter(120, setFacilityCount);
            animateCounter(5, setCityCount);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);

    const animatedElements = document.querySelectorAll('.animate-on-scroll');
    animatedElements.forEach((el) => observer.observe(el));

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      animatedElements.forEach((el) => observer.unobserve(el));
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  const animateCounter = (target: number, setter: React.Dispatch<React.SetStateAction<number>>) => {
    let start = 0;
    const duration = 2000;
    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
      setter(Math.floor(progress * target));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const handleLoginSuccess = async (email: string, password: string) => {
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-black text-white' : 'bg-white text-black'}`}>
      <Header
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        onLoginSuccess={handleLoginSuccess}
      />
      <Hero
        currentImage="https://images.unsplash.com/photo-1517838277536-f5f99be501cd?auto=format&fit=crop&w=2400&q=80"
        blurAmount={0}
        parallaxOffset={0}
      />
      <Stats
        memberCount={memberCount}
        facilityCount={facilityCount}
        cityCount={cityCount}
        counterRef={counterRef}
      />
      <Features />
      <JoinClub />
      <FloatingCTA show={true} />
    </div>
  );
};

export default LandingPage;