import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, MapPin, Star, Filter, FrownIcon, X, RefreshCw, Check, MessageCircle } from 'lucide-react';
import type { Player } from '../../types/matchmaking';
import { members } from '../../data/members';
import MatchCard from './MatchCard';
import { Dialog, DialogContent } from '../ui/dialog';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';

interface QuickMatchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const QuickMatchModal: React.FC<QuickMatchModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [selectedLevel, setSelectedLevel] = useState('all');
  const [selectedMatch, setSelectedMatch] = useState<any | null>(null);
  const [searchState, setSearchState] = useState<'searching' | 'found' | 'request-sent' | 'not-found'>('searching');
  const [potentialPlayers, setPotentialPlayers] = useState<Player[]>([]);
  const [isInitialSearch, setIsInitialSearch] = useState(true);

  const sports = Array.from(new Set(members.flatMap(m => m.sports)));
  const cities = Array.from(new Set(members.map(m => m.city)));
  const levels = ['all', 'Beginner', 'Intermediate', 'Advanced', 'Professional'];

  const filterPlayers = useCallback(() => {
    const filteredPlayers = members
      .filter(member => {
        const matchesSearch = searchTerm === '' || 
          member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.sports.some(sport => sport.toLowerCase().includes(searchTerm.toLowerCase()));

        const matchesSport = selectedSport === 'all' || member.sports.includes(selectedSport);
        const matchesCity = selectedCity === 'all' || member.city === selectedCity;
        const matchesLevel = selectedLevel === 'all' || member.skillLevel === selectedLevel;

        return matchesSearch && matchesSport && matchesCity && matchesLevel;
      })
      .map(member => ({
        id: member.id,
        name: member.name,
        age: member.age,
        location: member.city,
        distance: Math.floor(Math.random() * 10),
        sports: member.sports,
        skillLevel: member.skillLevel,
        rating: member.rating,
        matchPercentage: Math.floor(Math.random() * 15 + 85),
        availability: member.availability,
        image: member.image,
        recentActivity: `Active ${Math.floor(Math.random() * 3) + 1}h ago`,
        company: {
          name: member.company,
          position: member.jobTitle,
          industry: member.industry
        }
      }));

    setPotentialPlayers(filteredPlayers);
    setSearchState(filteredPlayers.length > 0 ? 'found' : 'not-found');
  }, [searchTerm, selectedSport, selectedCity, selectedLevel]);

  useEffect(() => {
    if (isOpen && isInitialSearch) {
      setSearchState('searching');
      const searchTimer = setTimeout(() => {
        filterPlayers();
        setIsInitialSearch(false);
      }, 1500);

      return () => clearTimeout(searchTimer);
    } else if (!isInitialSearch) {
      filterPlayers();
    }
  }, [isOpen, isInitialSearch, filterPlayers]);

  const handleSearchAgain = () => {
    setIsInitialSearch(true);
    setSearchTerm('');
    setSelectedSport('all');
    setSelectedCity('all');
    setSelectedLevel('all');
  };

  const handleMatchSelect = (match: any) => {
    setSelectedMatch(match);
    setSearchState('request-sent');
  };

  const handleStartChat = () => {
    if (selectedMatch) {
      const initialMessage = `Hi ${selectedMatch.player.name}! I'm interested in playing ${selectedMatch.sport} with you. Would you like to join me?`;
      
      handleClose();
      
      setTimeout(() => {
        navigate('/dashboard/messages', { 
          state: { 
            activeChat: selectedMatch.player.id,
            initialMessage
          }
        });
      }, 300);
    }
  };

  const handleViewActivities = () => {
    handleClose();
    setTimeout(() => {
      navigate('/dashboard/profile', { state: { activeTab: 'activities' } });
    }, 300);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSearchState('searching');
      setSelectedMatch(null);
      setSearchTerm('');
      setSelectedSport('all');
      setSelectedCity('all');
      setSelectedLevel('all');
      setPotentialPlayers([]);
      setIsInitialSearch(true);
    }, 300);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-2xl bg-dark-200 rounded-2xl p-6 border border-dark-300">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            {searchState === 'searching' && (
              <div className="text-center py-12">
                <div className="relative w-24 h-24 mx-auto mb-6">
                  <div className="absolute inset-0 border-4 border-t-transparent border-gold-400 rounded-full animate-spin"></div>
                  <img
                    src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/lines_gold-removebg-k1iVBout4rs8n3KtWk0IgssRYsSoTG.png"
                    alt="Playclub"
                    className="absolute inset-0 w-16 h-16 m-auto"
                  />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Finding Players Near You</h3>
                <p className="text-dark-800">Searching for available players...</p>
              </div>
            )}

            {searchState === 'found' && (
              <div>
                <div className="flex items-center justify-between mb-6 pr-12">
                  <h3 className="text-xl font-semibold text-white">
                    {potentialPlayers.length} Available Players
                  </h3>
                  <button
                    onClick={handleSearchAgain}
                    className="flex items-center space-x-2 px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
                  >
                    <RefreshCw className="h-4 w-4" />
                    <span>Search Again</span>
                  </button>
                </div>
                
                {/* Filters */}
                <div className="space-y-4 mb-6">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                    <input
                      type="text"
                      placeholder="Search players..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
                    />
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <Select value={selectedSport} onValueChange={setSelectedSport}>
                      <SelectTrigger>
                        <SelectValue placeholder="All Sports" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Sports</SelectItem>
                        {sports.map((sport) => (
                          <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <Select value={selectedCity} onValueChange={setSelectedCity}>
                      <SelectTrigger>
                        <SelectValue placeholder="All Cities" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Cities</SelectItem>
                        {cities.map((city) => (
                          <SelectItem key={city} value={city}>{city}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <Select value={selectedLevel} onValueChange={setSelectedLevel}>
                      <SelectTrigger>
                        <SelectValue placeholder="All Levels" />
                      </SelectTrigger>
                      <SelectContent>
                        {levels.map((level) => (
                          <SelectItem key={level} value={level}>
                            {level === 'all' ? 'All Levels' : level}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="space-y-4 max-h-[60vh] overflow-y-auto pr-2">
                  {potentialPlayers.map((player) => (
                    <MatchCard
                      key={player.id}
                      player={player}
                      sports={player.sports}
                      dates={[new Date().toISOString().split('T')[0]]}
                      timeSlots={['morning', 'afternoon', 'evening']}
                      onMatchSelect={handleMatchSelect}
                    />
                  ))}
                </div>
              </div>
            )}

            {searchState === 'not-found' && (
              <div className="text-center py-12">
                <FrownIcon className="h-12 w-12 text-dark-800 mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">No Players Found</h3>
                <p className="text-dark-800 mb-6">
                  Try adjusting your filters to find more players
                </p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={handleSearchAgain}
                    className="inline-flex items-center space-x-2 px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
                  >
                    <RefreshCw className="h-4 w-4 mr-2" />
                    Search Again
                  </button>
                  <button
                    onClick={() => {
                      setSearchTerm('');
                      setSelectedSport('all');
                      setSelectedCity('all');
                      setSelectedLevel('all');
                      filterPlayers();
                    }}
                    className="inline-flex items-center space-x-2 px-6 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
                  >
                    <Filter className="h-4 w-4 mr-2" />
                    Reset Filters
                  </button>
                </div>
              </div>
            )}

            {searchState === 'request-sent' && selectedMatch && (
              <div className="text-center py-8">
                <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
                  <Check className="h-8 w-8 text-dark-50" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Play Request Sent!</h3>
                <p className="text-dark-800 mb-2">
                  Your request has been sent to {selectedMatch.player.name}.
                </p>
                <p className="text-dark-800 mb-6">
                  You can view the status in your{' '}
                  <button
                    onClick={handleViewActivities}
                    className="text-gold-400 hover:text-gold-500 transition-colors"
                  >
                    pending activities
                  </button>
                  .
                </p>
                <div className="flex space-x-4">
                  <button
                    onClick={handleClose}
                    className="flex-1 px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
                  >
                    Close
                  </button>
                  <button
                    onClick={handleStartChat}
                    className="flex-1 bg-gold-400 hover:bg-gold-500 text-dark-50 px-4 py-2 rounded-xl transition-colors flex items-center justify-center space-x-2"
                  >
                    <MessageCircle className="h-5 w-5" />
                    <span>Send Message</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickMatchModal;