import React, { useState } from 'react';
import { Calendar, Clock, MapPin, ChevronRight, X, ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { facilities } from '../../data/facilities';
import DateRangePicker from '../ui/date-range-picker';

interface RequestMatchModalProps {
  isOpen: boolean;
  onClose: () => void;
  member: {
    id: string;
    name: string;
    city: string;
    sports: string[];
  };
}

const RequestMatchModal: React.FC<RequestMatchModalProps> = ({ isOpen, onClose, member }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [selectedSport, setSelectedSport] = useState('');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [selectedTime, setSelectedTime] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showMoreSports, setShowMoreSports] = useState(false);

  if (!isOpen) return null;

  const firstName = member.name.split(' ')[0];

  const primarySports = [
    { id: 'padel', emoji: '🎱', name: 'Padel' },
    { id: 'running', emoji: '🏃‍♂️', name: 'Running' },
    { id: 'football', emoji: '⚽', name: '5-a-side Football' },
    { id: 'pilates', emoji: '🏋️‍♀️', name: 'Pilates' },
    { id: 'yoga', emoji: '🧘‍♀️', name: 'Yoga' },
    { id: 'tennis', emoji: '🎾', name: 'Tennis' }
  ];

  const additionalSports = [
    { id: 'golf', emoji: '⛳', name: 'Golf' },
    { id: 'swimming', emoji: '🏊‍♂️', name: 'Swimming' },
    { id: 'cycling', emoji: '🚴‍♀️', name: 'Cycling' },
    { id: 'basketball', emoji: '🏀', name: 'Basketball' },
    { id: 'badminton', emoji: '🏸', name: 'Badminton' },
    { id: 'table-tennis', emoji: '🏓', name: 'Table Tennis' },
    { id: 'volleyball', emoji: '🏐', name: 'Volleyball' },
    { id: 'hiking', emoji: '🥾', name: 'Hiking' },
    { id: 'climbing', emoji: '🧗‍♀️', name: 'Rock Climbing' },
    { id: 'surfing', emoji: '🏄‍♂️', name: 'Surfing' },
    { id: 'snowboarding', emoji: '🏂', name: 'Snowboarding' },
    { id: 'skiing', emoji: '⛷️', name: 'Skiing' },
    { id: 'boxing', emoji: '🥊', name: 'Boxing' },
    { id: 'wrestling', emoji: '🤼‍♂️', name: 'Wrestling' }
  ];

  const timePeriods = [
    { value: 'morning', label: 'Morning', time: '6 AM - 11 AM' },
    { value: 'noon', label: 'Noon', time: '11 AM - 2 PM' },
    { value: 'afternoon', label: 'Afternoon', time: '2 PM - 5 PM' },
    { value: 'evening', label: 'Evening', time: '5 PM - 8 PM' },
    { value: 'night', label: 'Night', time: '8 PM - 11 PM' },
    { value: 'any', label: 'Any time', time: 'All day' }
  ];

  const handleNext = () => {
    if (step < 3 && isStepValid()) {
      setStep(step + 1);
    } else if (isStepValid()) {
      handleSubmit();
    }
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        return selectedSport !== '';
      case 2:
        return dateRange.start !== '' && dateRange.end !== '';
      case 3:
        return selectedTime !== '';
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to send match request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewActivities = () => {
    handleClose();
    navigate('/dashboard/profile', { state: { activeTab: 'activities' } });
  };

  const handleStartChat = () => {
    const timeLabel = timePeriods.find(t => t.value === selectedTime)?.label || selectedTime;
    const initialMessage = `Hi ${member.name}! I'm interested in playing ${selectedSport} during ${timeLabel} between ${new Date(dateRange.start).toLocaleDateString()} and ${new Date(dateRange.end).toLocaleDateString()}. Would you like to join me?`;
    
    handleClose();
    setTimeout(() => {
      navigate('/dashboard/messages', { 
        state: { 
          activeChat: member.id,
          initialMessage
        }
      });
    }, 300);
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setSelectedSport('');
      setDateRange({ start: '', end: '' });
      setSelectedTime('');
      setShowSuccess(false);
      setStep(1);
      onClose();
    }
  };

  const handleSportSelect = (emoji: string, name: string) => {
    setSelectedSport(`${emoji} ${name}`);
    setShowMoreSports(false);
  };

  if (showSuccess) {
    return (
      <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <div className="relative w-full max-w-md bg-dark-200 rounded-2xl p-6 border border-dark-300">
              <div className="text-center py-8">
                <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
                  <svg className="h-8 w-8 text-dark-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Match Request Sent!</h3>
                <p className="text-dark-800 mb-2">
                  Your request has been sent to {member.name}.
                </p>
                <p className="text-dark-800 mb-6">
                  You can view the status in your{' '}
                  <button
                    onClick={handleViewActivities}
                    className="text-gold-400 hover:text-gold-500 transition-colors"
                  >
                    pending activities
                  </button>
                  .
                </p>
                <div className="flex space-x-4">
                  <button
                    onClick={handleClose}
                    className="flex-1 px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
                  >
                    Close
                  </button>
                  <button
                    onClick={handleStartChat}
                    className="flex-1 bg-gold-400 hover:bg-gold-500 text-dark-50 px-4 py-2 rounded-xl transition-colors"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">What would you like to play with {firstName}?</h3>
            <p className="text-dark-800">Select a sport</p>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
              {primarySports.map((sport) => {
                const fullSport = `${sport.emoji} ${sport.name}`;
                const isSelected = selectedSport === fullSport;
                return (
                  <button
                    key={sport.id}
                    onClick={() => handleSportSelect(sport.emoji, sport.name)}
                    className={`flex flex-col items-center p-4 rounded-xl transition-all duration-300 transform hover:scale-105 ${
                      isSelected 
                        ? 'bg-gold-400 text-dark-50' 
                        : 'bg-dark-300 text-white hover:bg-dark-400'
                    }`}
                  >
                    <span className="text-3xl mb-2">{sport.emoji}</span>
                    <span className="text-center text-sm">{sport.name}</span>
                  </button>
                );
              })}
            </div>

            <div className="relative">
              <button
                onClick={() => setShowMoreSports(!showMoreSports)}
                className="w-full flex items-center justify-between p-4 bg-dark-300 hover:bg-dark-400 rounded-xl transition-colors text-white"
              >
                <span>More Sports</span>
                <ChevronDown className={`h-5 w-5 transition-transform ${showMoreSports ? 'rotate-180' : ''}`} />
              </button>
              {showMoreSports && (
                <div className="absolute z-10 mt-2 w-full bg-dark-300 rounded-xl border border-dark-400 shadow-lg">
                  <div className="p-2 grid grid-cols-1 gap-1 max-h-60 overflow-y-auto">
                    {additionalSports.map((sport) => {
                      const fullSport = `${sport.emoji} ${sport.name}`;
                      const isSelected = selectedSport === fullSport;
                      return (
                        <button
                          key={sport.id}
                          onClick={() => handleSportSelect(sport.emoji, sport.name)}
                          className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${
                            isSelected
                              ? 'bg-gold-400 text-dark-50'
                              : 'text-white hover:bg-dark-400'
                          }`}
                        >
                          <span className="text-2xl">{sport.emoji}</span>
                          <span>{sport.name}</span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            {selectedSport && (
              <div className="flex flex-wrap gap-2 mt-4">
                <div className="flex items-center space-x-2 px-3 py-1 bg-dark-300 rounded-full text-white">
                  <span>{selectedSport}</span>
                  <button
                    onClick={() => setSelectedSport('')}
                    className="hover:text-gold-400"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              </div>
            )}
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">When would you like to play?</h3>
            <p className="text-dark-800">Select a date range for your match</p>
            <DateRangePicker
              dateRange={dateRange}
              onDateRangeChange={setDateRange}
            />
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-white">What times work best?</h3>
            <p className="text-dark-800">Select your preferred time slot</p>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
              {timePeriods.map((period) => {
                const isSelected = selectedTime === period.value;
                return (
                  <button
                    key={period.value}
                    onClick={() => setSelectedTime(period.value)}
                    className={`flex flex-col items-center p-4 rounded-xl transition-all duration-300 ${
                      isSelected
                        ? 'bg-gold-400 text-dark-50'
                        : 'bg-dark-300 text-white hover:bg-dark-400'
                    }`}
                  >
                    <span className="font-medium mb-1">{period.label}</span>
                    <span className="text-sm opacity-80">{period.time}</span>
                  </button>
                );
              })}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-md bg-dark-200 rounded-2xl p-6 border border-dark-300">
            <div className="absolute top-0 left-0 right-0 h-1 bg-dark-300 rounded-t-2xl overflow-hidden">
              <div 
                className="h-full bg-gold-400 transition-all duration-300"
                style={{ width: `${(step / 3) * 100}%` }}
              />
            </div>

            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-dark-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>

            <div className="mt-6">
              {renderStep()}
            </div>

            <div className="flex justify-between items-center mt-8">
              {step > 1 && (
                <button
                  onClick={() => setStep(step - 1)}
                  className="text-dark-800 hover:text-white transition-colors"
                >
                  Back
                </button>
              )}
              <div className="flex-1" />
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2 text-sm text-dark-800">
                  <span className="font-medium text-gold-400">{step}</span>
                  <span>/</span>
                  <span>3</span>
                </div>
                {isStepValid() && (
                  <button
                    onClick={handleNext}
                    className="flex items-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
                  >
                    <span>{step === 3 ? 'Send Request' : 'Next'}</span>
                    <ChevronRight className="h-5 w-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestMatchModal;