import React from 'react';
import { X, MapPin, Users, Trophy, Calendar, Shield, Globe } from 'lucide-react';

interface ClubDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRequestJoin: () => void;
  club: {
    id: string;
    name: string;
    image: string;
    members: number;
    rating: number;
    location: string;
    amenities: string[];
    exclusivity: string;
    sports: string[];
    description: string;
    founder: {
      name: string;
      image: string;
    };
    admins: {
      name: string;
      image: string;
    }[];
    rules?: string[];
  };
}

const ClubDetailsModal: React.FC<ClubDetailsModalProps> = ({
  isOpen,
  onClose,
  onRequestJoin,
  club
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-3xl bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-10 p-2 bg-dark-900/50 rounded-lg text-white hover:bg-dark-900 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            {/* Cover Image */}
            <div className="relative h-64">
              <img
                src={club.image}
                alt={club.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/60 to-transparent" />
              <div className="absolute bottom-6 left-6 right-6">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-2xl font-bold text-white">{club.name}</h2>
                    <div className="flex items-center text-dark-800 mt-1">
                      <MapPin className="h-4 w-4 mr-1" />
                      {club.location}
                    </div>
                  </div>
                  <span className="px-3 py-1 bg-gold-400 text-dark-50 rounded-full text-sm">
                    {club.exclusivity}
                  </span>
                </div>
              </div>
            </div>

            <div className="p-6">
              {/* Quick Stats */}
              <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-dark-300 rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <Users className="h-5 w-5 text-gold-400" />
                    <span className="text-xl font-bold text-white">{club.members}</span>
                  </div>
                  <p className="text-dark-800 mt-1">Members</p>
                </div>
                <div className="bg-dark-300 rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <Trophy className="h-5 w-5 text-gold-400" />
                    <span className="text-xl font-bold text-white">{club.rating}</span>
                  </div>
                  <p className="text-dark-800 mt-1">Rating</p>
                </div>
                <div className="bg-dark-300 rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <Globe className="h-5 w-5 text-gold-400" />
                    <span className="text-xl font-bold text-white">{club.amenities.length}</span>
                  </div>
                  <p className="text-dark-800 mt-1">Amenities</p>
                </div>
              </div>

              {/* Description */}
              <div className="mb-6">
                <h3 className="text-white font-medium mb-2">About</h3>
                <p className="text-dark-800">{club.description}</p>
              </div>

              {/* Sports */}
              <div className="mb-6">
                <h3 className="text-white font-medium mb-3">Sports</h3>
                <div className="flex flex-wrap gap-2">
                  {club.sports.map((sport, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white"
                    >
                      {sport}
                    </span>
                  ))}
                </div>
              </div>

              {/* Amenities */}
              <div className="mb-6">
                <h3 className="text-white font-medium mb-3">Amenities</h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                  {club.amenities.map((amenity, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-dark-300 rounded-lg p-2"
                    >
                      <div className="w-1.5 h-1.5 bg-gold-400 rounded-full mr-2" />
                      <span className="text-white text-sm">{amenity}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Leadership */}
              <div className="mb-6">
                <h3 className="text-white font-medium mb-3">Club Leadership</h3>
                <div className="space-y-4">
                  <div className="bg-dark-300 rounded-xl p-4">
                    <div className="flex items-center space-x-3">
                      <img
                        src={club.founder.image}
                        alt={club.founder.name}
                        className="w-12 h-12 rounded-full object-cover border-2 border-gold-400"
                      />
                      <div>
                        <p className="text-white font-medium">{club.founder.name}</p>
                        <p className="text-dark-800 text-sm">Founder</p>
                      </div>
                    </div>
                  </div>
                  {club.admins.map((admin, index) => (
                    <div key={index} className="bg-dark-300 rounded-xl p-4">
                      <div className="flex items-center space-x-3">
                        <img
                          src={admin.image}
                          alt={admin.name}
                          className="w-12 h-12 rounded-full object-cover border-2 border-dark-400"
                        />
                        <div>
                          <p className="text-white font-medium">{admin.name}</p>
                          <p className="text-dark-800 text-sm">Admin</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Rules */}
              {club.rules && (
                <div className="mb-6">
                  <h3 className="text-white font-medium mb-3">Club Rules</h3>
                  <div className="bg-dark-300 rounded-xl p-4">
                    <div className="space-y-2">
                      {club.rules.map((rule, index) => (
                        <div key={index} className="flex items-center text-dark-800">
                          <Shield className="h-4 w-4 text-gold-400 mr-2" />
                          {rule}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Actions */}
              <div className="flex space-x-4">
                <button
                  onClick={onRequestJoin}
                  className="flex-1 bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-xl transition-colors"
                >
                  Request to Join
                </button>
                <button
                  onClick={onClose}
                  className="px-6 py-2 border border-dark-400 text-white rounded-xl hover:bg-dark-300 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubDetailsModal;