import { Member } from '../types/member';
import { trainers } from './trainers';
import { calculatePoints } from './points';

// Helper function to generate random points data with realistic ranges
const generateRandomPoints = (isTrainer: boolean = false, level: 'rookie' | 'amateur' | 'intermediate' | 'pro' | 'elite' | 'legend') => {
  const baseMultiplier = isTrainer ? 2 : 1;
  let pointsMultiplier;
  
  switch (level) {
    case 'rookie':
      pointsMultiplier = 0.2;
      break;
    case 'amateur':
      pointsMultiplier = 0.4;
      break;
    case 'intermediate':
      pointsMultiplier = 0.8;
      break;
    case 'pro':
      pointsMultiplier = 1.2;
      break;
    case 'elite':
      pointsMultiplier = 1.6;
      break;
    case 'legend':
      pointsMultiplier = 2.0;
      break;
    default:
      pointsMultiplier = 1.0;
  }

  const multiplier = baseMultiplier * pointsMultiplier;
  
  return {
    matches: Math.floor(Math.random() * 200 + 50) * multiplier * 100,
    training: Math.floor(Math.random() * 100 + 50) * multiplier * 75,
    connections: Math.floor(Math.random() * 300 + 100) * multiplier * 50,
    cities: Math.floor(Math.random() * 8 + 3) * multiplier * 200,
    achievements: Math.floor(Math.random() * 10 + 5) * multiplier * 250,
    events: Math.floor(Math.random() * 20 + 10) * multiplier * 100,
    total: 0
  };
};

const regularMembers: Member[] = [
  // Original members
  {
    id: '51',
    name: 'Sarah Connor',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80',
    city: 'Monte Carlo',
    sports: ['Tennis', 'Swimming', 'Yoga'],
    jobTitle: 'Investment Director',
    company: 'BlackRock',
    connections: 312,
    matchesPlayed: 85,
    citiesPlayed: ['Monte Carlo', 'Paris', 'New York', 'Dubai', 'Singapore', 'Hong Kong'],
    achievements: ['Tennis Champion', 'Marathon Finisher', 'Elite Network', 'Global Ambassador'],
    rating: 4.9,
    skillLevel: 'Professional',
    availability: ['morning', 'afternoon'],
    age: 35,
    industry: 'Finance',
    invitedBy: {
      id: '55',
      name: 'Uri Schneider',
      image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80'
    },
    isConnected: Math.random() > 0.5,
    connectionDate: Math.random() > 0.5 ? new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString() : undefined,
    joinDate: '2024-09-01',
    points: generateRandomPoints(false, 'legend')
  },
  {
    id: '52',
    name: 'James Wilson',
    image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80',
    city: 'Monte Carlo',
    sports: ['Tennis', 'Golf'],
    jobTitle: 'Managing Director',
    company: 'Goldman Sachs',
    connections: 278,
    matchesPlayed: 93,
    citiesPlayed: ['Monte Carlo', 'London', 'Geneva', 'Dubai'],
    achievements: ['Golf Tournament Champion', 'Elite Status', 'Network Leader'],
    rating: 4.8,
    skillLevel: 'Advanced',
    availability: ['afternoon', 'evening'],
    age: 45,
    industry: 'Banking',
    invitedBy: {
      id: '53',
      name: 'Jean-Pierre Dubois',
      image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80'
    },
    isConnected: Math.random() > 0.5,
    connectionDate: Math.random() > 0.5 ? new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString() : undefined,
    joinDate: '2024-08-15',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: '53',
    name: 'Jean-Pierre Dubois',
    image: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?auto=format&fit=crop&w=300&q=80',
    city: 'Paris',
    sports: ['Golf', 'Tennis'],
    jobTitle: 'Managing Director',
    company: 'BNP Paribas',
    connections: 278,
    matchesPlayed: 93,
    citiesPlayed: ['Paris', 'London', 'Geneva', 'Monte Carlo', 'Dubai'],
    achievements: ['Golf Tournament Champion', 'Elite Status', 'Network Leader'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['afternoon', 'evening'],
    age: 45,
    industry: 'Banking',
    isConnected: Math.random() > 0.5,
    connectionDate: Math.random() > 0.5 ? new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString() : undefined,
    joinDate: '2024-08-15',
    points: generateRandomPoints(false, 'pro')
  },
  {
    id: '54',
    name: 'Sofia Rossi',
    image: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&fit=crop&w=300&q=80',
    city: 'Rome',
    sports: ['Swimming', 'Yoga', 'Tennis'],
    jobTitle: 'Private Equity Partner',
    company: 'CVC Capital',
    connections: 198,
    matchesPlayed: 45,
    citiesPlayed: ['Rome', 'Milan', 'London', 'Paris'],
    achievements: ['Swimming Champion', 'Wellness Leader'],
    rating: 4.6,
    skillLevel: 'Advanced',
    availability: ['morning', 'evening'],
    age: 38,
    industry: 'Private Equity',
    invitedBy: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
    },
    isConnected: Math.random() > 0.5,
    connectionDate: Math.random() > 0.5 ? new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString() : undefined,
    joinDate: '2024-11-01',
    points: generateRandomPoints(false, 'intermediate')
  },
  {
    id: '55',
    name: 'Uri Schneider',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
    city: 'Valencia',
    sports: ['Tennis', 'Golf', 'Swimming'],
    jobTitle: 'Co-Founder',
    company: 'Playclub.ai',
    connections: 425,
    matchesPlayed: 156,
    citiesPlayed: ['Valencia', 'London', 'Paris', 'Dubai', 'Singapore'],
    achievements: ['Tennis Elite', 'Business Leader', 'Global Networker'],
    rating: 4.9,
    skillLevel: 'Professional',
    availability: ['morning', 'evening'],
    age: 40,
    industry: 'Technology',
    isConnected: Math.random() > 0.5,
    connectionDate: Math.random() > 0.5 ? new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString() : undefined,
    joinDate: '2024-07-15',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: '56',
    name: 'Isabella Martinez',
    image: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?auto=format&fit=crop&w=300&q=80',
    city: 'Madrid',
    sports: ['Tennis', 'Padel', 'Golf'],
    jobTitle: 'Investment Banker',
    company: 'Santander',
    connections: 289,
    matchesPlayed: 78,
    citiesPlayed: ['Madrid', 'Barcelona', 'London', 'Paris'],
    achievements: ['Padel Champion', 'Rising Star'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['afternoon', 'evening'],
    age: 33,
    industry: 'Banking',
    invitedBy: {
      id: '52',
      name: 'James Wilson',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
    },
    isConnected: Math.random() > 0.5,
    connectionDate: Math.random() > 0.5 ? new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString() : undefined,
    joinDate: '2024-12-01',
    points: generateRandomPoints(false, 'amateur')
  },
  // Luxembourg Members
  {
    id: 'lux1',
    name: 'Marie Weber',
    image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=300&q=80',
    city: 'Luxembourg',
    sports: ['Tennis', 'Pilates', 'Running'],
    jobTitle: 'Portfolio Manager',
    company: 'Pictet',
    connections: 187,
    matchesPlayed: 45,
    citiesPlayed: ['Luxembourg', 'Paris', 'Brussels'],
    achievements: ['Fitness Elite', 'Marathon Finisher'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['morning', 'evening'],
    age: 32,
    industry: 'Asset Management',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-10',
    points: generateRandomPoints(false, 'pro')
  },
  {
    id: 'lux2',
    name: 'Thomas Klein',
    image: 'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?auto=format&fit=crop&w=300&q=80',
    city: 'Luxembourg',
    sports: ['Padel', '5-a-side Football', 'Tennis'],
    jobTitle: 'Senior Director',
    company: 'European Investment Bank',
    connections: 234,
    matchesPlayed: 67,
    citiesPlayed: ['Luxembourg', 'Frankfurt', 'Brussels'],
    achievements: ['Team Captain', 'Sports Ambassador'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['afternoon', 'evening'],
    age: 38,
    industry: 'Banking',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-02-05',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'lux3',
    name: 'Sophie Muller',
    image: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&w=300&q=80',
    city: 'Luxembourg',
    sports: ['Yoga', 'Tennis', 'Running'],
    jobTitle: 'Legal Counsel',
    company: 'European Court of Justice',
    connections: 156,
    matchesPlayed: 34,
    citiesPlayed: ['Luxembourg', 'Brussels', 'Strasbourg'],
    achievements: ['Wellness Champion', 'Community Leader'],
    rating: 4.6,
    skillLevel: 'Advanced',
    availability: ['morning', 'afternoon'],
    age: 35,
    industry: 'Legal',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-25',
    points: generateRandomPoints(false, 'pro')
  },
  // Washington DC Members
  {
    id: 'dc1',
    name: 'Catherine Meyer',
    image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?auto=format&fit=crop&w=300&q=80',
    city: 'Washington DC',
    sports: ['Tennis', 'Golf', 'Running'],
    jobTitle: 'Senior Policy Advisor',
    company: 'World Bank',
    connections: 245,
    matchesPlayed: 58,
    citiesPlayed: ['Washington DC', 'New York', 'Boston'],
    achievements: ['Tennis League Champion', 'Networking Elite'],
    rating: 4.8,
    skillLevel: 'Advanced',
    availability: ['morning', 'evening'],
    age: 42,
    industry: 'International Development',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-15',
    points: generateRandomPoints(false, 'pro')
  },
  {
    id: 'dc2',
    name: 'David Park',
    image: 'https://images.unsplash.com/photo-1508341591423-4347099e1f19?auto=format&fit=crop&w=300&q=80',
    city: 'Washington DC',
    sports: ['Golf', 'Tennis', 'Swimming'],
    jobTitle: 'Executive Director',
    company: 'IMF',
    connections: 312,
    matchesPlayed: 72,
    citiesPlayed: ['Washington DC', 'London', 'Seoul'],
    achievements: ['Golf Tournament Winner', 'Global Networker'],
    rating: 4.7,
    skillLevel: 'Professional',
    availability: ['afternoon', 'evening'],
    age: 45,
    industry: 'Finance',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-02-01',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'dc3',
    name: 'Ana Garcia',
    image: 'https://images.unsplash.com/photo-1548142813-c348350df52b?auto=format&fit=crop&w=300&q=80',
    city: 'Washington DC',
    sports: ['Tennis', 'Yoga', 'Running'],
    jobTitle: 'Chief Economist',
    company: 'Inter-American Development Bank',
    connections: 198,
    matchesPlayed: 45,
    citiesPlayed: ['Washington DC', 'Miami', 'Madrid'],
    achievements: ['Wellness Ambassador', 'Community Builder'],
    rating: 4.6,
    skillLevel: 'Advanced',
    availability: ['morning', 'afternoon'],
    age: 38,
    industry: 'Economics',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-20',
    points: generateRandomPoints(false, 'pro')
  },
  // Barcelona Members
  {
    id: 'bcn1',
    name: 'Isabella Romano',
    image: 'https://images.unsplash.com/photo-1534751516642-a1af1ef26a56?auto=format&fit=crop&w=300&q=80',
    city: 'Barcelona',
    sports: ['Tennis', 'Padel', 'Yoga'],
    jobTitle: 'Innovation Director',
    company: 'CaixaBank',
    connections: 267,
    matchesPlayed: 89,
    citiesPlayed: ['Barcelona', 'Madrid', 'Rome'],
    achievements: ['Padel Champion', 'Wellness Leader'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['morning', 'evening'],
    age: 36,
    industry: 'Banking',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-05',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'bcn2',
    name: 'Jean-Marc Fischer',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80',
    city: 'Barcelona',
    sports: ['Golf', 'Tennis', 'Swimming'],
    jobTitle: 'Managing Partner',
    company: 'Deloitte',
    connections: 345,
    matchesPlayed: 67,
    citiesPlayed: ['Barcelona', 'Paris', 'Geneva'],
    achievements: ['Golf Elite', 'Business Network Leader'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['afternoon', 'evening'],
    age: 44,
    industry: 'Consulting',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-02-10',
    points: generateRandomPoints(false, 'pro')
  },
  {
    id: 'bcn3',
    name: 'Laura Schmidt',
    image: 'https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?auto=format&fit=crop&w=300&q=80',
    city: 'Barcelona',
    sports: ['Tennis', 'Running', 'Yoga'],
    jobTitle: 'Head of Strategy',
    company: 'Telefónica',
    connections: 234,
    matchesPlayed: 56,
    citiesPlayed: ['Barcelona', 'Munich', 'Madrid'],
    achievements: ['Marathon Finisher', 'Fitness Ambassador'],
    rating: 4.6,
    skillLevel: 'Advanced',
    availability: ['morning', 'afternoon'],
    age: 34,
    industry: 'Technology',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-15',
    points: generateRandomPoints(false, 'pro')
  },
  // Additional Members
  {
    id: 'sg1',
    name: 'Michael Zhang',
    image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80',
    city: 'Singapore',
    sports: ['Tennis', 'Golf', 'Swimming'],
    jobTitle: 'Head of APAC',
    company: 'Morgan Stanley',
    connections: 389,
    matchesPlayed: 92,
    citiesPlayed: ['Singapore', 'Hong Kong', 'Tokyo', 'Dubai'],
    achievements: ['Tennis Champion', 'Business Leader'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['morning', 'evening'],
    age: 41,
    industry: 'Banking',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-08',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'sg2',
    name: 'Elena Chen',
    image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80',
    city: 'Singapore',
    sports: ['Tennis', 'Yoga', 'Swimming'],
    jobTitle: 'Investment Director',
    company: 'Temasek',
    connections: 276,
    matchesPlayed: 64,
    citiesPlayed: ['Singapore', 'Shanghai', 'Sydney'],
    achievements: ['Wellness Champion', 'Network Builder'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['morning', 'afternoon'],
    age: 35,
    industry: 'Investment',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-12',
    points: generateRandomPoints(false, 'pro')
  },
  {
    id: 'lon1',
    name: 'Alexander Wright',
    image: 'https://images.unsplash.com/photo-1463453091185-61582044d556?auto=format&fit=crop&w=300&q=80',
    city: 'London',
    sports: ['Tennis', 'Golf', 'Running'],
    jobTitle: 'Senior Partner',
    company: 'McKinsey',
    connections: 412,
    matchesPlayed: 88,
    citiesPlayed: ['London', 'Paris', 'New York', 'Dubai'],
    achievements: ['Golf Champion', 'Elite Networker'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['morning', 'evening'],
    age: 43,
    industry: 'Consulting',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-03',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'lon2',
    name: 'Victoria Palmer',
    image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?auto=format&fit=crop&w=300&q=80',
    city: 'London',
    sports: ['Tennis', 'Swimming', 'Yoga'],
    jobTitle: 'Head of M&A',
    company: 'JP Morgan',
    connections: 345,
    matchesPlayed: 76,
    citiesPlayed: ['London', 'Geneva', 'Milan'],
    achievements: ['Tennis Elite', 'Wellness Leader'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['afternoon', 'evening'],
    age: 37,
    industry: 'Banking',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-18',
    points: generateRandomPoints(false, 'pro')
  },
  {
    id: 'dub1',
    name: 'Hassan Al-Rashid',
    image: 'https://images.unsplash.com/photo-1566492031773-4f4e44671857?auto=format&fit=crop&w=300&q=80',
    city: 'Dubai',
    sports: ['Tennis', 'Golf', 'Swimming'],
    jobTitle: 'CEO',
    company: 'Emirates Investment Authority',
    connections: 467,
    matchesPlayed: 95,
    citiesPlayed: ['Dubai', 'Abu Dhabi', 'London', 'Singapore'],
    achievements: ['Business Leader', 'Golf Champion'],
    rating: 4.9,
    skillLevel: 'Professional',
    availability: ['morning', 'evening'],
    age: 45,
    industry: 'Investment',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-05',
    points: generateRandomPoints(false, 'legend')
  },
  {
    id: 'dub2',
    name: 'Sophia Al-Malik',
    image: 'https://images.unsplash.com/photo-1548142813-c348350df52b?auto=format&fit=crop&w=300&q=80',
    city: 'Dubai',
    sports: ['Tennis', 'Yoga', 'Swimming'],
    jobTitle: 'Managing Director',
    company: 'Dubai Holding',
    connections: 356,
    matchesPlayed: 82,
    citiesPlayed: ['Dubai', 'Doha', 'London'],
    achievements: ['Tennis Champion', 'Wellness Ambassador'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['morning', 'afternoon'],
    age: 36,
    industry: 'Investment',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-22',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'par1',
    name: 'Antoine Dubois',
    image: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?auto=format&fit=crop&w=300&q=80',
    city: 'Paris',
    sports: ['Tennis', 'Golf', 'Running'],
    jobTitle: 'Investment Director',
    company: 'AXA Investment Managers',
    connections: 298,
    matchesPlayed: 71,
    citiesPlayed: ['Paris', 'London', 'Geneva'],
    achievements: ['Tennis Elite', 'Marathon Runner'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['morning', 'evening'],
    age: 39,
    industry: 'Investment Management',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-28',
    points: generateRandomPoints(false, 'pro')
  },
  {
    id: 'par2',
    name: 'Claire Laurent',
    image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80',
    city: 'Paris',
    sports: ['Tennis', 'Yoga', 'Swimming'],
    jobTitle: 'Partner',
    company: 'Rothschild & Co',
    connections: 324,
    matchesPlayed: 68,
    citiesPlayed: ['Paris', 'Monaco', 'London'],
    achievements: ['Tennis Champion', 'Wellness Leader'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['afternoon', 'evening'],
    age: 34,
    industry: 'Investment Banking',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-02-08',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'rom1',
    name: 'Marco Rossi',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
    city: 'Rome',
    sports: ['Tennis', 'Golf', 'Swimming'],
    jobTitle: 'Senior Partner',
    company: 'Mediobanca',
    connections: 287,
    matchesPlayed: 74,
    citiesPlayed: ['Rome', 'Milan', 'London'],
    achievements: ['Tennis Elite', 'Business Leader'],
    rating: 4.7,
    skillLevel: 'Professional',
    availability: ['morning', 'evening'],
    age: 42,
    industry: 'Investment Banking',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-01-15',
    points: generateRandomPoints(false, 'elite')
  },
  {
    id: 'rom2',
    name: 'Giulia Conti',
    image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?auto=format&fit=crop&w=300&q=80',
    city: 'Rome',
    sports: ['Tennis', 'Yoga', 'Running'],
    jobTitle: 'Investment Director',
    company: 'Generali Investments',
    connections: 245,
    matchesPlayed: 58,
    citiesPlayed: ['Rome', 'Florence', 'Milan'],
    achievements: ['Wellness Champion', 'Marathon Runner'],
    rating: 4.6,
    skillLevel: 'Advanced',
    availability: ['morning', 'afternoon'],
    age: 33,
    industry: 'Investment Management',
    isConnected: Math.random() > 0.5,
    joinDate: '2024-02-01',
    points: generateRandomPoints(false, 'pro')
  }
];

// Add trainer profiles to members array with enhanced points
const trainerMembers = trainers.map(trainer => ({
  id: `trainer-${trainer.id}`,
  name: trainer.name,
  image: trainer.image,
  city: trainer.city,
  sports: trainer.sports,
  jobTitle: 'Professional Trainer',
  company: 'Elite Sports Academy',
  connections: Math.floor(Math.random() * (500 - 200 + 1)) + 200,
  matchesPlayed: Math.floor(Math.random() * 200) + 100,
  citiesPlayed: [trainer.city, 'Monte Carlo', 'London', 'Paris', 'Dubai', 'Singapore', 'New York'].slice(0, Math.floor(Math.random() * 4) + 4),
  achievements: [...trainer.achievements, 'Elite Trainer Status', 'Global Mentor', 'Performance Excellence'],
  rating: trainer.rating,
  skillLevel: 'Professional',
  availability: trainer.availability,
  age: Math.floor(Math.random() * 15) + 30,
  industry: 'Sports & Fitness',
  isTrainer: true,
  isConnected: Math.random() > 0.5,
  connectionDate: Math.random() > 0.5 ? new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString() : undefined,
  joinDate: '2024-10-01',
  trainerInfo: {
    yearsExperience: trainer.yearsExperience,
    priceRange: trainer.priceRange,
    specialties: trainer.specialties,
    certifications: trainer.certifications,
    languages: trainer.languages,
    reviews: trainer.reviews
  },
  points: generateRandomPoints(true, 'legend')
}));

// Calculate total points for each // Calculate total points for each member
const membersWithTotalPoints = [...regularMembers, ...trainerMembers].map(member => {
  const pointsWithoutTotal = { ...member.points };
  delete pointsWithoutTotal.total;
  return {
    ...member,
    points: {
      ...member.points,
      total: calculatePoints(pointsWithoutTotal)
    }
  };
});

export const members: Member[] = membersWithTotalPoints;