import React from 'react';
import { Trophy, Calendar, MapPin, Users, Clock } from 'lucide-react';

const TournamentPlanner: React.FC = () => {
  const tournaments = [
    {
      id: '1',
      name: 'Elite Tennis Championship',
      date: 'March 15-20, 2024',
      location: 'Monte Carlo Tennis Club',
      participants: 32,
      spotsLeft: 8,
      prize: '€50,000',
      type: 'Singles',
      registrationDeadline: 'March 1, 2024',
      image: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=800&q=80'
    },
    {
      id: '2',
      name: 'Golf Masters Series',
      date: 'April 5-7, 2024',
      location: 'Royal Golf Club',
      participants: 24,
      spotsLeft: 6,
      prize: '€25,000',
      type: 'Individual',
      registrationDeadline: 'March 25, 2024',
      image: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=800&q=80'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {tournaments.map((tournament) => (
          <div
            key={tournament.id}
            className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="relative h-48">
              <img
                src={tournament.image}
                alt={tournament.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-dark-200 to-transparent" />
              <div className="absolute bottom-4 left-4 right-4">
                <h3 className="text-xl font-semibold text-white">{tournament.name}</h3>
                <div className="flex items-center text-dark-800 mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  {tournament.location}
                </div>
              </div>
            </div>

            <div className="p-6">
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="flex items-center text-dark-800">
                  <Calendar className="h-4 w-4 mr-2" />
                  {tournament.date}
                </div>
                <div className="flex items-center text-dark-800">
                  <Users className="h-4 w-4 mr-2" />
                  {tournament.spotsLeft} spots left
                </div>
                <div className="flex items-center text-dark-800">
                  <Trophy className="h-4 w-4 mr-2" />
                  Prize: {tournament.prize}
                </div>
                <div className="flex items-center text-dark-800">
                  <Clock className="h-4 w-4 mr-2" />
                  Register by {tournament.registrationDeadline}
                </div>
              </div>

              <div className="flex items-center justify-between mb-6">
                <div className="flex flex-wrap gap-2">
                  <span className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white">
                    {tournament.type}
                  </span>
                  <span className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white">
                    {tournament.participants} Players
                  </span>
                </div>
              </div>

              <div className="flex space-x-4">
                <button className="flex-1 bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-xl transition-colors">
                  Register Now
                </button>
                <button className="px-6 py-2 border border-dark-400 text-white rounded-xl hover:bg-dark-300 transition-colors">
                  Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TournamentPlanner;