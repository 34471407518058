import React, { useState } from 'react';
import { Heart } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

interface LikeButtonProps {
  postId: string;
  initialLikes: number;
  isLiked: boolean;
  onLike: (postId: string) => Promise<void>;
  likedBy?: Array<{ id: string; name: string }>;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  postId,
  initialLikes,
  isLiked: initialIsLiked,
  onLike,
  likedBy = []
}) => {
  const [isLiked, setIsLiked] = useState(initialIsLiked);
  const [likeCount, setLikeCount] = useState(initialLikes);
  const [isLoading, setIsLoading] = useState(false);

  const handleLike = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const previousState = { isLiked, likeCount };

    // Optimistic update
    setIsLiked(!isLiked);
    setLikeCount(prev => prev + (isLiked ? -1 : 1));

    try {
      await onLike(postId);
    } catch (error) {
      // Revert on failure
      setIsLiked(previousState.isLiked);
      setLikeCount(previousState.likeCount);
      console.error('Failed to like post:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const tooltipContent = () => {
    if (likedBy.length === 0) return 'Be the first to like this';
    if (likedBy.length <= 3) return `Liked by ${likedBy.map(user => user.name).join(', ')}`;
    return `Liked by ${likedBy[0].name} and ${likedBy.length - 1} others`;
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleLike}
              disabled={isLoading}
              className="flex items-center text-dark-800 hover:text-gold-400 transition-colors disabled:opacity-50"
              aria-label={isLiked ? 'Unlike' : 'Like'}
            >
              <div className="w-5 h-5 relative flex items-center justify-center">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={isLiked ? 'liked' : 'unliked'}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    transition={{ 
                      duration: 0.15,
                      ease: [0.4, 0, 0.2, 1]
                    }}
                    style={{ position: 'absolute' }}
                  >
                    <Heart
                      className={`h-5 w-5 transition-colors ${
                        isLiked ? 'fill-gold-400 text-gold-400' : 'fill-transparent'
                      }`}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
              <motion.span
                key={likeCount}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="ml-2"
              >
                {likeCount}
              </motion.span>
            </button>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltipContent()}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default LikeButton;