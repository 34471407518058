import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Star, Calendar, Activity, Globe, Users, Briefcase, ArrowLeft, Award, Target, Clock, TrendingUp, MessageCircle, Check, ChevronRight } from 'lucide-react';
import PostComposer from './PostComposer';
import CitiesPlayedModal from '../members/CitiesPlayedModal';
import MemberConnections from '../members/MemberConnections';
import NetworkTab from '../members/tabs/NetworkTab';
import ActivitiesTab from '../members/tabs/ActivitiesTab';
import TrainingRecommendations from '../performance/TrainingRecommendations';
import PostsTab from '../members/tabs/PostsTab';
import AchievementsTab from '../members/tabs/AchievementsTab';
import PerformanceTracker from '../performance/PerformanceTracker';
import ProfileTabs from '../members/ProfileTabs';
import AboutTab from '../profile/AboutTab';
import { posts } from '../../data/posts';

interface ProfileSectionProps {
  defaultTab?: string;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ defaultTab = 'about' }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'about' | 'posts' | 'network' | 'activities' | 'training' | 'achievements' | 'performance'>(defaultTab as any);
  const [showCitiesModal, setShowCitiesModal] = useState(false);
  const [showConnections, setShowConnections] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (defaultTab) {
      setActiveTab(defaultTab as any);
    }
  }, [defaultTab]);

  const currentUser = {
    id: 'current-user',
    name: 'Uri Schneider',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
    city: 'Valencia',
    jobTitle: 'Co-Founder',
    company: 'Playclub.ai',
    sports: ['Tennis', 'Golf'],
    skillLevel: 'Advanced',
    connections: 324,
    matchesPlayed: 62,
    citiesPlayed: ['Valencia', 'London', 'Paris'],
    achievements: ['Tournament Winner', 'Elite Status'],
    rating: 4.9,
    joinDate: '2024-10-01',
    industry: 'Technology'
  };

  const nextGame = {
    id: 'game-1',
    title: 'Tennis Doubles Match',
    time: '2:00 PM',
    date: 'Tomorrow',
    facility: {
      id: 'facility-1',
      name: 'Monte Carlo Tennis Club',
      address: 'Avenue Princesse Grace, 98000 Monaco',
      coordinates: {
        lat: 43.7384,
        lng: 7.4246
      }
    },
    participants: [
      { id: '51', name: 'Sarah Connor', image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80' },
      { id: '52', name: 'James Wilson', image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80' }
    ],
    notes: 'Pre-match warm-up starts at 1:30 PM'
  };

  const handleTrainerClick = (trainerId: string) => {
    navigate(`/dashboard/members/${trainerId}`);
  };

  const handleParticipantClick = (participantId: string) => {
    navigate(`/dashboard/members/${participantId}`);
  };

  const handleCancelGame = () => {
    setShowCancelConfirm(false);
  };

  const handleBroadcastMessage = () => {
    setShowMessageModal(false);
    setMessage('');
  };

  const userPosts = posts.filter(post => post.author.id === '55');

  return (
    <div className="space-y-6">
      <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
        <div className="relative h-48">
          <img
            src="https://images.unsplash.com/photo-1593368858664-a7fe556ab936?auto=format&fit=crop&w=1600&q=80"
            alt="Valencia cityscape"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/80 to-transparent" />
        </div>

        <div className="relative px-6 pb-6">
          <div className="flex flex-col items-center -mt-16">
            <img
              src={currentUser.image}
              alt={currentUser.name}
              className="w-32 h-32 rounded-full object-cover border-4 border-white"
            />
            <div className="mt-4 text-center">
              <h2 className="text-2xl font-bold text-white">{currentUser.name}</h2>
              <div className="flex items-center justify-center text-dark-800 mt-1">
                <MapPin className="h-4 w-4 mr-1" />
                {currentUser.city}
              </div>
              <div className="flex items-center justify-center text-dark-800 mt-1">
                <Briefcase className="h-4 w-4 mr-1" />
                {currentUser.jobTitle} at {currentUser.company}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
            <div className="bg-dark-300 rounded-xl p-4 text-center">
              <div className="text-2xl font-bold text-gold-400">{currentUser.matchesPlayed}</div>
              <div className="text-dark-800 text-sm mt-1">Matches Played</div>
            </div>
            <button
              onClick={() => setShowCitiesModal(true)}
              className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
            >
              <div className="text-2xl font-bold text-gold-400">{currentUser.citiesPlayed.length}</div>
              <div className="text-dark-800 text-sm mt-1">Cities Played</div>
            </button>
            <button
              onClick={() => setActiveTab('network')}
              className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
            >
              <div className="text-2xl font-bold text-gold-400">{currentUser.connections}</div>
              <div className="text-dark-800 text-sm mt-1">Connections</div>
            </button>
            <div className="bg-dark-300 rounded-xl p-4 text-center">
              <div className="text-2xl font-bold text-gold-400">{currentUser.rating}</div>
              <div className="text-dark-800 text-sm mt-1">Rating</div>
            </div>
          </div>
        </div>
      </div>

      <ProfileTabs activeTab={activeTab} onTabChange={setActiveTab} />

      {activeTab === 'about' && <AboutTab />}

      {activeTab === 'posts' && (
        <div className="space-y-6">
          <PostComposer onPost={() => {}} />
          <PostsTab posts={userPosts} />
        </div>
      )}

      {activeTab === 'network' && <NetworkTab />}

      {activeTab === 'activities' && (
        <ActivitiesTab activities={[]} isOwnProfile={true} />
      )}

      {activeTab === 'training' && <TrainingRecommendations />}

      {activeTab === 'achievements' && (
        <AchievementsTab userId={currentUser.id} />
      )}

      {activeTab === 'performance' && <PerformanceTracker />}

      {showCitiesModal && (
        <CitiesPlayedModal
          isOpen={true}
          cities={currentUser.citiesPlayed}
          memberName={currentUser.name}
          onClose={() => setShowCitiesModal(false)}
        />
      )}

      {showConnections && (
        <MemberConnections
          memberId={currentUser.id}
          onClose={() => setShowConnections(false)}
        />
      )}

      {showCancelConfirm && (
        <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center">
          <div className="bg-dark-200 rounded-xl p-6 max-w-md w-full mx-4">
            <h3 className="text-xl font-semibold text-white mb-4">Cancel Game</h3>
            <p className="text-dark-800 mb-6">
              Are you sure you want to cancel this game? All participants will be notified.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowCancelConfirm(false)}
                className="px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-lg transition-colors"
              >
                Keep Game
              </button>
              <button
                onClick={handleCancelGame}
                className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors"
              >
                Cancel Game
              </button>
            </div>
          </div>
        </div>
      )}

      {showMessageModal && (
        <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center">
          <div className="bg-dark-200 rounded-xl p-6 max-w-md w-full mx-4">
            <h3 className="text-xl font-semibold text-white mb-4">Message All Participants</h3>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              className="w-full h-32 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white placeholder-dark-800 resize-none mb-6"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowMessageModal(false)}
                className="px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleBroadcastMessage}
                disabled={!message.trim()}
                className="px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSection;