import React from 'react';
import { Bell } from 'lucide-react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useNotificationStore } from '../../store/notificationStore';
import NotificationItem from './NotificationItem';
import { cn } from '@/lib/utils';

const NotificationsDropdown: React.FC = () => {
  const { notifications, unreadCount, markAllAsRead } = useNotificationStore();
  const [open, setOpen] = React.useState(false);

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <button 
          className={cn(
            "relative p-1.5 rounded-full transition-colors",
            "dark:hover:bg-dark-400 dark:text-white",
            "light:hover:bg-gray-100 light:text-gray-900"
          )}
        >
          <Bell className="h-5 w-5" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 w-5 h-5 bg-gold-400 rounded-full text-dark-50 text-xs flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          sideOffset={5}
          className={cn(
            "w-80 sm:w-96 z-50",
            "bg-white dark:bg-dark-200",
            "border border-gray-200 dark:border-dark-300",
            "rounded-xl shadow-lg",
            "animate-in fade-in-0 zoom-in-95",
            "data-[side=bottom]:slide-in-from-top-2",
            "data-[side=left]:slide-in-from-right-2",
            "data-[side=right]:slide-in-from-left-2",
            "data-[side=top]:slide-in-from-bottom-2"
          )}
        >
          <div className="p-4 border-b border-gray-200 dark:border-dark-300">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Notifications
              </h3>
              {unreadCount > 0 && (
                <button
                  onClick={markAllAsRead}
                  className="text-sm text-gold-400 hover:text-gold-500"
                >
                  Mark all as read
                </button>
              )}
            </div>
          </div>

          <div className="max-h-[60vh] overflow-y-auto">
            {notifications.length > 0 ? (
              <div className="divide-y divide-gray-200 dark:divide-dark-300">
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onClose={() => setOpen(false)}
                  />
                ))}
              </div>
            ) : (
              <div className="py-8 text-center text-gray-500 dark:text-dark-800">
                No notifications yet
              </div>
            )}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default NotificationsDropdown;