import React, { useState } from 'react';
import { MapPin, Navigation2, Globe, Apple, Car } from 'lucide-react';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '../ui/dialog';

interface LocationActionsProps {
  venueName: string;
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

const LocationActions: React.FC<LocationActionsProps> = ({ venueName, address, coordinates }) => {
  const [showNavigationModal, setShowNavigationModal] = useState(false);

  const handleOpenMap = () => {
    window.open(`https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`, '_blank');
  };

  const navigationApps = [
    {
      name: 'Google Maps',
      url: `https://www.google.com/maps/dir/?api=1&destination=${coordinates.lat},${coordinates.lng}&destination_place_id=${encodeURIComponent(venueName)}`,
      icon: Globe
    },
    {
      name: 'Apple Maps',
      url: `maps://maps.apple.com/?daddr=${coordinates.lat},${coordinates.lng}&dname=${encodeURIComponent(venueName)}`,
      icon: Apple
    },
    {
      name: 'Waze',
      url: `https://www.waze.com/ul?ll=${coordinates.lat},${coordinates.lng}&navigate=yes&zoom=17`,
      icon: Car
    }
  ];

  return (
    <>
      <div className="flex items-center space-x-3">
        <Button
          onClick={handleOpenMap}
          variant="outline"
          className="flex-1 bg-dark-300 hover:bg-dark-400 text-white border-dark-400"
        >
          <MapPin className="h-4 w-4 mr-2 text-gold-400" />
          View on Map
        </Button>
        <Button
          onClick={() => setShowNavigationModal(true)}
          variant="outline"
          className="flex-1 bg-dark-300 hover:bg-dark-400 text-white border-dark-400"
        >
          <Navigation2 className="h-4 w-4 mr-2 text-gold-400" />
          Navigate
        </Button>
      </div>

      <Dialog open={showNavigationModal} onOpenChange={setShowNavigationModal}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle>Choose Navigation App</DialogTitle>
            <DialogDescription className="text-dark-800">
              Navigate to {venueName}
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-3 py-4">
            {navigationApps.map((app) => (
              <a
                key={app.name}
                href={app.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center space-x-3 w-full px-4 py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
              >
                <app.icon className="h-5 w-5 text-gold-400" />
                <span>{app.name}</span>
              </a>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LocationActions;