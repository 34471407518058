import React, { useState } from 'react';
import { MoreHorizontal, Reply, Trash2, Edit2, Check } from 'lucide-react';
import type { Message } from '../../types/chat';

interface MessageGroupProps {
  date: string;
  messages: Message[];
  onDelete: (messageId: string) => void;
  onEdit: (messageId: string, newContent: string) => void;
  onReply: (message: Message) => void;
  editingId: string | null;
}

const MessageGroup: React.FC<MessageGroupProps> = ({
  date,
  messages,
  onDelete,
  onEdit,
  onReply,
  editingId
}) => {
  const [editContent, setEditContent] = useState('');
  const [showActions, setShowActions] = useState<string | null>(null);

  const handleStartEdit = (message: Message) => {
    setEditContent(message.content);
  };

  const handleSaveEdit = (messageId: string) => {
    if (editContent.trim()) {
      onEdit(messageId, editContent);
    }
  };

  const getStatusIcon = (status?: 'sent' | 'delivered' | 'read') => {
    switch (status) {
      case 'sent':
        return <Check className="h-3 w-3 text-dark-800" />;
      case 'delivered':
        return (
          <div className="flex">
            <Check className="h-3 w-3 text-dark-800" />
            <Check className="h-3 w-3 text-dark-800 -ml-1" />
          </div>
        );
      case 'read':
        return (
          <div className="flex">
            <Check className="h-3 w-3 text-blue-400" />
            <Check className="h-3 w-3 text-blue-400 -ml-1" />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-center">
        <div className="px-3 py-1 bg-dark-300 rounded-full text-xs text-dark-800">
          {date}
        </div>
      </div>

      {messages.map((message) => (
        <div
          key={message.id}
          className={`flex ${message.isSender ? 'justify-end' : 'justify-start'}`}
        >
          <div className="group relative max-w-[85%] sm:max-w-[70%] break-words">
            {message.replyTo && (
              <div className={`mb-2 p-2 rounded-lg text-sm ${
                message.isSender ? 'bg-dark-400 text-white' : 'bg-dark-400 text-white'
              }`}>
                <div className="flex items-center space-x-2 text-dark-800 mb-1">
                  <Reply className="h-3 w-3" />
                  <span>Reply to</span>
                </div>
                <div className="pl-5 break-words">{message.replyTo.content}</div>
              </div>
            )}

            <div
              className={`rounded-2xl px-4 py-2 ${
                message.isSender
                  ? 'bg-gold-400 text-dark-50'
                  : 'bg-dark-300 text-white'
              }`}
            >
              {editingId === message.id ? (
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    className="flex-1 bg-dark-400 rounded-lg px-2 py-1 text-white"
                    autoFocus
                  />
                  <button
                    onClick={() => handleSaveEdit(message.id)}
                    className="p-1 hover:bg-dark-500 rounded-lg"
                  >
                    <Check className="h-4 w-4" />
                  </button>
                </div>
              ) : (
                <>
                  <p className="break-words whitespace-pre-wrap">{message.content}</p>
                  {message.image && (
                    <img
                      src={message.image}
                      alt="Message attachment"
                      className="mt-2 rounded-lg max-w-full"
                    />
                  )}
                </>
              )}
              <div className="flex items-center justify-end space-x-2 mt-1">
                <span className="text-xs opacity-75">{message.time}</span>
                {message.isSender && getStatusIcon(message.status)}
              </div>
            </div>

            <div className={`absolute ${message.isSender ? 'left-0' : 'right-0'} top-0 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity`}>
              <div className="flex items-center space-x-1 bg-dark-300 rounded-lg p-1">
                <button
                  onClick={() => onReply(message)}
                  className="p-1 hover:bg-dark-400 rounded-lg text-dark-800 hover:text-white"
                >
                  <Reply className="h-4 w-4" />
                </button>
                {message.isSender && (
                  <>
                    <button
                      onClick={() => {
                        handleStartEdit(message);
                        onEdit(message.id, message.content);
                      }}
                      className="p-1 hover:bg-dark-400 rounded-lg text-dark-800 hover:text-white"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => onDelete(message.id)}
                      className="p-1 hover:bg-dark-400 rounded-lg text-dark-800 hover:text-red-400"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessageGroup;