import React, { useState, useEffect, useRef } from 'react';
import { Send, MoreVertical, Search, Globe, ArrowLeft, Image, Smile, Reply, Trash2, Edit2, Check, X, Flag, UserMinus } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { conversations, messages as initialMessages } from '../../data/conversations';
import { members } from '../../data/members';
import type { Message, Conversation } from '../../types/chat';
import MessageGroup from './MessageGroup';
import EmojiPicker from './EmojiPicker';
import { Button } from '../ui/button';

const ChatSystem: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [activeChat, setActiveChat] = useState<string | null>(null);
  const [showMobileList, setShowMobileList] = useState(true);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [editingMessage, setEditingMessage] = useState<string | null>(null);
  const [replyingTo, setReplyingTo] = useState<Message | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const state = location.state as { activeChat?: string; initialMessage?: string; showMobileList?: boolean };
    if (state?.activeChat) {
      setActiveChat(state.activeChat);
      setShowMobileList(false);
      if (state.initialMessage) {
        setMessage(state.initialMessage);
      }

      if (!conversations.find(c => c.id === state.activeChat)) {
        const member = members.find(m => m.id === state.activeChat);
        if (member) {
          conversations.unshift({
            id: member.id,
            name: member.name,
            image: member.image,
            location: member.city,
            lastMessage: '',
            time: 'Now',
            unread: 0,
            online: true
          });
          initialMessages[member.id] = [];
        }
      }
    }
  }, [location.state]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (activeChat) {
      const currentMessages = initialMessages[activeChat] || [];
      setChatMessages(currentMessages);
      setTimeout(scrollToBottom, 100);
    }
  }, [activeChat]);

  const activeConversation = conversations.find(c => c.id === activeChat);

  const handleBlockUser = () => {
    setShowMenu(false);
  };

  const handleReportUser = () => {
    setShowMenu(false);
  };

  const handleClearChat = () => {
    setChatMessages([]);
    setShowMenu(false);
  };

  const handleSendMessage = () => {
    if (!message.trim() && !selectedImage) return;
    
    const newMessage: Message = {
      id: Date.now().toString(),
      content: message.trim(),
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      isSender: true,
      status: 'sent',
      replyTo: replyingTo,
      image: imagePreview
    };

    setChatMessages(prev => [...prev, newMessage]);
    if (activeConversation) {
      activeConversation.lastMessage = message.trim();
      activeConversation.time = 'Just now';
    }

    setMessage('');
    setSelectedImage(null);
    setImagePreview(null);
    setReplyingTo(null);
    setTimeout(scrollToBottom, 100);

    setTimeout(() => {
      setChatMessages(prev => 
        prev.map(msg => 
          msg.id === newMessage.id 
            ? { ...msg, status: 'delivered' } 
            : msg
        )
      );
    }, 1000);

    setTimeout(() => {
      setChatMessages(prev => 
        prev.map(msg => 
          msg.id === newMessage.id 
            ? { ...msg, status: 'read' } 
            : msg
        )
      );
    }, 2000);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteMessage = (messageId: string) => {
    setChatMessages(prev => prev.filter(msg => msg.id !== messageId));
  };

  const handleEditMessage = (messageId: string, newContent: string) => {
    setChatMessages(prev => 
      prev.map(msg => 
        msg.id === messageId 
          ? { ...msg, content: newContent, edited: true } 
          : msg
      )
    );
    setEditingMessage(null);
  };

  const filteredConversations = conversations.filter(conv =>
    conv.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    conv.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupMessagesByDate = (messages: Message[]) => {
    const groups: { [key: string]: Message[] } = {};
    
    messages.forEach(message => {
      const date = new Date().toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
    });

    return groups;
  };

  const messageGroups = groupMessagesByDate(chatMessages);

  const handleBackToList = () => {
    setShowMobileList(true);
    setActiveChat(null);
  };

  return (
    <div className="h-[calc(100vh-13rem)] flex flex-col bg-dark-200 rounded-2xl border border-dark-300">
      <div className="flex h-full">
        {/* Conversations List */}
        <div className={`w-full md:w-96 border-r border-dark-300 flex flex-col ${
          !showMobileList ? 'hidden md:flex' : ''
        }`}>
          <div className="p-4 border-b border-dark-300">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
              <input
                type="text"
                placeholder="Search conversations..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
              />
            </div>
          </div>
          <div className="flex-1 overflow-y-auto">
            {filteredConversations.map((conversation) => (
              <div
                key={conversation.id}
                onClick={() => {
                  setActiveChat(conversation.id);
                  setShowMobileList(false);
                }}
                className={`w-full p-4 hover:bg-dark-300 cursor-pointer transition-colors border-b border-dark-300 text-left ${
                  activeChat === conversation.id ? 'bg-dark-300' : ''
                }`}
              >
                <div className="flex items-center space-x-3">
                  <div className="relative flex-shrink-0">
                    <img
                      src={conversation.image}
                      alt={conversation.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    {conversation.online && (
                      <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-400 rounded-full border-2 border-dark-200" />
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <h4 className="text-base font-medium text-white truncate">{conversation.name}</h4>
                      <span className="text-dark-800 text-xs">{conversation.time}</span>
                    </div>
                    <div className="flex items-center text-dark-800 text-sm">
                      <Globe className="h-4 w-4 mr-1" />
                      <span className="truncate">{conversation.location}</span>
                    </div>
                    <p className="text-dark-800 text-sm truncate">{conversation.lastMessage}</p>
                  </div>
                  {conversation.unread > 0 && (
                    <div className="bg-gold-400 text-dark-50 text-xs rounded-full w-5 h-5 flex items-center justify-center">
                      {conversation.unread}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Chat Area */}
        <div className={`flex-1 flex flex-col h-full ${
          showMobileList ? 'hidden md:flex' : ''
        }`}>
          {activeConversation ? (
            <>
              {/* Chat Header */}
              <div className="p-4 border-b border-dark-300 flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <button
                    onClick={handleBackToList}
                    className="md:hidden text-dark-800 hover:text-white p-2 -ml-2"
                  >
                    <ArrowLeft className="h-5 w-5" />
                  </button>
                  <img
                    src={activeConversation.image}
                    alt={activeConversation.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div>
                    <h4 className="text-base font-medium text-white">{activeConversation.name}</h4>
                    <div className="flex items-center text-dark-800 text-sm">
                      <Globe className="h-4 w-4 mr-1" />
                      {activeConversation.location}
                      {activeConversation.online && (
                        <span className="ml-2 text-green-400">● Online</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <button 
                    onClick={() => setShowMenu(!showMenu)}
                    className="text-dark-800 hover:text-gold-400 p-2"
                  >
                    <MoreVertical className="h-5 w-5" />
                  </button>
                  {showMenu && (
                    <div className="absolute right-0 mt-2 w-48 bg-dark-300 rounded-xl border border-dark-400 shadow-lg z-50">
                      <div className="py-1">
                        <button
                          onClick={handleBlockUser}
                          className="flex items-center w-full px-4 py-2 text-white hover:bg-dark-400"
                        >
                          <UserMinus className="h-4 w-4 mr-2" />
                          Block User
                        </button>
                        <button
                          onClick={handleReportUser}
                          className="flex items-center w-full px-4 py-2 text-white hover:bg-dark-400"
                        >
                          <Flag className="h-4 w-4 mr-2" />
                          Report User
                        </button>
                        <button
                          onClick={handleClearChat}
                          className="flex items-center w-full px-4 py-2 text-white hover:bg-dark-400"
                        >
                          <Trash2 className="h-4 w-4 mr-2" />
                          Clear Chat
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Messages */}
              <div 
                ref={messagesContainerRef}
                className="flex-1 overflow-y-auto p-4 space-y-6"
              >
                {Object.entries(messageGroups).map(([date, messages]) => (
                  <MessageGroup
                    key={date}
                    date={date}
                    messages={messages}
                    onDelete={handleDeleteMessage}
                    onEdit={handleEditMessage}
                    onReply={(message) => setReplyingTo(message)}
                    editingId={editingMessage}
                  />
                ))}
                <div ref={messagesEndRef} />
                {isTyping && (
                  <div className="flex items-center space-x-2 text-dark-800">
                    <div className="flex space-x-1">
                      <div className="w-2 h-2 bg-dark-800 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                      <div className="w-2 h-2 bg-dark-800 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                      <div className="w-2 h-2 bg-dark-800 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                    </div>
                    <span>{activeConversation.name} is typing...</span>
                  </div>
                )}
              </div>

              {/* Reply Preview */}
              {replyingTo && (
                <div className="px-4 py-2 bg-dark-300 border-t border-dark-400 flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Reply className="h-4 w-4 text-dark-800" />
                    <span className="text-dark-800">Replying to:</span>
                    <span className="text-white">{replyingTo.content}</span>
                  </div>
                  <button
                    onClick={() => setReplyingTo(null)}
                    className="text-dark-800 hover:text-white"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              )}

              {/* Image Preview */}
              {imagePreview && (
                <div className="px-4 py-2 bg-dark-300 border-t border-dark-400">
                  <div className="relative inline-block">
                    <img
                      src={imagePreview}
                      alt="Upload preview"
                      className="h-20 rounded-lg"
                    />
                    <button
                      onClick={() => {
                        setSelectedImage(null);
                        setImagePreview(null);
                      }}
                      className="absolute -top-2 -right-2 p-1 bg-dark-900/80 rounded-full hover:bg-dark-900"
                    >
                      <X className="h-4 w-4 text-white" />
                    </button>
                  </div>
                </div>
              )}

              {/* Message Input */}
              <div className="p-4 border-t border-dark-300">
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2">
                    <Button
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      variant="ghost"
                      size="icon"
                      className="text-dark-800 hover:text-gold-400"
                    >
                      <Smile className="h-5 w-5" />
                    </Button>
                    <Button
                      onClick={() => fileInputRef.current?.click()}
                      variant="ghost"
                      size="icon"
                      className="text-dark-800 hover:text-gold-400"
                    >
                      <Image className="h-5 w-5" />
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                      />
                    </Button>
                  </div>
                  <div className="flex-1 min-w-0">
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={handleKeyPress}
                      placeholder="Type a message..."
                      className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white placeholder-dark-800"
                    />
                  </div>
                  <Button
                    onClick={handleSendMessage}
                    disabled={!message.trim() && !selectedImage}
                    className="bg-gold-400 hover:bg-gold-500 text-dark-50 p-2 rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex-shrink-0"
                  >
                    <Send className="h-5 w-5" />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center text-dark-800">
              Select a conversation to start chatting
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatSystem;