import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import EventsDashboard from '../components/events/EventsDashboard';

const Events = () => {
  return (
    <DashboardLayout>
      <EventsDashboard />
    </DashboardLayout>
  );
};

export default Events;