import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Star, Trophy, Users, Search, Filter, Briefcase, Clock, Award } from 'lucide-react';
import DashboardLayout from '../components/DashboardLayout';
import { members } from '../data/members';
import { getCurrentTitle } from '../data/points';

const Leaderboard: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'members' | 'trainers'>('members');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');

  const sports = useMemo(() => 
    ['all', ...new Set(members.flatMap(member => member.sports))],
    []
  );

  const cities = useMemo(() => 
    ['all', ...new Set(members.map(member => member.city))],
    []
  );

  const calculateTotalPoints = (points: Record<string, number>) => {
    return Object.entries(points).reduce((total, [key, value]) => {
      if (key !== 'total' && typeof value === 'number') {
        return total + value;
      }
      return total;
    }, 0);
  };

  const rankedMembers = useMemo(() => {
    return members
      .filter(member => !member.isTrainer)
      .map(member => ({
        ...member,
        totalPoints: calculateTotalPoints(member.points)
      }))
      .sort((a, b) => b.totalPoints - a.totalPoints)
      .map((member, index) => ({
        ...member,
        rank: index + 1
      }));
  }, []);

  const rankedTrainers = useMemo(() => {
    return members
      .filter(member => member.isTrainer && member.trainerInfo)
      .map(trainer => ({
        ...trainer,
        totalClients: Math.floor(Math.random() * 100) + 50, // In a real app, this would come from the API
        totalHours: Math.floor(Math.random() * 1000) + 200 // In a real app, this would come from the API
      }))
      .sort((a, b) => b.rating - a.rating)
      .map((trainer, index) => ({
        ...trainer,
        rank: index + 1
      }));
  }, []);

  const filteredMembers = useMemo(() => 
    rankedMembers.filter(member => {
      const matchesSearch = member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          member.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          member.jobTitle.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesSport = selectedSport === 'all' || member.sports.includes(selectedSport);
      const matchesCity = selectedCity === 'all' || member.city === selectedCity;
      
      return matchesSearch && matchesSport && matchesCity;
    }),
    [searchTerm, selectedSport, selectedCity, rankedMembers]
  );

  const filteredTrainers = useMemo(() => 
    rankedTrainers.filter(trainer => {
      const matchesSearch = trainer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          trainer.city.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesSport = selectedSport === 'all' || trainer.sports.includes(selectedSport);
      const matchesCity = selectedCity === 'all' || trainer.city === selectedCity;
      
      return matchesSearch && matchesSport && matchesCity;
    }),
    [searchTerm, selectedSport, selectedCity, rankedTrainers]
  );

  const handleProfileClick = (memberId: string) => {
    navigate(`/dashboard/members/${memberId}`);
  };

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-bold text-white">Global Rankings</h1>
              <p className="text-dark-800 mt-1">Track performance and achievements across the network</p>
            </div>
          </div>

          {/* Tabs */}
          <div className="flex space-x-4 mt-6 border-b border-dark-300">
            <button
              onClick={() => setActiveTab('members')}
              className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
                activeTab === 'members'
                  ? 'border-gold-400 text-gold-400'
                  : 'border-transparent text-dark-800 hover:text-white'
              }`}
            >
              Members
            </button>
            <button
              onClick={() => setActiveTab('trainers')}
              className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
                activeTab === 'trainers'
                  ? 'border-gold-400 text-gold-400'
                  : 'border-transparent text-dark-800 hover:text-white'
              }`}
            >
              Trainers
            </button>
          </div>
        </div>

        {/* Search and Filters */}
        <div className="bg-dark-200 rounded-2xl p-4 sm:p-6 border border-dark-300">
          <div className="flex flex-col space-y-4">
            {/* Search Bar */}
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
              <input
                type="text"
                placeholder={`Search ${activeTab}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
              />
            </div>

            {/* Filters */}
            <div className="flex flex-col sm:flex-row gap-4">
              <select
                value={selectedSport}
                onChange={(e) => setSelectedSport(e.target.value)}
                className="flex-1 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
              >
                <option value="all">All Sports</option>
                {sports.filter(sport => sport !== 'all').map((sport) => (
                  <option key={sport} value={sport}>{sport}</option>
                ))}
              </select>
              <select
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                className="flex-1 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
              >
                <option value="all">All Cities</option>
                {cities.filter(city => city !== 'all').map((city) => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Results Count */}
        <div className="text-dark-800">
          Found {activeTab === 'members' ? filteredMembers.length : filteredTrainers.length} {activeTab}
        </div>

        {/* List */}
        <div className="space-y-4">
          {activeTab === 'members' ? (
            // Members List
            filteredMembers.map((member) => {
              const title = getCurrentTitle(member.totalPoints);
              return (
                <div
                  key={member.id}
                  className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
                >
                  <div className="flex items-start space-x-4">
                    <div className="hidden sm:block flex-shrink-0 w-12 text-center">
                      <div className={`text-xl font-bold ${
                        member.rank === 1 ? 'text-gold-400' :
                        member.rank === 2 ? 'text-gray-400' :
                        member.rank === 3 ? 'text-bronze-400' :
                        'text-dark-800'
                      }`}>
                        #{member.rank}
                      </div>
                    </div>

                    <div className="relative flex-shrink-0">
                      <button
                        onClick={() => handleProfileClick(member.id)}
                        className="relative"
                      >
                        <img
                          src={member.image}
                          alt={member.name}
                          className="w-16 h-16 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-colors"
                        />
                        <div className="sm:hidden absolute -top-2 -left-2 w-6 h-6 rounded-full bg-dark-200 border border-dark-300 flex items-center justify-center">
                          <span className={`text-xs font-bold ${
                            member.rank === 1 ? 'text-gold-400' :
                            member.rank === 2 ? 'text-gray-400' :
                            member.rank === 3 ? 'text-bronze-400' :
                            'text-dark-800'
                          }`}>
                            #{member.rank}
                          </span>
                        </div>
                      </button>
                    </div>

                    <div className="flex-1 min-w-0">
                      <div className="flex flex-col sm:flex-row sm:items-start justify-between">
                        <div className="min-w-0">
                          <button
                            onClick={() => handleProfileClick(member.id)}
                            className="text-white font-medium hover:text-gold-400 transition-colors block text-left truncate max-w-full"
                          >
                            {member.name}
                          </button>
                          <div className="flex items-center text-dark-800 mt-1 space-x-1">
                            <MapPin className="h-4 w-4 flex-shrink-0" />
                            <span className="truncate">{member.city}</span>
                          </div>
                          <div className="flex flex-col text-dark-800 mt-1">
                            <div className="text-sm text-dark-800 dark:text-gold-400">{member.jobTitle}</div>
                            <div className="text-sm text-dark-800 dark:text-gold-400">@{member.company}</div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start sm:items-end mt-2 sm:mt-0 sm:ml-4">
                          <div className="flex items-center">
                            <span className={`text-xl ${title.color}`}>{title.icon}</span>
                            <span className="text-white ml-2">{title.name}</span>
                          </div>
                          <div className="font-medium text-dark-800 dark:text-gold-400">
                            {member.totalPoints.toLocaleString()} points
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-2 sm:gap-4 mt-4">
                        <div className="flex items-center text-dark-800 min-w-0">
                          <Trophy className="h-4 w-4 mr-2 flex-shrink-0" />
                          <span className="truncate">{member.matchesPlayed} matches</span>
                        </div>
                        <div className="flex items-center text-dark-800 min-w-0">
                          <Users className="h-4 w-4 mr-2 flex-shrink-0" />
                          <span className="truncate">{member.connections} connections</span>
                        </div>
                        <div className="flex items-center text-dark-800 min-w-0">
                          <Star className="h-4 w-4 mr-2 flex-shrink-0" />
                          <span className="truncate">{member.rating} rating</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            // Trainers List
            filteredTrainers.map((trainer) => (
              <div
                key={trainer.id}
                className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
              >
                <div className="flex items-start space-x-4">
                  <div className="hidden sm:block flex-shrink-0 w-12 text-center">
                    <div className={`text-xl font-bold ${
                      trainer.rank === 1 ? 'text-gold-400' :
                      trainer.rank === 2 ? 'text-gray-400' :
                      trainer.rank === 3 ? 'text-bronze-400' :
                      'text-dark-800'
                    }`}>
                      #{trainer.rank}
                    </div>
                  </div>

                  <div className="relative flex-shrink-0">
                    <button
                      onClick={() => handleProfileClick(trainer.id)}
                      className="relative"
                    >
                      <img
                        src={trainer.image}
                        alt={trainer.name}
                        className="w-16 h-16 rounded-full object-cover border-2 border-gold-400 hover:border-gold-500 transition-colors"
                      />
                      <div className="sm:hidden absolute -top-2 -left-2 w-6 h-6 rounded-full bg-dark-200 border border-dark-300 flex items-center justify-center">
                        <span className={`text-xs font-bold ${
                          trainer.rank === 1 ? 'text-gold-400' :
                          trainer.rank === 2 ? 'text-gray-400' :
                          trainer.rank === 3 ? 'text-bronze-400' :
                          'text-dark-800'
                        }`}>
                          #{trainer.rank}
                        </span>
                      </div>
                    </button>
                  </div>

                  <div className="flex-1 min-w-0">
                    <div className="flex flex-col sm:flex-row sm:items-start justify-between">
                      <div className="min-w-0">
                        <button
                          onClick={() => handleProfileClick(trainer.id)}
                          className="text-white font-medium hover:text-gold-400 transition-colors block text-left truncate max-w-full"
                        >
                          {trainer.name}
                        </button>
                        <div className="flex items-center text-dark-800 mt-1 space-x-1">
                          <MapPin className="h-4 w-4 flex-shrink-0" />
                          <span className="truncate">{trainer.city}</span>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-2">
                          {trainer.sports.map((sport, index) => (
                            <span
                              key={index}
                              className="px-2 py-1 bg-dark-300 rounded-full text-xs text-white"
                            >
                              {sport}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col items-start sm:items-end mt-2 sm:mt-0 sm:ml-4">
                        <div className="flex items-center">
                          <Star className="h-4 w-4 text-gold-400 mr-1" />
                          <span className="text-white">{trainer.rating}</span>
                          <span className="text-dark-800 ml-1">({trainer.trainerInfo?.reviews} reviews)</span>
                        </div>
                        <div className="text-dark-800">
                          {trainer.trainerInfo?.yearsExperience} years experience
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-3 gap-2 sm:gap-4 mt-4">
                      <div className="flex items-center text-dark-800 min-w-0">
                        <Users className="h-4 w-4 mr-2 flex-shrink-0" />
                        <span className="truncate">{trainer.totalClients} clients</span>
                      </div>
                      <div className="flex items-center text-dark-800 min-w-0">
                        <Clock className="h-4 w-4 mr-2 flex-shrink-0" />
                        <span className="truncate">{trainer.totalHours}h trained</span>
                      </div>
                      <div className="flex items-center text-dark-800 min-w-0">
                        <Award className="h-4 w-4 mr-2 flex-shrink-0" />
                        <span className="truncate">{trainer.trainerInfo?.certifications.length} certs</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Leaderboard;