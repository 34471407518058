import React from 'react';
import { MapPin, Trophy } from 'lucide-react';
import { PostAuthor } from '../../types/post';

interface PostHeaderProps {
  author: PostAuthor;
  timeAgo: string;
  achievement?: string;
  onAuthorClick: () => void;
}

const PostHeader: React.FC<PostHeaderProps> = ({
  author,
  timeAgo,
  achievement,
  onAuthorClick
}) => {
  return (
    <div className="flex items-start space-x-4 mb-4">
      <button
        onClick={onAuthorClick}
        className="flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-gold-400 rounded-full"
      >
        <img
          src={author.image}
          alt={author.name}
          className="w-12 h-12 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-colors"
        />
      </button>
      
      <div className="flex-1">
        <div className="flex items-start justify-between">
          <div>
            <button
              onClick={onAuthorClick}
              className="text-white font-medium hover:text-gold-400 transition-colors text-left"
            >
              {author.name}
            </button>
            <div className="flex items-center text-dark-800 text-sm">
              <MapPin className="h-4 w-4 mr-1" />
              {author.location}
            </div>
          </div>
          <span className="text-dark-800 text-sm">{timeAgo}</span>
        </div>

        {achievement && (
          <div className="flex items-center space-x-2 mt-2">
            <Trophy className="h-4 w-4 text-gold-400" />
            <span className="text-gold-400 text-sm">{achievement}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostHeader;