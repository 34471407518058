import React from 'react';
import { Map, Navigation } from 'lucide-react';

interface LocationMapProps {
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

const LocationMap: React.FC<LocationMapProps> = ({ address, coordinates }) => {
  const handleOpenMap = () => {
    window.open(`https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`, '_blank');
  };

  const handleNavigate = () => {
    // Create navigation options menu
    const navigationApps = [
      {
        name: 'Google Maps',
        url: `https://www.google.com/maps/dir/?api=1&destination=${coordinates.lat},${coordinates.lng}`
      },
      {
        name: 'Waze',
        url: `https://www.waze.com/ul?ll=${coordinates.lat},${coordinates.lng}&navigate=yes`
      },
      {
        name: 'Apple Maps',
        url: `maps://maps.apple.com/?daddr=${coordinates.lat},${coordinates.lng}`
      }
    ];

    // Create and show a modal with navigation options
    const modal = document.createElement('div');
    modal.className = 'fixed inset-0 z-50 flex items-center justify-center bg-dark-900/80 backdrop-blur-sm';
    modal.innerHTML = `
      <div class="bg-dark-200 rounded-2xl p-6 border border-dark-300 max-w-sm w-full mx-4">
        <h3 class="text-lg font-semibold text-white mb-4">Choose Navigation App</h3>
        <div class="space-y-2">
          ${navigationApps.map(app => `
            <a href="${app.url}" target="_blank" rel="noopener noreferrer" 
               class="block w-full px-4 py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors text-center">
              ${app.name}
            </a>
          `).join('')}
        </div>
        <button class="w-full mt-4 px-4 py-3 bg-dark-400 hover:bg-dark-500 text-dark-800 rounded-xl transition-colors">
          Cancel
        </button>
      </div>
    `;

    // Add event listeners
    modal.querySelector('button')?.addEventListener('click', () => {
      document.body.removeChild(modal);
    });

    document.body.appendChild(modal);
  };

  return (
    <div className="space-y-3">
      <div className="bg-dark-300 rounded-xl p-4">
        <p className="text-dark-800 text-sm mb-3">Address:</p>
        <p className="text-white">{address}</p>
      </div>
      
      <div className="grid grid-cols-2 gap-3">
        <button
          onClick={handleOpenMap}
          className="flex items-center justify-center space-x-2 px-4 py-3 bg-dark-300 hover:bg-dark-400 rounded-xl transition-colors"
        >
          <Map className="h-5 w-5 text-gold-400" />
          <span className="text-white">View Map</span>
        </button>
        
        <button
          onClick={handleNavigate}
          className="flex items-center justify-center space-x-2 px-4 py-3 bg-dark-300 hover:bg-dark-400 rounded-xl transition-colors"
        >
          <Navigation className="h-5 w-5 text-gold-400" />
          <span className="text-white">Navigate</span>
        </button>
      </div>
    </div>
  );
};

export default LocationMap;