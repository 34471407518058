import React, { useState } from 'react';
import { Switch } from '../../ui/switch';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import { cn } from '@/lib/utils';

const PrivacySettings: React.FC = () => {
  const [settings, setSettings] = useState({
    profileVisibility: 'public',
    showOnlineStatus: true,
    showLastSeen: true,
    showLocation: true,
    allowMessages: 'connections',
    showActivity: true,
    showStats: true
  });

  return (
    <div className="bg-white dark:bg-dark-200 rounded-2xl p-6 border border-gray-200 dark:border-dark-300">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Privacy Settings</h2>

      <div className="space-y-6">
        <div>
          <Label className="text-gray-700 dark:text-dark-800">Profile Visibility</Label>
          <Select
            value={settings.profileVisibility}
            onValueChange={(value) => setSettings({ ...settings, profileVisibility: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400">
              <SelectItem value="public" className="text-gray-900 dark:text-white">Public</SelectItem>
              <SelectItem value="connections" className="text-gray-900 dark:text-white">Connections Only</SelectItem>
              <SelectItem value="private" className="text-gray-900 dark:text-white">Private</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Who Can Message Me</Label>
          <Select
            value={settings.allowMessages}
            onValueChange={(value) => setSettings({ ...settings, allowMessages: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400">
              <SelectItem value="everyone" className="text-gray-900 dark:text-white">Everyone</SelectItem>
              <SelectItem value="connections" className="text-gray-900 dark:text-white">Connections Only</SelectItem>
              <SelectItem value="none" className="text-gray-900 dark:text-white">No One</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <Label className="text-gray-700 dark:text-dark-800">Show Online Status</Label>
            <Switch
              checked={settings.showOnlineStatus}
              onCheckedChange={(checked) => setSettings({ ...settings, showOnlineStatus: checked })}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label className="text-gray-700 dark:text-dark-800">Show Last Seen</Label>
            <Switch
              checked={settings.showLastSeen}
              onCheckedChange={(checked) => setSettings({ ...settings, showLastSeen: checked })}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label className="text-gray-700 dark:text-dark-800">Show Location</Label>
            <Switch
              checked={settings.showLocation}
              onCheckedChange={(checked) => setSettings({ ...settings, showLocation: checked })}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label className="text-gray-700 dark:text-dark-800">Show Activity Status</Label>
            <Switch
              checked={settings.showActivity}
              onCheckedChange={(checked) => setSettings({ ...settings, showActivity: checked })}
            />
          </div>

          <div className="flex items-center justify-between">
            <Label className="text-gray-700 dark:text-dark-800">Show Performance Stats</Label>
            <Switch
              checked={settings.showStats}
              onCheckedChange={(checked) => setSettings({ ...settings, showStats: checked })}
            />
          </div>
        </div>

        <div className="flex justify-end">
          <Button className="bg-gold-400 hover:bg-gold-500 text-dark-50">
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;