import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { RequestAccessForm } from './RequestAccessForm';

interface HeroProps {
  blurAmount: number;
  parallaxOffset: number;
  headerHeight?: number;
  isDesktop?: boolean;
}

export const Hero: React.FC<HeroProps> = ({
  blurAmount,
  parallaxOffset,
  headerHeight = 0,
  isDesktop = true,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [showRequestForm, setShowRequestForm] = useState(false);

  const backgroundImages = [
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/padel%203-Da5mXaEDfVEVUblufI8E3pMcW9mXYe.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/running%202-YoV5MktlEI3tgHfWURPKZSj63bEdHP.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/padel%201-nqaUsaTuDagu9UywhDe22habKsIYrP.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/pilatis%201-mfYbMVt3RWjJsrRsdcgoQEye8Guy4D.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/soccer-hXKyjSwmLhVfLQF1dK2VtjLRVPNWon.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/yoga-oaBEB75QiyYFsJmZXcc1hUBJwRnuKP.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/running2-YI6AI1w56xQVEy40oMfcOcAKhP5Wek.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/biking-sSJuhPolpXT8whaYvX21lKYZ1guuPn.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gym-LrTROxuJ6y1ZzDEMRKqwR0YwQdz9O9.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/yoga2-U9F8BtVlolw0M2hfra94MjOHCwxMYx.webp",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/swimming-O8A7hiwZhfLbvoT5e6O39wrkC422su.webp"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % backgroundImages.length;
        setNextImageIndex((nextIndex + 1) % backgroundImages.length);
        return nextIndex;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="relative min-h-[120vh] overflow-hidden">
        {/* Current Image */}
        <div
          className="absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ease-in-out"
          style={{
            backgroundImage: `url(${backgroundImages[currentImageIndex]})`,
            filter: `blur(${blurAmount}px)`,
            transform: `translateY(${parallaxOffset}px)`,
            top: isDesktop ? `-${headerHeight}px` : `${headerHeight}px`,
            height: isDesktop ? '100%' : `calc(100% - ${headerHeight}px)`,
          }}
        />
        {/* Next Image (for smooth transition) */}
        <div
          className="absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ease-in-out opacity-0"
          style={{
            backgroundImage: `url(${backgroundImages[nextImageIndex]})`,
            filter: `blur(${blurAmount}px)`,
            transform: `translateY(${parallaxOffset}px)`,
            top: isDesktop ? `-${headerHeight}px` : `${headerHeight}px`,
            height: isDesktop ? '100%' : `calc(100% - ${headerHeight}px)`,
          }}
        />
        <div
          className="absolute inset-0 bg-gradient-to-b from-transparent via-black to-black opacity-60"
          style={{
            top: isDesktop ? `-${headerHeight}px` : `${headerHeight}px`,
          }}
        />
        <div className="absolute inset-0 flex items-center">
          <main className="container mx-auto px-4 py-40 md:py-48 lg:py-56 relative z-10">
            <section className="max-w-4xl mx-auto text-center animate-fade-in">
              <h1 className="text-4xl md:text-6xl font-bold mb-8 mt-16">
                Your Sports Playground. Everywhere.
              </h1>
              <p className="text-xl md:text-2xl mb-12 font-light">
                The sports network for business travelers—find partners and play on the go.
              </p>
              <div className="mb-12">
                <h2 className="text-2xl font-light mb-6">Private Invite Only</h2>
                <div className="flex justify-center">
                  <Button
                    onClick={() => setShowRequestForm(true)}
                    className="relative overflow-hidden group bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:via-yellow-600 hover:to-yellow-700 text-black font-light py-6 px-16 rounded-full transition-all duration-300 text-3xl transform hover:scale-105 shadow-lg hover:shadow-xl border border-yellow-300 backdrop-blur-sm"
                  >
                    <span className="relative z-10">Request Invite</span>
                    <div className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-yellow-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-200"></div>
                  </Button>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>

      <RequestAccessForm 
        isOpen={showRequestForm} 
        onClose={() => setShowRequestForm(false)} 
      />
    </>
  );
};