import React from 'react';
import { MapPin, Building, Clock } from 'lucide-react';

interface CityCardProps {
  city: string;
  image: string;
  venueCount: number;
  active: boolean;
  onClick: () => void;
}

const CityCard: React.FC<CityCardProps> = ({ city, image, venueCount, active, onClick }) => {
  return (
    <button
      onClick={active ? onClick : undefined}
      className={`relative w-full h-64 rounded-2xl overflow-hidden group cursor-pointer ${
        !active && 'cursor-default'
      }`}
    >
      <img
        src={image}
        alt={`${city} landmark`}
        className={`w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 ${
          !active && 'grayscale brightness-50'
        }`}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-black/20 group-hover:from-black/95 group-hover:via-black/60 group-hover:to-black/30 transition-all duration-300" />
      <div className="absolute bottom-0 left-0 right-0 p-6">
        <div className="flex items-center space-x-2 mb-2">
          <MapPin className="h-5 w-5 text-gold-400" />
          <h3 className="text-xl font-semibold [color:white] dark:text-white">{city}</h3>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex items-center px-3 py-1 bg-black/50 backdrop-blur-sm rounded-full">
            {active ? (
              <>
                <Building className="h-4 w-4 text-gold-400 mr-2" />
                <span className="[color:white] dark:text-white">{venueCount} Active Venues</span>
              </>
            ) : (
              <>
                <Clock className="h-4 w-4 text-gold-400 mr-2" />
                <span className="[color:white] dark:text-white">Coming Soon</span>
              </>
            )}
          </div>
        </div>
      </div>
    </button>
  );
};

export default CityCard;