import React, { useState } from 'react';
import { UserPlus, UserMinus, Check, Loader2, Link } from 'lucide-react';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { useNotificationStore } from '../../store/notificationStore';

interface ConnectionButtonProps {
  isConnected?: boolean;
  memberId: string;
  memberName: string;
  memberImage: string;
  onConnect: () => Promise<void>;
  onDisconnect: () => Promise<void>;
}

const ConnectionButton: React.FC<ConnectionButtonProps> = ({
  isConnected = false,
  memberId,
  memberName,
  memberImage,
  onConnect,
  onDisconnect
}) => {
  const [status, setStatus] = useState<'none' | 'pending' | 'connected'>(
    isConnected ? 'connected' : 'none'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);
  const { addNotification } = useNotificationStore();

  const handleClick = async () => {
    if (status === 'none') {
      setIsLoading(true);
      try {
        await onConnect();
        setStatus('pending');
        
        // Simulate acceptance after 3 seconds for demo purposes
        setTimeout(() => {
          setStatus('connected');
          
          // Add hardcoded notification for Sophie Muller
          addNotification({
            id: `connection-${Date.now()}`,
            type: 'connection_accepted',
            title: 'Connection Request Accepted',
            message: 'Sophie Muller accepted your connection request',
            timestamp: new Date().toISOString(),
            read: false,
            actionUrl: '/dashboard/profile',
            sender: {
              id: '54',
              name: 'Sophie Muller',
              image: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&fit=crop&w=300&q=80'
            },
            metadata: {
              activeTab: 'network' // Add metadata for tab selection
            }
          });
        }, 3000);
      } catch (error) {
        console.error('Failed to send connection request:', error);
      } finally {
        setIsLoading(false);
      }
    } else if (status === 'connected') {
      setShowDisconnectConfirm(true);
    }
  };

  const handleDisconnect = async () => {
    setIsLoading(true);
    try {
      await onDisconnect();
      setStatus('none');
    } catch (error) {
      console.error('Failed to disconnect:', error);
    } finally {
      setIsLoading(false);
      setShowDisconnectConfirm(false);
    }
  };

  if (showDisconnectConfirm) {
    return (
      <div className="flex items-center gap-2">
        <Button
          onClick={handleDisconnect}
          disabled={isLoading}
          variant="destructive"
          className="h-10 px-4 flex items-center space-x-2"
        >
          {isLoading ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <>
              <UserMinus className="h-4 w-4" />
              <span>Disconnect</span>
            </>
          )}
        </Button>
        <Button
          onClick={() => setShowDisconnectConfirm(false)}
          variant="outline"
          className="h-10 px-4"
        >
          Cancel
        </Button>
      </div>
    );
  }

  return (
    <Button
      onClick={handleClick}
      disabled={isLoading || status === 'connected'}
      variant={status === 'connected' ? 'outline' : 'default'}
      className={cn(
        "h-10 px-4 flex items-center space-x-2",
        status === 'connected'
          ? cn(
              "border-2",
              "dark:bg-dark-300/50 dark:border-gold-400 dark:text-gold-400 dark:hover:bg-dark-400/50",
              "light:bg-white light:border-gold-500 light:text-gold-600 light:hover:bg-gold-50"
            )
          : cn(
              "dark:bg-gold-400 dark:hover:bg-gold-500 dark:text-dark-50",
              "light:bg-gold-500 light:hover:bg-gold-600 light:text-white"
            )
      )}
    >
      {isLoading ? (
        <Loader2 className="h-4 w-4 animate-spin" />
      ) : status === 'pending' ? (
        <>
          <Check className="h-4 w-4" />
          <span>Pending</span>
        </>
      ) : status === 'connected' ? (
        <>
          <Link className="h-4 w-4" />
          <span>Connected</span>
        </>
      ) : (
        <>
          <UserPlus className="h-4 w-4" />
          <span>Connect</span>
        </>
      )}
    </Button>
  );
};

export default ConnectionButton;