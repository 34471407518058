import React, { useState } from 'react';
import { Camera, Link as LinkIcon, MapPin, Edit2, Plus, X, Save } from 'lucide-react';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';
import PhotoGallery from './PhotoGallery';
import SportsSelector from './SportsSelector';

interface SocialLink {
  platform: string;
  url: string;
}

interface AboutTabProps {
  initialData?: {
    fullName: string;
    bio: string;
    currentCity: string;
    socialLinks: SocialLink[];
    sports: string[];
    photos: string[];
  };
}

const AboutTab: React.FC<AboutTabProps> = ({ initialData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    fullName: initialData?.fullName || 'Uri Schneider',
    bio: initialData?.bio || 'Passionate about sports and technology. Co-founder of Playclub.ai.',
    currentCity: initialData?.currentCity || 'Valencia',
    socialLinks: initialData?.socialLinks || [
      { platform: 'LinkedIn', url: 'https://linkedin.com/in/urischneider' },
      { platform: 'Twitter', url: 'https://twitter.com/urischneider' }
    ],
    sports: initialData?.sports || [
      '🎾 Tennis',
      '⛳ Golf',
      '🏊‍♂️ Swimming'
    ],
    photos: initialData?.photos || [
      'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1587974928442-77dc3e0dba72?auto=format&fit=crop&w=800&q=80'
    ]
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full name is required';
    }

    if (!formData.currentCity.trim()) {
      newErrors.currentCity = 'Current city is required';
    }

    if (formData.bio.length > 500) {
      newErrors.bio = 'Bio must be less than 500 characters';
    }

    formData.socialLinks.forEach((link, index) => {
      if (link.url && !isValidUrl(link.url)) {
        newErrors[`socialLink${index}`] = 'Please enter a valid URL';
      }
    });

    if (formData.sports.length === 0) {
      newErrors.sports = 'Please select at least one sport';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      // Here you would typically make an API call to save the data
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const handleAddSocialLink = () => {
    setFormData(prev => ({
      ...prev,
      socialLinks: [...prev.socialLinks, { platform: '', url: '' }]
    }));
  };

  const handleRemoveSocialLink = (index: number) => {
    setFormData(prev => ({
      ...prev,
      socialLinks: prev.socialLinks.filter((_, i) => i !== index)
    }));
  };

  const handleUpdateSocialLink = (index: number, field: 'platform' | 'url', value: string) => {
    const newLinks = [...formData.socialLinks];
    newLinks[index][field] = value;
    setFormData(prev => ({
      ...prev,
      socialLinks: newLinks
    }));

    // Clear any previous errors for this field
    if (errors[`socialLink${index}`]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[`socialLink${index}`];
        return newErrors;
      });
    }

    // Validate URL immediately
    if (field === 'url' && value && !isValidUrl(value)) {
      setErrors(prev => ({
        ...prev,
        [`socialLink${index}`]: 'Please enter a valid URL'
      }));
    }
  };

  const handlePhotoUpload = (file: File) => {
    const url = URL.createObjectURL(file);
    setFormData(prev => ({
      ...prev,
      photos: [...prev.photos, url]
    }));
  };

  const handlePhotoDelete = (photoUrl: string) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter(url => url !== photoUrl)
    }));
  };

  const handleSportsChange = (sports: string[]) => {
    setFormData(prev => ({
      ...prev,
      sports
    }));
    // Clear any previous sports-related errors
    if (errors.sports) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.sports;
        return newErrors;
      });
    }
  };

  return (
    <div className="space-y-6">
      {/* Personal Details */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-white">Personal Details</h3>
          <Button
            onClick={() => isEditing ? handleSave() : setIsEditing(true)}
            className={isEditing ? 'bg-gold-400 hover:bg-gold-500 text-dark-50' : 'bg-dark-300 hover:bg-dark-400 text-white'}
          >
            {isEditing ? (
              <>
                <Save className="h-4 w-4 mr-2" />
                Save Changes
              </>
            ) : (
              <>
                <Edit2 className="h-4 w-4 mr-2" />
                Edit Profile
              </>
            )}
          </Button>
        </div>

        <div className="space-y-4">
          <div>
            <Label htmlFor="fullName" className="text-dark-800">Full Name</Label>
            <Input
              id="fullName"
              value={formData.fullName}
              onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
              disabled={!isEditing}
              className="bg-dark-300 border-dark-400 text-white mt-1"
              error={errors.fullName}
            />
            {errors.fullName && (
              <p className="text-red-400 text-sm mt-1">{errors.fullName}</p>
            )}
          </div>

          <div>
            <Label htmlFor="bio" className="text-dark-800">Bio</Label>
            <textarea
              id="bio"
              value={formData.bio}
              onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
              disabled={!isEditing}
              className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white mt-1 h-32 resize-none"
            />
            <p className="text-dark-800 text-sm mt-1">
              {formData.bio.length}/500 characters
            </p>
            {errors.bio && (
              <p className="text-red-400 text-sm">{errors.bio}</p>
            )}
          </div>

          <div>
            <Label htmlFor="currentCity" className="text-dark-800">Current City</Label>
            <div className="relative">
              <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
              <Input
                id="currentCity"
                value={formData.currentCity}
                onChange={(e) => setFormData({ ...formData, currentCity: e.target.value })}
                disabled={!isEditing}
                className="pl-10 bg-dark-300 border-dark-400 text-white mt-1"
                error={errors.currentCity}
              />
            </div>
            {errors.currentCity && (
              <p className="text-red-400 text-sm mt-1">{errors.currentCity}</p>
            )}
          </div>
        </div>
      </div>

      {/* Photo Gallery */}
      <PhotoGallery
        photos={formData.photos}
        onUpload={handlePhotoUpload}
        onDelete={handlePhotoDelete}
      />

      {/* Social Links */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-2">
            <LinkIcon className="h-5 w-5 text-gold-400" />
            <h3 className="text-lg font-semibold text-white">Social Links</h3>
          </div>
          <Button
            onClick={handleAddSocialLink}
            className="bg-dark-300 hover:bg-dark-400 text-white"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Link
          </Button>
        </div>

        <div className="space-y-4">
          {formData.socialLinks.map((link, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="flex-1">
                <Input
                  value={link.platform}
                  onChange={(e) => handleUpdateSocialLink(index, 'platform', e.target.value)}
                  placeholder="Platform"
                  className="bg-dark-300 border-dark-400 text-white"
                />
              </div>
              <div className="flex-1">
                <div className="relative">
                  <LinkIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
                  <Input
                    value={link.url}
                    onChange={(e) => handleUpdateSocialLink(index, 'url', e.target.value)}
                    placeholder="URL"
                    className="pl-10 bg-dark-300 border-dark-400 text-white"
                  />
                </div>
                {errors[`socialLink${index}`] && (
                  <p className="text-red-400 text-sm mt-1">{errors[`socialLink${index}`]}</p>
                )}
              </div>
              <Button
                onClick={() => handleRemoveSocialLink(index)}
                className="bg-dark-300 hover:bg-dark-400 text-white p-2"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>
      </div>

      {/* Sports Preferences */}
      <SportsSelector
        selectedSports={formData.sports}
        onSportsChange={handleSportsChange}
      />
      {errors.sports && (
        <p className="text-red-400 text-sm mt-1">{errors.sports}</p>
      )}
    </div>
  );
};

export default AboutTab;