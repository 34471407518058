import React, { useState } from 'react';
import { LogIn, LogOut, UserPlus, UserCircle } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from './ui/button';
import { LoginModal } from './LoginModal';
import { InviteModal } from './InviteModal';
import { RequestAccessForm } from './RequestAccessForm';
import { useAuth } from '../contexts/AuthContext';
import NotificationsDropdown from './notifications/NotificationsDropdown';
import { ThemeAwareLogo } from './brand/ThemeAwareLogo';

interface HeaderProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  onLoginSuccess: (email: string, password: string) => void;
}

export const Header: React.FC<HeaderProps> = ({
  onLoginSuccess,
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showRequestAccessForm, setShowRequestAccessForm] = useState(false);
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const handleLoginSubmit = async (email: string, password: string) => {
    await onLoginSuccess(email, password);
    setShowLoginModal(false);
  };

  return (
    <>
      <header className={`fixed top-0 left-0 right-0 z-50 ${isLandingPage ? 'bg-dark-200/50 backdrop-blur-sm' : 'bg-opacity-80 backdrop-blur-md bg-app border-b border-dark-300'}`}>
        <div className="max-w-[1400px] mx-auto px-2 sm:px-3">
          <div className="h-16 flex items-center justify-between">
            {/* Logo section */}
            <Link to="/" className="flex-shrink-0 flex items-center space-x-2">
              <ThemeAwareLogo 
                height={36} 
                colorScheme="white"
              />
            </Link>

            {/* Right section with actions */}
            <div className="flex items-center">
              {isAuthenticated ? (
                <div className="flex items-center gap-2 sm:gap-3">
                  <div className="flex items-center gap-1">
                    <button
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                      className="p-1.5 text-app-muted hover:text-app lg:hidden"
                      aria-label="Toggle menu"
                    >
                      {isMenuOpen ? (
                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      ) : (
                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                      )}
                    </button>
                    <NotificationsDropdown />
                  </div>
                  
                  <Button
                    onClick={() => setShowInviteModal(true)}
                    variant="outline"
                    size="sm"
                    className="hidden sm:flex items-center bg-app-darker text-app hover:bg-app transition-colors rounded-full px-2 py-1"
                  >
                    <UserPlus className="h-4 w-4" />
                    <span className="ml-1">Invite</span>
                  </Button>
                  
                  <Button
                    onClick={() => logout()}
                    variant="outline"
                    size="sm"
                    className="bg-app-darker text-app hover:bg-app transition-colors rounded-full px-2 py-1 flex items-center"
                  >
                    <LogOut className="h-4 w-4" />
                    <span className="hidden sm:inline ml-1">Logout</span>
                  </Button>

                  <button
                    onClick={() => navigate('/dashboard/profile')}
                    className="p-1.5 hover:bg-app-darker rounded-full transition-colors"
                  >
                    <UserCircle className="h-5 w-5" />
                  </button>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <Button
                    onClick={() => setShowLoginModal(true)}
                    variant="outline"
                    size="sm"
                    className="bg-gold-400/90 backdrop-blur-sm text-dark-50 hover:bg-gold-500 transition-colors rounded-full px-2 py-1 flex items-center border-gold-300"
                  >
                    <LogIn className="h-4 w-4" />
                    <span className="hidden sm:inline ml-1">Members Login</span>
                    <span className="sm:hidden ml-1">Login</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <LoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onSuccess={handleLoginSubmit}
        onRequestAccess={() => {
          setShowLoginModal(false);
          setShowRequestAccessForm(true);
        }}
      />

      <InviteModal 
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      />

      <RequestAccessForm
        isOpen={showRequestAccessForm}
        onClose={() => setShowRequestAccessForm(false)}
      />
    </>
  );
};

export default Header;