import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Shield, RefreshCw } from 'lucide-react';
import { sendVerificationCode, verifyCode } from '../../../lib/twilio';

interface PhoneVerificationStepProps {
  phone: string;
  onVerificationComplete: () => void;
  onBack: () => void;
}

export default function PhoneVerificationStep({ 
  phone, 
  onVerificationComplete, 
  onBack 
}: PhoneVerificationStepProps) {
  const [verificationCode, setVerificationCode] = useState('');
  const [isResending, setIsResending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(60);

  React.useEffect(() => {
    // Send initial verification code
    sendVerificationCode(phone);
  }, [phone]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendCode = async () => {
    setIsResending(true);
    setError('');
    try {
      await sendVerificationCode(phone);
      setTimer(60);
    } catch (err) {
      setError('Failed to send verification code. Please try again.');
    } finally {
      setIsResending(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsVerifying(true);
    setError('');
    
    try {
      const isValid = await verifyCode(phone, verificationCode);
      if (isValid) {
        onVerificationComplete();
      } else {
        setError('Invalid verification code. Please try again.');
      }
    } catch (err) {
      setError('Failed to verify code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-dark-200 rounded-2xl p-8 border border-dark-300"
    >
      <div className="flex items-center space-x-3 mb-6">
        <div className="bg-gold-400/20 p-2 rounded-lg">
          <Shield className="h-6 w-6 text-gold-400" />
        </div>
        <div>
          <h2 className="text-2xl font-bold text-white">Verify Your Phone</h2>
          <p className="text-dark-800">Enter the code sent to {phone}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <Label htmlFor="code" className="text-dark-800">
            Verification Code
          </Label>
          <Input
            id="code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, ''))}
            className="bg-dark-300 border-dark-400 text-white mt-2 text-lg tracking-wider text-center"
            placeholder="Enter 6-digit code"
            maxLength={6}
            pattern="[0-9]{6}"
            required
          />
          {error && (
            <p className="text-red-400 text-sm mt-2">{error}</p>
          )}
        </div>

        <div className="flex items-center justify-between text-sm">
          <span className="text-dark-800">
            {timer > 0 ? (
              `Resend code in ${timer}s`
            ) : (
              <button
                type="button"
                onClick={handleResendCode}
                disabled={isResending}
                className="text-gold-400 hover:text-gold-500 flex items-center space-x-2"
              >
                <RefreshCw className={`h-4 w-4 ${isResending ? 'animate-spin' : ''}`} />
                <span>Resend Code</span>
              </button>
            )}
          </span>
        </div>

        <div className="flex justify-between pt-6">
          <Button
            type="button"
            onClick={onBack}
            className="bg-dark-300 hover:bg-dark-400 text-white"
          >
            Back
          </Button>
          <Button
            type="submit"
            disabled={verificationCode.length !== 6 || isVerifying}
            className="bg-gold-400 hover:bg-gold-500 text-dark-50"
          >
            {isVerifying ? 'Verifying...' : 'Verify & Continue'}
          </Button>
        </div>
      </form>
    </motion.div>
  );
}