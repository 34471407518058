import React, { useState } from 'react';
import { 
  User, CreditCard, Bell, Globe, Lock, Shield, 
  Mail, Smartphone, Palette, Eye, Languages, Rocket
} from 'lucide-react';
import AccountSettings from './sections/AccountSettings';
import PaymentSettings from './sections/PaymentSettings';
import NotificationSettings from './sections/NotificationSettings';
import PrivacySettings from './sections/PrivacySettings';
import SecuritySettings from './sections/SecuritySettings';
import PreferenceSettings from './sections/PreferenceSettings';
import { useNavigate } from 'react-router-dom';

const SettingsPanel: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('account');

  const tabs = [
    { id: 'account', label: 'Account', icon: User },
    { id: 'payment', label: 'Payment', icon: CreditCard },
    { id: 'notifications', label: 'Notifications', icon: Bell },
    { id: 'privacy', label: 'Privacy', icon: Eye },
    { id: 'security', label: 'Security', icon: Shield },
    { id: 'preferences', label: 'Preferences', icon: Palette },
    { id: 'onboarding', label: 'Onboarding', icon: Rocket }
  ];

  const handleTabChange = (tabId: string) => {
    if (tabId === 'onboarding') {
      navigate('/onboarding');
    } else {
      setActiveTab(tabId);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'account':
        return <AccountSettings />;
      case 'payment':
        return <PaymentSettings />;
      case 'notifications':
        return <NotificationSettings />;
      case 'privacy':
        return <PrivacySettings />;
      case 'security':
        return <SecuritySettings />;
      case 'preferences':
        return <PreferenceSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
      {/* Sidebar */}
      <div className="lg:col-span-1">
        <div className="bg-dark-200 rounded-2xl p-4 border border-dark-300">
          <nav className="space-y-1">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => handleTabChange(tab.id)}
                className={`w-full flex items-center space-x-3 px-4 py-3 rounded-xl transition-colors ${
                  activeTab === tab.id
                    ? 'bg-dark-300 text-gold-400'
                    : 'text-dark-800 hover:bg-dark-300 hover:text-white'
                }`}
              >
                <tab.icon className="h-5 w-5" />
                <span>{tab.label}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Content */}
      <div className="lg:col-span-3">
        {renderContent()}
      </div>
    </div>
  );
};

export default SettingsPanel;