import React from 'react';
import { useNavigate } from 'react-router-dom';
import { X, MapPin, Trophy, Star, MessageCircle, UserPlus } from 'lucide-react';
import { members } from '../../data/members';
import { facilities } from '../../data/facilities';

interface CityMembersModalProps {
  city: string;
  isOpen: boolean;
  onClose: () => void;
}

const CityMembersModal: React.FC<CityMembersModalProps> = ({ city, isOpen, onClose }) => {
  const navigate = useNavigate();
  const cityMembers = members.filter(member => member.city === city);
  const cityVenues = facilities.filter(facility => facility.location === city);

  const [selectedMember, setSelectedMember] = React.useState<string | null>(null);
  const [selectedSport, setSelectedSport] = React.useState<string>('');
  const [selectedVenue, setSelectedVenue] = React.useState<string>('');

  if (!isOpen) return null;

  const handleProfileClick = (memberId: string) => {
    navigate(`/dashboard/members/${memberId}`);
    onClose();
  };

  const handleRequestMatch = (memberId: string) => {
    setSelectedMember(memberId);
  };

  const handleSubmitMatchRequest = () => {
    // Here you would handle the match request submission
    console.log('Match request submitted:', {
      memberId: selectedMember,
      sport: selectedSport,
      venue: selectedVenue
    });
    setSelectedMember(null);
    setSelectedSport('');
    setSelectedVenue('');
  };

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-3xl bg-dark-200 rounded-2xl p-6 border border-dark-300">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>

            <h2 className="text-2xl font-bold text-white mb-2">
              {city} Members ({cityMembers.length})
            </h2>
            <p className="text-dark-800 mb-6">Connect and play with members in {city}</p>

            <div className="space-y-4 max-h-[60vh] overflow-y-auto pr-2">
              {cityMembers.map((member) => (
                <div
                  key={member.id}
                  className="bg-dark-300 rounded-xl p-4 flex items-start space-x-4"
                >
                  <button
                    onClick={() => handleProfileClick(member.id)}
                    className="flex-shrink-0"
                  >
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-16 h-16 rounded-xl object-cover border-2 border-dark-400 hover:border-gold-400 transition-colors"
                    />
                  </button>
                  <div className="flex-1">
                    <div className="flex items-start justify-between">
                      <div>
                        <button
                          onClick={() => handleProfileClick(member.id)}
                          className="text-white font-medium hover:text-gold-400 transition-colors"
                        >
                          {member.name}
                        </button>
                        <div className="flex items-center text-dark-800 mt-1">
                          <MapPin className="h-4 w-4 mr-1" />
                          {member.city}
                        </div>
                        <div className="flex items-center text-dark-800 mt-1">
                          <Trophy className="h-4 w-4 mr-1" />
                          {member.skillLevel}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Star className="h-4 w-4 text-gold-400 mr-1" />
                        <span className="text-white">{member.rating}</span>
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-2 mt-3">
                      {member.sports.map((sport, idx) => (
                        <span
                          key={idx}
                          className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white"
                        >
                          {sport}
                        </span>
                      ))}
                    </div>

                    <div className="flex items-center space-x-3 mt-4">
                      {!member.isConnected && (
                        <button className="flex items-center space-x-2 px-4 py-2 bg-dark-400 hover:bg-dark-500 rounded-lg text-white transition-colors">
                          <UserPlus className="h-4 w-4" />
                          <span>Connect</span>
                        </button>
                      )}
                      <button
                        onClick={() => handleRequestMatch(member.id)}
                        className="flex items-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors"
                      >
                        <MessageCircle className="h-4 w-4" />
                        <span>Request Match</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Match Request Form */}
            {selectedMember && (
              <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm flex items-center justify-center">
                <div className="bg-dark-200 rounded-xl p-6 w-full max-w-md border border-dark-300">
                  <h3 className="text-xl font-bold text-white mb-4">Request Match</h3>
                  
                  <div className="space-y-4">
                    <div>
                      <label className="block text-dark-800 mb-2">Sport</label>
                      <select
                        value={selectedSport}
                        onChange={(e) => setSelectedSport(e.target.value)}
                        className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                      >
                        <option value="">Select Sport</option>
                        {members.find(m => m.id === selectedMember)?.sports.map(sport => (
                          <option key={sport} value={sport}>{sport}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block text-dark-800 mb-2">Venue</label>
                      <select
                        value={selectedVenue}
                        onChange={(e) => setSelectedVenue(e.target.value)}
                        className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                      >
                        <option value="">Select Venue</option>
                        {cityVenues
                          .filter(venue => 
                            !selectedSport || 
                            venue.type.toLowerCase() === selectedSport.toLowerCase()
                          )
                          .map(venue => (
                            <option key={venue.id} value={venue.id}>{venue.name}</option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="flex justify-end space-x-3 mt-6">
                      <button
                        onClick={() => setSelectedMember(null)}
                        className="px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-lg transition-colors"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSubmitMatchRequest}
                        disabled={!selectedSport || !selectedVenue}
                        className="px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Send Request
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityMembersModal;