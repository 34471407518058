import React, { useState } from 'react';
import { Users, MapPin, Calendar, Trophy } from 'lucide-react';
import JoinGroupModal from './JoinGroupModal';

interface GroupMatchmakingProps {
  preferences: any;
  onPreferencesUpdate: (prefs: any) => void;
}

const GroupMatchmaking: React.FC<GroupMatchmakingProps> = ({ preferences, onPreferencesUpdate }) => {
  const [selectedGroup, setSelectedGroup] = useState<any>(null);

  const groups = [
    {
      id: '1',
      name: 'Tennis Doubles Group',
      members: [
        {
          name: 'Sarah Connor',
          image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
        },
        {
          name: 'James Wilson',
          image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
        }
      ],
      sport: 'Tennis',
      skillLevel: 'Advanced',
      location: 'Monte Carlo Tennis Club',
      nextSession: 'Tomorrow, 10:00 AM',
      spotsLeft: 2,
      rules: [
        'Minimum rating of 4.5 required',
        'Regular attendance expected',
        'Professional conduct required',
        'Must have own equipment'
      ]
    },
    {
      id: '2',
      name: 'Golf Foursome',
      members: [
        {
          name: 'Michael Chen',
          image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80'
        }
      ],
      sport: 'Golf',
      skillLevel: 'Intermediate',
      location: 'Royal Golf Club',
      nextSession: 'Saturday, 9:00 AM',
      spotsLeft: 3,
      rules: [
        'Handicap verification required',
        'Club dress code applies',
        'Punctuality is essential'
      ]
    }
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {groups.map((group) => (
          <div
            key={group.id}
            className="bg-dark-200 rounded-2xl p-6 border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-white">{group.name}</h3>
              <span className="px-3 py-1 bg-gold-400 text-dark-50 rounded-full text-sm">
                {group.spotsLeft} spots left
              </span>
            </div>

            <div className="flex items-center space-x-2 mb-4">
              <div className="flex -space-x-3">
                {group.members.map((member, index) => (
                  <img
                    key={index}
                    src={member.image}
                    alt={member.name}
                    className="w-10 h-10 rounded-full border-2 border-dark-200"
                  />
                ))}
                {group.spotsLeft > 0 && (
                  <div className="w-10 h-10 rounded-full bg-dark-300 border-2 border-dark-200 flex items-center justify-center">
                    <Users className="h-5 w-5 text-dark-800" />
                  </div>
                )}
              </div>
              <span className="text-dark-800">
                {group.members.length} members
              </span>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="flex items-center text-dark-800">
                <Trophy className="h-4 w-4 mr-2" />
                {group.skillLevel}
              </div>
              <div className="flex items-center text-dark-800">
                <MapPin className="h-4 w-4 mr-2" />
                {group.location}
              </div>
              <div className="flex items-center text-dark-800 col-span-2">
                <Calendar className="h-4 w-4 mr-2" />
                {group.nextSession}
              </div>
            </div>

            <button
              onClick={() => setSelectedGroup(group)}
              className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-xl transition-colors"
            >
              Join Group
            </button>
          </div>
        ))}
      </div>

      {selectedGroup && (
        <JoinGroupModal
          isOpen={true}
          onClose={() => setSelectedGroup(null)}
          group={selectedGroup}
        />
      )}
    </div>
  );
};

export default GroupMatchmaking;