import React from 'react';
import { MapPin, Heart, MessageCircle, Share2 } from 'lucide-react';
import type { Post } from '../../../types/post';

interface PostsTabProps {
  posts: Post[];
}

const PostsTab: React.FC<PostsTabProps> = ({ posts }) => {
  return (
    <div className="space-y-6">
      {posts.map((post) => (
        <div
          key={post.id}
          className="bg-dark-200 rounded-2xl p-6 border border-dark-300"
        >
          <div className="flex items-start space-x-4">
            <img
              src={post.author.image}
              alt={post.author.name}
              className="w-12 h-12 rounded-full object-cover"
            />
            <div className="flex-1">
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-base font-medium text-white">{post.author.name}</h3>
                  <div className="flex items-center text-dark-800 text-sm">
                    <MapPin className="h-4 w-4 mr-1" />
                    {post.author.location}
                  </div>
                </div>
                <span className="text-dark-800 text-sm">{post.timeAgo}</span>
              </div>

              <p className="text-white mt-3">{post.content}</p>

              {post.media && (
                <img
                  src={post.media}
                  alt="Post media"
                  className="w-full h-64 object-cover rounded-xl mt-4"
                />
              )}

              <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-300">
                <button className="flex items-center space-x-2 text-dark-800 hover:text-gold-400">
                  <Heart className="h-5 w-5" />
                  <span>{post.likes}</span>
                </button>
                <button className="flex items-center space-x-2 text-dark-800 hover:text-gold-400">
                  <MessageCircle className="h-5 w-5" />
                  <span>{post.comments}</span>
                </button>
                <button className="flex items-center space-x-2 text-dark-800 hover:text-gold-400">
                  <Share2 className="h-5 w-5" />
                  <span>{post.shares}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostsTab;