import { City } from '../types/city';

export const cityLandmarks: Record<string, string> = {
  // Active cities
  'luxembourg': 'https://images.unsplash.com/photo-1587974928442-77dc3e0dba72?auto=format&fit=crop&w=1600&q=80',
  'singapore': 'https://images.unsplash.com/photo-1525625293386-3f8f99389edd?auto=format&fit=crop&w=1600&q=80',
  'rome': 'https://images.unsplash.com/photo-1552832230-c0197dd311b5?auto=format&fit=crop&w=1600&q=80',
  'madrid': 'https://images.unsplash.com/photo-1543783207-ec64e4d95325?auto=format&fit=crop&w=1600&q=80',
  'washington dc': 'https://images.unsplash.com/photo-1501466044931-62695aada8e9?auto=format&fit=crop&w=1600&q=80',
  'london': 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?auto=format&fit=crop&w=1600&q=80',
  'dubai': 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=1600&q=80',
  'paris': 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?auto=format&fit=crop&w=1600&q=80',
  'barcelona': 'https://images.unsplash.com/photo-1583422409516-2895a77efded?auto=format&fit=crop&w=1600&q=80',
  
  // Major financial centers
  'tokyo': 'https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?auto=format&fit=crop&w=1600&q=80',
  'vienna': 'https://images.unsplash.com/photo-1516550893923-42d28e5677af?auto=format&fit=crop&w=1600&q=80',
  'dublin': 'https://images.unsplash.com/photo-1549918864-48ac978761a4?auto=format&fit=crop&w=1600&q=80',
  'munich': 'https://images.unsplash.com/photo-1595867818082-083862f3d630?auto=format&fit=crop&w=1600&q=80',
  'warsaw': 'https://images.unsplash.com/photo-1519197924294-4ba991a11128?auto=format&fit=crop&w=1600&q=80',
  'milano': 'https://images.unsplash.com/photo-1520440229-6469a149ac59?auto=format&fit=crop&w=1600&q=80',
  'milan': 'https://images.unsplash.com/photo-1520440229-6469a149ac59?auto=format&fit=crop&w=1600&q=80',
  'frankfurt': 'https://images.unsplash.com/photo-1467269204594-9661b134dd2b?auto=format&fit=crop&w=1600&q=80',
  'amsterdam': 'https://images.unsplash.com/photo-1512470876302-972faa2aa9a4?auto=format&fit=crop&w=1600&q=80',
  
  // Asian cities
  'moscow': 'https://images.unsplash.com/photo-1513326738677-b964603b136d?auto=format&fit=crop&w=1600&q=80',
  'hong kong': 'https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&w=1600&q=80',
  'seoul': 'https://images.unsplash.com/photo-1538485399081-7c8ed7c8982c?auto=format&fit=crop&w=1600&q=80',
  'osaka': 'https://images.unsplash.com/photo-1590559899731-a382839e5549?auto=format&fit=crop&w=1600&q=80',
  'jakarta': 'https://images.unsplash.com/photo-1555899434-94d1368aa7af?auto=format&fit=crop&w=1600&q=80',
  
  // European cities
  'geneva': 'https://images.unsplash.com/photo-1573843981267-be1999ff37cd?auto=format&fit=crop&w=1600&q=80',
  'zurich': 'https://images.unsplash.com/photo-1515488764276-beab7607c1e6?auto=format&fit=crop&w=1600&q=80',
  'stockholm': 'https://images.unsplash.com/photo-1509356843151-3e7d96241e11?auto=format&fit=crop&w=1600&q=80',
  'oslo': 'https://images.unsplash.com/photo-1513889961551-628c1e489012?auto=format&fit=crop&w=1600&q=80',
  'copenhagen': 'https://images.unsplash.com/photo-1513622470522-26c3c8a854bc?auto=format&fit=crop&w=1600&q=80',
  'prague': 'https://images.unsplash.com/photo-1541849546-216549ae216d?auto=format&fit=crop&w=1600&q=80',
  'brussels': 'https://images.unsplash.com/photo-1559113202-c916b8e44373?auto=format&fit=crop&w=1600&q=80',
  'lisbon': 'https://images.unsplash.com/photo-1555881400-74d7acaacd8b?auto=format&fit=crop&w=1600&q=80',
  
  // Americas
  'são paulo': 'https://images.unsplash.com/photo-1554168848-228452c09d60?auto=format&fit=crop&w=1600&q=80',
  'rio de janeiro': 'https://images.unsplash.com/photo-1483729558449-99ef09a8c325?auto=format&fit=crop&w=1600&q=80',
  'mexico city': 'https://images.unsplash.com/photo-1518659526054-190340b32735?auto=format&fit=crop&w=1600&q=80',
  'miami': 'https://images.unsplash.com/photo-1506966953602-c20cc11f75e3?auto=format&fit=crop&w=1600&q=80',
  
  // Oceania and Middle East
  'sydney': 'https://images.unsplash.com/photo-1506973035872-a4ec16b8e8d9?auto=format&fit=crop&w=1600&q=80',
  'melbourne': 'https://images.unsplash.com/photo-1514395462725-fb4566210144?auto=format&fit=crop&w=1600&q=80',
  'abu dhabi': 'https://images.unsplash.com/photo-1551927336-09d50efd69cd?auto=format&fit=crop&w=1600&q=80',
  'riyadh': 'https://images.unsplash.com/photo-1586724237569-f3d0c1dee8c6?auto=format&fit=crop&w=1600&q=80',
  'st. moritz': 'https://images.unsplash.com/photo-1548777123-e216912df7d8?auto=format&fit=crop&w=1600&q=80',
  'monte carlo': 'https://images.unsplash.com/photo-1612698093158-e07ac200d44e?auto=format&fit=crop&w=1600&q=80'
};

export const activeCities = [
  'Luxembourg', 'Singapore', 'Rome', 'Madrid', 'Washington DC',
  'London', 'Dubai', 'Paris', 'Barcelona'  // Added new active cities
];

export const cities: City[] = [
  // Active cities first
  { name: 'Luxembourg', image: cityLandmarks['luxembourg'], venueCount: 12, active: true },
  { name: 'Singapore', image: cityLandmarks['singapore'], venueCount: 18, active: true },
  { name: 'Rome', image: cityLandmarks['rome'], venueCount: 15, active: true },
  { name: 'Madrid', image: cityLandmarks['madrid'], venueCount: 20, active: true },
  { name: 'Washington DC', image: cityLandmarks['washington dc'], venueCount: 14, active: true },
  { name: 'London', image: cityLandmarks['london'], venueCount: 25, active: true },
  { name: 'Dubai', image: cityLandmarks['dubai'], venueCount: 22, active: true },
  { name: 'Paris', image: cityLandmarks['paris'], venueCount: 28, active: true },
  { name: 'Barcelona', image: cityLandmarks['barcelona'], venueCount: 16, active: true },
  
  // Coming soon cities
  { name: 'Tokyo', image: cityLandmarks['tokyo'], venueCount: 0, active: false },
  { name: 'Vienna', image: cityLandmarks['vienna'], venueCount: 0, active: false },
  { name: 'Dublin', image: cityLandmarks['dublin'], venueCount: 0, active: false },
  { name: 'Munich', image: cityLandmarks['munich'], venueCount: 0, active: false },
  { name: 'Warsaw', image: cityLandmarks['warsaw'], venueCount: 0, active: false },
  { name: 'Milano', image: cityLandmarks['milano'], venueCount: 0, active: false },
  { name: 'Frankfurt', image: cityLandmarks['frankfurt'], venueCount: 0, active: false },
  { name: 'Amsterdam', image: cityLandmarks['amsterdam'], venueCount: 0, active: false }
];