import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Star, Calendar as CalendarIcon, Clock, ChevronLeft } from 'lucide-react';
import type { Player } from '../../types/matchmaking';
import type { MatchOption } from './QuickMatchPreferences';
import MutualFriendsModal from './MutualFriendsModal';
import DatePicker from '../ui/calendar';
import { cn } from '@/lib/utils';

interface MatchCardProps {
  player: Player;
  sports: string[];
  dates: string[];
  timeSlots: string[];
  onMatchSelect: (match: MatchOption) => void;
}

const sportEmojis: Record<string, string> = {
  'Tennis': '🎾',
  'Golf': '⛳',
  'Swimming': '🏊‍♂️',
  'Running': '🏃‍♂️',
  'Cycling': '🚴‍♂️',
  'Football': '⚽',
  'Basketball': '🏀',
  'Badminton': '🏸',
  'Table Tennis': '🏓',
  'Volleyball': '🏐',
  'Padel': '🎱',
  'Yoga': '🧘‍♀️',
  'Pilates': '🏋️‍♀️'
};

const timePeriods: Record<string, { label: string, time: string }> = {
  'morning': { label: 'Morning', time: '6-11 AM' },
  'noon': { label: 'Noon', time: '11-2 PM' },
  'afternoon': { label: 'Afternoon', time: '2-5 PM' },
  'evening': { label: 'Evening', time: '5-8 PM' },
  'night': { label: 'Night', time: '8-11 PM' }
};

const MatchCard: React.FC<MatchCardProps> = ({
  player,
  sports,
  dates,
  timeSlots,
  onMatchSelect
}) => {
  const navigate = useNavigate();
  const [selectedSport, setSelectedSport] = useState(sports[0]);
  const [selectedDate, setSelectedDate] = useState(dates[0]);
  const [selectedTime, setSelectedTime] = useState(timeSlots[0]);
  const [showScheduling, setShowScheduling] = useState(false);
  const [showMutualFriends, setShowMutualFriends] = useState(false);
  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'sent'>('idle');
  const mutualFriendsCount = Math.floor(Math.random() * 10) + 1;

  const handleConfirm = async () => {
    setRequestStatus('pending');
    
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    onMatchSelect({
      player,
      sport: selectedSport,
      date: selectedDate,
      timeSlot: selectedTime
    });
    
    setRequestStatus('sent');
  };

  if (requestStatus === 'sent') {
    return (
      <div className="text-center py-8">
        <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
          <CalendarIcon className="h-8 w-8 text-dark-50" />
        </div>
        <h3 className="text-xl font-semibold text-white mb-2">Match Request Sent!</h3>
        <p className="text-dark-800 mb-6">
          Your request has been sent to {player.name}. You'll be notified once they respond.
        </p>
        <button
          onClick={() => navigate('/dashboard/activities')}
          className="px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
        >
          View Activities
        </button>
      </div>
    );
  }

  return (
    <div className="bg-dark-300 rounded-xl overflow-hidden group">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <Star className="h-5 w-5 text-gold-400" />
            <span className="text-gold-400 font-medium">{player.matchPercentage}% Match</span>
          </div>
          <span className="text-dark-800">{player.recentActivity}</span>
        </div>

        <div className="flex items-start space-x-4">
          <div className="relative">
            <img
              src={player.image}
              alt={player.name}
              className="w-20 h-20 rounded-xl object-cover border-2 border-gold-400"
            />
            <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 px-2 py-0.5 bg-dark-400 rounded-full text-xs text-white whitespace-nowrap">
              Age {player.age}
            </div>
          </div>

          <div className="flex-1">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-medium text-white">{player.name}</h3>
                <div className="flex items-center text-dark-800 mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  {player.location} • {player.distance}km away
                </div>
                <div className="text-dark-800 text-sm mt-1">
                  {player.company.name} • {player.company.industry}
                </div>
              </div>
              <div className="flex items-center">
                <Star className="h-4 w-4 text-gold-400 mr-1" />
                <span className="text-white">{player.rating}</span>
              </div>
            </div>

            <div className="flex flex-wrap gap-2 mt-3">
              {player.sports.map((sport, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 px-3 py-1.5 bg-dark-400 rounded-full text-sm text-white"
                >
                  <span className="text-xl">{sportEmojis[sport] || '🎯'}</span>
                  <span>{sport}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {!showScheduling && (
          <button
            onClick={() => setShowScheduling(true)}
            className="w-full mt-4 flex items-center justify-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
          >
            <span>Let's Play</span>
            <div className="flex items-center space-x-1">
              {player.sports.slice(0, 3).map((sport, index) => (
                <span key={index} className="text-lg">{sportEmojis[sport] || '🎯'}</span>
              ))}
              {player.sports.length > 3 && <span>...</span>}
            </div>
          </button>
        )}

        {showScheduling && requestStatus === 'idle' && (
          <div className="mt-4 space-y-4">
            <button
              onClick={() => setShowScheduling(false)}
              className="flex items-center text-dark-800 hover:text-white mb-4"
            >
              <ChevronLeft className="h-4 w-4 mr-1" />
              Back
            </button>

            <div>
              <label className="block text-dark-800 mb-2">Select Sport</label>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                {player.sports.map((sport) => (
                  <button
                    key={sport}
                    onClick={() => setSelectedSport(sport)}
                    className={`flex items-center justify-center space-x-2 p-3 rounded-xl transition-colors ${
                      selectedSport === sport
                        ? 'bg-gold-400 text-dark-50'
                        : 'bg-dark-400 text-white hover:bg-dark-500'
                    }`}
                  >
                    <span className="text-xl">{sportEmojis[sport] || '🎯'}</span>
                    <span>{sport}</span>
                  </button>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-dark-800 mb-2">Select Date</label>
              <DatePicker
                selectedDate={selectedDate}
                onDateSelect={setSelectedDate}
                className="w-full max-w-[280px]"
              />
            </div>

            <div>
              <label className="block text-dark-800 mb-2">Select Time</label>
              <div className="grid grid-cols-3 sm:grid-cols-5 gap-2">
                {timeSlots.map((time) => {
                  const period = timePeriods[time];
                  return (
                    <button
                      key={time}
                      onClick={() => setSelectedTime(time)}
                      className={`flex flex-col items-center p-2 rounded-xl transition-colors ${
                        selectedTime === time
                          ? 'bg-gold-400 text-dark-50'
                          : 'bg-dark-400 text-white hover:bg-dark-500'
                      }`}
                    >
                      <span className="text-sm font-medium">{period.label}</span>
                      <span className="text-xs opacity-80">{period.time}</span>
                    </button>
                  );
                })}
              </div>
            </div>

            <button
              onClick={handleConfirm}
              className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 px-4 py-2 rounded-xl transition-colors"
            >
              Send Request
            </button>
          </div>
        )}

        {requestStatus === 'pending' && (
          <div className="bg-dark-400 p-6">
            <div className="flex justify-center">
              <button
                disabled
                className="flex items-center justify-center space-x-2 px-6 py-2 bg-dark-500 text-white rounded-lg cursor-wait"
              >
                <div className="w-4 h-4 border-2 border-t-transparent border-white rounded-full animate-spin" />
                <span>Sending Request...</span>
              </button>
            </div>
          </div>
        )}
      </div>

      <MutualFriendsModal
        isOpen={showMutualFriends}
        onClose={() => setShowMutualFriends(false)}
        playerId={player.id}
        playerName={player.name}
        count={mutualFriendsCount}
      />
    </div>
  );
};

export default MatchCard;