import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { CheckCircle2, Shield, AlertCircle, Users, Globe, Trophy, Building, Star } from 'lucide-react';
import { FormData } from '../lib/types';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { countries } from 'countries-list';
import CitySearch from './CitySearch';

interface RequestAccessFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_FORM_DATA: FormData = {
  fullName: '',
  gender: '',
  email: '',
  company: '',
  role: '',
  country: '',
  city: '',
  dateOfBirth: '',
};

export const RequestAccessForm: React.FC<RequestAccessFormProps> = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [showSuccess, setShowSuccess] = useState(false);
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const { isSubmitting, submitError, validationErrors, handleSubmit } = useFormSubmission();

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const allFields = Object.keys(formData).reduce((acc, key) => ({
      ...acc,
      [key]: true
    }), {});
    setTouched(allFields);

    const success = await handleSubmit(formData);
    if (success) {
      setShowSuccess(true);
      setFormData(INITIAL_FORM_DATA);
      setTouched({});
    }
  };

  const handleClose = () => {
    setShowSuccess(false);
    setTouched({});
    onClose();
  };

  const handleFieldBlur = (fieldName: string) => {
    if (showSuccess) return;
    setTouched(prev => ({
      ...prev,
      [fieldName]: true
    }));
  };

  const getFieldError = (fieldName: string) => {
    if (showSuccess || !touched[fieldName]) return null;

    if (!formData[fieldName] && fieldName !== 'city') {
      return 'This field is required';
    }

    if (fieldName === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      return 'Please enter a valid email address';
    }

    if (fieldName === 'dateOfBirth') {
      const birthDate = new Date(formData.dateOfBirth);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      if (age < 18) {
        return 'You must be at least 18 years old';
      }
    }

    return validationErrors[fieldName] || null;
  };

  if (showSuccess) {
    return (
      <Dialog open={isOpen} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <div className="text-center py-6">
            <div className="bg-green-400/20 p-4 rounded-full w-16 h-16 mx-auto mb-6 flex items-center justify-center">
              <CheckCircle2 className="h-8 w-8 text-green-400" />
            </div>
            <h2 className="text-2xl font-bold mb-4">Application Received</h2>
            <p className="text-lg mb-4">We review each application to maintain a high-quality network. Not all are accepted, but that's what keeps us exceptional. Stay tuned! 🌟</p>
            <Button onClick={handleClose} className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 py-3 text-lg">
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300 max-h-[95vh] overflow-y-auto">
        <DialogHeader>
          <div className="flex justify-center mb-2">
            <div className="bg-gold-400/20 p-3 rounded-full">
              <Star className="h-6 w-6 text-gold-400" />
            </div>
          </div>
          <DialogTitle className="text-2xl text-center">Apply to Playclub</DialogTitle>
        </DialogHeader>

        {/* Network Benefits */}
        <div className="grid grid-cols-3 gap-3 mb-4">
          <div className="bg-dark-300 rounded-xl p-3 text-center hover:bg-dark-400 transition-all duration-300">
            <div className="bg-dark-400 p-3 rounded-lg w-12 h-12 mx-auto mb-2 flex items-center justify-center">
              <Users className="h-6 w-6 text-gold-400" />
            </div>
            <div className="text-xs">Elite Network</div>
          </div>
          <div className="bg-dark-300 rounded-xl p-3 text-center hover:bg-dark-400 transition-all duration-300">
            <div className="bg-dark-400 p-3 rounded-lg w-12 h-12 mx-auto mb-2 flex items-center justify-center">
              <Globe className="h-6 w-6 text-gold-400" />
            </div>
            <div className="text-xs">Global Access</div>
          </div>
          <div className="bg-dark-300 rounded-xl p-3 text-center hover:bg-dark-400 transition-all duration-300">
            <div className="bg-dark-400 p-3 rounded-lg w-12 h-12 mx-auto mb-2 flex items-center justify-center">
              <Trophy className="h-6 w-6 text-gold-400" />
            </div>
            <div className="text-xs">Premium Venues</div>
          </div>
        </div>

        <form onSubmit={handleFormSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <Input
                value={formData.fullName}
                onChange={(e) => setFormData({...formData, fullName: e.target.value})}
                onBlur={() => handleFieldBlur('fullName')}
                className="bg-dark-300 border-dark-400 text-white h-10"
                placeholder="Full Name *"
              />
              {getFieldError('fullName') && (
                <div className="text-red-400 text-xs flex items-center mt-1">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  {getFieldError('fullName')}
                </div>
              )}
            </div>

            <div className="col-span-2">
              <Input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                onBlur={() => handleFieldBlur('email')}
                className="bg-dark-300 border-dark-400 text-white h-10"
                placeholder="Email *"
              />
              {getFieldError('email') && (
                <div className="text-red-400 text-xs flex items-center mt-1">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  {getFieldError('email')}
                </div>
              )}
            </div>

            <div className="relative">
              <Building className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
              <Input
                value={formData.company}
                onChange={(e) => setFormData({...formData, company: e.target.value})}
                onBlur={() => handleFieldBlur('company')}
                className="pl-10 bg-dark-300 border-dark-400 text-white h-10"
                placeholder="Company *"
              />
              {getFieldError('company') && (
                <div className="text-red-400 text-xs flex items-center mt-1">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  {getFieldError('company')}
                </div>
              )}
            </div>

            <div>
              <Input
                value={formData.role}
                onChange={(e) => setFormData({...formData, role: e.target.value})}
                onBlur={() => handleFieldBlur('role')}
                className="bg-dark-300 border-dark-400 text-white h-10"
                placeholder="Role *"
              />
              {getFieldError('role') && (
                <div className="text-red-400 text-xs flex items-center mt-1">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  {getFieldError('role')}
                </div>
              )}
            </div>

            <div>
              <Select
                value={formData.country}
                onValueChange={(value) => {
                  setFormData({...formData, country: value, city: ''});
                  handleFieldBlur('country');
                }}
              >
                <SelectTrigger className="bg-dark-300 border-dark-400 text-white h-10">
                  <SelectValue placeholder="Country *" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(countries)
                    .map(([code, country]) => ({
                      code,
                      name: country.name
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ code, name }) => (
                      <SelectItem key={code} value={name}>{name}</SelectItem>
                    ))
                  }
                </SelectContent>
              </Select>
              {getFieldError('country') && (
                <div className="text-red-400 text-xs flex items-center mt-1">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  {getFieldError('country')}
                </div>
              )}
            </div>

            <div>
              <CitySearch
                selectedCity={formData.city}
                onCitySelect={(city) => {
                  setFormData({...formData, city: city});
                  handleFieldBlur('city');
                }}
                country={formData.country}
              />
            </div>

            <div>
              <Input
                type="date"
                value={formData.dateOfBirth}
                onChange={(e) => setFormData({...formData, dateOfBirth: e.target.value})}
                onBlur={() => handleFieldBlur('dateOfBirth')}
                className="bg-dark-300 border-dark-400 text-white h-10"
                max={new Date().toISOString().split('T')[0]}
                placeholder="Date of Birth *"
                aria-label="Date of Birth (must be 18 or older)"
                title="Date of Birth (must be 18 or older)"
              />
              <p className="text-dark-800 text-xs mt-1">Must be 18+ years old to apply</p>
              {getFieldError('dateOfBirth') && (
                <div className="text-red-400 text-xs flex items-center mt-1">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  {getFieldError('dateOfBirth')}
                </div>
              )}
            </div>

            <div>
              <Select
                value={formData.gender}
                onValueChange={(value) => {
                  setFormData({...formData, gender: value});
                  handleFieldBlur('gender');
                }}
              >
                <SelectTrigger className="bg-dark-300 border-dark-400 text-white h-10">
                  <SelectValue placeholder="Gender" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="male">Male</SelectItem>
                  <SelectItem value="female">Female</SelectItem>
                  <SelectItem value="rather_not_say">Rather not say</SelectItem>
                </SelectContent>
              </Select>
              {getFieldError('gender') && (
                <div className="text-red-400 text-xs flex items-center mt-1">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  {getFieldError('gender')}
                </div>
              )}
            </div>
          </div>

          {submitError && (
            <div className="text-red-400 text-sm text-center flex items-center justify-center">
              <AlertCircle className="h-4 w-4 mr-2" />
              {submitError}
            </div>
          )}

          <div className="bg-dark-300/50 backdrop-blur-sm p-4 rounded-xl border border-dark-400">
            <div className="flex items-center space-x-3 mb-3">
              <Shield className="h-5 w-5 text-gold-400 flex-shrink-0" />
              <span className="text-sm font-medium text-white">Exclusive Membership</span>
            </div>
          </div>

          <Button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-gradient-to-r from-gold-400 to-gold-500 hover:from-gold-500 hover:to-gold-600 text-dark-50 py-3 text-lg font-medium shadow-lg hover:shadow-xl transition-all duration-300"
          >
            {isSubmitting ? 'Submitting...' : 'Request Access'}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RequestAccessForm;