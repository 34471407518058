import React from 'react';
import { Star } from 'lucide-react';
import { ReviewStats } from '../../types/review';
import { Progress } from '../ui/progress';

interface ReviewStatsProps {
  stats: ReviewStats;
}

const ReviewStatsDisplay: React.FC<ReviewStatsProps> = ({ stats }) => {
  const { averageRating, totalReviews, ratingDistribution } = stats;

  const getPercentage = (count: number) => {
    return totalReviews > 0 ? (count / totalReviews) * 100 : 0;
  };

  return (
    <div className="bg-white dark:bg-dark-200 rounded-2xl p-6 border border-gray-200 dark:border-dark-300">
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Reviews</h3>
          <div className="flex items-center space-x-2 mt-2">
            <div className="flex">
              {[1, 2, 3, 4, 5].map((star) => (
                <Star
                  key={star}
                  className={`h-5 w-5 ${
                    star <= Math.round(averageRating)
                      ? 'fill-gold-400 text-gold-400'
                      : 'text-gray-300 dark:text-dark-800'
                  }`}
                />
              ))}
            </div>
            <span className="text-2xl font-bold text-gray-900 dark:text-white">
              {averageRating.toFixed(1)}
            </span>
          </div>
          <p className="text-gray-500 dark:text-dark-800 mt-1">
            Based on {totalReviews} {totalReviews === 1 ? 'review' : 'reviews'}
          </p>
        </div>
      </div>

      <div className="space-y-3 mt-6">
        {[5, 4, 3, 2, 1].map((rating) => (
          <div key={rating} className="flex items-center space-x-4">
            <div className="flex items-center space-x-1 w-12">
              <span className="text-gray-900 dark:text-white">{rating}</span>
              <Star className="h-4 w-4 text-gold-400" />
            </div>
            <div className="flex-1">
              <Progress
                value={getPercentage(ratingDistribution[rating as keyof typeof ratingDistribution])}
                className="h-2"
              />
            </div>
            <div className="w-12 text-right text-gray-500 dark:text-dark-800">
              {ratingDistribution[rating as keyof typeof ratingDistribution]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewStatsDisplay;