import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Star, Calendar, Briefcase, MessageCircle, Users2 } from 'lucide-react';
import BookSessionModal from './BookSessionModal';
import MutualFriendsModal from './MutualFriendsModal';
import RequestMatchModal from './RequestMatchModal';
import ConnectionButton from '../members/ConnectionButton';

interface PlayerCardProps {
  player: {
    id: string;
    name: string;
    age: number;
    location: string;
    distance: number;
    sports: string[];
    skillLevel: string;
    rating: number;
    matchPercentage: number;
    availability: string[];
    image: string;
    recentActivity: string;
    company: {
      name: string;
      position: string;
      industry: string;
    };
    isTrainer?: boolean;
    trainerInfo?: {
      priceRange: string;
    };
    isConnected?: boolean;
    connectionStatus?: 'none' | 'pending' | 'connected';
  };
}

const sportEmojis: Record<string, string> = {
  'Tennis': '🎾',
  'Golf': '⛳',
  'Swimming': '🏊‍♂️',
  'Running': '🏃‍♂️',
  'Cycling': '🚴‍♂️',
  'Football': '⚽',
  'Basketball': '🏀',
  'Badminton': '🏸',
  'Table Tennis': '🏓',
  'Volleyball': '🏐',
  'Padel': '🎱',
  'Yoga': '🧘‍♀️',
  'Pilates': '🏋️‍♀️'
};

const PlayerCard: React.FC<PlayerCardProps> = ({ player }) => {
  const navigate = useNavigate();
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showMutualFriends, setShowMutualFriends] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const mutualFriendsCount = Math.floor(Math.random() * 10) + 1;

  const handleProfileClick = () => {
    navigate(`/dashboard/members/${player.id}`);
  };

  const handleConnect = async () => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500));
  };

  const handleDisconnect = async () => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500));
  };

  const handleMessageClick = () => {
    navigate('/dashboard/messages', { 
      state: { 
        activeChat: player.id,
        initialMessage: `Hi ${player.name}! I'd like to connect with you on Playclub.`
      }
    });
  };

  return (
    <div className="bg-dark-200 rounded-xl sm:rounded-2xl p-4 sm:p-6 border border-dark-300 hover:border-gold-400 transition-all duration-300">
      <div className="flex flex-col sm:flex-row sm:items-start gap-4 sm:gap-6">
        {/* Profile Image */}
        <button
          onClick={handleProfileClick}
          className="flex-shrink-0 relative mx-auto sm:mx-0"
        >
          <img
            src={player.image}
            alt={player.name}
            className="w-20 h-20 sm:w-24 sm:h-24 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors shadow-lg"
          />
          <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 px-2 py-1 bg-dark-900/90 backdrop-blur-sm rounded-full">
            <div className="flex items-center space-x-1">
              <Star className="h-3 w-3 text-gold-400" />
              <span className="text-white text-sm font-medium">{player.rating}</span>
            </div>
          </div>
        </button>

        {/* Player Info */}
        <div className="flex-1 min-w-0 text-center sm:text-left">
          <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-2">
            <div>
              <button
                onClick={handleProfileClick}
                className="text-xl font-medium text-white hover:text-gold-400 transition-colors"
              >
                {player.name}
              </button>
              <div className="flex items-center justify-center sm:justify-start text-dark-800 mt-1">
                <MapPin className="h-4 w-4 mr-1" />
                {player.location} • {player.distance}km away
              </div>
              <div className="flex items-center justify-center sm:justify-start text-dark-800 mt-1">
                <Briefcase className="h-4 w-4 mr-1" />
                <span className="text-white">{player.company.position}</span>
                <span className="mx-1">•</span>
                <span>{player.company.name}</span>
              </div>
              {/* Mutual Connections */}
              <button
                onClick={() => setShowMutualFriends(true)}
                className="flex items-center justify-center sm:justify-start text-dark-800 hover:text-gold-400 transition-colors mt-2"
              >
                <Users2 className="h-4 w-4 mr-1" />
                {mutualFriendsCount} mutual connection{mutualFriendsCount !== 1 ? 's' : ''}
              </button>
            </div>
            <span className="px-3 py-1 bg-gold-400/10 text-gold-400 rounded-full text-sm font-medium self-center">
              {player.matchPercentage}% Match
            </span>
          </div>

          {/* Sports Grid */}
          <div className="mt-4">
            <div className="flex flex-wrap justify-center sm:justify-start gap-2">
              {player.sports.map((sport, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 px-3 py-1.5 bg-dark-300 rounded-full hover:bg-dark-400 transition-colors cursor-default group"
                >
                  <span className="text-xl group-hover:scale-110 transition-transform">
                    {sportEmojis[sport] || '🎯'}
                  </span>
                  <span className="text-sm text-dark-800 group-hover:text-white transition-colors">
                    {sport}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-between mt-4 pt-4 border-t border-dark-300 gap-3">
            <div className="flex items-center gap-2 w-full sm:w-auto">
              <button
                onClick={() => setShowMatchModal(true)}
                className="flex-1 sm:flex-initial flex items-center justify-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors text-sm"
              >
                <Calendar className="h-4 w-4" />
                <span>Request Match</span>
              </button>
              <ConnectionButton
                isConnected={player.isConnected}
                onConnect={handleConnect}
                onDisconnect={handleDisconnect}
              />
              <button 
                onClick={handleMessageClick}
                className="p-2 bg-dark-300 hover:bg-dark-400 rounded-lg transition-colors"
              >
                <MessageCircle className="h-5 w-5 text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      {player.isTrainer && (
        <BookSessionModal
          isOpen={showBookingModal}
          onClose={() => setShowBookingModal(false)}
          trainer={{
            id: player.id,
            name: player.name,
            sports: player.sports,
            priceRange: player.trainerInfo?.priceRange || '',
            location: player.location
          }}
        />
      )}

      <MutualFriendsModal
        isOpen={showMutualFriends}
        onClose={() => setShowMutualFriends(false)}
        playerId={player.id}
        playerName={player.name}
        count={mutualFriendsCount}
      />

      <RequestMatchModal
        isOpen={showMatchModal}
        onClose={() => setShowMatchModal(false)}
        member={{
          id: player.id,
          name: player.name,
          city: player.location,
          sports: player.sports
        }}
      />
    </div>
  );
};

export default PlayerCard;