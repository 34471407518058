import React from 'react';
import { Activity, Trophy, MapPin, Briefcase, Globe, UserPlus } from 'lucide-react';
import type { Member } from '../../../types/member';
import { useNavigate } from 'react-router-dom';

interface AboutTabProps {
  member: Member;
  sports: string[];
  achievements: string[];
}

const AboutTab: React.FC<AboutTabProps> = ({ member, sports }) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleInviterClick = (inviterId: string) => {
    navigate(`/dashboard/members/${inviterId}`);
  };

  return (
    <div className="space-y-6">
      {/* Content Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Sports & Skills */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
            <Activity className="h-5 w-5 text-gold-400 mr-2" />
            Sports & Skills
          </h3>
          <div className="space-y-4">
            {sports.map((sport, index) => (
              <div key={index} className="bg-dark-300 rounded-xl p-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-white">{sport}</span>
                  <span className="text-gold-400">{member.skillLevel}</span>
                </div>
                <div className="w-full bg-dark-400 rounded-full h-2">
                  <div
                    className="bg-gold-400 h-2 rounded-full transition-all duration-300"
                    style={{ width: `${85 + Math.random() * 15}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Professional Info */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
            <Briefcase className="h-5 w-5 text-gold-400 mr-2" />
            Professional Info
          </h3>
          <div className="space-y-4">
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="text-dark-800 mb-1">Company</div>
              <div className="text-white">{member.company}</div>
            </div>
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="text-dark-800 mb-1">Position</div>
              <div className="text-white">{member.jobTitle}</div>
            </div>
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="text-dark-800 mb-1">Industry</div>
              <div className="text-white">{member.industry}</div>
            </div>
          </div>
        </div>

        {/* Membership Info */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
            <UserPlus className="h-5 w-5 text-gold-400 mr-2" />
            Membership Info
          </h3>
          <div className="space-y-4">
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="text-dark-800 mb-1">Member Since</div>
              <div className="text-white">{formatDate(member.joinDate)}</div>
            </div>
            {member.invitedBy && (
              <div className="bg-dark-300 rounded-xl p-4">
                <div className="text-dark-800 mb-2">Invited By</div>
                <button
                  onClick={() => handleInviterClick(member.invitedBy!.id)}
                  className="flex items-center space-x-3 hover:bg-dark-400 p-2 rounded-lg transition-colors"
                >
                  <img
                    src={member.invitedBy.image}
                    alt={member.invitedBy.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <span className="text-white hover:text-gold-400 transition-colors">
                    {member.invitedBy.name}
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Trainer Info (if applicable) */}
        {member.isTrainer && member.trainerInfo && (
          <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
            <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
              <Trophy className="h-5 w-5 text-gold-400 mr-2" />
              Trainer Info
            </h3>
            <div className="space-y-4">
              <div className="bg-dark-300 rounded-xl p-4">
                <div className="text-dark-800 mb-1">Experience</div>
                <div className="text-white">{member.trainerInfo.yearsExperience} years</div>
              </div>
              <div className="bg-dark-300 rounded-xl p-4">
                <div className="text-dark-800 mb-1">Rate</div>
                <div className="text-white">{member.trainerInfo.priceRange}</div>
              </div>
              <div className="bg-dark-300 rounded-xl p-4">
                <div className="text-dark-800 mb-1">Languages</div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {member.trainerInfo.languages.map((lang, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white"
                    >
                      {lang}
                    </span>
                  ))}
                </div>
              </div>
              <div className="bg-dark-300 rounded-xl p-4">
                <div className="text-dark-800 mb-1">Specialties</div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {member.trainerInfo.specialties.map((specialty, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white"
                    >
                      {specialty}
                    </span>
                  ))}
                </div>
              </div>
              <div className="bg-dark-300 rounded-xl p-4">
                <div className="text-dark-800 mb-1">Certifications</div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {member.trainerInfo.certifications.map((cert, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white"
                    >
                      {cert}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Cities Played */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
            <Globe className="h-5 w-5 text-gold-400 mr-2" />
            Cities Played
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {member.citiesPlayed.map((city, index) => (
              <div key={index} className="bg-dark-300 rounded-xl p-4 flex items-center space-x-3">
                <MapPin className="h-5 w-5 text-gold-400" />
                <span className="text-white">{city}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTab;