import React, { useState, useCallback } from 'react';
import { MapPin, Compass } from 'lucide-react';
import { Command, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem } from './ui/command';
import { searchCities, findNearestCities } from '../lib/api';
import type { City } from '../types/city';
import { cities } from '../data/cities';
import Fuse from 'fuse.js';

interface CitySearchProps {
  selectedCity: string;
  onCitySelect: (city: string) => void;
  country?: string;
}

const CitySearch: React.FC<CitySearchProps> = ({ selectedCity, onCitySelect, country }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<City[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLocating, setIsLocating] = useState(false);

  // Initialize Fuse.js for fuzzy search
  const fuse = new Fuse(cities, {
    keys: ['name'],
    threshold: 0.3,
    includeScore: true
  });

  const handleSearch = useCallback((query: string) => {
    setSearchTerm(query);
    if (!query) {
      setSearchResults([]);
      return;
    }

    // Perform fuzzy search
    const results = fuse.search(query).map(result => result.item);
    const filteredResults = country 
      ? results.filter(city => city.country === country)
      : results;
    
    setSearchResults(filteredResults);
  }, [country, fuse]);

  const handleFindNearest = async () => {
    setIsLocating(true);
    try {
      if (!navigator.geolocation) {
        throw new Error('Geolocation is not supported by your browser');
      }

      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const nearestCities = await findNearestCities(position.coords);
      if (country) {
        const filteredCities = nearestCities.filter(city => city.country === country);
        if (filteredCities.length > 0) {
          onCitySelect(filteredCities[0].name);
          setSearchTerm(filteredCities[0].name);
        }
      } else if (nearestCities.length > 0) {
        onCitySelect(nearestCities[0].name);
        setSearchTerm(nearestCities[0].name);
      }
    } catch (error) {
      console.error('Error finding nearest location:', error);
    } finally {
      setIsLocating(false);
    }
  };

  const handleSelect = (city: string) => {
    onCitySelect(city);
    setSearchTerm(city);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={() => setIsOpen(true)}
          placeholder="Enter city..."
          className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white placeholder-dark-800"
        />
        {!country && (
          <button
            onClick={handleFindNearest}
            disabled={isLocating}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-dark-800 hover:text-gold-400 disabled:opacity-50"
          >
            <Compass className="h-4 w-4" />
          </button>
        )}
      </div>
      
      {isOpen && searchTerm && searchResults.length > 0 && (
        <div className="absolute top-full left-0 right-0 mt-1 z-50">
          <div className="bg-dark-200 border border-dark-400 rounded-xl shadow-lg max-h-[200px] overflow-y-auto">
            {searchResults.map((city) => (
              <button
                key={city.name}
                onClick={() => handleSelect(city.name)}
                className="w-full flex items-center px-3 py-2 hover:bg-dark-300 text-left"
              >
                <MapPin className="h-4 w-4 mr-2 text-dark-800" />
                <span className="text-white">{city.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CitySearch;