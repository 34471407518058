import React from 'react';
import { Star, ThumbsUp, MoreVertical, CheckCircle } from 'lucide-react';
import { Review } from '../../types/review';
import { formatDistanceToNow } from 'date-fns';

interface ReviewListProps {
  reviews: Review[];
  onLike?: (reviewId: string) => Promise<void>;
  onReport?: (reviewId: string) => Promise<void>;
}

const ReviewList: React.FC<ReviewListProps> = ({
  reviews,
  onLike,
  onReport
}) => {
  return (
    <div className="space-y-6">
      {reviews.map((review) => (
        <div
          key={review.id}
          className="bg-dark-300 rounded-xl p-4 hover:bg-dark-400 transition-colors"
        >
          <div className="flex items-start justify-between">
            <div className="flex items-start space-x-3">
              <img
                src={review.authorImage}
                alt={review.authorName}
                className="w-10 h-10 rounded-full object-cover"
              />
              <div>
                <div className="flex items-center space-x-2">
                  <span className="font-medium text-white">{review.authorName}</span>
                  {review.isVerified && (
                    <CheckCircle className="h-4 w-4 text-green-400" />
                  )}
                </div>
                <div className="flex items-center space-x-2 mt-1">
                  <div className="flex">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`h-4 w-4 ${
                          star <= review.rating
                            ? 'fill-gold-400 text-gold-400'
                            : 'text-dark-800'
                        }`}
                      />
                    ))}
                  </div>
                  <span className="text-dark-800 text-sm">
                    {formatDistanceToNow(new Date(review.timestamp), { addSuffix: true })}
                  </span>
                </div>
              </div>
            </div>
            <button className="text-dark-800 hover:text-white p-1">
              <MoreVertical className="h-5 w-5" />
            </button>
          </div>

          <p className="text-white mt-3">{review.content}</p>

          {(review.sport || review.venue) && (
            <div className="flex flex-wrap gap-2 mt-3">
              {review.sport && (
                <span className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white">
                  {review.sport}
                </span>
              )}
              {review.venue && (
                <span className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white">
                  {review.venue}
                </span>
              )}
            </div>
          )}

          <div className="flex items-center space-x-4 mt-4">
            <button
              onClick={() => onLike?.(review.id)}
              className={`flex items-center space-x-2 ${
                review.isLiked ? 'text-gold-400' : 'text-dark-800 hover:text-gold-400'
              } transition-colors`}
            >
              <ThumbsUp className="h-4 w-4" />
              <span>{review.likes}</span>
            </button>
            <button
              onClick={() => onReport?.(review.id)}
              className="text-dark-800 hover:text-white text-sm transition-colors"
            >
              Report
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewList;