import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, MoreVertical, Reply } from 'lucide-react';
import { Comment } from '../../types/comment';
import { formatDistanceToNow } from 'date-fns';
import { motion } from 'framer-motion';

interface CommentItemProps {
  comment: Comment;
}

const CommentItem: React.FC<CommentItemProps> = ({ comment }) => {
  const navigate = useNavigate();
  const [isLiked, setIsLiked] = useState(comment.isLiked || false);
  const [likeCount, setLikeCount] = useState(comment.likes);

  const handleAuthorClick = () => {
    navigate(`/dashboard/members/${comment.author.id}`);
  };

  const handleLike = () => {
    setIsLiked(!isLiked);
    setLikeCount(prev => prev + (isLiked ? -1 : 1));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="group flex space-x-3"
    >
      <button
        onClick={handleAuthorClick}
        className="flex-shrink-0 mt-1"
      >
        <img
          src={comment.author.image}
          alt={comment.author.name}
          className="w-8 h-8 rounded-full object-cover border border-dark-400 hover:border-gold-400 transition-colors"
        />
      </button>
      
      <div className="flex-1 min-w-0">
        <div className="bg-dark-300 rounded-2xl px-4 py-2.5">
          <div className="flex items-start justify-between">
            <button
              onClick={handleAuthorClick}
              className="font-medium text-white hover:text-gold-400 transition-colors"
            >
              {comment.author.name}
            </button>
            <button className="opacity-0 group-hover:opacity-100 transition-opacity text-dark-800 hover:text-white">
              <MoreVertical className="h-4 w-4" />
            </button>
          </div>
          <p className="text-white text-sm mt-1">{comment.content}</p>
        </div>
        
        <div className="flex items-center space-x-4 mt-1 px-2">
          <button 
            onClick={handleLike}
            className={`text-xs transition-colors ${
              isLiked ? 'text-gold-400' : 'text-dark-800 hover:text-gold-400'
            }`}
          >
            Like {likeCount > 0 && `· ${likeCount}`}
          </button>
          <button className="text-dark-800 hover:text-gold-400 text-xs transition-colors">
            Reply
          </button>
          <span className="text-dark-800 text-xs">
            {formatDistanceToNow(new Date(comment.timestamp), { addSuffix: true })}
          </span>
        </div>

        {comment.replies && comment.replies.length > 0 && (
          <div className="mt-2 pl-4 space-y-3">
            {comment.replies.map((reply) => (
              <CommentItem key={reply.id} comment={reply} />
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default CommentItem;