import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { 
  MessageSquare, UserPlus, Calendar, AtSign, ChevronRight 
} from 'lucide-react';
import type { Notification } from '../../types/notification';
import { useNotificationStore } from '../../store/notificationStore';
import { cn } from '@/lib/utils';

interface NotificationItemProps {
  notification: Notification;
  onClose?: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ 
  notification,
  onClose 
}) => {
  const navigate = useNavigate();
  const { markAsRead } = useNotificationStore();

  const getIcon = () => {
    switch (notification.type) {
      case 'match_request':
      case 'match_accepted':
      case 'match_declined':
        return Calendar;
      case 'new_message':
        return MessageSquare;
      case 'mention':
        return AtSign;
      case 'connection_request':
      case 'connection_accepted':
        return UserPlus;
      default:
        return MessageSquare;
    }
  };

  const handleClick = () => {
    markAsRead(notification.id);
    
    if (notification.actionUrl) {
      // Handle navigation with metadata for tab selection
      if (notification.metadata?.activeTab) {
        navigate(notification.actionUrl, { 
          state: { activeTab: notification.metadata.activeTab }
        });
      } else {
        navigate(notification.actionUrl);
      }
    }
    
    if (onClose) onClose();
  };

  const Icon = getIcon();

  return (
    <button
      onClick={handleClick}
      className={cn(
        "w-full text-left p-4 transition-colors",
        "hover:bg-gray-50 dark:hover:bg-dark-300",
        !notification.read && "bg-gray-50/50 dark:bg-dark-300/50"
      )}
    >
      <div className="flex items-start space-x-4">
        {notification.sender ? (
          <img
            src={notification.sender.image}
            alt={notification.sender.name}
            className="w-10 h-10 rounded-full object-cover"
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-gray-100 dark:bg-dark-400 flex items-center justify-center">
            <Icon className="h-5 w-5 text-gold-400" />
          </div>
        )}
        <div className="flex-1 min-w-0">
          <p className="text-gray-900 dark:text-white font-medium">
            {notification.title}
          </p>
          <p className="text-gray-500 dark:text-dark-800 text-sm truncate">
            {notification.message}
          </p>
          <p className="text-gray-400 dark:text-dark-800 text-xs mt-1">
            {formatDistanceToNow(new Date(notification.timestamp), { addSuffix: true })}
          </p>
        </div>
        <ChevronRight className="h-5 w-5 text-gray-400 dark:text-dark-800 flex-shrink-0" />
      </div>
    </button>
  );
};

export default NotificationItem;