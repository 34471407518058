import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from '@/lib/utils';

interface CalendarProps {
  selectedDate: string;
  onDateSelect: (date: string) => void;
  minDate?: string;
  maxDate?: string;
  className?: string;
}

const Calendar: React.FC<CalendarProps> = ({
  selectedDate,
  onDateSelect,
  minDate = new Date().toISOString().split('T')[0],
  maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
  className
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [hoverDate, setHoverDate] = useState<string | null>(null);

  const weekDays = [
    { key: 'sun', label: 'S' },
    { key: 'mon', label: 'M' },
    { key: 'tue', label: 'T' },
    { key: 'wed', label: 'W' },
    { key: 'thu', label: 'T' },
    { key: 'fri', label: 'F' },
    { key: 'sat', label: 'S' }
  ];

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];
    
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null);
    }
    
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(year, month, i));
    }
    
    return days;
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  const isDateDisabled = (date: Date) => {
    const dateStr = formatDate(date);
    return dateStr < minDate || dateStr > maxDate;
  };

  return (
    <div className={cn("bg-dark-400 rounded-lg p-2", className)}>
      <div className="flex items-center justify-between mb-1">
        <button
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1))}
          className="p-1 hover:bg-dark-500 rounded-lg transition-colors"
        >
          <ChevronLeft className="h-3 w-3 text-dark-800" />
        </button>
        <span className="text-white text-xs">
          {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </span>
        <button
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1))}
          className="p-1 hover:bg-dark-500 rounded-lg transition-colors"
        >
          <ChevronRight className="h-3 w-3 text-dark-800" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-0.5">
        {weekDays.map(day => (
          <div key={day.key} className="text-center text-dark-800 text-[10px] py-0.5">
            {day.label}
          </div>
        ))}
        {getDaysInMonth(currentMonth).map((date, index) => (
          <div key={`day-${index}`} className="aspect-square">
            {date ? (
              <button
                onClick={() => !isDateDisabled(date) && onDateSelect(formatDate(date))}
                onMouseEnter={() => setHoverDate(formatDate(date))}
                onMouseLeave={() => setHoverDate(null)}
                disabled={isDateDisabled(date)}
                className={cn(
                  "w-full h-full flex items-center justify-center text-[10px] rounded transition-colors",
                  isDateDisabled(date)
                    ? "text-dark-800 cursor-not-allowed"
                    : formatDate(date) === selectedDate
                    ? "bg-gold-400 text-dark-50"
                    : hoverDate === formatDate(date)
                    ? "bg-gold-400/50 text-dark-50"
                    : "text-white hover:bg-dark-500"
                )}
              >
                {date.getDate()}
              </button>
            ) : (
              <div className="w-full h-full" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;