import React from 'react';
import { CommandGroup } from '../ui/command';
import { SearchResultItem } from './SearchResultItem';
import { Loader2 } from 'lucide-react';
import { SearchResults as SearchResultsType } from '../../types/search';
import { useTheme } from '../../contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface SearchResultsProps {
  isLoading: boolean;
  query: string;
  results: SearchResultsType;
  hasSearched: boolean;
  onSelect: (type: string, id: string) => void;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  isLoading,
  query,
  results,
  hasSearched,
  onSelect,
}) => {
  const { isDarkMode } = useTheme();
  const hasResults = Object.values(results).some(arr => arr.length > 0);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-6">
        <Loader2 className={cn(
          "h-6 w-6 animate-spin",
          isDarkMode ? "text-dark-800" : "text-gray-400"
        )} />
      </div>
    );
  }

  if (!query) {
    return (
      <div className={cn(
        "py-6 text-center",
        isDarkMode ? "text-dark-800" : "text-gray-500"
      )}>
        Start typing to search...
      </div>
    );
  }

  if (hasSearched && !hasResults) {
    return (
      <div className={cn(
        "py-6 text-center",
        isDarkMode ? "text-dark-800" : "text-gray-500"
      )}>
        No results found for "{query}"
      </div>
    );
  }

  return (
    <>
      {results.players.length > 0 && (
        <CommandGroup heading="Players">
          {results.players.map(player => (
            <SearchResultItem
              key={player.id}
              item={player}
              type="player"
              onSelect={onSelect}
            />
          ))}
        </CommandGroup>
      )}

      {results.trainers.length > 0 && (
        <CommandGroup heading="Trainers">
          {results.trainers.map(trainer => (
            <SearchResultItem
              key={trainer.id}
              item={trainer}
              type="trainer"
              onSelect={onSelect}
            />
          ))}
        </CommandGroup>
      )}

      {results.facilities.length > 0 && (
        <CommandGroup heading="Facilities">
          {results.facilities.map(facility => (
            <SearchResultItem
              key={facility.id}
              item={facility}
              type="facility"
              onSelect={onSelect}
            />
          ))}
        </CommandGroup>
      )}

      {results.posts.length > 0 && (
        <CommandGroup heading="Posts">
          {results.posts.map(post => (
            <SearchResultItem
              key={post.id}
              item={post}
              type="post"
              onSelect={onSelect}
            />
          ))}
        </CommandGroup>
      )}
    </>
  );
};