import React, { useState } from 'react';
import { Users, MapPin, Trophy, Star, ChevronRight, Plus, Search, Filter, Globe, Shield } from 'lucide-react';
import CreateClubModal from './CreateClubModal';
import ClubDetailsModal from './ClubDetailsModal';
import JoinClubModal from './JoinClubModal';

const ClubsList: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [selectedClub, setSelectedClub] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');

  const clubs = [
    {
      id: '1',
      name: 'Monte Carlo Tennis Club',
      image: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=800&q=80',
      members: 324,
      rating: 4.9,
      location: 'Monte Carlo, Monaco',
      amenities: ['Pro Courts', 'Training Programs', 'Events'],
      exclusivity: 'Private',
      sports: ['Tennis', 'Padel'],
      description: 'Elite tennis club for professionals and enthusiasts',
      founder: {
        name: 'Sarah Connor',
        image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
      },
      admins: [
        {
          name: 'James Wilson',
          image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
        }
      ],
      rules: [
        'Professional conduct required',
        'Minimum rating of 4.5',
        'Active participation expected',
        'Proper tennis attire required'
      ]
    },
    {
      id: '2',
      name: 'Elite Golf Society',
      image: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=800&q=80',
      members: 156,
      rating: 4.8,
      location: 'Nice, France',
      amenities: ['Championship Course', 'Pro Shop', 'Events'],
      exclusivity: 'Invitation Only',
      sports: ['Golf'],
      description: 'Exclusive golf club for business professionals',
      founder: {
        name: 'Michael Chen',
        image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80'
      },
      admins: [],
      rules: [
        'Handicap verification required',
        'Club dress code applies',
        'Etiquette standards enforced',
        'Regular participation expected'
      ]
    }
  ];

  const handleRequestJoin = (club: any) => {
    setSelectedClub(club);
    setShowJoinModal(true);
  };

  const handleViewDetails = (club: any) => {
    setSelectedClub(club);
    setShowDetailsModal(true);
  };

  return (
    <div className="space-y-6">
      {/* Header with Create Club Button */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
          <div>
            <h2 className="text-xl font-bold text-white">Sports Clubs</h2>
            <p className="text-dark-800 mt-1">Join exclusive clubs or create your own</p>
          </div>
          <button
            onClick={() => setShowCreateModal(true)}
            className="flex items-center space-x-2 px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
          >
            <Plus className="h-5 w-5" />
            <span>Create Club</span>
          </button>
        </div>

        {/* Search and Filters */}
        <div className="mt-6 space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <input
              type="text"
              placeholder="Search clubs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
            />
          </div>
          <div className="flex flex-wrap gap-4">
            <select
              value={selectedSport}
              onChange={(e) => setSelectedSport(e.target.value)}
              className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
            >
              <option value="all">All Sports</option>
              <option value="tennis">Tennis</option>
              <option value="golf">Golf</option>
              <option value="padel">Padel</option>
            </select>
            <select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
            >
              <option value="all">All Locations</option>
              <option value="monaco">Monaco</option>
              <option value="nice">Nice</option>
              <option value="paris">Paris</option>
            </select>
          </div>
        </div>
      </div>

      {/* Clubs Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {clubs.map((club) => (
          <div
            key={club.id}
            className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="relative h-48">
              <img
                src={club.image}
                alt={club.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-4 right-4 px-3 py-1 bg-gold-400 text-dark-50 rounded-full text-sm">
                {club.exclusivity}
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/60 to-transparent" />
              <div className="absolute bottom-4 left-4 right-4">
                <h3 className="text-xl font-semibold text-white">{club.name}</h3>
                <div className="flex items-center text-dark-800 mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  {club.location}
                </div>
              </div>
            </div>

            <div className="p-6">
              <div className="flex items-center space-x-4 mb-4">
                <div className="flex -space-x-3">
                  <img
                    src={club.founder.image}
                    alt={club.founder.name}
                    className="w-10 h-10 rounded-full border-2 border-dark-200"
                    title={`Founder: ${club.founder.name}`}
                  />
                  {club.admins.map((admin, index) => (
                    <img
                      key={index}
                      src={admin.image}
                      alt={admin.name}
                      className="w-10 h-10 rounded-full border-2 border-dark-200"
                      title={`Admin: ${admin.name}`}
                    />
                  ))}
                </div>
                <div className="flex items-center">
                  <Star className="h-4 w-4 text-gold-400 mr-1" />
                  <span className="text-white">{club.rating}</span>
                </div>
              </div>

              <p className="text-dark-800 mb-4">{club.description}</p>

              <div className="flex flex-wrap gap-2 mb-4">
                {club.sports.map((sport, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white"
                  >
                    {sport}
                  </span>
                ))}
              </div>

              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="flex items-center text-dark-800">
                  <Users className="h-4 w-4 mr-2" />
                  {club.members} members
                </div>
                <div className="flex items-center text-dark-800">
                  <Globe className="h-4 w-4 mr-2" />
                  {club.amenities.length} amenities
                </div>
              </div>

              <div className="flex space-x-4">
                <button
                  onClick={() => handleRequestJoin(club)}
                  className="flex-1 bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-xl transition-colors"
                >
                  Request to Join
                </button>
                <button
                  onClick={() => handleViewDetails(club)}
                  className="px-6 py-2 border border-dark-400 text-white rounded-xl hover:bg-dark-300 transition-colors"
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modals */}
      {showCreateModal && (
        <CreateClubModal
          isOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
        />
      )}

      {selectedClub && showDetailsModal && (
        <ClubDetailsModal
          isOpen={showDetailsModal}
          onClose={() => {
            setShowDetailsModal(false);
            setSelectedClub(null);
          }}
          onRequestJoin={() => {
            setShowDetailsModal(false);
            handleRequestJoin(selectedClub);
          }}
          club={selectedClub}
        />
      )}

      {selectedClub && showJoinModal && (
        <JoinClubModal
          isOpen={showJoinModal}
          onClose={() => {
            setShowJoinModal(false);
            setSelectedClub(null);
          }}
          club={selectedClub}
        />
      )}
    </div>
  );
};

export default ClubsList;