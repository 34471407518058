import React, { useState } from 'react';
import { Switch } from '../../ui/switch';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';

const NotificationSettings: React.FC = () => {
  const [settings, setSettings] = useState({
    email: {
      matches: true,
      messages: true,
      connections: true,
      events: true,
      marketing: false
    },
    push: {
      matches: true,
      messages: true,
      connections: true,
      events: true
    }
  });

  const handleToggle = (category: 'email' | 'push', setting: string) => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [setting]: !prev[category][setting as keyof typeof prev[typeof category]]
      }
    }));
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <h2 className="text-xl font-semibold text-white mb-6">Notification Settings</h2>

      {/* Email Notifications */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-white mb-4">Email Notifications</h3>
        <div className="space-y-4">
          {Object.entries(settings.email).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between">
              <Label className="text-dark-800">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Label>
              <Switch
                checked={value}
                onCheckedChange={() => handleToggle('email', key)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Push Notifications */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-white mb-4">Push Notifications</h3>
        <div className="space-y-4">
          {Object.entries(settings.push).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between">
              <Label className="text-dark-800">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Label>
              <Switch
                checked={value}
                onCheckedChange={() => handleToggle('push', key)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end">
        <Button className="bg-gold-400 hover:bg-gold-500 text-dark-50">
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default NotificationSettings;