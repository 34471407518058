import { BrandAsset } from './types';

export const BRAND_ASSETS = {
  logo: {
    full: {
      color: {
        withBg: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Color%20logo%20with%20background-DqnkcQZ1Ba4d19Hw62CWVAtdfhfTBI.png',
          alt: 'Playclub Full Color Logo with Background'
        },
        transparent: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Color%20logo%20-%20no%20background-GkP4zJgpaTZKYhkz22VpXHzyLyx3Hj.png',
          alt: 'Playclub Full Color Logo'
        }
      },
      white: {
        transparent: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/White%20logo%20-%20no%20background-orxsmBEIaIQlqyVQP1fnj5nliNPNaH.png',
          alt: 'Playclub Full White Logo'
        }
      },
      black: {
        transparent: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Black%20logo%20-%20no%20background-jlz2QSH0CdzZ92CWy4G2y9MPrVGcBl.png',
          alt: 'Playclub Full Black Logo'
        }
      }
    },
    symbol: {
      color: {
        transparent: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/symbol-umVohOVwZpOdeCiAd0KBSK8mZHZ9BL.png',
          alt: 'Playclub Symbol'
        }
      }
    },
    text: {
      white: {
        transparent: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/text%20logo%20white%20fonts-9xi9fzWk2lR9syL1zCTYf65O4opRyI.png',
          alt: 'Playclub Text Logo'
        }
      }
    },
    combined: {
      dark: {
        transparent: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/playclub_logo_dark_mode_space-UWuCxsgn7nO0J7TlJXESljOdHhuYPg.png',
          alt: 'Playclub Combined Logo Dark Mode'
        }
      },
      light: {
        transparent: {
          url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/playclub_logo_light_mode_space-LM3YSF7iBU6X3ILvNgNJ0EdFGLjFmn.png',
          alt: 'Playclub Combined Logo Light Mode'
        }
      }
    }
  },
  social: {
    profile: [
      {
        url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Social_Profile_Mirko-pLJhyS0olnV9mhomoT19Kl9hKyXPCk.png',
        alt: 'Playclub Social Profile 1'
      },
      {
        url: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Social_Profile_Mirko%20(1)-QiJkvqqeMao0yqGxmvy6tuQlCJqT3A.png',
        alt: 'Playclub Social Profile 2'
      }
    ]
  }
} as const;

export const getLogoAsset = (
  variant: string = 'full',
  colorScheme: string = 'color',
  background: string = 'transparent'
): BrandAsset => {
  try {
    if (variant === 'combined') {
      // Use dark logo for white colorScheme, light logo for others
      return BRAND_ASSETS.logo.combined[colorScheme === 'white' ? 'dark' : 'light'].transparent;
    }
    
    const variantAssets = BRAND_ASSETS.logo[variant as keyof typeof BRAND_ASSETS.logo];
    const colorAssets = variantAssets[colorScheme as keyof typeof variantAssets];
    const asset = colorAssets[background as keyof typeof colorAssets];
    
    if (!asset) {
      throw new Error('Asset not found');
    }
    
    return asset;
  } catch (error) {
    // Fallback to full color logo with transparent background
    return BRAND_ASSETS.logo.full.color.transparent;
  }
};