import React, { useState } from 'react';
import { Star } from 'lucide-react';
import ReviewModal from '../reviews/ReviewModal';
import ReviewList from '../reviews/ReviewList';
import ReviewStatsDisplay from '../reviews/ReviewStats';
import type { Review, ReviewStats } from '../../types/review';

interface ReviewsSectionProps {
  memberId: string;
  memberName: string;
  sports: string[];
  rating: number;
  reviewCount: number;
}

const ReviewsSection: React.FC<ReviewsSectionProps> = ({
  memberId,
  memberName,
  sports,
  rating,
  reviewCount
}) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [stats, setStats] = useState<ReviewStats>({
    averageRating: rating,
    totalReviews: reviewCount,
    ratingDistribution: {
      1: Math.floor(Math.random() * 10),
      2: Math.floor(Math.random() * 15),
      3: Math.floor(Math.random() * 20),
      4: Math.floor(Math.random() * 30),
      5: Math.floor(Math.random() * 40)
    }
  });

  const handleReviewSubmit = async (review: {
    rating: number;
    content: string;
    sport?: string;
    venue?: string;
  }) => {
    // In a real app, this would be an API call
    const newReview: Review = {
      id: Date.now().toString(),
      authorId: 'current-user',
      authorName: 'Uri Schneider',
      authorImage: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
      targetId: memberId,
      rating: review.rating,
      content: review.content,
      sport: review.sport,
      venue: review.venue,
      timestamp: new Date().toISOString(),
      likes: 0,
      isVerified: true
    };

    setReviews(prev => [newReview, ...prev]);
    
    // Update stats
    const newTotal = stats.totalReviews + 1;
    const newAvg = ((stats.averageRating * stats.totalReviews) + review.rating) / newTotal;
    
    setStats(prev => ({
      ...prev,
      averageRating: newAvg,
      totalReviews: newTotal,
      ratingDistribution: {
        ...prev.ratingDistribution,
        [review.rating]: prev.ratingDistribution[review.rating as keyof typeof prev.ratingDistribution] + 1
      }
    }));
  };

  const handleLikeReview = async (reviewId: string) => {
    setReviews(prev => 
      prev.map(review => 
        review.id === reviewId
          ? {
              ...review,
              likes: review.isLiked ? review.likes - 1 : review.likes + 1,
              isLiked: !review.isLiked
            }
          : review
      )
    );
  };

  const handleReportReview = async (reviewId: string) => {
    // In a real app, this would be an API call
    console.log('Reporting review:', reviewId);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold text-white">Reviews</h3>
        <button
          onClick={() => setShowReviewModal(true)}
          className="px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
        >
          Write Review
        </button>
      </div>

      <ReviewStatsDisplay stats={stats} />
      
      <ReviewList
        reviews={reviews}
        onLike={handleLikeReview}
        onReport={handleReportReview}
      />

      <ReviewModal
        isOpen={showReviewModal}
        onClose={() => setShowReviewModal(false)}
        targetId={memberId}
        targetName={memberName}
        targetType="member"
        onSubmit={handleReviewSubmit}
        sports={sports}
      />
    </div>
  );
};

export default ReviewsSection;