import { BadgeCategory } from '../types/badge';

export const badgeCategories: BadgeCategory[] = [
  {
    id: 'matches',
    name: 'Match Master',
    description: 'Badges earned through playing matches',
    badges: [
      {
        id: 'matches-10',
        name: 'Match Starter',
        description: 'Play 10 matches',
        icon: '🎯',
        category: 'matches',
        tier: 'bronze',
        requirement: 10
      },
      {
        id: 'matches-50',
        name: 'Match Enthusiast',
        description: 'Play 50 matches',
        icon: '🎮',
        category: 'matches',
        tier: 'silver',
        requirement: 50
      },
      {
        id: 'matches-100',
        name: 'Match Veteran',
        description: 'Play 100 matches',
        icon: '🏆',
        category: 'matches',
        tier: 'gold',
        requirement: 100
      },
      {
        id: 'matches-500',
        name: 'Match Legend',
        description: 'Play 500 matches',
        icon: '👑',
        category: 'matches',
        tier: 'platinum',
        requirement: 500
      },
      {
        id: 'matches-1000',
        name: 'Match Immortal',
        description: 'Play 1000 matches',
        icon: '⚡',
        category: 'matches',
        tier: 'elite',
        requirement: 1000
      }
    ]
  },
  {
    id: 'cities',
    name: 'Global Player',
    description: 'Badges earned by playing in different cities',
    badges: [
      {
        id: 'cities-3',
        name: 'City Explorer',
        description: 'Play in 3 different cities',
        icon: '🌆',
        category: 'cities',
        tier: 'bronze',
        requirement: 3
      },
      {
        id: 'cities-5',
        name: 'City Hopper',
        description: 'Play in 5 different cities',
        icon: '✈️',
        category: 'cities',
        tier: 'silver',
        requirement: 5
      },
      {
        id: 'cities-10',
        name: 'Globe Trotter',
        description: 'Play in 10 different cities',
        icon: '🌍',
        category: 'cities',
        tier: 'gold',
        requirement: 10
      },
      {
        id: 'cities-20',
        name: 'World Traveler',
        description: 'Play in 20 different cities',
        icon: '🗺️',
        category: 'cities',
        tier: 'platinum',
        requirement: 20
      },
      {
        id: 'cities-50',
        name: 'International Legend',
        description: 'Play in 50 different cities',
        icon: '🌟',
        category: 'cities',
        tier: 'elite',
        requirement: 50
      }
    ]
  },
  {
    id: 'connections',
    name: 'Network Builder',
    description: 'Badges earned through building connections',
    badges: [
      {
        id: 'connections-10',
        name: 'Social Starter',
        description: 'Connect with 10 players',
        icon: '🤝',
        category: 'connections',
        tier: 'bronze',
        requirement: 10
      },
      {
        id: 'connections-50',
        name: 'Network Grower',
        description: 'Connect with 50 players',
        icon: '👥',
        category: 'connections',
        tier: 'silver',
        requirement: 50
      },
      {
        id: 'connections-100',
        name: 'Connection Master',
        description: 'Connect with 100 players',
        icon: '🌐',
        category: 'connections',
        tier: 'gold',
        requirement: 100
      },
      {
        id: 'connections-500',
        name: 'Influencer',
        description: 'Connect with 500 players',
        icon: '💫',
        category: 'connections',
        tier: 'platinum',
        requirement: 500
      },
      {
        id: 'connections-1000',
        name: 'Elite Networker',
        description: 'Connect with 1000 players',
        icon: '🌠',
        category: 'connections',
        tier: 'elite',
        requirement: 1000
      }
    ]
  },
  {
    id: 'training',
    name: 'Training Champion',
    description: 'Badges earned through training and exercises',
    badges: [
      {
        id: 'training-10',
        name: 'Training Initiate',
        description: 'Complete 10 training sessions',
        icon: '💪',
        category: 'training',
        tier: 'bronze',
        requirement: 10
      },
      {
        id: 'training-50',
        name: 'Training Enthusiast',
        description: 'Complete 50 training sessions',
        icon: '🎯',
        category: 'training',
        tier: 'silver',
        requirement: 50
      },
      {
        id: 'training-100',
        name: 'Training Expert',
        description: 'Complete 100 training sessions',
        icon: '🏋️‍♂️',
        category: 'training',
        tier: 'gold',
        requirement: 100
      },
      {
        id: 'training-500',
        name: 'Training Master',
        description: 'Complete 500 training sessions',
        icon: '🎓',
        category: 'training',
        tier: 'platinum',
        requirement: 500
      },
      {
        id: 'training-1000',
        name: 'Training Legend',
        description: 'Complete 1000 training sessions',
        icon: '🔥',
        category: 'training',
        tier: 'elite',
        requirement: 1000
      }
    ]
  },
  {
    id: 'achievements',
    name: 'Special Achievements',
    description: 'Special badges earned through unique accomplishments',
    badges: [
      {
        id: 'win-streak-5',
        name: 'Win Streak',
        description: 'Win 5 matches in a row',
        icon: '🎯',
        category: 'achievements',
        tier: 'bronze',
        requirement: 5
      },
      {
        id: 'tournament-winner',
        name: 'Tournament Champion',
        description: 'Win a tournament',
        icon: '🏆',
        category: 'achievements',
        tier: 'gold',
        requirement: 1
      },
      {
        id: 'perfect-rating',
        name: 'Perfect Rating',
        description: 'Maintain 5.0 rating for a month',
        icon: '⭐',
        category: 'achievements',
        tier: 'platinum',
        requirement: 1
      },
      {
        id: 'multi-sport',
        name: 'Multi-Sport Athlete',
        description: 'Play matches in 3 different sports',
        icon: '🎽',
        category: 'achievements',
        tier: 'silver',
        requirement: 3
      },
      {
        id: 'elite-status',
        name: 'Elite Status',
        description: 'Achieve Elite status in any category',
        icon: '👑',
        category: 'achievements',
        tier: 'elite',
        requirement: 1
      }
    ]
  }
];