import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../ui/button';
import { CheckCircle2, Trophy, Users, Calendar } from 'lucide-react';

interface CompletionStepProps {
  onComplete: () => void;
}

const CompletionStep: React.FC<CompletionStepProps> = ({ onComplete }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-dark-200 rounded-2xl p-8 border border-dark-300 text-center"
    >
      <div className="flex justify-center mb-6">
        <div className="bg-green-400/20 p-4 rounded-full">
          <CheckCircle2 className="h-12 w-12 text-green-400" />
        </div>
      </div>

      <h2 className="text-2xl font-bold text-white mb-4">
        You're in. Let's play!
      </h2>
      <p className="text-dark-800 mb-8 max-w-md mx-auto">
        Your profile is now complete. Get ready to connect, play, and network with elite professionals.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <div className="bg-dark-300 rounded-xl p-4">
          <Trophy className="h-6 w-6 text-gold-400 mb-2 mx-auto" />
          <h3 className="text-lg font-medium text-white mb-1">Find Matches</h3>
          <p className="text-dark-800 text-sm">
            Connect with players at your level
          </p>
        </div>
        
        <div className="bg-dark-300 rounded-xl p-4">
          <Users className="h-6 w-6 text-gold-400 mb-2 mx-auto" />
          <h3 className="text-lg font-medium text-white mb-1">Network</h3>
          <p className="text-dark-800 text-sm">
            Build meaningful connections
          </p>
        </div>
        
        <div className="bg-dark-300 rounded-xl p-4">
          <Calendar className="h-6 w-6 text-gold-400 mb-2 mx-auto" />
          <h3 className="text-lg font-medium text-white mb-1">Book Sessions</h3>
          <p className="text-dark-800 text-sm">
            Reserve courts and trainers
          </p>
        </div>
      </div>

      <Button
        onClick={onComplete}
        className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-8 py-6 rounded-xl text-lg"
      >
        Enter Playclub
      </Button>
    </motion.div>
  );
};

export default CompletionStep;