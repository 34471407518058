import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WelcomeStep from './steps/WelcomeStep';
import ProfileStep from './steps/ProfileStep';
import PhoneVerificationStep from './steps/PhoneVerificationStep';
import SportsSelectionStep from './steps/SportsSelectionStep';
import CompletionStep from './steps/CompletionStep';
import { Progress } from '../ui/progress';

export interface OnboardingData {
  fullName: string;
  email: string;
  company: string;
  jobTitle: string;
  dateOfBirth: string;
  gender: string;
  city: string;
  nationality: string;
  phone: string;
  sports: string[];
  availability: string[];
  profilePic?: string;
}

const initialData: OnboardingData = {
  fullName: '',
  email: '',
  company: '',
  jobTitle: '',
  dateOfBirth: '',
  gender: '',
  city: '',
  nationality: '',
  phone: '',
  sports: [],
  availability: [],
  profilePic: ''
};

export default function OnboardingFlow() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<OnboardingData>(initialData);

  const totalSteps = 5;
  const progress = (currentStep / totalSteps) * 100;

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      navigate('/dashboard');
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateFormData = (data: Partial<OnboardingData>) => {
    setFormData({ ...formData, ...data });
  };

  const handleVerificationComplete = () => {
    handleNext();
  };

  const handleComplete = () => {
    navigate('/dashboard');
  };

  return (
    <div className="min-h-screen bg-dark-50 py-12 px-4">
      <div className="max-w-3xl mx-auto">
        {/* Progress bar */}
        <div className="mb-8">
          <Progress value={progress} className="h-2" />
          <div className="flex justify-between mt-2 text-dark-800">
            <span>Step {currentStep} of {totalSteps}</span>
            <span>{Math.round(progress)}% Complete</span>
          </div>
        </div>

        {/* Steps */}
        {currentStep === 1 && (
          <WelcomeStep onNext={handleNext} />
        )}

        {currentStep === 2 && (
          <ProfileStep
            data={formData}
            onUpdate={updateFormData}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}

        {currentStep === 3 && (
          <PhoneVerificationStep
            phone={formData.phone}
            onVerificationComplete={handleVerificationComplete}
            onBack={handleBack}
          />
        )}

        {currentStep === 4 && (
          <SportsSelectionStep
            data={formData}
            onUpdate={updateFormData}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}

        {currentStep === 5 && (
          <CompletionStep onComplete={handleComplete} />
        )}
      </div>
    </div>
  );
}