import React, { useState } from 'react';
import { Star } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Button } from '../ui/button';
import { useNotificationStore } from '../../store/notificationStore';

interface TrainerReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  trainerId: string;
  trainerName: string;
  onSubmit: (review: {
    rating: number;
    content: string;
    sport?: string;
    venue?: string;
  }) => Promise<void>;
  sports?: string[];
  venues?: string[];
}

const TrainerReviewModal: React.FC<TrainerReviewModalProps> = ({
  isOpen,
  onClose,
  trainerId,
  trainerName,
  onSubmit,
  sports,
  venues
}) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [content, setContent] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedVenue, setSelectedVenue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addNotification } = useNotificationStore();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (rating === 0) return;

    setIsSubmitting(true);
    try {
      await onSubmit({
        rating,
        content,
        sport: selectedSport,
        venue: selectedVenue
      });

      addNotification({
        id: `review_${Date.now()}`,
        type: 'new_message',
        title: 'Review Submitted',
        message: `Your review for ${trainerName} has been submitted successfully.`,
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: `/dashboard/members/trainer-${trainerId}`
      });

      onClose();
    } catch (error) {
      console.error('Failed to submit review:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md bg-white dark:bg-dark-200 border-gray-200 dark:border-dark-300">
        <DialogHeader>
          <DialogTitle className="text-gray-900 dark:text-white">
            Review {trainerName}
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6 mt-4">
          {/* Rating Stars */}
          <div className="flex flex-col items-center space-y-2">
            <div className="flex space-x-1">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  onClick={() => setRating(star)}
                  onMouseEnter={() => setHoveredRating(star)}
                  onMouseLeave={() => setHoveredRating(0)}
                  className="p-1 transition-colors focus:outline-none"
                >
                  <Star
                    className={`h-8 w-8 ${
                      star <= (hoveredRating || rating)
                        ? 'fill-gold-400 text-gold-400'
                        : 'text-gray-300 dark:text-dark-800'
                    } transition-colors`}
                  />
                </button>
              ))}
            </div>
            <span className="text-gray-500 dark:text-dark-800">
              {rating === 0 ? 'Select a rating' : `${rating} out of 5 stars`}
            </span>
          </div>

          {/* Sport Selection */}
          {sports && sports.length > 0 && (
            <div>
              <label className="block text-gray-700 dark:text-dark-800 mb-2">Sport</label>
              <select
                value={selectedSport}
                onChange={(e) => setSelectedSport(e.target.value)}
                className="w-full bg-white dark:bg-dark-300 border border-gray-200 dark:border-dark-400 rounded-xl px-4 py-2 text-gray-900 dark:text-white"
              >
                <option value="">Select a sport</option>
                {sports.map((sport) => (
                  <option key={sport} value={sport}>{sport}</option>
                ))}
              </select>
            </div>
          )}

          {/* Venue Selection */}
          {venues && venues.length > 0 && (
            <div>
              <label className="block text-gray-700 dark:text-dark-800 mb-2">Venue</label>
              <select
                value={selectedVenue}
                onChange={(e) => setSelectedVenue(e.target.value)}
                className="w-full bg-white dark:bg-dark-300 border border-gray-200 dark:border-dark-400 rounded-xl px-4 py-2 text-gray-900 dark:text-white"
              >
                <option value="">Select a venue</option>
                {venues.map((venue) => (
                  <option key={venue} value={venue}>{venue}</option>
                ))}
              </select>
            </div>
          )}

          {/* Review Content */}
          <div>
            <label className="block text-gray-700 dark:text-dark-800 mb-2">Review</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Write your review..."
              className="w-full h-32 bg-white dark:bg-dark-300 border border-gray-200 dark:border-dark-400 rounded-xl px-4 py-2 text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-dark-800 resize-none"
              required
            />
          </div>

          <div className="flex justify-end space-x-3">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              disabled={isSubmitting}
              className="border-gray-200 dark:border-dark-400 text-gray-900 dark:text-white"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={rating === 0 || !content.trim() || isSubmitting}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              {isSubmitting ? 'Submitting...' : 'Submit Review'}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TrainerReviewModal;