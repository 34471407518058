import React, { useState } from 'react';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import { cn } from '@/lib/utils';

const PreferenceSettings: React.FC = () => {
  const [preferences, setPreferences] = useState({
    language: 'en',
    timezone: 'Europe/Monaco',
    dateFormat: 'MM/DD/YYYY',
    timeFormat: '24h',
    distanceUnit: 'km',
    theme: 'dark'
  });

  const timezones = [
    { value: 'Europe/Monaco', label: 'Monaco (UTC+1)' },
    { value: 'Europe/London', label: 'London (UTC+0)' },
    { value: 'Europe/Paris', label: 'Paris (UTC+1)' },
    { value: 'Europe/Madrid', label: 'Madrid (UTC+1)' },
    { value: 'Europe/Rome', label: 'Rome (UTC+1)' },
    { value: 'Europe/Berlin', label: 'Berlin (UTC+1)' },
    { value: 'Europe/Amsterdam', label: 'Amsterdam (UTC+1)' },
    { value: 'Europe/Brussels', label: 'Brussels (UTC+1)' },
    { value: 'Europe/Vienna', label: 'Vienna (UTC+1)' },
    { value: 'Europe/Zurich', label: 'Zurich (UTC+1)' },
    { value: 'Europe/Copenhagen', label: 'Copenhagen (UTC+1)' },
    { value: 'Europe/Stockholm', label: 'Stockholm (UTC+1)' },
    { value: 'Europe/Oslo', label: 'Oslo (UTC+1)' },
    { value: 'Europe/Warsaw', label: 'Warsaw (UTC+1)' },
    { value: 'Europe/Budapest', label: 'Budapest (UTC+1)' },
    { value: 'Europe/Prague', label: 'Prague (UTC+1)' },
    { value: 'Europe/Athens', label: 'Athens (UTC+2)' },
    { value: 'Europe/Istanbul', label: 'Istanbul (UTC+3)' },
    { value: 'Europe/Moscow', label: 'Moscow (UTC+3)' },
    { value: 'Asia/Dubai', label: 'Dubai (UTC+4)' },
    { value: 'Asia/Muscat', label: 'Muscat (UTC+4)' },
    { value: 'Asia/Karachi', label: 'Karachi (UTC+5)' },
    { value: 'Asia/Kolkata', label: 'Mumbai (UTC+5:30)' },
    { value: 'Asia/Dhaka', label: 'Dhaka (UTC+6)' },
    { value: 'Asia/Bangkok', label: 'Bangkok (UTC+7)' },
    { value: 'Asia/Singapore', label: 'Singapore (UTC+8)' },
    { value: 'Asia/Hong_Kong', label: 'Hong Kong (UTC+8)' },
    { value: 'Asia/Shanghai', label: 'Shanghai (UTC+8)' },
    { value: 'Asia/Taipei', label: 'Taipei (UTC+8)' },
    { value: 'Asia/Seoul', label: 'Seoul (UTC+9)' },
    { value: 'Asia/Tokyo', label: 'Tokyo (UTC+9)' },
    { value: 'Australia/Perth', label: 'Perth (UTC+8)' },
    { value: 'Australia/Adelaide', label: 'Adelaide (UTC+9:30)' },
    { value: 'Australia/Sydney', label: 'Sydney (UTC+10)' },
    { value: 'Australia/Melbourne', label: 'Melbourne (UTC+10)' },
    { value: 'Australia/Brisbane', label: 'Brisbane (UTC+10)' },
    { value: 'Pacific/Auckland', label: 'Auckland (UTC+12)' },
    { value: 'Pacific/Fiji', label: 'Fiji (UTC+12)' },
    { value: 'America/Anchorage', label: 'Anchorage (UTC-9)' },
    { value: 'America/Los_Angeles', label: 'Los Angeles (UTC-8)' },
    { value: 'America/Vancouver', label: 'Vancouver (UTC-8)' },
    { value: 'America/Phoenix', label: 'Phoenix (UTC-7)' },
    { value: 'America/Denver', label: 'Denver (UTC-7)' },
    { value: 'America/Chicago', label: 'Chicago (UTC-6)' },
    { value: 'America/Mexico_City', label: 'Mexico City (UTC-6)' },
    { value: 'America/New_York', label: 'New York (UTC-5)' },
    { value: 'America/Toronto', label: 'Toronto (UTC-5)' },
    { value: 'America/Montreal', label: 'Montreal (UTC-5)' },
    { value: 'America/Bogota', label: 'Bogota (UTC-5)' },
    { value: 'America/Sao_Paulo', label: 'São Paulo (UTC-3)' },
    { value: 'America/Buenos_Aires', label: 'Buenos Aires (UTC-3)' },
    { value: 'Atlantic/Cape_Verde', label: 'Cape Verde (UTC-1)' },
    { value: 'Atlantic/Azores', label: 'Azores (UTC-1)' }
  ].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="bg-white dark:bg-dark-200 rounded-2xl p-6 border border-gray-200 dark:border-dark-300">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Preferences</h2>

      <div className="space-y-6">
        <div>
          <Label className="text-gray-700 dark:text-dark-800">Language</Label>
          <Select
            value={preferences.language}
            onValueChange={(value) => setPreferences({ ...preferences, language: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400">
              <SelectItem value="en" className="text-gray-900 dark:text-white">English</SelectItem>
              <SelectItem value="fr" className="text-gray-900 dark:text-white">Français</SelectItem>
              <SelectItem value="es" className="text-gray-900 dark:text-white">Español</SelectItem>
              <SelectItem value="de" className="text-gray-900 dark:text-white">Deutsch</SelectItem>
              <SelectItem value="it" className="text-gray-900 dark:text-white">Italiano</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Timezone</Label>
          <Select
            value={preferences.timezone}
            onValueChange={(value) => setPreferences({ ...preferences, timezone: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400 max-h-[300px]">
              {timezones.map((timezone) => (
                <SelectItem 
                  key={timezone.value} 
                  value={timezone.value}
                  className="text-gray-900 dark:text-white"
                >
                  {timezone.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Date Format</Label>
          <Select
            value={preferences.dateFormat}
            onValueChange={(value) => setPreferences({ ...preferences, dateFormat: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400">
              <SelectItem value="MM/DD/YYYY" className="text-gray-900 dark:text-white">MM/DD/YYYY</SelectItem>
              <SelectItem value="DD/MM/YYYY" className="text-gray-900 dark:text-white">DD/MM/YYYY</SelectItem>
              <SelectItem value="YYYY-MM-DD" className="text-gray-900 dark:text-white">YYYY-MM-DD</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Time Format</Label>
          <Select
            value={preferences.timeFormat}
            onValueChange={(value) => setPreferences({ ...preferences, timeFormat: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400">
              <SelectItem value="12h" className="text-gray-900 dark:text-white">12-hour</SelectItem>
              <SelectItem value="24h" className="text-gray-900 dark:text-white">24-hour</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Distance Unit</Label>
          <Select
            value={preferences.distanceUnit}
            onValueChange={(value) => setPreferences({ ...preferences, distanceUnit: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400">
              <SelectItem value="km" className="text-gray-900 dark:text-white">Kilometers</SelectItem>
              <SelectItem value="mi" className="text-gray-900 dark:text-white">Miles</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Theme</Label>
          <Select
            value={preferences.theme}
            onValueChange={(value) => setPreferences({ ...preferences, theme: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400">
              <SelectItem value="dark" className="text-gray-900 dark:text-white">Dark</SelectItem>
              <SelectItem value="light" className="text-gray-900 dark:text-white">Light</SelectItem>
              <SelectItem value="system" className="text-gray-900 dark:text-white">System</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="flex justify-end">
          <Button className="bg-gold-400 hover:bg-gold-500 text-dark-50">
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreferenceSettings;