import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { UserPlus, Mail, Info, CheckCircle2, Shield, AlertCircle } from 'lucide-react';

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InviteModal: React.FC<InviteModalProps> = ({ isOpen, onClose }) => {
  const [invites, setInvites] = useState([{ name: '', email: '' }]);
  const [showGuide, setShowGuide] = useState(true);
  const [remainingInvites, setRemainingInvites] = useState(5);
  const [showSuccess, setShowSuccess] = useState(false);
  const [sentInvites, setSentInvites] = useState<{ name: string; email: string }[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    localStorage.setItem('remainingInvites', '5');
    setRemainingInvites(5);
  }, []);

  const handleAddInvite = () => {
    if (invites.length < remainingInvites) {
      setInvites([...invites, { name: '', email: '' }]);
    }
  };

  const handleRemoveInvite = (index: number) => {
    const newInvites = invites.filter((_, i) => i !== index);
    setInvites(newInvites);
  };

  const handleInviteChange = (index: number, field: 'name' | 'email', value: string) => {
    const newInvites = [...invites];
    newInvites[index][field] = value;
    setInvites(newInvites);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const newRemainingInvites = remainingInvites - invites.length;
      setRemainingInvites(newRemainingInvites);
      localStorage.setItem('remainingInvites', newRemainingInvites.toString());
      
      setSentInvites([...invites]);
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to send invitations:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    if (!showSuccess) {
      onClose();
    }
    setInvites([{ name: '', email: '' }]);
    setShowSuccess(false);
    setShowGuide(true);
  };

  if (showSuccess) {
    return (
      <Dialog open={isOpen} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <div className="flex justify-center mb-4">
              <div className="bg-green-400/20 p-3 rounded-full">
                <CheckCircle2 className="h-6 w-6 text-green-400" />
              </div>
            </div>
            <DialogTitle className="text-center">Invitations Sent!</DialogTitle>
            <DialogDescription className="text-center text-dark-800">
              {sentInvites.length} {sentInvites.length === 1 ? 'invitation' : 'invitations'} sent successfully
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div className="bg-dark-300 p-4 rounded-xl">
              <div className="space-y-2">
                {sentInvites.map((invite, index) => (
                  <div key={index} className="text-white">
                    {invite.name} ({invite.email})
                  </div>
                ))}
              </div>
            </div>
            
            <div className="text-center text-dark-800">
              You have {remainingInvites} {remainingInvites === 1 ? 'invite' : 'invites'} remaining
            </div>
          </div>

          <DialogFooter>
            <Button
              onClick={handleClose}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50 w-full"
            >
              Done
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  if (remainingInvites <= 0) {
    return (
      <Dialog open={isOpen} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <div className="flex justify-center mb-4">
              <div className="bg-dark-300 p-3 rounded-full">
                <AlertCircle className="h-6 w-6 text-gold-400" />
              </div>
            </div>
            <DialogTitle className="text-center">No Invites Available</DialogTitle>
            <DialogDescription className="text-center text-dark-800">
              You've used all your invites. Earn more by being active in the community and maintaining a good reputation.
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div className="bg-dark-300 p-4 rounded-xl">
              <h4 className="font-medium text-white mb-2">How to Earn More Invites</h4>
              <ul className="space-y-2 text-dark-800">
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  Participate actively in community events
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  Maintain high ratings from your matches
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  Have successful referrals join the network
                </li>
              </ul>
            </div>
          </div>

          <DialogFooter>
            <Button
              onClick={handleClose}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  if (showGuide) {
    return (
      <Dialog open={isOpen} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Shield className="h-5 w-5 text-gold-400" />
              Exclusive Invite System
            </DialogTitle>
            <DialogDescription className="text-dark-800">
              Your invites are a reflection of your reputation in our network
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div className="bg-dark-300 p-4 rounded-xl">
              <h4 className="text-lg font-medium text-white mb-2">Important Information</h4>
              <ul className="space-y-2 text-dark-800">
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  You have {remainingInvites} exclusive invites
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  Each invite is tied to your reputation
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  Only invite trusted professionals
                </li>
              </ul>
            </div>

            <div className="bg-dark-300 p-4 rounded-xl">
              <h4 className="text-lg font-medium text-white mb-2">Guidelines</h4>
              <ul className="space-y-2 text-dark-800">
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  Ensure invitees align with our community values
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  You'll be notified of their activity
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1.5 w-1.5 bg-gold-400 rounded-full"></span>
                  Additional invites earned through reputation
                </li>
              </ul>
            </div>
          </div>

          <DialogFooter>
            <Button
              onClick={() => setShowGuide(false)}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              Continue to Invite
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <DialogTitle>Invite Professionals</DialogTitle>
          <DialogDescription className="text-dark-800">
            You have {remainingInvites} {remainingInvites === 1 ? 'invite' : 'invites'} remaining
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4 py-4 max-h-[60vh] overflow-y-auto pr-4">
          {invites.map((invite, index) => (
            <div key={index} className="space-y-4">
              {index > 0 && (
                <div className="border-t border-dark-300 pt-4">
                  <button
                    type="button"
                    onClick={() => handleRemoveInvite(index)}
                    className="text-dark-800 hover:text-red-400 text-sm"
                  >
                    Remove
                  </button>
                </div>
              )}
              
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor={`name-${index}`} className="text-right text-dark-800">
                  Name
                </Label>
                <Input
                  id={`name-${index}`}
                  value={invite.name}
                  onChange={(e) => handleInviteChange(index, 'name', e.target.value)}
                  className="col-span-3 bg-dark-300 border-dark-400 text-white"
                  placeholder="John Smith"
                  required
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor={`email-${index}`} className="text-right text-dark-800">
                  Email
                </Label>
                <div className="col-span-3 relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
                  <Input
                    id={`email-${index}`}
                    type="email"
                    value={invite.email}
                    onChange={(e) => handleInviteChange(index, 'email', e.target.value)}
                    className="pl-10 bg-dark-300 border-dark-400 text-white"
                    placeholder="john@company.com"
                    required
                  />
                </div>
              </div>
            </div>
          ))}

          {invites.length < remainingInvites && (
            <Button
              type="button"
              onClick={handleAddInvite}
              variant="outline"
              className="mt-2 w-full bg-dark-300 border-dark-400 text-white hover:bg-dark-400"
            >
              <UserPlus className="h-4 w-4 mr-2" />
              Add Another Invite
            </Button>
          )}

          <DialogFooter>
            <Button
              type="submit"
              disabled={isSubmitting}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              {isSubmitting ? 'Sending...' : 'Send Invites'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};