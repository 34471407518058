import React from 'react';
import { Star } from 'lucide-react';
import { Progress } from '../ui/progress';

interface SimplifiedTrainerReviewStatsProps {
  rating: number;
  totalReviews: number;
  distribution: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
}

const SimplifiedTrainerReviewStats: React.FC<SimplifiedTrainerReviewStatsProps> = ({
  rating,
  totalReviews,
  distribution
}) => {
  const getPercentage = (count: number) => {
    return totalReviews > 0 ? (count / totalReviews) * 100 : 0;
  };

  return (
    <div className="space-y-6">
      {/* Overall Rating */}
      <div className="flex items-center justify-center space-x-4">
        <div className="text-center">
          <div className="text-4xl font-bold text-gray-900 dark:text-white mb-2">
            {rating.toFixed(1)}
          </div>
          <div className="flex justify-center mb-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                className={`h-5 w-5 ${
                  star <= Math.round(rating)
                    ? 'fill-gold-400 text-gold-400'
                    : 'text-gray-300 dark:text-dark-800'
                }`}
              />
            ))}
          </div>
          <div className="text-gray-500 dark:text-dark-800 text-sm">
            {totalReviews} {totalReviews === 1 ? 'review' : 'reviews'}
          </div>
        </div>
      </div>

      {/* Rating Distribution */}
      <div className="space-y-3">
        {[5, 4, 3, 2, 1].map((rating) => (
          <div key={rating} className="flex items-center space-x-4">
            <div className="flex items-center space-x-1 w-12">
              <span className="text-gray-900 dark:text-white">{rating}</span>
              <Star className="h-4 w-4 text-gold-400" />
            </div>
            <div className="flex-1">
              <Progress
                value={getPercentage(distribution[rating as keyof typeof distribution])}
                className="h-2"
              />
            </div>
            <div className="w-12 text-right text-gray-500 dark:text-dark-800">
              {distribution[rating as keyof typeof distribution]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimplifiedTrainerReviewStats;