import React, { useState } from 'react';
import PostCard from '../social/PostCard';
import PostComposer from './PostComposer';
import WelcomeMessage from './WelcomeMessage';
import { posts as initialPosts } from '../../data/posts';

const SocialFeed: React.FC = () => {
  const [posts, setPosts] = useState(initialPosts);

  const handleNewPost = (content: string, image?: File) => {
    const newPost = {
      id: String(Date.now()),
      author: {
        id: '55',
        name: 'Uri Schneider',
        image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
        location: 'Monte Carlo Tennis Club'
      },
      content,
      media: image ? URL.createObjectURL(image) : undefined,
      likes: 0,
      comments: 0,
      shares: 0,
      timeAgo: 'Just now',
      isLiked: false,
      likedBy: []
    };

    setPosts([newPost, ...posts]);
  };

  return (
    <div className="space-y-6">
      <WelcomeMessage />
      <PostComposer onPost={handleNewPost} />
      {posts.map((post) => (
        <PostCard key={post.id} {...post} />
      ))}
    </div>
  );
};

export default SocialFeed;