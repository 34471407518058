import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import MatchmakingSystem from '../components/matchmaking/MatchmakingSystem';

const MatchMaking = () => {
  return (
    <DashboardLayout>
      <MatchmakingSystem />
    </DashboardLayout>
  );
};

export default MatchMaking;