import React from 'react';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Switch } from '../../ui/switch';

const SecuritySettings: React.FC = () => {
  return (
    <div className="space-y-6">
      {/* Password Change */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h2 className="text-xl font-semibold text-white mb-6">Change Password</h2>
        <div className="space-y-4">
          <div>
            <Label htmlFor="currentPassword" className="text-dark-800">Current Password</Label>
            <Input
              id="currentPassword"
              type="password"
              className="bg-dark-300 border-dark-400 text-white mt-1"
            />
          </div>
          <div>
            <Label htmlFor="newPassword" className="text-dark-800">New Password</Label>
            <Input
              id="newPassword"
              type="password"
              className="bg-dark-300 border-dark-400 text-white mt-1"
            />
          </div>
          <div>
            <Label htmlFor="confirmPassword" className="text-dark-800">Confirm New Password</Label>
            <Input
              id="confirmPassword"
              type="password"
              className="bg-dark-300 border-dark-400 text-white mt-1"
            />
          </div>
          <div className="flex justify-end">
            <Button className="bg-gold-400 hover:bg-gold-500 text-dark-50">
              Update Password
            </Button>
          </div>
        </div>
      </div>

      {/* Two-Factor Authentication */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-xl font-semibold text-white">Two-Factor Authentication</h2>
            <p className="text-dark-800 mt-1">Add an extra layer of security to your account</p>
          </div>
          <Switch />
        </div>
        <div className="text-dark-800">
          Two-factor authentication adds an extra layer of security to your account by requiring more than just a password to sign in.
        </div>
      </div>

      {/* Login History */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h2 className="text-xl font-semibold text-white mb-6">Login History</h2>
        <div className="space-y-4">
          {[
            { device: 'iPhone 13', location: 'Monaco', time: '2 hours ago', current: true },
            { device: 'MacBook Pro', location: 'Monaco', time: '1 day ago' },
            { device: 'iPad Pro', location: 'Paris, France', time: '3 days ago' }
          ].map((session, index) => (
            <div
              key={index}
              className="bg-dark-300 rounded-xl p-4 flex items-center justify-between"
            >
              <div>
                <div className="text-white">{session.device}</div>
                <div className="text-dark-800 text-sm">
                  {session.location} • {session.time}
                </div>
              </div>
              <div className="flex items-center space-x-4">
                {session.current && (
                  <span className="px-3 py-1 bg-green-400/20 text-green-400 rounded-full text-xs">
                    Current Session
                  </span>
                )}
                {!session.current && (
                  <button className="text-red-400 hover:text-red-300">
                    End Session
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SecuritySettings;