import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ArrowLeft, MapPin, Star, Clock, Filter } from 'lucide-react';
import { facilities } from '../../data/facilities';
import { cities } from '../../data/cities';
import { members } from '../../data/members';
import CityCard from './CityCard';
import FacilityBooking from './FacilityBooking';
import WeatherWidget from './WeatherWidget';
import CityMembersModal from './CityMembersModal';
import LocationMap from './LocationMap';

interface LocationState {
  selectedCity?: string;
  showFacilityDetails?: boolean;
  facilityId?: string;
}

const FacilityManagement: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  
  const [selectedCity, setSelectedCity] = useState<string | null>(state?.selectedCity || null);
  const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [selectedSport, setSelectedSport] = useState<string>('all');

  const cityFacilities = useMemo(() => 
    facilities.filter(facility => facility.location === selectedCity),
    [selectedCity]
  );

  const sportTypes = useMemo(() => {
    if (!selectedCity) return [];
    const types = new Set(cityFacilities.map(f => f.type));
    return ['all', ...Array.from(types)];
  }, [selectedCity, cityFacilities]);

  const filteredFacilities = useMemo(() => 
    cityFacilities.filter(facility => 
      selectedSport === 'all' || facility.type === selectedSport
    ),
    [cityFacilities, selectedSport]
  );

  // Handle initial facility selection from search
  useEffect(() => {
    if (state?.showFacilityDetails && state?.facilityId) {
      setSelectedFacility(state.facilityId);
    }
  }, [state]);

  const handleCityClick = useCallback((city: string) => {
    setSelectedCity(city);
    setSelectedSport('all');
  }, []);

  const handleBookNow = (facilityId: string) => {
    setSelectedFacility(facilityId);
  };

  const handleBack = () => {
    setSelectedCity(null);
    setSelectedFacility(null);
    setSelectedSport('all');
  };

  return (
    <div className="space-y-6">
      {selectedCity ? (
        <>
          <button
            onClick={handleBack}
            className="flex items-center space-x-2 px-4 py-2 bg-dark-200 rounded-xl text-white hover:bg-dark-300 transition-colors border border-dark-300"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>Back to Cities</span>
          </button>

          <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
            <div className="relative h-64">
              <img
                src={cities.find(c => c.name === selectedCity)?.image}
                alt={`${selectedCity} cityscape`}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-dark-900/90 via-dark-900/40 to-transparent" />
              <div className="absolute bottom-6 left-6">
                <h2 className="text-2xl font-bold [color:white] dark:text-white mb-2">
                  {selectedCity} Sport Playgrounds
                </h2>
                <button
                  onClick={() => setShowMembersModal(true)}
                  className="px-4 py-2 bg-dark-900/50 backdrop-blur-sm rounded-full [color:white] dark:text-white hover:bg-dark-900/70 transition-colors"
                >
                  {members.filter(m => m.city === selectedCity).length} Members in {selectedCity}
                </button>
              </div>
            </div>
            <div className="p-6 border-t border-dark-300 space-y-4">
              <WeatherWidget location={selectedCity} />
              
              <div className="flex items-center space-x-3">
                <Filter className="h-5 w-5 text-dark-800 flex-shrink-0" />
                <select
                  value={selectedSport}
                  onChange={(e) => setSelectedSport(e.target.value)}
                  className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                >
                  {sportTypes.map((sport) => (
                    <option key={sport} value={sport}>
                      {sport === 'all' ? 'All Sports' : sport}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            {filteredFacilities.map((facility) => (
              <div
                key={facility.id}
                className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300 hover:border-gold-400 transition-all duration-300"
              >
                <div className="flex flex-col sm:flex-row">
                  <div className="w-full sm:w-1/3">
                    <div className="relative h-48 sm:h-full">
                      <img
                        src={facility.coverImage}
                        alt={facility.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>
                  <div className="w-full sm:w-2/3 p-6">
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="text-xl font-semibold text-white">{facility.name}</h3>
                        <div className="flex items-center text-dark-800 mt-1">
                          <Clock className="h-4 w-4 mr-1" />
                          {facility.openingHours}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Star className="h-4 w-4 text-gold-400 mr-1" />
                        <span className="text-white">{facility.rating}</span>
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-2 mt-4">
                      {facility.amenities.map((amenity, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white"
                        >
                          {amenity}
                        </span>
                      ))}
                    </div>

                    <p className="text-dark-800 mt-4">{facility.description}</p>

                    <div className="mt-4">
                      <LocationMap 
                        address={facility.address}
                        coordinates={facility.coordinates}
                      />
                    </div>

                    <div className="flex items-center justify-between mt-6">
                      <span className="text-gold-400 font-medium">{facility.priceRange}</span>
                      <div className="flex items-center space-x-4">
                        <button
                          onClick={() => handleBookNow(facility.id)}
                          className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-lg transition-colors"
                        >
                          Book Now
                        </button>
                        {facility.virtualTour && (
                          <button className="px-4 py-2 border border-dark-400 text-white rounded-lg hover:bg-dark-300 transition-colors">
                            Virtual Tour
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {cities.map((city) => (
            <CityCard
              key={city.name}
              city={city.name}
              image={city.image}
              venueCount={city.venueCount}
              active={city.active}
              onClick={() => handleCityClick(city.name)}
            />
          ))}
        </div>
      )}

      {selectedFacility && (
        <FacilityBooking
          facility={facilities.find(f => f.id === selectedFacility)}
          selectedDate={new Date()}
          onDateChange={() => {}}
          onClose={() => setSelectedFacility(null)}
          isModal={true}
        />
      )}

      {showMembersModal && selectedCity && (
        <CityMembersModal
          city={selectedCity}
          isOpen={showMembersModal}
          onClose={() => setShowMembersModal(false)}
        />
      )}
    </div>
  );
};

export default FacilityManagement;