import React from 'react';
import { Comment } from '../../types/comment';
import CommentItem from './CommentItem';
import { MessageCircle } from 'lucide-react';

interface CommentListProps {
  comments: Comment[];
}

const CommentList: React.FC<CommentListProps> = ({ comments }) => {
  return (
    <div className="space-y-4">
      {comments.map((comment) => (
        <CommentItem key={comment.id} comment={comment} />
      ))}
      {comments.length === 0 && (
        <div className="text-center py-8">
          <MessageCircle className="h-8 w-8 text-dark-800 mx-auto mb-2" />
          <p className="text-dark-800">
            No comments yet. Start the conversation!
          </p>
        </div>
      )}
    </div>
  );
};

export default CommentList;