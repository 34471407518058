import React, { useState, useMemo } from 'react';
import { X, Upload, Plus, Minus } from 'lucide-react';
import { cities } from '../../../data/cities';

interface CreateClubModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateClubModal: React.FC<CreateClubModalProps> = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    country: '',
    city: '',
    sports: [] as string[],
    exclusivity: 'public',
    rules: [''],
    coverImage: null as File | null
  });

  // Predefined sports with emojis
  const availableSports = [
    { emoji: '🎾', name: 'Tennis' },
    { emoji: '⛳', name: 'Golf' },
    { emoji: '🏊‍♂️', name: 'Swimming' },
    { emoji: '🏃‍♂️', name: 'Running' },
    { emoji: '🚴‍♂️', name: 'Cycling' },
    { emoji: '⚽', name: 'Football' },
    { emoji: '🏀', name: 'Basketball' },
    { emoji: '🏸', name: 'Badminton' },
    { emoji: '🏓', name: 'Table Tennis' },
    { emoji: '🏐', name: 'Volleyball' },
    { emoji: '🎱', name: 'Padel' },
    { emoji: '🧘‍♀️', name: 'Yoga' },
    { emoji: '🏋️‍♀️', name: 'Pilates' }
  ];

  // Countries list
  const countries = [
    'France',
    'Monaco',
    'Spain',
    'Italy',
    'Switzerland',
    'United Kingdom',
    'Germany',
    'Singapore',
    'United Arab Emirates',
    'United States'
  ].sort();

  // Available cities based on selected country
  const availableCities = useMemo(() => {
    if (!formData.country) return [];
    return cities
      .filter(city => city.active)
      .map(city => city.name)
      .sort();
  }, [formData.country]);

  const handleSportToggle = (sport: string) => {
    setFormData(prev => ({
      ...prev,
      sports: prev.sports.includes(sport)
        ? prev.sports.filter(s => s !== sport)
        : [...prev.sports, sport]
    }));
  };

  const handleRuleChange = (index: number, value: string) => {
    const newRules = [...formData.rules];
    newRules[index] = value;
    setFormData({ ...formData, rules: newRules });
  };

  const addRule = () => {
    setFormData({ ...formData, rules: [...formData.rules, ''] });
  };

  const removeRule = (index: number) => {
    const newRules = formData.rules.filter((_, i) => i !== index);
    setFormData({ ...formData, rules: newRules });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData({ ...formData, coverImage: file });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle club creation
    console.log('Creating club:', formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-2xl bg-dark-200 rounded-2xl p-6 border border-dark-300">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>

            <h2 className="text-xl font-bold text-white mb-6">Create New Club</h2>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Basic Information */}
              <div className="space-y-4">
                <div>
                  <label className="block text-dark-800 mb-2">Club Name *</label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                    required
                  />
                </div>

                <div>
                  <label className="block text-dark-800 mb-2">Description *</label>
                  <textarea
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white h-24 resize-none"
                    required
                  />
                </div>

                {/* Location Selection */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-dark-800 mb-2">Country *</label>
                    <select
                      value={formData.country}
                      onChange={(e) => setFormData({ ...formData, country: e.target.value, city: '' })}
                      className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                      required
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>{country}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-dark-800 mb-2">City *</label>
                    <select
                      value={formData.city}
                      onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                      className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                      required
                      disabled={!formData.country}
                    >
                      <option value="">Select City</option>
                      {availableCities.map((city) => (
                        <option key={city} value={city}>{city}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Sports Selection */}
              <div>
                <label className="block text-dark-800 mb-2">Sports *</label>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                  {availableSports.map((sport) => {
                    const isSelected = formData.sports.includes(sport.name);
                    return (
                      <button
                        key={sport.name}
                        type="button"
                        onClick={() => handleSportToggle(sport.name)}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-xl transition-colors ${
                          isSelected
                            ? 'bg-gold-400 text-dark-50'
                            : 'bg-dark-300 text-white hover:bg-dark-400'
                        }`}
                      >
                        <span className="text-xl">{sport.emoji}</span>
                        <span>{sport.name}</span>
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* Club Rules */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <label className="text-dark-800">Club Rules</label>
                  <button
                    type="button"
                    onClick={addRule}
                    className="text-gold-400 hover:text-gold-500"
                  >
                    <Plus className="h-5 w-5" />
                  </button>
                </div>
                {formData.rules.map((rule, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={rule}
                      onChange={(e) => handleRuleChange(index, e.target.value)}
                      className="flex-1 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                      placeholder="Enter rule"
                    />
                    <button
                      type="button"
                      onClick={() => removeRule(index)}
                      className="p-2 text-dark-800 hover:text-red-400"
                    >
                      <Minus className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </div>

              {/* Exclusivity */}
              <div>
                <label className="block text-dark-800 mb-2">Exclusivity *</label>
                <select
                  value={formData.exclusivity}
                  onChange={(e) => setFormData({ ...formData, exclusivity: e.target.value })}
                  className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                  required
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                  <option value="invitation">Invitation Only</option>
                </select>
              </div>

              {/* Cover Image */}
              <div>
                <label className="block text-dark-800 mb-2">Cover Image</label>
                <div className="flex items-center space-x-4">
                  <label className="flex items-center space-x-2 px-4 py-2 bg-dark-300 rounded-xl hover:bg-dark-400 transition-colors cursor-pointer">
                    <Upload className="h-5 w-5 text-dark-800" />
                    <span className="text-white">Choose Image</span>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </label>
                  {formData.coverImage && (
                    <span className="text-dark-800">
                      {formData.coverImage.name}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-end space-x-4 pt-6">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-6 py-2 border border-dark-400 text-white rounded-xl hover:bg-dark-300 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
                >
                  Create Club
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateClubModal;