import React from 'react';
import { Trophy, Target, Clock, TrendingUp } from 'lucide-react';

const MatchStatistics: React.FC = () => {
  const matchStats = {
    overall: {
      winRate: 75,
      totalMatches: 48,
      avgDuration: '1h 45m',
      improvement: '+12%'
    },
    recentMatches: [
      {
        opponent: 'Sarah Connor',
        result: 'Won',
        score: '6-4, 7-5',
        date: '2 days ago',
        stats: {
          aces: 8,
          winners: 24,
          errors: 12
        }
      },
      {
        opponent: 'James Wilson',
        result: 'Lost',
        score: '4-6, 6-7',
        date: '1 week ago',
        stats: {
          aces: 5,
          winners: 18,
          errors: 15
        }
      }
    ]
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* Overall Stats */}
      <div className="lg:col-span-1 space-y-6">
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-lg font-semibold text-white mb-6">Overall Performance</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between">
                <Trophy className="h-5 w-5 text-gold-400" />
                <span className="text-2xl font-bold text-white">{matchStats.overall.winRate}%</span>
              </div>
              <p className="text-dark-800 mt-2">Win Rate</p>
            </div>
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between">
                <Target className="h-5 w-5 text-gold-400" />
                <span className="text-2xl font-bold text-white">{matchStats.overall.totalMatches}</span>
              </div>
              <p className="text-dark-800 mt-2">Total Matches</p>
            </div>
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between">
                <Clock className="h-5 w-5 text-gold-400" />
                <span className="text-2xl font-bold text-white">{matchStats.overall.avgDuration}</span>
              </div>
              <p className="text-dark-800 mt-2">Avg Duration</p>
            </div>
            <div className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between">
                <TrendingUp className="h-5 w-5 text-gold-400" />
                <span className="text-2xl font-bold text-green-400">{matchStats.overall.improvement}</span>
              </div>
              <p className="text-dark-800 mt-2">Improvement</p>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Matches */}
      <div className="lg:col-span-2">
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-lg font-semibold text-white mb-6">Recent Matches</h3>
          <div className="space-y-4">
            {matchStats.recentMatches.map((match, index) => (
              <div key={index} className="bg-dark-300 rounded-xl p-4">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h4 className="text-white font-medium">vs {match.opponent}</h4>
                    <p className="text-dark-800 text-sm">{match.date}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className={`text-sm ${
                      match.result === 'Won' ? 'text-green-400' : 'text-red-400'
                    }`}>
                      {match.result}
                    </span>
                    <span className="text-white">{match.score}</span>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="text-center">
                    <p className="text-xl font-bold text-white">{match.stats.aces}</p>
                    <p className="text-dark-800 text-sm">Aces</p>
                  </div>
                  <div className="text-center">
                    <p className="text-xl font-bold text-white">{match.stats.winners}</p>
                    <p className="text-dark-800 text-sm">Winners</p>
                  </div>
                  <div className="text-center">
                    <p className="text-xl font-bold text-white">{match.stats.errors}</p>
                    <p className="text-dark-800 text-sm">Errors</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchStatistics;