import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Menu, X, Users, 
  Dumbbell, MapPin, Calendar, MessageSquare,
  Trophy, Settings, UserPlus, Plus, Sun, Moon,
  Activity, Search, UserCircle
} from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { InviteModal } from './InviteModal';
import QuickMatchModal from './matchmaking/QuickMatchModal';
import NotificationsDropdown from './notifications/NotificationsDropdown';
import { ThemeAwareLogo } from './brand/ThemeAwareLogo';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showQuickMatch, setShowQuickMatch] = useState(false);
  const [hasClickedMenu, setHasClickedMenu] = useState(() => {
    return localStorage.getItem('hasClickedMenu') === 'true';
  });
  const { isDarkMode, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsSidebarOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.getElementById('sidebar');
      const menuButton = document.getElementById('menu-button');
      
      if (sidebar && menuButton && 
          !sidebar.contains(event.target as Node) && 
          !menuButton.contains(event.target as Node) &&
          window.innerWidth < 1024) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMenuOpen = () => {
    setIsSidebarOpen(true);
    if (!hasClickedMenu) {
      setHasClickedMenu(true);
      localStorage.setItem('hasClickedMenu', 'true');
    }
  };

  const handleSearchClick = () => {
    navigate('/dashboard/search');
  };

  const navigationItems = [
    { name: 'Invite', icon: UserPlus, path: '', onClick: () => setShowInviteModal(true) },
    { name: 'Leaderboard', icon: Trophy, path: '/dashboard/leaderboard' },
    { name: 'Settings', icon: Settings, path: '/dashboard/settings' }
  ];

  const bottomNavItems = [
    { name: 'Feed', icon: Activity, path: '/dashboard' },
    { name: 'Messages', icon: MessageSquare, path: '/dashboard/messages' },
    { name: 'Activities', icon: Calendar, path: '/dashboard/activities' },
    { name: 'Facilities', icon: MapPin, path: '/dashboard/facilities' },
    { name: 'Trainers', icon: Dumbbell, path: '/dashboard/training' },
    { name: 'Players', icon: Users, path: '/dashboard/match' }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : 'light'}`}>
      {/* Top Bar */}
      <header className="fixed top-0 left-0 right-0 h-16 bg-app/80 backdrop-blur-md border-b border-dark-300 z-50">
        <div className="h-full flex items-center">
          {/* Left section with logo - aligned with sidebar on desktop */}
          <div className="w-64 hidden lg:flex items-center px-6">
            <ThemeAwareLogo
              height={32}
              className="cursor-pointer"
              onClick={() => navigate('/dashboard')}
              colorScheme={isDarkMode ? 'white' : 'color'}
            />
          </div>

          {/* Mobile logo and menu */}
          <div className="lg:hidden flex items-center px-4 flex-1">
            <ThemeAwareLogo
              height={28}
              className="cursor-pointer"
              onClick={() => navigate('/dashboard')}
              colorScheme={isDarkMode ? 'white' : 'color'}
            />
          </div>

          {/* Right section */}
          <div className="flex items-center gap-2 px-4 ml-auto">
            <button
              onClick={handleSearchClick}
              className="p-1.5 hover:bg-app-darker rounded-full transition-colors"
              aria-label="Search"
            >
              <Search className="h-5 w-5" />
            </button>

            <NotificationsDropdown />

            <button
              onClick={() => navigate('/dashboard/profile')}
              className="p-1.5 hover:bg-app-darker rounded-full transition-colors"
            >
              <UserCircle className="h-5 w-5" />
            </button>

            <button
              id="menu-button"
              onClick={handleMenuOpen}
              className="lg:hidden p-1.5 text-app-muted hover:text-app"
            >
              {isSidebarOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
              {!hasClickedMenu && !isSidebarOpen && (
                <span 
                  className="absolute -top-1 -right-1 h-3 w-3 bg-gold-400 rounded-full animate-pulse" 
                  style={{ zIndex: 60 }}
                />
              )}
            </button>
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <div
        id="sidebar"
        className={`fixed inset-y-0 left-0 z-40 w-64 bg-app border-r border-dark-300 transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 mt-16`}
      >
        <div className="h-full flex flex-col">
          {/* Navigation */}
          <nav className="flex-1 px-4 py-6 space-y-1 overflow-y-auto scrollbar-thin scrollbar-thumb-dark-400 scrollbar-track-dark-300">
            {/* Navigation Items */}
            {navigationItems.map((item) => (
              <button
                key={item.name}
                onClick={() => item.onClick ? item.onClick() : navigate(item.path)}
                className={`flex items-center w-full px-4 py-3 rounded-xl transition-colors ${
                  location.pathname === item.path
                    ? 'bg-app-darker text-gold-400'
                    : 'text-app-muted hover:text-app hover:bg-app-darker'
                }`}
              >
                <item.icon className="h-5 w-5 mr-3" />
                <span className="text-sm">{item.name}</span>
              </button>
            ))}

            {/* Theme Toggle */}
            <button
              onClick={toggleTheme}
              className="flex items-center w-full px-4 py-3 rounded-xl text-app-muted hover:text-app hover:bg-app-darker transition-colors"
            >
              {isDarkMode ? <Sun className="h-5 w-5 mr-3" /> : <Moon className="h-5 w-5 mr-3" />}
              <span className="text-sm">{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </button>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="lg:pl-64 pt-20 pb-24">
        <main className="p-4 lg:p-8">
          {children}
        </main>
      </div>

      {/* Floating Play Button */}
      <div className="fixed right-4 bottom-24 z-50 flex flex-col items-center">
        <button
          onClick={() => setShowQuickMatch(true)}
          className="w-16 h-16 bg-gold-400/90 hover:bg-gold-500/90 text-dark-50 rounded-full shadow-lg transition-all duration-300 flex items-center justify-center backdrop-blur-sm"
        >
          <Plus className="h-8 w-8" />
        </button>
        <span className="mt-2 px-3 py-1 bg-app/90 backdrop-blur-sm text-xs font-medium text-app">
          Play
        </span>
      </div>

      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-app border-t border-dark-300 z-40">
        <div className="w-full px-2 py-2 pb-safe">
          <div className="grid grid-cols-6 gap-0 max-w-screen-lg mx-auto">
            {bottomNavItems.map((item) => (
              <button
                key={item.name}
                onClick={() => navigate(item.path)}
                className={`flex flex-col items-center justify-center min-w-[40px] py-1 ${
                  location.pathname === item.path ? 'text-gold-400' : 'text-app-muted'
                }`}
              >
                <item.icon className="h-[18px] w-[18px] min-h-[18px] min-w-[18px]" />
                <span className="text-[10px] mt-0.5 leading-tight">{item.name}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Modals */}
      <InviteModal 
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      />
      <QuickMatchModal
        isOpen={showQuickMatch}
        onClose={() => setShowQuickMatch(false)}
      />
    </div>
  );
};

export default DashboardLayout;