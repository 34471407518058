import React from 'react';
import { ThumbsUp } from 'lucide-react';

interface PostContentProps {
  content: string;
  media?: string;
  endorsements?: string[];
}

const PostContent: React.FC<PostContentProps> = ({
  content,
  media,
  endorsements
}) => {
  return (
    <div className="space-y-4">
      <p className="text-white">{content}</p>

      {media && (
        <img
          src={media}
          alt="Post media"
          className="w-full h-64 object-cover rounded-xl"
          loading="lazy"
        />
      )}

      {endorsements && endorsements.length > 0 && (
        <div className="flex items-center space-x-2">
          <ThumbsUp className="h-4 w-4 text-gold-400" />
          <span className="text-white text-sm">Endorsed for:</span>
          <div className="flex flex-wrap gap-2">
            {endorsements.map((skill, index) => (
              <span
                key={index}
                className="px-2 py-1 bg-dark-300 rounded-full text-xs text-white"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PostContent;