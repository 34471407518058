import React, { useRef } from 'react';
import { Camera, X, ImagePlus } from 'lucide-react';

interface PhotoGalleryProps {
  photos: string[];
  onUpload: (file: File) => void;
  onDelete?: (photoUrl: string) => void;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos, onUpload, onDelete }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onUpload(file);
      // Reset input value to allow uploading the same file again
      e.target.value = '';
    }
  };

  const handleDelete = (photoUrl: string) => {
    if (onDelete) {
      onDelete(photoUrl);
    }
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-white">Photo Gallery</h3>
        <label
          htmlFor="photo-upload"
          className="flex items-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors cursor-pointer"
        >
          <ImagePlus className="h-5 w-5" />
          <span>Add Photo</span>
        </label>
        <input
          ref={fileInputRef}
          id="photo-upload"
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.map((photo, index) => (
          <div
            key={index}
            className="relative aspect-square rounded-xl overflow-hidden group"
          >
            <img
              src={photo}
              alt={`Gallery photo ${index + 1}`}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-dark-900/80 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
              <button
                onClick={() => handleDelete(photo)}
                className="p-2 bg-red-500 rounded-lg text-white hover:bg-red-600 transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}

        <label
          htmlFor="photo-upload"
          className="aspect-square rounded-xl border-2 border-dashed border-dark-400 flex items-center justify-center cursor-pointer hover:border-gold-400 transition-colors"
        >
          <Camera className="h-8 w-8 text-dark-800" />
        </label>
      </div>
    </div>
  );
};

export default PhotoGallery;