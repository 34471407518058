import React, { useState, useMemo } from 'react';
import { MapPin, Users, Plus, Filter, ChevronRight, Search, FrownIcon, X, RefreshCw, Trophy, Compass } from 'lucide-react';
import type { Player, MatchPreference } from '../../types/matchmaking';
import { members } from '../../data/members';
import CitySearch from '../members/CitySearch';
import QuickMatchModal from './QuickMatchModal';
import PlayerCard from './PlayerCard';
import GroupMatchmaking from './social/GroupMatchmaking';
import ClubsList from './social/ClubsList';
import TournamentPlanner from './social/TournamentPlanner';
import { findNearestCities } from '../../lib/api';

const MatchmakingSystem: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'singles' | 'groups' | 'clubs' | 'tournaments'>('singles');
  const [showFilters, setShowFilters] = useState(true);
  const [showQuickMatch, setShowQuickMatch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLocating, setIsLocating] = useState(false);
  const [preferences, setPreferences] = useState<MatchPreference>({
    sport: 'all',
    skillLevel: 'all',
    maxDistance: 100,
    ageRange: [18, 99],
    availability: ['morning', 'afternoon', 'evening', 'weekend'],
    city: 'All Cities',
    gender: 'all'
  });

  const defaultPreferences: MatchPreference = {
    sport: 'all',
    skillLevel: 'all',
    maxDistance: 100,
    ageRange: [18, 99],
    availability: ['morning', 'afternoon', 'evening', 'weekend'],
    city: 'All Cities',
    gender: 'all'
  };

  const handleResetFilters = () => {
    setPreferences(defaultPreferences);
    setSearchTerm('');
  };

  const handleFindNearest = async () => {
    setIsLocating(true);
    try {
      if (!navigator.geolocation) {
        throw new Error('Geolocation is not supported by your browser');
      }

      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const nearestCities = await findNearestCities(position.coords);
      if (nearestCities.length > 0) {
        setPreferences(prev => ({ ...prev, city: nearestCities[0].name }));
      }
    } catch (error) {
      console.error('Error finding nearest location:', error);
    } finally {
      setIsLocating(false);
    }
  };

  const filteredMembers = useMemo(() => {
    return members
      .filter(member => {
        const matchesSearch = searchTerm === '' || 
          member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.jobTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.sports.some(sport => sport.toLowerCase().includes(searchTerm.toLowerCase()));

        const matchesCity = preferences.city === 'All Cities' || 
          member.city.toLowerCase().includes(preferences.city.toLowerCase());

        const matchesSport = preferences.sport === 'all' || 
          (member.sports && member.sports.some(sport => 
            sport.toLowerCase() === preferences.sport.replace(/^[^\s]+ /, '').toLowerCase()
          ));

        const matchesSkill = preferences.skillLevel === 'all' || 
          member.skillLevel.toLowerCase() === preferences.skillLevel.toLowerCase();

        const matchesAge = member.age >= preferences.ageRange[0] && 
          member.age <= preferences.ageRange[1];

        const hasMatchingAvailability = member.availability && 
          member.availability.some(time => 
            preferences.availability.includes(time)
          );

        return matchesSearch && matchesCity && matchesSport && matchesSkill && matchesAge && hasMatchingAvailability;
      })
      .map(member => ({
        id: member.id,
        name: member.name,
        age: member.age,
        location: member.city,
        distance: Math.floor(Math.random() * preferences.maxDistance),
        sports: member.sports,
        skillLevel: member.skillLevel,
        rating: member.rating,
        matchPercentage: Math.floor(Math.random() * 15 + 85),
        availability: member.availability,
        image: member.image,
        recentActivity: '2h ago',
        company: {
          name: member.company,
          position: member.jobTitle,
          industry: member.industry
        }
      }))
      .sort((a, b) => b.matchPercentage - a.matchPercentage);
  }, [preferences, searchTerm]);

  const EmptyState = () => (
    <div className="bg-dark-200 rounded-2xl p-6 sm:p-8 border border-dark-300 text-center">
      <div className="flex justify-center mb-4">
        <FrownIcon className="h-12 w-12 text-dark-800" />
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">No players found</h3>
      <p className="text-dark-800 mb-6 max-w-md mx-auto">
        Try adjusting your filters or search criteria to find more players.
      </p>
      <ul className="text-dark-800 space-y-2 mb-6 text-left max-w-md mx-auto">
        <li>• Broadening your location range</li>
        <li>• Selecting different sports</li>
        <li>• Adjusting skill level preferences</li>
        <li>• Expanding availability options</li>
      </ul>
      <button
        onClick={handleResetFilters}
        className="inline-flex items-center space-x-2 px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
      >
        <RefreshCw className="h-5 w-5" />
        <span>Reset Filters</span>
      </button>
    </div>
  );

  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="bg-dark-200 rounded-xl sm:rounded-2xl p-4 sm:p-6 border border-dark-300">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <div>
            <h2 className="text-xl font-bold text-white">Find & Play</h2>
            <p className="text-dark-800 mt-1 text-sm sm:text-base">Find your perfect sports partner</p>
          </div>
          <div className="flex items-center space-x-2 sm:space-x-4">
            <button 
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center space-x-2 px-3 py-2 bg-dark-300 rounded-lg hover:bg-dark-400 transition-colors"
            >
              <Filter className="h-5 w-5 text-dark-800" />
              <span className="text-white text-sm">{showFilters ? 'Hide Filters' : 'Show Filters'}</span>
            </button>
            <button
              onClick={() => setShowQuickMatch(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors text-sm sm:text-base"
            >
              <Plus className="h-5 w-5" />
              <span className="hidden sm:inline">Quick Play</span>
              <span className="sm:hidden">Play</span>
            </button>
          </div>
        </div>

        <div className="mt-4 sm:mt-6">
          <div className="flex flex-col sm:flex-row items-stretch sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
              <input
                type="text"
                placeholder="Search players..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 sm:py-3 bg-dark-300 border border-dark-400 rounded-lg sm:rounded-xl text-white placeholder-dark-800 text-sm sm:text-base"
              />
            </div>
            <button
              onClick={handleResetFilters}
              className="flex items-center justify-center space-x-2 px-4 py-2 sm:py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-lg sm:rounded-xl transition-colors text-sm sm:text-base"
            >
              <RefreshCw className="h-5 w-5" />
              <span className="hidden sm:inline">Reset</span>
            </button>
          </div>
        </div>

        {showFilters && (
          <div className="mt-6 space-y-4 border-t border-dark-300 pt-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <label className="block text-dark-800 text-sm">City</label>
                  <button
                    onClick={handleFindNearest}
                    disabled={isLocating}
                    className="flex items-center space-x-2 text-sm text-gold-400 hover:text-gold-500 disabled:opacity-50"
                  >
                    <Compass className="h-4 w-4" />
                    <span>{isLocating ? 'Finding...' : 'Find Nearest'}</span>
                  </button>
                </div>
                <CitySearch
                  selectedCity={preferences.city}
                  onCitySelect={(city) => setPreferences(prev => ({ ...prev, city }))}
                />
              </div>

              <div className="space-y-2">
                <label className="block text-dark-800 text-sm">Sport</label>
                <select
                  value={preferences.sport}
                  onChange={(e) => setPreferences(prev => ({ ...prev, sport: e.target.value }))}
                  className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white h-[38px]"
                >
                  <option value="all">All Sports</option>
                  <option value="🎾 Tennis">Tennis</option>
                  <option value="⛳ Golf">Golf</option>
                  <option value="🏊‍♂️ Swimming">Swimming</option>
                  <option value="🏃‍♂️ Running">Running</option>
                  <option value="🚴‍♂️ Cycling">Cycling</option>
                  <option value="⚽ Football">Football</option>
                  <option value="🏀 Basketball">Basketball</option>
                  <option value="🏸 Badminton">Badminton</option>
                  <option value="🏓 Table Tennis">Table Tennis</option>
                  <option value="🏐 Volleyball">Volleyball</option>
                  <option value="🎱 Padel">Padel</option>
                  <option value="🧘‍♀️ Yoga">Yoga</option>
                  <option value="🏋️‍♀️ Pilates">Pilates</option>
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-dark-800 text-sm">Skill Level</label>
                <select
                  value={preferences.skillLevel}
                  onChange={(e) => setPreferences(prev => ({ ...prev, skillLevel: e.target.value }))}
                  className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white h-[38px]"
                >
                  <option value="all">All Levels</option>
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                  <option value="professional">Professional</option>
                </select>
              </div>

              <div className="space-y-2">
                <label className="block text-dark-800 text-sm">Gender</label>
                <select
                  value={preferences.gender}
                  onChange={(e) => setPreferences(prev => ({ ...prev, gender: e.target.value }))}
                  className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white h-[38px]"
                >
                  <option value="all">All</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="rather_not_say">Rather not say</option>
                </select>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="text-dark-800 text-sm">Maximum Distance</label>
                <span className="text-white text-sm">{preferences.maxDistance} km</span>
              </div>
              <input
                type="range"
                min="5"
                max="100"
                value={preferences.maxDistance}
                onChange={(e) => setPreferences(prev => ({ ...prev, maxDistance: parseInt(e.target.value, 10) }))}
                className="w-full accent-gold-400"
              />
            </div>
          </div>
        )}

        <div className="flex items-center space-x-2 sm:space-x-4 mt-6 border-t border-dark-300 pt-4 overflow-x-auto scrollbar-thin scrollbar-thumb-dark-400 scrollbar-track-transparent">
          <button
            onClick={() => setActiveTab('singles')}
            className={`flex items-center space-x-2 px-3 sm:px-4 py-2 rounded-lg whitespace-nowrap text-sm sm:text-base transition-colors ${
              activeTab === 'singles' ? 'bg-dark-300 text-gold-400' : 'text-dark-800 hover:text-white'
            }`}
          >
            <Users className="h-4 w-4 sm:h-5 sm:w-5" />
            <span>Singles</span>
          </button>
          <button
            onClick={() => setActiveTab('groups')}
            className={`flex items-center space-x-2 px-3 sm:px-4 py-2 rounded-lg whitespace-nowrap text-sm sm:text-base transition-colors ${
              activeTab === 'groups' ? 'bg-dark-300 text-gold-400' : 'text-dark-800 hover:text-white'
            }`}
          >
            <Users className="h-4 w-4 sm:h-5 sm:w-5" />
            <span>Groups</span>
          </button>
          <button
            onClick={() => setActiveTab('clubs')}
            className={`flex items-center space-x-2 px-3 sm:px-4 py-2 rounded-lg whitespace-nowrap text-sm sm:text-base transition-colors ${
              activeTab === 'clubs' ? 'bg-dark-300 text-gold-400' : 'text-dark-800 hover:text-white'
            }`}
          >
            <Trophy className="h-4 w-4 sm:h-5 sm:w-5" />
            <span>Clubs</span>
          </button>
          <button
            onClick={() => setActiveTab('tournaments')}
            className={`flex items-center space-x-2 px-3 sm:px-4 py-2 rounded-lg whitespace-nowrap text-sm sm:text-base transition-colors ${
              activeTab === 'tournaments' ? 'bg-dark-300 text-gold-400' : 'text-dark-800 hover:text-white'
            }`}
          >
            <Trophy className="h-4 w-4 sm:h-5 sm:w-5" />
            <span>Tournaments</span>
          </button>
        </div>
      </div>

      <div className="space-y-4 sm:space-y-6">
        {activeTab === 'singles' && (
          <div className="space-y-4 sm:space-y-6">
            {filteredMembers.length > 0 ? (
              filteredMembers.map((player) => (
                <PlayerCard key={player.id} player={player} />
              ))
            ) : (
              <EmptyState />
            )}
          </div>
        )}

        {activeTab === 'groups' && (
          <GroupMatchmaking
            preferences={preferences}
            onPreferencesUpdate={setPreferences}
          />
        )}

        {activeTab === 'clubs' && <ClubsList />}

        {activeTab === 'tournaments' && <TournamentPlanner />}
      </div>

      <QuickMatchModal
        isOpen={showQuickMatch}
        onClose={() => setShowQuickMatch(false)}
      />
    </div>
  );
};

export default MatchmakingSystem;