import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  MapPin, Trophy, Star, Calendar, Activity, Globe, Users, 
  Briefcase, ArrowLeft, Award, Target, Clock, TrendingUp,
  MessageCircle, Check, ChevronRight
} from 'lucide-react';
import DashboardLayout from '../components/DashboardLayout';
import MemberConnections from '../components/members/MemberConnections';
import CitiesPlayedModal from '../components/members/CitiesPlayedModal';
import ConnectionButton from '../components/members/ConnectionButton';
import ReviewsSection from '../components/members/ReviewsSection';
import { members } from '../data/members';
import { cityLandmarks } from '../data/cities';
import BookSessionModal from '../components/matchmaking/BookSessionModal';
import { posts as initialPosts } from '../data/posts';
import AboutTab from '../components/members/tabs/AboutTab';
import ActivitiesTab from '../components/members/tabs/ActivitiesTab';
import PostsTab from '../components/members/tabs/PostsTab';
import RequestMatchModal from '../components/matchmaking/RequestMatchModal';
import { getCurrentTitle } from '../data/points';

const MemberProfile: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'about' | 'activities' | 'posts' | 'reviews'>('about');
  const [showConnections, setShowConnections] = useState(false);
  const [showCitiesModal, setShowCitiesModal] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [memberPosts, setMemberPosts] = useState(initialPosts.filter(post => post.author.id === id));

  const member = members.find(m => m.id === id);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [id]);

  if (!member) {
    return (
      <DashboardLayout>
        <div className="text-center text-dark-800 py-12">
          Member not found
        </div>
      </DashboardLayout>
    );
  }

  const citiesPlayed = member.citiesPlayed || [];
  const sports = member.sports || [];
  const achievements = member.achievements || [];

  const cityImage = member.city ? 
    cityLandmarks[member.city.toLowerCase()] || 
    'https://images.unsplash.com/photo-1587974928442-77dc3e0dba72?auto=format&fit=crop&w=1600&q=80' : 
    'https://images.unsplash.com/photo-1587974928442-77dc3e0dba72?auto=format&fit=crop&w=1600&q=80';

  const handleConnect = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
  };

  const handleDisconnect = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
  };

  const handleRequestMatch = () => {
    setShowMatchModal(true);
  };

  const handleBookSession = () => {
    if (member.isTrainer) {
      setShowBookingModal(true);
    }
  };

  const handleMessageClick = () => {
    navigate('/dashboard/messages', {
      state: {
        activeChat: member.id,
        initialMessage: `Hi ${member.name}! I'd like to connect with you on Playclub.`,
        showMobileList: false
      }
    });
  };

  const totalPoints = Object.values(member.points).reduce((sum, value) => {
    if (typeof value === 'number') {
      return sum + value;
    }
    return sum;
  }, 0);

  const currentTitle = getCurrentTitle(totalPoints);

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-dark-800 hover:text-white transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        {/* Profile Header */}
        <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
          <div className="relative h-48">
            <img
              src={cityImage}
              alt={`${member.city} cityscape`}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/80 to-transparent" />
          </div>

          <div className="relative px-6 pb-6">
            <div className="flex flex-col items-center -mt-16">
              <img
                src={member.image}
                alt={member.name}
                className={`w-32 h-32 rounded-full object-cover border-4 ${
                  member.isTrainer ? 'border-gold-400' : 'border-white'
                }`}
              />
              <div className="mt-4 text-center">
                <div className="flex items-center justify-center space-x-3">
                  <h2 className="text-2xl font-bold text-white">{member.name}</h2>
                  {member.isTrainer && (
                    <span className="px-3 py-1 bg-gold-400 text-dark-50 rounded-full text-sm">
                      Elite Trainer
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-center text-dark-800 mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  {member.city}
                </div>
                <div className="flex items-center justify-center text-dark-800 mt-1">
                  <Briefcase className="h-4 w-4 mr-1" />
                  {member.jobTitle} at {member.company}
                </div>
                <div className="flex items-center justify-center mt-2">
                  <span className={`flex items-center space-x-2 px-3 py-1 rounded-full text-sm ${currentTitle.color} bg-dark-300`}>
                    <span>{currentTitle.icon}</span>
                    <span>{currentTitle.name}</span>
                  </span>
                </div>
              </div>
            </div>
            
            {/* Quick Stats */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
              <div className="bg-dark-300 rounded-xl p-4 text-center">
                <div className="text-2xl font-bold text-gold-400">{member.matchesPlayed || 0}</div>
                <div className="text-dark-800 text-sm mt-1">Matches Played</div>
              </div>
              <button
                onClick={() => setShowCitiesModal(true)}
                className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
              >
                <div className="text-2xl font-bold text-gold-400">{citiesPlayed.length}</div>
                <div className="text-dark-800 text-sm mt-1">Cities Played</div>
              </button>
              <button
                onClick={() => setShowConnections(true)}
                className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
              >
                <div className="text-2xl font-bold text-gold-400">{member.connections || 0}</div>
                <div className="text-dark-800 text-sm mt-1">Connections</div>
              </button>
              <button
                onClick={() => setActiveTab('reviews')}
                className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
              >
                <div className="flex items-center justify-center text-2xl font-bold text-gold-400">
                  {member.rating}
                  <Star className="h-5 w-5 ml-1" />
                </div>
                <div className="text-dark-800 text-sm mt-1">
                  {member.isTrainer ? `${member.trainerInfo?.reviews || 0} Reviews` : 'Rating'}
                </div>
              </button>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-wrap items-center gap-2 mt-4">
              {/* Primary Actions */}
              <div className="flex items-center gap-2">
                <ConnectionButton
                  isConnected={member.isConnected || false}
                  memberId={member.id}
                  memberName={member.name}
                  memberImage={member.image}
                  onConnect={handleConnect}
                  onDisconnect={handleDisconnect}
                />
                <button 
                  onClick={handleRequestMatch}
                  className="px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors flex items-center space-x-2"
                >
                  <Calendar className="h-4 w-4" />
                  <span>Request Match</span>
                </button>
              </div>

              {/* Secondary Actions */}
              <div className="flex items-center gap-2">
                {member.isTrainer && (
                  <button 
                    onClick={handleBookSession}
                    className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-4 py-2 rounded-xl transition-colors flex items-center space-x-2"
                  >
                    <Calendar className="h-4 w-4" />
                    <span>Book Session</span>
                  </button>
                )}
                <button 
                  onClick={handleMessageClick}
                  className="bg-dark-300 hover:bg-dark-400 text-white px-4 py-2 rounded-xl transition-colors flex items-center space-x-2"
                >
                  <MessageCircle className="h-4 w-4" />
                  <span>Message</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex items-center space-x-4 border-b border-dark-300 overflow-x-auto">
          <button
            onClick={() => setActiveTab('about')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
              activeTab === 'about'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            About
          </button>
          <button
            onClick={() => setActiveTab('activities')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
              activeTab === 'activities'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            Activities
          </button>
          <button
            onClick={() => setActiveTab('posts')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
              activeTab === 'posts'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            Posts
          </button>
          <button
            onClick={() => setActiveTab('reviews')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
              activeTab === 'reviews'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            Reviews
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === 'about' && (
          <AboutTab member={member} sports={sports} achievements={achievements} />
        )}

        {activeTab === 'activities' && (
          <ActivitiesTab activities={[]} isOwnProfile={false} />
        )}

        {activeTab === 'posts' && (
          <PostsTab posts={memberPosts} />
        )}

        {activeTab === 'reviews' && (
          <ReviewsSection
            memberId={member.id}
            memberName={member.name}
            sports={member.sports}
            rating={member.rating}
            reviewCount={member.isTrainer ? member.trainerInfo?.reviews || 0 : 0}
          />
        )}

        {/* Modals */}
        {showConnections && (
          <MemberConnections
            memberId={member.id}
            onClose={() => setShowConnections(false)}
          />
        )}

        {showCitiesModal && (
          <CitiesPlayedModal
            isOpen={true}
            cities={citiesPlayed}
            memberName={member.name}
            onClose={() => setShowCitiesModal(false)}
          />
        )}

        {showBookingModal && member.isTrainer && (
          <BookSessionModal
            trainer={{
              id: member.id,
              name: member.name,
              sports: member.sports,
              priceRange: member.trainerInfo?.priceRange || '',
              location: member.city
            }}
            isOpen={showBookingModal}
            onClose={() => setShowBookingModal(false)}
          />
        )}

        {showMatchModal && (
          <RequestMatchModal
            isOpen={showMatchModal}
            onClose={() => setShowMatchModal(false)}
            member={{
              id: member.id,
              name: member.name,
              city: member.city,
              sports: member.sports
            }}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default MemberProfile;