import { Facility } from '../types/facility';

export const facilities: Facility[] = [
  // Luxembourg Venues
  {
    id: 'lux-1',
    name: 'Coque Sports Centre',
    type: 'Multi-Sport',
    location: 'Luxembourg',
    address: '2 Rue Léon Hengen, 1745 Luxembourg',
    coordinates: {
      lat: 49.6289,
      lng: 6.1508
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Olympic Pool', 'Tennis Courts', 'Fitness Center', 'Spa'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€80-200/hour',
    description: 'National sports and culture centre with Olympic-standard facilities',
    openingHours: '6:00-23:00'
  },
  {
    id: 'lux-2',
    name: 'Royal Tennis Club',
    type: 'Tennis',
    location: 'Luxembourg',
    address: '5 Rue des Tennis, 2530 Luxembourg',
    coordinates: {
      lat: 49.6157,
      lng: 6.1385
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Clay Courts', 'Pro Shop', 'Training Academy', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€60-120/hour',
    description: 'Premier tennis facility with professional coaching',
    openingHours: '7:00-22:00'
  },
  {
    id: 'lux-3',
    name: 'Golf Club Grand-Ducal',
    type: 'Golf',
    location: 'Luxembourg',
    address: '1 Route de Trèves, 2633 Senningerberg',
    coordinates: {
      lat: 49.6453,
      lng: 6.2219
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=1600&q=80',
    amenities: ['18-Hole Course', 'Practice Area', 'Pro Shop', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€100-250/round',
    description: 'Historic golf club with challenging course',
    openingHours: 'Sunrise to Sunset'
  },
  {
    id: 'lux-4',
    name: 'Badminton Nation Luxembourg',
    type: 'Multi-Sport',
    location: 'Luxembourg',
    address: '12 Rue de l\'Innovation, 1896 Kockelscheuer',
    coordinates: {
      lat: 49.5789,
      lng: 6.1276
    },
    rating: 4.7,
    coverImage: 'https://images.unsplash.com/photo-1626224583764-f87db24ac4ea?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Professional Courts', 'Training Programs', 'Equipment Rental', 'Cafe'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€30-60/hour',
    description: 'Modern badminton facility with professional coaching',
    openingHours: '8:00-22:00'
  },
  {
    id: 'lux-5',
    name: 'Luxembourg Aquatics Center',
    type: 'Swimming',
    location: 'Luxembourg',
    address: '3 Rue des Piscines, 2327 Luxembourg',
    coordinates: {
      lat: 49.6112,
      lng: 6.1319
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Olympic Pool', 'Training Pool', 'Spa', 'Fitness Area'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€40-80/hour',
    description: 'State-of-the-art aquatics facility with comprehensive programs',
    openingHours: '6:00-22:00'
  },

  // Singapore Venues
  {
    id: 'sg-1',
    name: 'Singapore Sports Hub',
    type: 'Multi-Sport',
    location: 'Singapore',
    address: '1 Stadium Drive, Singapore 397629',
    coordinates: {
      lat: 1.3041,
      lng: 103.8732
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1577224682124-f87b20de2bf5?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Stadium', 'Aquatic Center', 'Indoor Arena', 'Gym'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: 'S$50-200/hour',
    description: 'World-class integrated sports, entertainment and lifestyle hub',
    openingHours: '6:00-22:00'
  },
  {
    id: 'sg-2',
    name: 'Marina Bay Golf Course',
    type: 'Golf',
    location: 'Singapore',
    address: '80 Rhu Cross, Singapore 437437',
    coordinates: {
      lat: 1.2906,
      lng: 103.8734
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=1600&q=80',
    amenities: ['18-Hole Course', 'Driving Range', 'Pro Shop', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: 'S$150-300/round',
    description: 'Public golf course with stunning city views',
    openingHours: 'Sunrise to Sunset'
  },
  {
    id: 'sg-3',
    name: 'OCBC Arena',
    type: 'Multi-Sport',
    location: 'Singapore',
    address: '5 Stadium Drive, Singapore 397631',
    coordinates: {
      lat: 1.3036,
      lng: 103.8753
    },
    rating: 4.7,
    coverImage: 'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Indoor Courts', 'Training Facilities', 'Fitness Center', 'Event Space'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: 'S$40-120/hour',
    description: 'Multi-purpose indoor sports facility',
    openingHours: '7:00-22:00'
  },
  {
    id: 'sg-4',
    name: 'Singapore Tennis Center',
    type: 'Tennis',
    location: 'Singapore',
    address: '100 Guillemard Road, Singapore 399718',
    coordinates: {
      lat: 1.3142,
      lng: 103.8924
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Hard Courts', 'Pro Shop', 'Training Academy', 'Cafe'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: 'S$30-80/hour',
    description: 'Premier tennis facility with professional coaching',
    openingHours: '7:00-22:00'
  },
  {
    id: 'sg-5',
    name: 'Singapore Swimming Club',
    type: 'Swimming',
    location: 'Singapore',
    address: '45 Tanjong Rhu Road, Singapore 436899',
    coordinates: {
      lat: 1.2998,
      lng: 103.8789
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Olympic Pool', 'Training Pool', 'Spa', 'Restaurant'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: 'S$40-100/hour',
    description: 'Exclusive swimming club with comprehensive facilities',
    openingHours: '6:00-22:00'
  },

  // Rome Venues
  {
    id: 'rome-1',
    name: 'Foro Italico Tennis',
    type: 'Tennis',
    location: 'Rome',
    address: 'Viale del Foro Italico, 00135 Roma RM, Italy',
    coordinates: {
      lat: 41.9283,
      lng: 12.4584
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Clay Courts', 'Stadium Court', 'Training Center', 'Pro Shop'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€80-200/hour',
    description: 'Historic tennis complex hosting international tournaments',
    openingHours: '7:00-22:00'
  },
  {
    id: 'rome-2',
    name: 'Roma Aquatics Center',
    type: 'Swimming',
    location: 'Rome',
    address: 'Via delle Terme di Caracalla, 00153 Roma RM, Italy',
    coordinates: {
      lat: 41.8789,
      lng: 12.4892
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Olympic Pool', 'Training Pool', 'Spa', 'Fitness Area'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€40-90/hour',
    description: 'Modern aquatics facility with professional training programs',
    openingHours: '6:00-22:00'
  },
  {
    id: 'rome-3',
    name: 'Golf Club Roma',
    type: 'Golf',
    location: 'Rome',
    address: 'Via dei Campi Sportivi, 00197 Roma RM, Italy',
    coordinates: {
      lat: 41.9321,
      lng: 12.4823
    },
    rating: 4.7,
    coverImage: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=1600&q=80',
    amenities: ['18-Hole Course', 'Driving Range', 'Pro Shop', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€120-280/round',
    description: 'Prestigious golf club in the heart of Rome',
    openingHours: 'Sunrise to Sunset'
  },
  {
    id: 'rome-4',
    name: 'Roma Sport Center',
    type: 'Multi-Sport',
    location: 'Rome',
    address: 'Via Flaminia Nuova, 00191 Roma RM, Italy',
    coordinates: {
      lat: 41.9456,
      lng: 12.4789
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Indoor Courts', 'Fitness Center', 'Swimming Pool', 'Spa'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€50-150/hour',
    description: 'Comprehensive sports facility with modern amenities',
    openingHours: '6:00-23:00'
  },
  {
    id: 'rome-5',
    name: 'Roma Padel Academy',
    type: 'Multi-Sport',
    location: 'Rome',
    address: 'Via Tiburtina, 00159 Roma RM, Italy',
    coordinates: {
      lat: 41.9112,
      lng: 12.5377
    },
    rating: 4.7,
    coverImage: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Indoor Courts', 'Training Programs', 'Pro Shop', 'Cafe'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€40-80/hour',
    description: 'Modern padel facility with professional instruction',
    openingHours: '8:00-22:00'
  },

  // Madrid Venues
  {
    id: 'mad-1',
    name: 'Club de Tenis Madrid',
    type: 'Tennis',
    location: 'Madrid',
    address: 'Calle del Tenis 1, 28009 Madrid, Spain',
    coordinates: {
      lat: 40.4168,
      lng: -3.7038
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Clay Courts', 'Pro Shop', 'Training Academy', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€50-120/hour',
    description: 'Premier tennis club with professional facilities',
    openingHours: '7:00-23:00'
  },
  {
    id: 'mad-2',
    name: 'Madrid Golf Club',
    type: 'Golf',
    location: 'Madrid',
    address: 'Avenida del Golf 10, 28040 Madrid, Spain',
    coordinates: {
      lat: 40.4489,
      lng: -3.7619
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=1600&q=80',
    amenities: ['18-Hole Course', 'Driving Range', 'Club House', 'Pro Shop'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€80-200/round',
    description: 'Exclusive golf club with stunning city views',
    openingHours: 'Sunrise to Sunset'
  },

  // Washington DC Venues
  {
    id: 'dc-1',
    name: 'Capital Tennis Club',
    type: 'Tennis',
    location: 'Washington DC',
    address: '1500 Tennis Court Ave, Washington, DC 20001',
    coordinates: {
      lat: 38.8977,
      lng: -77.0365
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Indoor Courts', 'Outdoor Courts', 'Pro Shop', 'Cafe'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '$60-120/hour',
    description: 'Premier tennis facility in the heart of DC',
    openingHours: '6:00-22:00'
  },
  {
    id: 'dc-2',
    name: 'DC Sports Complex',
    type: 'Multi-Sport',
    location: 'Washington DC',
    address: '2000 Sports Center Blvd, Washington, DC 20002',
    coordinates: {
      lat: 38.9072,
      lng: -77.0369
    },
    rating: 4.7,
    coverImage: 'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Indoor Courts', 'Swimming Pool', 'Fitness Center', 'Spa'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '$40-100/hour',
    description: 'Comprehensive sports facility with modern amenities',
    openingHours: '5:00-23:00'
  },

  // London Venues
  {
    id: 'lon-1',
    name: 'Queens Club',
    type: 'Tennis',
    location: 'London',
    address: 'Palliser Road, London W14 9EQ',
    coordinates: {
      lat: 51.4847,
      lng: -0.2134
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Grass Courts', 'Indoor Courts', 'Pro Shop', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '£80-200/hour',
    description: 'Historic tennis club with world-class facilities',
    openingHours: '7:00-22:00'
  },
  {
    id: 'lon-2',
    name: 'London Aquatics Centre',
    type: 'Swimming',
    location: 'London',
    address: 'Queen Elizabeth Olympic Park, London E20 2ZQ',
    coordinates: {
      lat: 51.5407,
      lng: -0.0127
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Olympic Pool', 'Diving Pool', 'Training Pool', 'Gym'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '£40-100/hour',
    description: 'Olympic standard aquatics facility',
    openingHours: '6:00-22:00'
  },

  // Dubai Venues
  {
    id: 'dub-1',
    name: 'Dubai Tennis Stadium',
    type: 'Tennis',
    location: 'Dubai',
    address: 'Dubai Tennis Stadium, Dubai',
    coordinates: {
      lat: 25.2048,
      lng: 55.2708
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Center Court', 'Practice Courts', 'VIP Lounge', 'Pro Shop'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: 'AED 200-500/hour',
    description: 'World-class tennis facility hosting international tournaments',
    openingHours: '6:00-23:00'
  },
  {
    id: 'dub-2',
    name: 'Emirates Golf Club',
    type: 'Golf',
    location: 'Dubai',
    address: 'Emirates Golf Club, Dubai',
    coordinates: {
      lat: 25.0907,
      lng: 55.1562
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Championship Course', 'Driving Range', 'Club House', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: 'AED 400-1200/round',
    description: 'Prestigious golf club with championship courses',
    openingHours: 'Sunrise to Sunset'
  },

  // Paris Venues
  {
    id: 'par-1',
    name: 'Roland Garros',
    type: 'Tennis',
    location: 'Paris',
    address: '2 Avenue Gordon Bennett, 75016 Paris',
    coordinates: {
      lat: 48.8471,
      lng: 2.2530
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Clay Courts', 'Training Center', 'Museum', 'Pro Shop'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€100-300/hour',
    description: 'Home of the French Open',
    openingHours: '8:00-21:00'
  },
  {
    id: 'par-2',
    name: 'Racing Club de France',
    type: 'Multi-Sport',
    location: 'Paris',
    address: '5 Rue Éblé, 75007 Paris',
    coordinates: {
      lat: 48.8502,
      lng: 2.3088
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Tennis Courts', 'Swimming Pool', 'Fitness Center', 'Restaurant'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€60-150/hour',
    description: 'Historic private sports club',
    openingHours: '7:00-22:00'
  },

  // Barcelona Venues
  {
    id: 'bcn-1',
    name: 'Real Club de Tenis Barcelona',
    type: 'Tennis',
    location: 'Barcelona',
    address: 'Bosch i Gimpera 5-13, 08034 Barcelona',
    coordinates: {
      lat: 41.3851,
      lng: 2.1734
    },
    rating: 4.9,
    coverImage: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Clay Courts', 'Training Academy', 'Pro Shop', 'Restaurant'],
    weatherDependent: true,
    virtualTour: true,
    priceRange: '€70-180/hour',
    description: 'Historic tennis club hosting ATP tournaments',
    openingHours: '7:00-22:00'
  },
  {
    id: 'bcn-2',
    name: 'Club Natació Barcelona',
    type: 'Swimming',
    location: 'Barcelona',
    address: 'Passeig Joan de Borbó 93, 08003 Barcelona',
    coordinates: {
      lat: 41.3758,
      lng: 2.1901
    },
    rating: 4.8,
    coverImage: 'https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1600&q=80',
    amenities: ['Olympic Pool', 'Beach Access', 'Fitness Center', 'Restaurant'],
    weatherDependent: false,
    virtualTour: true,
    priceRange: '€40-100/hour',
    description: 'Historic swimming club with Mediterranean views',
    openingHours: '6:30-22:00'
  }
];