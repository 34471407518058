import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { Label } from '../../ui/label';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';

interface SportsSelectionStepProps {
  data: {
    sports: string[];
  };
  onUpdate: (data: Partial<{ sports: string[] }>) => void;
  onNext: () => void;
  onBack: () => void;
}

const sports = [
  // Priority sports
  '🧘‍♀️ Yoga',
  '🏃‍♂️ Running',
  '🚴‍♂️ Biking',
  '⚽ Football',
  '🎾 Padel',
  '🧘‍♂️ Pilates',
  // Additional popular sports
  '🎾 Tennis',
  '⛳ Golf',
  '🏊‍♂️ Swimming',
  '🏀 Basketball',
  '🏸 Badminton',
  '🏓 Table Tennis',
  '🏐 Volleyball',
  '🎱 Pool',
  '🏋️‍♀️ Weight Training',
  '🤸‍♀️ Gymnastics',
  '🏄‍♂️ Surfing',
  '⛷️ Skiing',
  '🏂 Snowboarding',
  '🚣‍♂️ Rowing',
  '🏹 Archery',
  '🤺 Fencing',
  '🥊 Boxing',
  '🤼‍♂️ Wrestling',
  '🎯 Darts',
  '🎳 Bowling',
  '🧗‍♀️ Rock Climbing',
  '🛹 Skateboarding',
  '🛼 Roller Skating',
  '🏇 Horse Riding',
  '🤸 Parkour',
  '🎪 Circus Arts',
  '🏉 Rugby',
  '🏑 Field Hockey',
  '🥍 Lacrosse',
  '🏸 Squash',
  '🎿 Cross-Country Skiing',
  '🛷 Sledding',
  '🏄‍♀️ Windsurfing',
  '🚣 Kayaking',
  '🤿 Scuba Diving',
  '🏊‍♀️ Water Polo',
  '🤸‍♂️ Trampoline',
  '🎭 Dance Sport',
  '🎪 Acrobatics',
  '🎯 Shooting Sports',
  '🎪 Aerial Arts',
  '🏃‍♀️ Trail Running',
  '🚴‍♀️ Mountain Biking',
  '🏊‍♂️ Open Water Swimming'
];

export default function SportsSelectionStep({ data, onUpdate, onNext, onBack }: SportsSelectionStepProps) {
  const [selectedSport, setSelectedSport] = useState<string>('');

  const handleSportChange = (value: string) => {
    if (value && !data.sports.includes(value)) {
      onUpdate({ sports: [...data.sports, value] });
    }
    setSelectedSport('');
  };

  const removeSport = (sportToRemove: string) => {
    onUpdate({ sports: data.sports.filter(sport => sport !== sportToRemove) });
  };

  const availableSports = sports.filter(sport => !data.sports.includes(sport));

  return (
    <div className="bg-dark-200 rounded-2xl p-8 border border-dark-300">
      <h2 className="text-2xl font-bold text-white mb-2">Select Your Sports</h2>
      <p className="text-dark-800 mb-6">Choose the sports you're interested in playing</p>

      <div className="space-y-6">
        <div>
          <Label className="text-dark-800 mb-2 block">Select Multiple Sports</Label>
          <Select
            value={selectedSport}
            onValueChange={handleSportChange}
          >
            <SelectTrigger className="bg-dark-300 border-dark-400 text-white">
              <SelectValue placeholder="Choose a sport" />
            </SelectTrigger>
            <SelectContent>
              {availableSports.map((sport) => (
                <SelectItem
                  key={sport}
                  value={sport}
                  className="cursor-pointer hover:bg-dark-400"
                >
                  {sport}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Selected Sports Display */}
        <div className="flex flex-wrap gap-2">
          {data.sports.map((sport) => (
            <div
              key={sport}
              className="bg-dark-300 px-3 py-1 rounded-full text-white flex items-center gap-2"
            >
              <span>{sport}</span>
              <button
                onClick={() => removeSport(sport)}
                className="hover:text-red-400"
              >
                ×
              </button>
            </div>
          ))}
        </div>

        <div className="flex justify-between pt-6">
          <Button
            onClick={onBack}
            className="bg-dark-300 hover:bg-dark-400 text-white"
          >
            Back
          </Button>
          <Button
            onClick={onNext}
            disabled={data.sports.length === 0}
            className="bg-gold-400 hover:bg-gold-500 text-dark-50"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}