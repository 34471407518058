import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trophy } from 'lucide-react';
import { PostAuthor } from '../../types/post';
import { Comment } from '../../types/comment';
import PostHeader from './PostHeader';
import PostContent from './PostContent';
import LikeButton from './LikeButton';
import CommentSection from './CommentSection';
import SharePopover from './SharePopover';
import { useNotificationStore } from '../../store/notificationStore';

interface PostCardProps {
  id: string;
  author: PostAuthor;
  content: string;
  media?: string;
  likes: number;
  comments: number;
  shares: number;
  timeAgo: string;
  achievement?: string;
  endorsements?: string[];
  isLiked?: boolean;
  likedBy?: Array<{ id: string; name: string }>;
}

const PostCard: React.FC<PostCardProps> = ({
  id,
  author,
  content,
  media,
  likes,
  comments,
  shares,
  timeAgo,
  achievement,
  endorsements,
  isLiked = false,
  likedBy = []
}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [commentsList, setCommentsList] = useState<Comment[]>([]);
  const [shareCount, setShareCount] = useState(shares);

  const handleLike = async (postId: string) => {
    // In a real app, this would make an API call
    await new Promise(resolve => setTimeout(resolve, 500));

    // Add notification for demo purposes
    addNotification({
      id: `like_${Date.now()}`,
      type: 'new_message',
      title: 'New Like',
      message: `${author.name} liked your post`,
      timestamp: new Date().toISOString(),
      read: false,
      actionUrl: '/dashboard',
      sender: {
        id: author.id,
        name: author.name,
        image: author.image
      }
    });
  };

  const handleAddComment = async (postId: string, content: string) => {
    // In a real app, this would make an API call
    const newComment: Comment = {
      id: Date.now().toString(),
      author: {
        id: '55', // Current user ID
        name: 'Uri Schneider',
        image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80'
      },
      content,
      timestamp: new Date().toISOString(),
      likes: 0
    };

    setCommentsList(prev => [...prev, newComment]);

    // Add notification for demo purposes
    addNotification({
      id: `comment_${Date.now()}`,
      type: 'new_message',
      title: 'New Comment',
      message: `${author.name} commented on your post`,
      timestamp: new Date().toISOString(),
      read: false,
      actionUrl: '/dashboard',
      sender: {
        id: author.id,
        name: author.name,
        image: author.image
      }
    });
  };

  const handleShare = () => {
    setShareCount(prev => prev + 1);
  };

  return (
    <div className="bg-dark-200 rounded-2xl border border-dark-300">
      <div className="p-6">
        <PostHeader
          author={author}
          timeAgo={timeAgo}
          achievement={achievement}
          onAuthorClick={() => navigate(`/dashboard/members/${author.id}`)}
        />

        <PostContent
          content={content}
          media={media}
          endorsements={endorsements}
        />

        <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-300">
          <LikeButton
            postId={id}
            initialLikes={likes}
            isLiked={isLiked}
            onLike={handleLike}
            likedBy={likedBy}
          />
          
          <button
            onClick={() => setIsCommentsExpanded(!isCommentsExpanded)}
            className="flex items-center space-x-2 text-dark-800 hover:text-gold-400 transition-colors"
          >
            <span>{commentsList.length || comments} comments</span>
          </button>
          
          <SharePopover
            post={{ id, author, content }}
            shareCount={shareCount}
            onShare={handleShare}
          />
        </div>
      </div>

      {isCommentsExpanded && (
        <CommentSection
          postId={id}
          comments={commentsList}
          onAddComment={handleAddComment}
          onToggleComments={() => setIsCommentsExpanded(!isCommentsExpanded)}
          isExpanded={isCommentsExpanded}
        />
      )}
    </div>
  );
};

export default PostCard;