import { City } from '../types/city';
import { cities, cityLandmarks } from '../data/cities';
import Fuse from 'fuse.js';

export async function searchCities(query: string): Promise<City[]> {
  if (!query || query.length < 2) {
    return cities.filter(city => city.active);
  }

  try {
    // Initialize Fuse.js for fuzzy search
    const fuse = new Fuse(cities, {
      keys: ['name'],
      threshold: 0.3,
      includeScore: true
    });

    // Perform fuzzy search
    const results = fuse.search(query).map(result => result.item);
    return results.filter(city => city.active);
  } catch (error) {
    console.error('City search error:', error);
    return cities.filter(city => city.active);
  }
}

export async function findNearestCities(coords: GeolocationCoordinates): Promise<City[]> {
  // City coordinates (latitude, longitude)
  const cityCoords: Record<string, [number, number]> = {
    'Monte Carlo': [43.7384, 7.4246],
    'Singapore': [1.3521, 103.8198],
    'Rome': [41.9028, 12.4964],
    'Madrid': [40.4168, -3.7038],
    'Washington DC': [38.8977, -77.0365],
    'London': [51.5074, -0.1278],
    'Dubai': [25.2048, 55.2708],
    'Paris': [48.8566, 2.3522],
    'Barcelona': [41.3851, 2.1734],
  };

  // Calculate distances using Haversine formula
  const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  };

  // Calculate distances for all active cities
  const citiesWithDistances = cities
    .filter(city => city.active)
    .map(city => {
      const coords2 = cityCoords[city.name];
      if (!coords2) return { ...city, distance: Infinity };
      
      const distance = getDistance(
        coords.latitude,
        coords.longitude,
        coords2[0],
        coords2[1]
      );
      
      return { ...city, distance };
    })
    .sort((a, b) => a.distance - b.distance);

  return citiesWithDistances.slice(0, 3); // Return 3 nearest cities
}

export async function getCitiesByCountry(country: string): Promise<City[]> {
  // Filter cities based on country
  return cities.filter(city => city.active && city.country === country);
}

export async function getCountries(): Promise<string[]> {
  // Get unique list of countries from active cities
  const uniqueCountries = new Set(cities.filter(city => city.active).map(city => city.country));
  return Array.from(uniqueCountries).sort();
}