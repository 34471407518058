import React from 'react';
import { ChevronUp } from 'lucide-react';

interface FloatingCTAProps {
  show: boolean;
}

export const FloatingCTA: React.FC<FloatingCTAProps> = ({ show }) => {
  if (!show) return null;

  return (
    <button
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      className="fixed bottom-8 right-8 bg-gold-400 p-4 rounded-full shadow-lg hover:bg-gold-500 transition-colors"
    >
      <ChevronUp className="w-6 h-6" />
    </button>
  );
};