import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import ChatSystem from '../components/chat/ChatSystem';

const Messages = () => {
  return (
    <DashboardLayout>
      <ChatSystem />
    </DashboardLayout>
  );
};

export default Messages;