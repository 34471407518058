import { Post } from '../types/post';

export const posts: Post[] = [
  {
    id: '1',
    author: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80',
      location: 'Monte Carlo Tennis Club'
    },
    content: "Just clinched the Dubai Corporate Tennis Championship! Huge thanks to my doubles partner @UriSchneider. The competition was fierce but we pulled through! 🏆 #TennisLife",
    media: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=800&q=80',
    likes: 245,
    comments: 42,
    shares: 15,
    timeAgo: '2h ago',
    achievement: 'Tournament Winner'
  },
  {
    id: 'us1',
    author: {
      id: '55',
      name: 'Uri Schneider',
      image: 'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?auto=format&fit=crop&w=300&q=80',
      location: 'Valencia'
    },
    content: "Excited to share that I've joined the advisory board of the Valencia Tennis Association. Looking forward to contributing to the growth of tennis in our region! 🎾 #TennisLeadership",
    likes: 312,
    comments: 87,
    shares: 45,
    timeAgo: '1h ago'
  },
  {
    id: '2',
    author: {
      id: '52',
      name: 'James Wilson',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80',
      location: 'Paris'
    },
    content: "Perfect conditions at Golf National today. Shot my personal best! The course improvements are remarkable. Open invitation for a round this weekend. ⛳",
    media: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=800&q=80',
    likes: 156,
    comments: 31,
    shares: 8,
    timeAgo: '5h ago'
  },
  {
    id: '3',
    author: {
      id: 'dub1',
      name: 'Hassan Al-Rashid',
      image: 'https://images.unsplash.com/photo-1566492031773-4f4e44671857?auto=format&fit=crop&w=300&q=80',
      location: 'Emirates Golf Club'
    },
    content: "Incredible round of golf with fellow CEOs at Emirates Golf Club. Business deals are best made on the green! 🏌️‍♂️ #BusinessGolf",
    media: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=800&q=80',
    likes: 423,
    comments: 67,
    shares: 28,
    timeAgo: '3h ago'
  },
  {
    id: '4',
    author: {
      id: 'lon1',
      name: 'Alexander Wright',
      image: 'https://images.unsplash.com/photo-1463453091185-61582044d556?auto=format&fit=crop&w=300&q=80',
      location: 'Queens Club'
    },
    content: "Great morning session at Queens Club. The serve is really coming together! Thanks to @MichaelChen for the expert coaching. 🎾",
    likes: 189,
    comments: 34,
    shares: 12,
    timeAgo: '4h ago'
  },
  {
    id: '5',
    author: {
      id: 'sg1',
      name: 'Michael Zhang',
      image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80',
      location: 'Singapore Sports Hub'
    },
    content: "Hosted an exclusive tennis clinic for Morgan Stanley's APAC leadership team. Amazing energy and competitive spirit! 🎾 #CorporateSports",
    media: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=800&q=80',
    likes: 287,
    comments: 45,
    shares: 19,
    timeAgo: '6h ago'
  },
  {
    id: '6',
    author: {
      id: 'par1',
      name: 'Antoine Dubois',
      image: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?auto=format&fit=crop&w=300&q=80',
      location: 'Roland Garros'
    },
    content: "Early morning practice at Roland Garros. There's something magical about these clay courts at sunrise. Ready for the corporate tournament next week! ☀️🎾",
    media: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=800&q=80',
    likes: 234,
    comments: 41,
    shares: 15,
    timeAgo: '8h ago'
  },
  {
    id: '7',
    author: {
      id: 'dc1',
      name: 'Catherine Meyer',
      image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?auto=format&fit=crop&w=300&q=80',
      location: 'Capital Tennis Club'
    },
    content: "Fantastic networking event at Capital Tennis Club today. Who knew policy discussions could be this fun? Thanks to everyone who joined! 🎾🤝",
    likes: 176,
    comments: 38,
    shares: 22,
    timeAgo: '10h ago'
  },
  {
    id: '8',
    author: {
      id: 'bcn2',
      name: 'Jean-Marc Fischer',
      image: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?auto=format&fit=crop&w=300&q=80',
      location: 'Real Club de Tenis Barcelona'
    },
    content: "Just wrapped up our quarterly partners' tennis tournament. Great way to strengthen team bonds and stay active! 🎾 #DeloitteSports",
    media: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=800&q=80',
    likes: 312,
    comments: 54,
    shares: 27,
    timeAgo: '12h ago'
  },
  {
    id: '9',
    author: {
      id: 'rom1',
      name: 'Marco Rossi',
      image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
      location: 'Rome Tennis Club'
    },
    content: "Honored to host the annual Banking & Finance Tennis Cup. Congratulations to all participants! Special thanks to @SarahConnor for an amazing final match. 🏆",
    likes: 289,
    comments: 47,
    shares: 31,
    timeAgo: '1d ago'
  },
  {
    id: '10',
    author: {
      id: 'dub2',
      name: 'Sophia Al-Malik',
      image: 'https://images.unsplash.com/photo-1548142813-c348350df52b?auto=format&fit=crop&w=300&q=80',
      location: 'Dubai Tennis Stadium'
    },
    content: "Leading the new corporate wellness initiative at Dubai Holding through sports. Today's tennis session with our executive team was a huge success! 🎾💪",
    media: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=800&q=80',
    likes: 345,
    comments: 62,
    shares: 41,
    timeAgo: '1d ago'
  }
];