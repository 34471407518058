import { useState } from 'react';
import { FormData } from '../lib/types';
import { validateFormData, ValidationErrors } from '../lib/validation';

interface UseFormSubmissionReturn {
  isSubmitting: boolean;
  submitError: string | null;
  validationErrors: ValidationErrors;
  handleSubmit: (formData: FormData) => Promise<boolean>;
}

export function useFormSubmission(): UseFormSubmissionReturn {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  const handleSubmit = async (formData: FormData): Promise<boolean> => {
    setIsSubmitting(true);
    setSubmitError(null);
    setValidationErrors({});

    // Validate form data
    const errors = validateFormData(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setIsSubmitting(false);
      return false;
    }

    try {
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1500));
      setValidationErrors({}); // Clear all validation errors on success
      return true;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to submit form';
      setSubmitError(errorMessage);
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    submitError,
    validationErrors,
    handleSubmit,
  };
}