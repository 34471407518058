import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Users, MapPin, MessageCircle, UserMinus, Filter } from 'lucide-react';
import { members } from '../../../data/members';
import MutualConnectionsModal from '../modals/MutualConnectionsModal';

interface Connection {
  id: string;
  name: string;
  image: string;
  jobTitle: string;
  company: string;
  city: string;
  mutualFriends: number;
  mutualSports: string[];
  distance: number;
}

const NetworkTab: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'name' | 'distance' | 'mutual'>('name');
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState<string | null>(null);
  const [selectedMutuals, setSelectedMutuals] = useState<{ id: string; name: string; count: number } | null>(null);

  // Filter connected members and add mutual info
  const connections: Connection[] = members
    .filter(member => member.isConnected)
    .map(member => ({
      id: member.id,
      name: member.name,
      image: member.image,
      jobTitle: member.jobTitle,
      company: member.company,
      city: member.city,
      mutualFriends: Math.floor(Math.random() * 10) + 1,
      mutualSports: member.sports.filter(sport => ['Tennis', 'Golf'].includes(sport)),
      distance: Math.floor(Math.random() * 1000)
    }));

  const filteredConnections = connections.filter(connection =>
    connection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    connection.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    connection.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedConnections = [...filteredConnections].sort((a, b) => {
    switch (sortBy) {
      case 'distance':
        return a.distance - b.distance;
      case 'mutual':
        return b.mutualFriends - a.mutualFriends;
      default:
        return a.name.localeCompare(b.name);
    }
  });

  const handleDisconnect = async (connectionId: string) => {
    // Handle disconnect logic here
    setShowDisconnectConfirm(null);
  };

  const formatDistance = (meters: number) => {
    return meters >= 1000 
      ? `${(meters / 1000).toFixed(1)}km away`
      : `${meters}m away`;
  };

  const handleMessageClick = (connectionId: string, connectionName: string) => {
    const firstName = connectionName.split(' ')[0];
    navigate('/dashboard/messages', {
      state: {
        activeChat: connectionId,
        initialMessage: `Hi ${firstName}!`,
        showMobileList: false
      }
    });
  };

  return (
    <div className="space-y-6">
      {/* Search and Filters */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex flex-col space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <input
              type="text"
              placeholder="Search connections..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
            />
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Filter className="h-5 w-5 text-dark-800" />
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value as 'name' | 'distance' | 'mutual')}
                className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
              >
                <option value="name">Sort by Name</option>
                <option value="distance">Sort by Distance</option>
                <option value="mutual">Sort by Mutual Friends</option>
              </select>
            </div>
            <div className="text-dark-800">
              {filteredConnections.length} connections
            </div>
          </div>
        </div>
      </div>

      {/* Connections List */}
      <div className="space-y-4">
        {sortedConnections.map((connection) => (
          <div
            key={connection.id}
            className="bg-dark-200 rounded-xl p-4 border border-dark-300"
          >
            <div className="flex items-start space-x-4">
              <button
                onClick={() => navigate(`/dashboard/members/${connection.id}`)}
                className="flex-shrink-0"
              >
                <img
                  src={connection.image}
                  alt={connection.name}
                  className="w-16 h-16 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-colors"
                />
              </button>
              <div className="flex-1">
                <div className="flex items-start justify-between">
                  <div>
                    <button
                      onClick={() => navigate(`/dashboard/members/${connection.id}`)}
                      className="text-white font-medium hover:text-gold-400 transition-colors"
                    >
                      {connection.name}
                    </button>
                    <div className="text-dark-800 mt-1">
                      {connection.jobTitle} at {connection.company}
                    </div>
                    <div className="flex items-center text-dark-800 mt-1">
                      <MapPin className="h-4 w-4 mr-1" />
                      {connection.city} • {formatDistance(connection.distance)}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button 
                      onClick={() => handleMessageClick(connection.id, connection.name)}
                      className="p-2 bg-dark-300 hover:bg-dark-400 rounded-lg transition-colors"
                    >
                      <MessageCircle className="h-5 w-5 text-white" />
                    </button>
                    <button
                      onClick={() => setShowDisconnectConfirm(connection.id)}
                      className="p-2 bg-dark-300 hover:bg-dark-400 rounded-lg transition-colors"
                    >
                      <UserMinus className="h-5 w-5 text-white" />
                    </button>
                  </div>
                </div>

                <div className="flex items-center space-x-6 mt-4">
                  <button
                    onClick={() => setSelectedMutuals({
                      id: connection.id,
                      name: connection.name,
                      count: connection.mutualFriends
                    })}
                    className="flex items-center text-dark-800 hover:text-white transition-colors"
                  >
                    <Users className="h-4 w-4 mr-2" />
                    {connection.mutualFriends} mutual connections
                  </button>
                  {connection.mutualSports.length > 0 && (
                    <div className="flex items-center space-x-2">
                      {connection.mutualSports.map((sport, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-dark-300 rounded-full text-xs text-white"
                        >
                          {sport}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Disconnect Confirmation */}
            {showDisconnectConfirm === connection.id && (
              <div className="mt-4 p-4 bg-dark-300 rounded-xl">
                <p className="text-white mb-4">
                  Are you sure you want to disconnect from {connection.name}?
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setShowDisconnectConfirm(null)}
                    className="px-4 py-2 bg-dark-400 hover:bg-dark-500 text-white rounded-lg transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleDisconnect(connection.id)}
                    className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors"
                  >
                    Disconnect
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Mutual Connections Modal */}
      {selectedMutuals && (
        <MutualConnectionsModal
          isOpen={true}
          onClose={() => setSelectedMutuals(null)}
          connectionName={selectedMutuals.name}
          mutualCount={selectedMutuals.count}
        />
      )}
    </div>
  );
};

export default NetworkTab;