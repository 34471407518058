import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Calendar, Clock, Filter, Activity, Search, RefreshCw, X } from 'lucide-react';
import { Input } from '../../ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import ActivityDetailsModal from "../modals/ActivityDetailsModal";
import ManageActivityModal from "../modals/ManageActivityModal";

interface TrainingSession {
  id: string;
  sport: string;
  trainer: {
    id: string;
    name: string;
    image: string;
  };
  date: string;
  time: string;
  venue: {
    name: string;
    city: string;
  };
  type: string;
  focus: string;
  status: 'upcoming' | 'completed' | 'cancelled';
}

const TrainingActivitiesTab: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [sortOrder, setSortOrder] = useState<'recent' | 'oldest'>('recent');
  const [selectedSession, setSelectedSession] = useState<TrainingSession | null>(null);
  const [showManageModal, setShowManageModal] = useState(false);

  const trainingSessions: TrainingSession[] = [
    {
      id: '1',
      sport: '🎾 Tennis',
      trainer: {
        id: '1',
        name: 'Rafael Torres',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-20',
      time: '10:00',
      venue: {
        name: 'Monte Carlo Tennis Club',
        city: 'Monte Carlo'
      },
      type: 'Advanced Training',
      focus: 'Serve Technique',
      status: 'upcoming'
    },
    {
      id: '2',
      sport: '⛳ Golf',
      trainer: {
        id: '2',
        name: 'Victoria Chen',
        image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-15',
      time: '14:00',
      venue: {
        name: 'Royal Golf Club',
        city: 'Nice'
      },
      type: 'Performance Analysis',
      focus: 'Swing Mechanics',
      status: 'completed'
    }
  ];

  const filteredSessions = trainingSessions.filter(session => {
    const matchesSearch = session.trainer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         session.venue.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         session.venue.city.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesSport = selectedSport === 'all' || session.sport === selectedSport;
    const matchesCity = selectedCity === 'all' || session.venue.city === selectedCity;
    const matchesStatus = selectedStatus === 'all' || session.status === selectedStatus;
    
    return matchesSearch && matchesSport && matchesCity && matchesStatus;
  }).sort((a, b) => {
    const dateA = new Date(`${a.date} ${a.time}`).getTime();
    const dateB = new Date(`${b.date} ${b.time}`).getTime();
    return sortOrder === 'recent' ? dateB - dateA : dateA - dateB;
  });

  const handleTrainerClick = (trainerId: string) => {
    navigate(`/dashboard/members/trainer-${trainerId}`);
  };

  const handleViewDetails = (session: TrainingSession) => {
    setSelectedSession(session);
  };

  const handleManageSession = (session: TrainingSession) => {
    setSelectedSession(session);
    setShowManageModal(true);
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setSelectedSport('all');
    setSelectedCity('all');
    setSelectedStatus('all');
    setSortOrder('recent');
  };

  return (
    <div className="space-y-6">
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex flex-col space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <Input
              type="text"
              placeholder="Search training sessions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 bg-dark-300 border-dark-400 text-white"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
            <Select value={selectedSport} onValueChange={setSelectedSport}>
              <SelectTrigger>
                <SelectValue placeholder="All Sports" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Sports</SelectItem>
                <SelectItem value="🎾 Tennis">Tennis</SelectItem>
                <SelectItem value="⛳ Golf">Golf</SelectItem>
                <SelectItem value="🏊‍♂️ Swimming">Swimming</SelectItem>
              </SelectContent>
            </Select>

            <Select value={selectedCity} onValueChange={setSelectedCity}>
              <SelectTrigger>
                <SelectValue placeholder="All Cities" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Cities</SelectItem>
                <SelectItem value="Monte Carlo">Monte Carlo</SelectItem>
                <SelectItem value="Nice">Nice</SelectItem>
                <SelectItem value="Paris">Paris</SelectItem>
              </SelectContent>
            </Select>

            <Select value={selectedStatus} onValueChange={setSelectedStatus}>
              <SelectTrigger>
                <SelectValue placeholder="All Statuses" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Statuses</SelectItem>
                <SelectItem value="upcoming">Upcoming</SelectItem>
                <SelectItem value="completed">Completed</SelectItem>
                <SelectItem value="cancelled">Cancelled</SelectItem>
              </SelectContent>
            </Select>

            <Select value={sortOrder} onValueChange={(value: 'recent' | 'oldest') => setSortOrder(value)}>
              <SelectTrigger>
                <SelectValue placeholder="Sort Order" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="recent">Most Recent</SelectItem>
                <SelectItem value="oldest">Oldest First</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {filteredSessions.map((session) => (
          <div
            key={session.id}
            className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => handleTrainerClick(session.trainer.id)}
                  className="relative group"
                >
                  <img
                    src={session.trainer.image}
                    alt={session.trainer.name}
                    className="w-12 h-12 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                  />
                  <div className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-dark-200 border border-dark-300 flex items-center justify-center">
                    <span className="text-xs">{session.sport.split(' ')[0]}</span>
                  </div>
                </button>
                <div>
                  <button
                    onClick={() => handleTrainerClick(session.trainer.id)}
                    className="text-white font-medium hover:text-gold-400 transition-colors"
                  >
                    {session.trainer.name}
                  </button>
                  <div className="flex items-center text-dark-800 text-sm">
                    <MapPin className="h-4 w-4 mr-1" />
                    {session.venue.name}
                  </div>
                </div>
              </div>
              <span className={`text-sm ${
                session.status === 'upcoming' ? 'text-blue-400' :
                session.status === 'completed' ? 'text-green-400' :
                'text-red-400'
              }`}>
                {session.status.charAt(0).toUpperCase() + session.status.slice(1)}
              </span>
            </div>

            <div className="mt-4">
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 text-dark-800 text-sm">
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {new Date(session.date).toLocaleDateString()}
                </div>
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {session.time}
                </div>
                <div className="flex items-center">
                  <Activity className="h-4 w-4 mr-1" />
                  {session.type}
                </div>
                <div className="flex items-center">
                  <Filter className="h-4 w-4 mr-1" />
                  {session.focus}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-4 pt-4 border-t border-dark-300 space-y-4 sm:space-y-0">
                <div className="flex items-center space-x-3">
                  <button
                    onClick={() => handleViewDetails(session)}
                    className="text-white hover:text-gold-400"
                  >
                    View Details
                  </button>
                  {session.status === 'upcoming' && (
                    <button
                      onClick={() => handleManageSession(session)}
                      className="bg-dark-300 hover:bg-dark-400 text-white px-4 py-2 rounded-lg transition-colors"
                    >
                      Manage
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {filteredSessions.length === 0 && (
          <div className="text-center py-12 bg-dark-200 rounded-2xl border border-dark-300">
            <Activity className="h-12 w-12 text-dark-800 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-white mb-2">No Training Sessions Found</h3>
            <p className="text-dark-800 mb-6">
              Try adjusting your filters or search criteria
            </p>
            <button
              onClick={handleResetFilters}
              className="inline-flex items-center space-x-2 px-6 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Reset Filters
            </button>
          </div>
        )}
      </div>

      {selectedSession && (
        <ActivityDetailsModal
          isOpen={true}
          onClose={() => setSelectedSession(null)}
          activity={{
            id: selectedSession.id,
            sport: selectedSession.sport,
            opponent: {
              id: selectedSession.trainer.id,
              name: selectedSession.trainer.name,
              image: selectedSession.trainer.image
            },
            date: selectedSession.date,
            time: selectedSession.time,
            venue: selectedSession.venue,
            status: selectedSession.status === 'completed' ? 'played' : 'confirmed'
          }}
          isOwnProfile={true}
        />
      )}

      {selectedSession && showManageModal && (
        <ManageActivityModal
          isOpen={true}
          onClose={() => {
            setShowManageModal(false);
            setSelectedSession(null);
          }}
          activity={{
            id: selectedSession.id,
            sport: selectedSession.sport,
            opponent: {
              id: selectedSession.trainer.id,
              name: selectedSession.trainer.name,
              image: selectedSession.trainer.image
            },
            date: selectedSession.date,
            time: selectedSession.time,
            venue: selectedSession.venue,
            status: 'confirmed'
          }}
        />
      )}
    </div>
  );
};

export default TrainingActivitiesTab;