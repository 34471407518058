import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import SocialFeed from '../components/dashboard/SocialFeed';

const Dashboard = () => {
  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Social Feed */}
        <SocialFeed />
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;