import React, { useState } from 'react';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Camera, LogOut } from 'lucide-react';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const AccountSettings: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [profileData, setProfileData] = useState({
    fullName: 'Uri Schneider',
    email: 'uri@example.com',
    phone: '+1 234 567 8900',
    company: 'Tech Ventures',
    jobTitle: 'CEO',
    bio: 'Passionate about sports and technology.'
  });

  const handleSave = async () => {
    // Handle saving profile data
    console.log('Saving profile data:', profileData);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="space-y-6">
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h2 className="text-xl font-semibold text-white mb-6">Account Settings</h2>

        {/* Profile Picture */}
        <div className="mb-8">
          <Label className="text-dark-800 mb-2 block">Profile Picture</Label>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80"
                alt="Profile"
                className="w-24 h-24 rounded-xl object-cover"
              />
              <label className="absolute bottom-0 right-0 p-2 bg-gold-400 rounded-lg cursor-pointer">
                <Camera className="h-4 w-4 text-dark-50" />
                <input type="file" className="hidden" accept="image/*" />
              </label>
            </div>
            <div className="text-dark-800 text-sm">
              Recommended: Square image, at least 400x400px
            </div>
          </div>
        </div>

        {/* Form Fields */}
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="fullName" className="text-dark-800">Full Name</Label>
              <Input
                id="fullName"
                value={profileData.fullName}
                onChange={(e) => setProfileData({ ...profileData, fullName: e.target.value })}
                className="bg-dark-300 border-dark-400 text-white mt-1"
              />
            </div>
            <div>
              <Label htmlFor="email" className="text-dark-800">Email</Label>
              <Input
                id="email"
                type="email"
                value={profileData.email}
                onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                className="bg-dark-300 border-dark-400 text-white mt-1"
              />
            </div>
            <div>
              <Label htmlFor="phone" className="text-dark-800">Phone</Label>
              <Input
                id="phone"
                value={profileData.phone}
                onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
                className="bg-dark-300 border-dark-400 text-white mt-1"
              />
            </div>
            <div>
              <Label htmlFor="company" className="text-dark-800">Company</Label>
              <Input
                id="company"
                value={profileData.company}
                onChange={(e) => setProfileData({ ...profileData, company: e.target.value })}
                className="bg-dark-300 border-dark-400 text-white mt-1"
              />
            </div>
            <div>
              <Label htmlFor="jobTitle" className="text-dark-800">Job Title</Label>
              <Input
                id="jobTitle"
                value={profileData.jobTitle}
                onChange={(e) => setProfileData({ ...profileData, jobTitle: e.target.value })}
                className="bg-dark-300 border-dark-400 text-white mt-1"
              />
            </div>
          </div>

          <div>
            <Label htmlFor="bio" className="text-dark-800">Bio</Label>
            <textarea
              id="bio"
              value={profileData.bio}
              onChange={(e) => setProfileData({ ...profileData, bio: e.target.value })}
              className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white mt-1 h-32 resize-none"
            />
          </div>

          <div className="flex justify-between items-center pt-6 border-t border-dark-300">
            <button
              onClick={handleLogout}
              className="flex items-center space-x-2 px-4 py-2 bg-dark-300 hover:bg-dark-400 text-red-400 rounded-xl transition-colors"
            >
              <LogOut className="h-5 w-5" />
              <span>Logout</span>
            </button>
            <Button
              onClick={handleSave}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;