import React from 'react';
import { MapPin, X } from 'lucide-react';

interface CitiesPlayedModalProps {
  isOpen: boolean;
  onClose: () => void;
  cities: string[];
  memberName: string;
}

const CitiesPlayedModal: React.FC<CitiesPlayedModalProps> = ({
  isOpen,
  onClose,
  cities,
  memberName
}) => {
  if (!isOpen) return null;

  // Create a map to track city occurrences and make unique keys
  const cityMap = cities.reduce((acc, city, index) => {
    const key = `${city}-${index}`;
    if (!acc[city]) {
      acc[city] = { count: 1, firstKey: key };
    } else {
      acc[city].count++;
    }
    return acc;
  }, {} as Record<string, { count: number; firstKey: string }>);

  // Sort cities by name
  const uniqueCities = [...new Set(cities)].sort();

  return (
    <div className="fixed inset-0 z-50 bg-dark-50/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="bg-dark-200 rounded-2xl w-full max-w-2xl border border-dark-300">
            {/* Header */}
            <div className="p-6 border-b border-dark-300">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-xl font-bold text-white">
                    Cities Played In
                  </h2>
                  <p className="text-dark-800">
                    {memberName}'s global presence
                  </p>
                </div>
                <button
                  onClick={onClose}
                  className="text-dark-800 hover:text-white transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Cities Grid */}
            <div className="p-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {uniqueCities.map((city) => (
                  <div
                    key={cityMap[city].firstKey}
                    className="bg-dark-300 rounded-xl p-4 flex items-center space-x-3"
                  >
                    <div className="bg-dark-400 p-2 rounded-lg">
                      <MapPin className="h-5 w-5 text-gold-400" />
                    </div>
                    <div>
                      <div className="text-white">{city}</div>
                      {cityMap[city].count > 1 && (
                        <div className="text-dark-800 text-sm">
                          {cityMap[city].count} matches
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitiesPlayedModal;