import React from 'react';

interface ProfileTabsProps {
  activeTab: 'about' | 'posts' | 'network' | 'activities' | 'training' | 'achievements' | 'performance';
  onTabChange: (tab: 'about' | 'posts' | 'network' | 'activities' | 'training' | 'achievements' | 'performance') => void;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="flex items-center space-x-4 border-b border-dark-300 overflow-x-auto">
      <button
        onClick={() => onTabChange('about')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
          activeTab === 'about'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        About
      </button>
      <button
        onClick={() => onTabChange('posts')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
          activeTab === 'posts'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        Posts
      </button>
      <button
        onClick={() => onTabChange('network')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
          activeTab === 'network'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        Network
      </button>
      <button
        onClick={() => onTabChange('activities')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
          activeTab === 'activities'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        Activities
      </button>
      <button
        onClick={() => onTabChange('training')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
          activeTab === 'training'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        Training
      </button>
      <button
        onClick={() => onTabChange('achievements')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
          activeTab === 'achievements'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        Achievements
      </button>
      <button
        onClick={() => onTabChange('performance')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap ${
          activeTab === 'performance'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        Performance
      </button>
    </div>
  );
};

export default ProfileTabs;