import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import SettingsPanel from '../components/settings/SettingsPanel';

const Settings = () => {
  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-bold text-white">Settings</h1>
              <p className="text-dark-800 mt-1">Manage your account preferences</p>
            </div>
          </div>
        </div>

        {/* Settings Panel */}
        <SettingsPanel />
      </div>
    </DashboardLayout>
  );
};

export default Settings;