import React from 'react';
import { Sun, Cloud, CloudRain, Wind } from 'lucide-react';

interface WeatherWidgetProps {
  location: string;
}

const WeatherWidget: React.FC<WeatherWidgetProps> = ({ location }) => {
  // In a real app, fetch weather data from an API
  const weatherData = {
    current: {
      temp: 22,
      condition: 'sunny',
      wind: 12
    },
    forecast: [
      { time: '09:00', temp: 20, condition: 'sunny' },
      { time: '12:00', temp: 24, condition: 'cloudy' },
      { time: '15:00', temp: 23, condition: 'partly-cloudy' },
      { time: '18:00', temp: 19, condition: 'clear' }
    ]
  };

  const getWeatherIcon = (condition: string) => {
    switch (condition) {
      case 'sunny':
        return <Sun className="h-5 w-5 text-gold-400" />;
      case 'cloudy':
        return <Cloud className="h-5 w-5 text-dark-800" />;
      case 'rainy':
        return <CloudRain className="h-5 w-5 text-blue-400" />;
      default:
        return <Sun className="h-5 w-5 text-gold-400" />;
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          {getWeatherIcon(weatherData.current.condition)}
          <div>
            <span className="text-white font-medium">{weatherData.current.temp}°C</span>
            <span className="text-dark-800 text-sm ml-2">
              Wind: {weatherData.current.wind} km/h
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          {weatherData.forecast.map((forecast, index) => (
            <div key={index} className="text-center">
              <span className="text-dark-800 text-xs">{forecast.time}</span>
              <div className="my-1">{getWeatherIcon(forecast.condition)}</div>
              <span className="text-white text-sm">{forecast.temp}°C</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeatherWidget;