import { FormData } from './types';

export interface ValidationErrors {
  fullName?: string;
  email?: string;
  company?: string;
  role?: string;
  country?: string;
  dateOfBirth?: string;
}

export function validateFormData(data: FormData): ValidationErrors {
  const errors: ValidationErrors = {};

  if (!data.fullName.trim()) {
    errors.fullName = 'Full Name is required';
  }

  if (!data.email.trim()) {
    errors.email = 'Email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!data.company.trim()) {
    errors.company = 'Company is required';
  }

  if (!data.role?.trim()) {
    errors.role = 'Role is required';
  }

  if (!data.country) {
    errors.country = 'Country is required';
  }

  if (!data.dateOfBirth) {
    errors.dateOfBirth = 'Date of Birth is required';
  } else {
    const birthDate = new Date(data.dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    if (age < 18) {
      errors.dateOfBirth = 'You must be at least 18 years old';
    }
  }

  return errors;
}