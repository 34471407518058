import React from 'react';
import { Calendar, MapPin, Users, Trophy } from 'lucide-react';

const EventsDashboard = () => {
  const upcomingEvents = [
    {
      title: 'Tennis Tournament',
      type: 'Tournament',
      date: 'March 15-20, 2024',
      location: 'Royal Tennis Club',
      participants: 32,
      prize: '€5,000'
    },
    {
      title: 'Golf Masters Series',
      type: 'Championship',
      date: 'April 5-7, 2024',
      location: 'Elite Golf Club',
      participants: 24,
      prize: '€3,000'
    }
  ];

  const myEvents = [
    {
      title: 'Tennis Training Session',
      date: 'Tomorrow, 10:00 AM',
      location: 'Royal Tennis Club',
      type: 'Training'
    },
    {
      title: 'Golf Practice Round',
      date: 'Friday, 2:00 PM',
      location: 'Elite Golf Club',
      type: 'Practice'
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
        <div className="flex items-start justify-between">
          <div>
            <h1 className="text-2xl font-bold text-white">Events Calendar</h1>
            <p className="text-dark-800 mt-1">Manage your sports events and tournaments</p>
          </div>
          <button className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-xl transition-colors">
            Create Event
          </button>
        </div>
      </div>

      {/* Calendar View */}
      <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-white">March 2024</h2>
          <div className="flex items-center space-x-4">
            <button className="text-dark-800 hover:text-white">Today</button>
            <div className="flex items-center space-x-2">
              <button className="p-2 bg-dark-300 rounded-lg hover:bg-dark-400">
                <Calendar className="h-5 w-5 text-gold-400" />
              </button>
            </div>
          </div>
        </div>
        {/* Calendar Grid */}
        <div className="grid grid-cols-7 gap-2">
          {/* Calendar implementation would go here */}
        </div>
      </div>

      {/* Upcoming Tournaments */}
      <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
        <h2 className="text-xl font-semibold text-white mb-6">Upcoming Tournaments</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {upcomingEvents.map((event, index) => (
            <div
              key={index}
              className="bg-dark-300 rounded-xl p-6 border border-dark-400 hover:border-gold-400 transition-all duration-300"
            >
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h3 className="text-white font-medium">{event.title}</h3>
                  <span className="inline-block px-3 py-1 bg-dark-400 rounded-full text-xs text-white mt-2">
                    {event.type}
                  </span>
                </div>
                <div className="text-gold-400 font-medium">{event.prize}</div>
              </div>
              <div className="space-y-2 text-dark-800">
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-2" />
                  {event.date}
                </div>
                <div className="flex items-center">
                  <MapPin className="h-4 w-4 mr-2" />
                  {event.location}
                </div>
                <div className="flex items-center">
                  <Users className="h-4 w-4 mr-2" />
                  {event.participants} participants
                </div>
              </div>
              <button className="w-full mt-4 bg-dark-400 hover:bg-dark-500 text-white px-4 py-2 rounded-lg transition-colors">
                Register Now
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* My Events */}
      <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
        <h2 className="text-xl font-semibold text-white mb-6">My Events</h2>
        <div className="space-y-4">
          {myEvents.map((event, index) => (
            <div
              key={index}
              className="bg-dark-300 rounded-xl p-4 flex items-center justify-between"
            >
              <div className="flex items-center space-x-4">
                <div className="bg-dark-400 p-3 rounded-lg">
                  <Trophy className="h-5 w-5 text-gold-400" />
                </div>
                <div>
                  <h3 className="text-white font-medium">{event.title}</h3>
                  <p className="text-dark-800 text-sm">{event.location}</p>
                  <p className="text-dark-800 text-sm">{event.date}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <span className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white">
                  {event.type}
                </span>
                <button className="bg-dark-400 text-white px-4 py-2 rounded-lg hover:bg-dark-500 transition-colors">
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventsDashboard;