import React from 'react';
import { 
  Users, MapPin, Trophy, Brain, Globe, Calendar, 
  Video, Activity, MessageSquare, Star 
} from 'lucide-react';
import { Button } from '../components/ui/button';

const Features = () => {
  const mainFeatures = [
    {
      title: "Match with Players",
      description: "Connect with like-minded athletes worldwide based on your preferences",
      icon: Users,
      image: "https://images.unsplash.com/photo-1461896836934-ffe607ba8211?auto=format&fit=crop&w=2400&q=80",
      benefits: [
        "AI-powered matchmaking",
        "Skill level verification",
        "Location-based matching",
        "Schedule coordination"
      ]
    },
    {
      title: "Premium Facilities",
      description: "Access exclusive sports venues and clubs across the globe",
      icon: MapPin,
      image: "https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=2400&q=80",
      benefits: [
        "Global facility network",
        "Priority booking",
        "Equipment rental",
        "Exclusive amenities"
      ]
    },
    {
      title: "Elite Trainers",
      description: "Train with certified professionals tailored to your goals",
      icon: Trophy,
      image: "https://images.unsplash.com/photo-1571019614242-c5c5dee9f50b?auto=format&fit=crop&w=2400&q=80",
      benefits: [
        "Personalized coaching",
        "Video analysis",
        "Progress tracking",
        "Flexible scheduling"
      ]
    }
  ];

  const aiFeatures = [
    {
      title: "Performance Analysis",
      description: "Real-time feedback and detailed performance metrics",
      icon: Brain
    },
    {
      title: "Video Analysis",
      description: "AI-powered technique analysis and improvement suggestions",
      icon: Video
    },
    {
      title: "Progress Tracking",
      description: "Comprehensive progress monitoring and goal setting",
      icon: Activity
    }
  ];

  const testimonials = [
    {
      name: "Sarah Connor",
      role: "Tennis Professional",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80",
      quote: "Playclub.ai has transformed how I train and connect with other athletes during my business travels."
    },
    {
      name: "James Wilson",
      role: "Investment Banker & Golf Enthusiast",
      image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80",
      quote: "The perfect platform for maintaining my active lifestyle while managing a busy schedule."
    }
  ];

  return (
    <div className="min-h-screen bg-dark-50">
      {/* Hero Section */}
      <section className="relative h-[60vh] overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url(https://images.unsplash.com/photo-1574629810360-7efbbe195018?auto=format&fit=crop&w=2400&q=80)",
          }}
        />
        <div className="absolute inset-0 bg-black/60" />
        <div className="relative h-full flex items-center">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              Elite Features for Elite Athletes
            </h1>
            <p className="text-xl text-gray-300 max-w-2xl">
              Experience a new level of sports networking and training with our premium features designed for discerning athletes.
            </p>
          </div>
        </div>
      </section>

      {/* Main Features */}
      <section className="py-24 bg-dark-100">
        <div className="container mx-auto px-4">
          <div className="space-y-24">
            {mainFeatures.map((feature, index) => (
              <div 
                key={index}
                className={`grid md:grid-cols-2 gap-12 items-center ${
                  index % 2 === 1 ? 'md:grid-flow-dense' : ''
                }`}
              >
                <div className={index % 2 === 1 ? 'md:col-start-2' : ''}>
                  <div className="flex items-center space-x-2 mb-6">
                    <div className="p-2 bg-dark-200 rounded-lg">
                      <feature.icon className="h-6 w-6 text-gold-400" />
                    </div>
                    <h2 className="text-3xl font-bold text-white">{feature.title}</h2>
                  </div>
                  <p className="text-gray-400 mb-8">{feature.description}</p>
                  <ul className="space-y-4">
                    {feature.benefits.map((benefit, idx) => (
                      <li key={idx} className="flex items-center text-white">
                        <Star className="h-5 w-5 text-gold-400 mr-3" />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="relative h-80 rounded-2xl overflow-hidden">
                  <img 
                    src={feature.image}
                    alt={feature.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* AI Features */}
      <section className="py-24 bg-dark-200">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">AI-Powered Excellence</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Leverage cutting-edge artificial intelligence to enhance your performance and achieve your goals.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {aiFeatures.map((feature, index) => (
              <div key={index} className="bg-dark-300 rounded-2xl p-8 border border-dark-400 hover:border-gold-400 transition-all duration-300">
                <div className="bg-dark-400 p-3 rounded-xl w-fit mb-6">
                  <feature.icon className="h-6 w-6 text-gold-400" />
                </div>
                <h3 className="text-xl font-bold text-white mb-4">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section className="py-24 bg-dark-100">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">How It Works</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Get started with Playclub.ai in three simple steps.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                step: 1,
                title: "Create Your Profile",
                description: "Set up your athletic profile with your preferences and expertise.",
                icon: Users
              },
              {
                step: 2,
                title: "Connect & Book",
                description: "Find partners, book facilities, or schedule training sessions.",
                icon: Calendar
              },
              {
                step: 3,
                title: "Play & Improve",
                description: "Enjoy your activities and track your progress with AI insights.",
                icon: Activity
              }
            ].map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-dark-200 rounded-2xl p-8 border border-dark-300">
                  <div className="absolute -top-4 -right-4 w-12 h-12 bg-gold-400 rounded-full flex items-center justify-center text-dark-100 font-bold">
                    {step.step}
                  </div>
                  <div className="bg-dark-300 p-3 rounded-xl w-fit mb-6">
                    <step.icon className="h-6 w-6 text-gold-400" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-4">{step.title}</h3>
                  <p className="text-gray-400">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-24 bg-dark-200">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">Member Stories</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Hear from our elite community of athletes and professionals.
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-dark-300 rounded-2xl p-8 border border-dark-400">
                <div className="flex items-center space-x-4 mb-6">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <div>
                    <h3 className="text-white font-bold">{testimonial.name}</h3>
                    <p className="text-gray-400">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-white italic">"{testimonial.quote}"</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-dark-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-8">
            Ready to Join the Elite?
          </h2>
          <Button
            className="bg-gradient-to-r from-gold-400 to-gold-600 text-dark-50 px-8 py-6 text-lg rounded-xl hover:from-gold-500 hover:to-gold-700"
          >
            Request Access
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Features;