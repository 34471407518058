import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import { Camera, Upload, Linkedin, User, Briefcase, MapPin, Phone } from 'lucide-react';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

interface ProfileStepProps {
  data: {
    fullName: string;
    email: string;
    company: string;
    jobTitle: string;
    dateOfBirth: string;
    gender: string;
    city: string;
    nationality: string;
    phone: string;
    profilePic?: string;
  };
  onUpdate: (data: Partial<ProfileStepProps['data']>) => void;
  onNext: () => void;
  onBack: () => void;
}

const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'rather_not_say', label: 'Rather not say' }
];

export default function ProfileStep({ data, onUpdate, onNext, onBack }: ProfileStepProps) {
  const [previewUrl, setPreviewUrl] = useState<string>(data.profilePic || '');
  const [isImporting, setIsImporting] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      onUpdate({ profilePic: url });
    }
  };

  const handleLinkedInSuccess = (response: any) => {
    setIsImporting(true);
    // Handle LinkedIn data import
    console.log('LinkedIn response:', response);
    setIsImporting(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onNext();
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-dark-200 rounded-2xl p-8 border border-dark-300"
    >
      <h2 className="text-2xl font-bold text-white mb-2">Create Your Profile</h2>
      <p className="text-dark-800 mb-6">Tell us about yourself to get started</p>
      
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Profile Picture */}
        <div className="flex flex-col items-center">
          <div className="relative">
            <div className={`w-32 h-32 rounded-full overflow-hidden border-4 border-dark-400 ${
              previewUrl ? '' : 'bg-dark-300'
            }`}>
              {previewUrl ? (
                <img
                  src={previewUrl}
                  alt="Profile preview"
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <Camera className="h-8 w-8 text-dark-800" />
                </div>
              )}
            </div>
            <label className="absolute bottom-0 right-0 p-2 bg-gold-400 rounded-full cursor-pointer hover:bg-gold-500 transition-colors">
              <Upload className="h-4 w-4 text-dark-50" />
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleFileChange}
              />
            </label>
          </div>
          <p className="text-dark-800 text-sm mt-2">Upload a profile picture</p>
        </div>

        {/* Personal Information */}
        <div className="bg-dark-300 rounded-xl p-6">
          <div className="flex items-center justify-between mb-4 flex-wrap gap-4">
            <div className="flex items-center space-x-2">
              <User className="h-5 w-5 text-gold-400" />
              <h3 className="text-lg font-semibold text-white">Personal Information</h3>
            </div>
            <LinkedIn
              clientId="your_linkedin_client_id"
              redirectUri={`${window.location.origin}/linkedin`}
              onSuccess={handleLinkedInSuccess}
              onError={(error) => {
                console.error('LinkedIn login failed:', error);
              }}
            >
              {({ linkedInLogin }) => (
                <Button
                  type="button"
                  onClick={linkedInLogin}
                  disabled={isImporting}
                  className="bg-[#0077B5] hover:bg-[#006097] text-white flex items-center space-x-2 w-full sm:w-auto"
                >
                  <Linkedin className="h-5 w-5" />
                  <span>{isImporting ? 'Importing...' : 'Import from LinkedIn'}</span>
                </Button>
              )}
            </LinkedIn>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="fullName" className="text-dark-800">Full Name *</Label>
              <Input
                id="fullName"
                value={data.fullName}
                onChange={(e) => onUpdate({ fullName: e.target.value })}
                className="bg-dark-400 border-dark-500 text-white mt-1"
                required
              />
            </div>

            <div>
              <Label htmlFor="email" className="text-dark-800">Email *</Label>
              <Input
                id="email"
                type="email"
                value={data.email}
                onChange={(e) => onUpdate({ email: e.target.value })}
                className="bg-dark-400 border-dark-500 text-white mt-1"
                required
              />
            </div>

            <div>
              <Label htmlFor="dateOfBirth" className="text-dark-800">Date of Birth *</Label>
              <Input
                id="dateOfBirth"
                type="date"
                value={data.dateOfBirth}
                onChange={(e) => onUpdate({ dateOfBirth: e.target.value })}
                className="bg-dark-400 border-dark-500 text-white mt-1"
                max={maxDate.toISOString().split('T')[0]}
                min={minDate.toISOString().split('T')[0]}
                required
              />
            </div>

            <div>
              <Label htmlFor="gender" className="text-dark-800">Gender *</Label>
              <Select
                value={data.gender}
                onValueChange={(value) => onUpdate({ gender: value })}
              >
                <SelectTrigger className="bg-dark-400 border-dark-500 text-white mt-1">
                  <SelectValue placeholder="Select gender" />
                </SelectTrigger>
                <SelectContent>
                  {genders.map((gender) => (
                    <SelectItem key={gender.value} value={gender.value}>
                      {gender.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        {/* Professional Information */}
        <div className="bg-dark-300 rounded-xl p-6">
          <div className="flex items-center space-x-2 mb-4">
            <Briefcase className="h-5 w-5 text-gold-400" />
            <h3 className="text-lg font-semibold text-white">Professional Information</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="company" className="text-dark-800">Company *</Label>
              <Input
                id="company"
                value={data.company}
                onChange={(e) => onUpdate({ company: e.target.value })}
                className="bg-dark-400 border-dark-500 text-white mt-1"
                required
              />
            </div>

            <div>
              <Label htmlFor="jobTitle" className="text-dark-800">Job Title *</Label>
              <Input
                id="jobTitle"
                value={data.jobTitle}
                onChange={(e) => onUpdate({ jobTitle: e.target.value })}
                className="bg-dark-400 border-dark-500 text-white mt-1"
                required
              />
            </div>
          </div>
        </div>

        {/* Location Information */}
        <div className="bg-dark-300 rounded-xl p-6">
          <div className="flex items-center space-x-2 mb-4">
            <MapPin className="h-5 w-5 text-gold-400" />
            <h3 className="text-lg font-semibold text-white">Location</h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="nationality" className="text-dark-800">Nationality *</Label>
              <Input
                id="nationality"
                value={data.nationality}
                onChange={(e) => onUpdate({ nationality: e.target.value })}
                className="bg-dark-400 border-dark-500 text-white mt-1"
                placeholder="Enter your nationality"
                required
              />
            </div>

            <div>
              <Label htmlFor="city" className="text-dark-800">City *</Label>
              <Input
                id="city"
                value={data.city}
                onChange={(e) => onUpdate({ city: e.target.value })}
                className="bg-dark-400 border-dark-500 text-white mt-1"
                placeholder="Enter your city"
                required
              />
            </div>
          </div>
        </div>

        {/* Contact Information */}
        <div className="bg-dark-300 rounded-xl p-6">
          <div className="flex items-center space-x-2 mb-4">
            <Phone className="h-5 w-5 text-gold-400" />
            <h3 className="text-lg font-semibold text-white">Contact</h3>
          </div>

          <Label htmlFor="phone" className="text-dark-800">Phone Number *</Label>
          <div className="mt-1">
            <PhoneInput
              international
              defaultCountry="MC"
              value={data.phone}
              onChange={(value) => onUpdate({ phone: value || '' })}
              className="bg-dark-400 border border-dark-500 rounded-xl px-4 py-2 text-white w-full"
              required
            />
          </div>
          <p className="text-dark-800 text-sm mt-1">
            You'll receive a verification code via SMS
          </p>
        </div>

        <div className="flex justify-between pt-6">
          <Button
            type="button"
            onClick={onBack}
            className="bg-dark-300 hover:bg-dark-400 text-white"
          >
            Back
          </Button>
          <Button
            type="submit"
            className="bg-gold-400 hover:bg-gold-500 text-dark-50"
          >
            Continue
          </Button>
        </div>
      </form>
    </motion.div>
  );
}