import React, { useState } from 'react';
import { Send, Smile } from 'lucide-react';
import { motion } from 'framer-motion';
import CommentList from './CommentList';
import { Comment } from '../../types/comment';

interface CommentSectionProps {
  postId: string;
  comments: Comment[];
  onAddComment: (postId: string, content: string) => Promise<void>;
  onToggleComments: () => void;
  isExpanded: boolean;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  postId,
  comments,
  onAddComment,
  isExpanded
}) => {
  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.trim() || isSubmitting) return;

    setIsSubmitting(true);
    try {
      await onAddComment(postId, newComment.trim());
      setNewComment('');
    } catch (error) {
      console.error('Failed to add comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="border-t border-dark-300"
    >
      <div className="p-4 space-y-4">
        <CommentList comments={comments} />
        
        <form onSubmit={handleSubmit} className="relative flex items-center">
          <div className="absolute left-3 top-1/2 -translate-y-1/2">
            <button
              type="button"
              className="text-dark-800 hover:text-gold-400 transition-colors p-1"
            >
              <Smile className="h-5 w-5" />
            </button>
          </div>
          
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Write a comment..."
            className="flex-1 bg-dark-300 border border-dark-400 rounded-full pl-12 pr-12 py-2.5 text-white placeholder-dark-800"
          />
          
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <button
              type="submit"
              disabled={!newComment.trim() || isSubmitting}
              className="text-gold-400 hover:text-gold-500 disabled:text-dark-800 transition-colors p-1"
            >
              <Send className="h-5 w-5" />
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default CommentSection;