import { Conversation, Message } from '../types/chat';

export const conversations: Conversation[] = [
  {
    id: "1",
    name: "Sarah Connor",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80",
    location: "Monte Carlo Tennis Club",
    lastMessage: "Looking forward to our match tomorrow!",
    time: "2m ago",
    unread: 2,
    online: true
  },
  {
    id: "2",
    name: "James Wilson",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80",
    location: "Royal Golf Club",
    lastMessage: "The court is booked for 3 PM",
    time: "1h ago",
    unread: 0,
    online: true
  },
  {
    id: "3",
    name: "Emma Rodriguez",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=300&q=80",
    location: "Barcelona Tennis Academy",
    lastMessage: "Great session today! Let's practice again next week",
    time: "3h ago",
    unread: 1,
    online: false
  },
  {
    id: "4",
    name: "Michael Chen",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80",
    location: "Singapore Sports Hub",
    lastMessage: "The tournament starts at 9 AM sharp",
    time: "5h ago",
    unread: 0,
    online: true
  },
  {
    id: "5",
    name: "Sofia Martinez",
    image: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80",
    location: "Madrid Padel Club",
    lastMessage: "Should we invite Alex to join our doubles match?",
    time: "1d ago",
    unread: 0,
    online: false
  },
  {
    id: "6",
    name: "Thomas Weber",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80",
    location: "Geneva Tennis Club",
    lastMessage: "Perfect conditions for our cycling session tomorrow",
    time: "2h ago",
    unread: 3,
    online: true
  },
  {
    id: "7",
    name: "Anna Lee",
    image: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=300&q=80",
    location: "Seoul Sports Complex",
    lastMessage: "Thanks for the table tennis tips!",
    time: "4h ago",
    unread: 0,
    online: false
  },
  {
    id: "8",
    name: "David Kim",
    image: "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?auto=format&fit=crop&w=300&q=80",
    location: "Tokyo Fitness Center",
    lastMessage: "Your form has improved significantly",
    time: "6h ago",
    unread: 1,
    online: true
  },
  {
    id: "9",
    name: "Maria Silva",
    image: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?auto=format&fit=crop&w=300&q=80",
    location: "Rio Tennis Club",
    lastMessage: "Let's review the match video tomorrow",
    time: "1d ago",
    unread: 0,
    online: false
  },
  {
    id: "10",
    name: "Alexander Petrov",
    image: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80",
    location: "Moscow Golf Resort",
    lastMessage: "Great round today! Your putting was excellent",
    time: "2d ago",
    unread: 0,
    online: true
  },
  {
    id: "11",
    name: "Isabella Romano",
    image: "https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&fit=crop&w=300&q=80",
    location: "Milan Swimming Center",
    lastMessage: "Swimming technique analysis ready for review",
    time: "3h ago",
    unread: 2,
    online: true
  },
  {
    id: "12",
    name: "Lucas Schmidt",
    image: "https://images.unsplash.com/photo-1463453091185-61582044d556?auto=format&fit=crop&w=300&q=80",
    location: "Munich Sports Academy",
    lastMessage: "Yoga session confirmed for tomorrow morning",
    time: "5h ago",
    unread: 0,
    online: false
  },
  {
    id: "13",
    name: "Sophie Laurent",
    image: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?auto=format&fit=crop&w=300&q=80",
    location: "Paris Tennis Club",
    lastMessage: "Tournament schedule updated. Check your matches",
    time: "1d ago",
    unread: 4,
    online: true
  },
  {
    id: "14",
    name: "Henrik Nielsen",
    image: "https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?auto=format&fit=crop&w=300&q=80",
    location: "Copenhagen Padel Center",
    lastMessage: "Ready for our training session?",
    time: "30m ago",
    unread: 1,
    online: true
  },
  {
    id: "15",
    name: "Victoria Chang",
    image: "https://images.unsplash.com/photo-1534751516642-a1af1ef26a56?auto=format&fit=crop&w=300&q=80",
    location: "Hong Kong Sports Club",
    lastMessage: "Your serve is getting much more consistent",
    time: "4h ago",
    unread: 0,
    online: false
  }
];

export const messages: Record<string, Message[]> = {
  "1": [
    {
      id: "1",
      content: "Hey Sarah! Are we still on for tennis tomorrow at Monte Carlo Club?",
      time: "10:30 AM",
      isSender: true,
      status: 'read'
    },
    {
      id: "2",
      content: "Yes, definitely! I've been practicing my backhand all week 🎾",
      time: "10:32 AM",
      isSender: false
    },
    {
      id: "3",
      content: "Perfect! I'll bring my new Wilson racket to try it out",
      time: "10:33 AM",
      isSender: true,
      status: 'read'
    },
    {
      id: "4",
      content: "Great! Have you seen the weather forecast? It's going to be perfect",
      time: "10:35 AM",
      isSender: false
    }
  ],
  "2": [
    {
      id: "1",
      content: "The weather looks perfect for golf today",
      time: "9:15 AM",
      isSender: false
    },
    {
      id: "2",
      content: "Indeed! I've booked us a tee time for 3 PM",
      time: "9:20 AM",
      isSender: true,
      status: 'read'
    },
    {
      id: "3",
      content: "Should we invite Thomas from Goldman Sachs to join us?",
      time: "9:22 AM",
      isSender: false
    },
    {
      id: "4",
      content: "Great idea! He mentioned wanting to discuss the new venture",
      time: "9:25 AM",
      isSender: true,
      status: 'read'
    }
  ],
  "3": [
    {
      id: "1",
      content: "Your backhand technique has improved significantly!",
      time: "2:30 PM",
      isSender: false
    },
    {
      id: "2",
      content: "Thanks Emma! Those training sessions really helped",
      time: "2:35 PM",
      isSender: true,
      status: 'read'
    },
    {
      id: "3",
      content: "Want to practice again next week? Same time?",
      time: "2:40 PM",
      isSender: false
    }
  ],
  "4": [
    {
      id: "1",
      content: "Important update about tomorrow's tournament",
      time: "8:00 AM",
      isSender: false
    },
    {
      id: "2",
      content: "What's the update?",
      time: "8:05 AM",
      isSender: true,
      status: 'read'
    },
    {
      id: "3",
      content: "They've moved the start time to 9 AM due to weather forecast",
      time: "8:07 AM",
      isSender: false
    }
  ],
  "5": [
    {
      id: "1",
      content: "Hey Sofia! Are you up for padel this weekend?",
      time: "3:15 PM",
      isSender: true,
      status: 'read'
    },
    {
      id: "2",
      content: "Absolutely! I've been wanting to try the new courts",
      time: "3:20 PM",
      isSender: false
    },
    {
      id: "3",
      content: "Great! I was thinking Saturday morning?",
      time: "3:22 PM",
      isSender: true,
      status: 'read'
    }
  ],
  "6": [
    {
      id: "1",
      content: "Ready for our cycling session tomorrow?",
      time: "4:00 PM",
      isSender: false
    },
    {
      id: "2",
      content: "Absolutely! What route are we taking?",
      time: "4:05 PM",
      isSender: true,
      status: 'read'
    },
    {
      id: "3",
      content: "I was thinking the lake circuit - about 40km",
      time: "4:07 PM",
      isSender: false
    }
  ],
  "7": [
    {
      id: "1",
      content: "Your serve technique is really improving!",
      time: "11:00 AM",
      isSender: false
    },
    {
      id: "2",
      content: "Thanks! Been practicing those drills you showed me",
      time: "11:05 AM",
      isSender: true,
      status: 'read'
    }
  ],
  "8": [
    {
      id: "1",
      content: "Here's your personalized training plan for next week",
      time: "3:00 PM",
      isSender: false,
      image: "https://images.unsplash.com/photo-1571019614242-c5c5dee9f50b?auto=format&fit=crop&w=800&q=80"
    },
    {
      id: "2",
      content: "This looks great! Thanks for customizing it",
      time: "3:05 PM",
      isSender: true,
      status: 'read'
    }
  ],
  "9": [
    {
      id: "1",
      content: "Check out this rally from yesterday's match",
      time: "5:00 PM",
      isSender: false,
      image: "https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=800&q=80"
    },
    {
      id: "2",
      content: "Impressive footwork! Let's analyze it tomorrow",
      time: "5:05 PM",
      isSender: true,
      status: 'read'
    }
  ],
  "10": [
    {
      id: "1",
      content: "Your putting technique has improved significantly",
      time: "2:00 PM",
      isSender: false
    },
    {
      id: "2",
      content: "Thanks! Those alignment drills really helped",
      time: "2:05 PM",
      isSender: true,
      status: 'read'
    }
  ],
  "11": [
    {
      id: "1",
      content: "Here's your swimming analysis video",
      time: "10:00 AM",
      isSender: false,
      image: "https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=800&q=80"
    },
    {
      id: "2",
      content: "Perfect! I'll review it before tomorrow's session",
      time: "10:05 AM",
      isSender: true,
      status: 'read'
    }
  ],
  "12": [
    {
      id: "1",
      content: "Remember to focus on breathing during tomorrow's session",
      time: "7:00 PM",
      isSender: false
    },
    {
      id: "2",
      content: "Will do! The mindfulness exercises are helping",
      time: "7:05 PM",
      isSender: true,
      status: 'read'
    }
  ],
  "13": [
    {
      id: "1",
      content: "Tournament draw is out! You're in Group A",
      time: "1:00 PM",
      isSender: false
    },
    {
      id: "2",
      content: "Great! Who's my first opponent?",
      time: "1:05 PM",
      isSender: true,
      status: 'read'
    }
  ],
  "14": [
    {
      id: "1",
      content: "Courts are ready for our padel session",
      time: "11:30 AM",
      isSender: false
    },
    {
      id: "2",
      content: "Perfect! I'll be there in 15 minutes",
      time: "11:32 AM",
      isSender: true,
      status: 'read'
    }
  ],
  "15": [
    {
      id: "1",
      content: "Your serve consistency has improved by 25%",
      time: "4:30 PM",
      isSender: false
    },
    {
      id: "2",
      content: "That's great news! The practice is paying off",
      time: "4:35 PM",
      isSender: true,
      status: 'read'
    }
  ]
};