import React from 'react';
import { Target, Clock, Calendar, ChevronRight, Star, Users, Activity, Trophy, MapPin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import LocationActions from '../facilities/LocationActions';

const TrainingRecommendations: React.FC = () => {
  const navigate = useNavigate();

  // Training stats data
  const trainingStats = {
    hoursCompleted: 156,
    trainersBooked: 8,
    sessionsCompleted: 42,
    nextSession: {
      id: 'next-1',
      trainer: {
        id: '1', // ID matching Rafael Torres from trainers array
        name: 'Rafael Torres',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
      },
      sport: '🎾 Tennis',
      date: '2024-03-16',
      time: '10:00',
      venue: {
        name: 'Monte Carlo Tennis Club',
        city: 'Monte Carlo',
        coordinates: {
          lat: 43.7384,
          lng: 7.4246
        }
      },
      status: 'confirmed',
      type: 'Advanced Training',
      focus: 'Serve Technique & Power'
    }
  };

  const recommendations = [
    {
      id: '1',
      title: 'Serve Power Enhancement',
      type: 'Technique',
      duration: '45 mins',
      difficulty: 'Advanced',
      coach: {
        id: '1',
        name: 'Rafael Torres',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
      },
      rating: 4.9,
      description: 'Focus on improving serve power through targeted exercises',
      exercises: [
        'Jump serve practice',
        'Core rotation drills',
        'Service motion analysis'
      ]
    },
    {
      id: '2',
      title: 'Backhand Consistency',
      type: 'Drill',
      duration: '30 mins',
      difficulty: 'Intermediate',
      coach: {
        id: '51',
        name: 'Sarah Connor',
        image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
      },
      rating: 4.8,
      description: 'Develop a more reliable backhand through repetition',
      exercises: [
        'Cross-court consistency',
        'Down-the-line practice',
        'Return positioning'
      ]
    }
  ];

  const handleTrainerClick = (trainerId: string) => {
    // Navigate to the trainer's profile page
    navigate(`/dashboard/members/trainer-${trainerId}`);
  };

  return (
    <div className="space-y-6">
      {/* Training Stats */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="flex items-center justify-between mb-2">
            <Clock className="h-5 w-5 text-gold-400" />
            <span className="text-2xl font-bold text-white">{trainingStats.hoursCompleted}h</span>
          </div>
          <div className="text-dark-800 text-sm">Hours Trained</div>
        </div>
        
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="flex items-center justify-between mb-2">
            <Users className="h-5 w-5 text-gold-400" />
            <span className="text-2xl font-bold text-white">{trainingStats.trainersBooked}</span>
          </div>
          <div className="text-dark-800 text-sm">Trainers Booked</div>
        </div>
        
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="flex items-center justify-between mb-2">
            <Activity className="h-5 w-5 text-gold-400" />
            <span className="text-2xl font-bold text-white">{trainingStats.sessionsCompleted}</span>
          </div>
          <div className="text-dark-800 text-sm">Sessions Completed</div>
        </div>

        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="flex items-center justify-between mb-2">
            <Trophy className="h-5 w-5 text-gold-400" />
            <span className="text-2xl font-bold text-white">92%</span>
          </div>
          <div className="text-dark-800 text-sm">Success Rate</div>
        </div>
      </div>

      {/* Next Session - Styled like Activities */}
      {trainingStats.nextSession && (
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => handleTrainerClick('1')}
                className="relative group"
              >
                <img
                  src={trainingStats.nextSession.trainer.image}
                  alt={trainingStats.nextSession.trainer.name}
                  className="w-12 h-12 rounded-xl object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                />
                <div className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-dark-200 border border-dark-300 flex items-center justify-center">
                  <span className="text-xs">{trainingStats.nextSession.sport.split(' ')[0]}</span>
                </div>
              </button>
              <div>
                <button
                  onClick={() => handleTrainerClick('1')}
                  className="text-white font-medium hover:text-gold-400 transition-colors"
                >
                  {trainingStats.nextSession.trainer.name}
                </button>
                <div className="flex items-center text-dark-800 text-sm">
                  <MapPin className="h-4 w-4 mr-1" />
                  {trainingStats.nextSession.venue.name}
                </div>
              </div>
            </div>
            <span className="text-blue-400 text-sm">
              Next Session
            </span>
          </div>

          <div className="mt-4">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="flex items-center text-dark-800 text-sm">
                <Calendar className="h-4 w-4 mr-2" />
                {new Date(trainingStats.nextSession.date).toLocaleDateString()}
              </div>
              <div className="flex items-center text-dark-800 text-sm">
                <Clock className="h-4 w-4 mr-2" />
                {trainingStats.nextSession.time}
              </div>
              <div className="flex items-center text-dark-800 text-sm">
                <Target className="h-4 w-4 mr-2" />
                {trainingStats.nextSession.type}
              </div>
              <div className="flex items-center text-dark-800 text-sm">
                <Activity className="h-4 w-4 mr-2" />
                {trainingStats.nextSession.focus}
              </div>
            </div>

            <LocationActions
              venueName={trainingStats.nextSession.venue.name}
              address={`${trainingStats.nextSession.venue.name}, ${trainingStats.nextSession.venue.city}`}
              coordinates={trainingStats.nextSession.venue.coordinates}
            />
          </div>
        </div>
      )}

      {/* Recommendations */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {recommendations.map((rec) => (
          <div
            key={rec.id}
            className="bg-dark-200 rounded-2xl p-6 border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="flex items-start justify-between mb-6">
              <div>
                <h3 className="text-xl font-semibold text-white">{rec.title}</h3>
                <div className="flex items-center space-x-4 mt-2">
                  <span className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white">
                    {rec.type}
                  </span>
                  <div className="flex items-center text-dark-800">
                    <Clock className="h-4 w-4 mr-1" />
                    {rec.duration}
                  </div>
                  <div className="flex items-center text-dark-800">
                    <Target className="h-4 w-4 mr-1" />
                    {rec.difficulty}
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-1">
                <Star className="h-4 w-4 text-gold-400" />
                <span className="text-white">{rec.rating}</span>
              </div>
            </div>

            <p className="text-dark-800 mb-4">{rec.description}</p>

            <div className="space-y-3 mb-6">
              {rec.exercises.map((exercise, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-3 bg-dark-300 rounded-xl p-3"
                >
                  <div className="bg-dark-400 w-8 h-8 rounded-lg flex items-center justify-center text-gold-400">
                    {index + 1}
                  </div>
                  <span className="text-white">{exercise}</span>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-between pt-4 border-t border-dark-300">
              <div className="flex items-center space-x-3">
                <button
                  onClick={() => handleTrainerClick(rec.coach.id)}
                  className="relative group"
                >
                  <img
                    src={rec.coach.image}
                    alt={rec.coach.name}
                    className="w-10 h-10 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                  />
                </button>
                <div>
                  <button
                    onClick={() => handleTrainerClick(rec.coach.id)}
                    className="text-white hover:text-gold-400 transition-colors font-medium"
                  >
                    {rec.coach.name}
                  </button>
                  <div className="text-dark-800 text-sm">Recommended Coach</div>
                </div>
              </div>
              <Button className="bg-gold-400 hover:bg-gold-500 text-dark-50">
                Start Training
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingRecommendations;