import React from 'react';
import TrainerSearch from './TrainerSearch';

const TrainingDashboard: React.FC = () => {
  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
        <div className="flex items-start justify-between">
          <div>
            <h1 className="text-2xl font-bold text-white">Trainers</h1>
            <p className="text-dark-800 mt-1">Find and book sessions with elite trainers</p>
          </div>
        </div>
      </div>

      {/* Trainer Search */}
      <div className="min-h-[400px]">
        <TrainerSearch />
      </div>
    </div>
  );
};

export default TrainingDashboard;