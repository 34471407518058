import React from 'react';
import { Calendar, Clock, CreditCard, X } from 'lucide-react';
import type { Facility } from '../../types/facility';
import LocationMap from './LocationMap';

interface FacilityBookingProps {
  facility: Facility | undefined;
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  onClose: () => void;
  isModal?: boolean;
}

const FacilityBooking: React.FC<FacilityBookingProps> = ({
  facility,
  selectedDate,
  onDateChange,
  onClose,
  isModal = false
}) => {
  if (!facility) return null;

  const defaultSlots = [
    { time: '09:00', available: true, price: '€150' },
    { time: '10:00', available: false, price: '€150' },
    { time: '11:00', available: true, price: '€150' },
    { time: '14:00', available: true, price: '€150' },
    { time: '15:00', available: true, price: '€150' },
    { time: '16:00', available: false, price: '€150' }
  ];

  const dateString = selectedDate.toISOString().split('T')[0];
  const availableSlots = defaultSlots;

  const BookingContent = () => (
    <>
      {/* Location Information */}
      <div className="mb-6">
        <LocationMap 
          address={facility.address}
          coordinates={facility.coordinates}
        />
      </div>

      <div className="mb-6">
        <label className="block text-dark-800 mb-2">Select Date</label>
        <input
          type="date"
          value={dateString}
          onChange={(e) => onDateChange(new Date(e.target.value))}
          className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
        />
      </div>

      <div className="mb-6">
        <label className="block text-dark-800 mb-2">Available Times</label>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
          {availableSlots.map((slot, index) => (
            <button
              key={index}
              disabled={!slot.available}
              className={`flex items-center justify-between px-4 py-2 rounded-lg ${
                slot.available
                  ? 'bg-dark-300 text-white hover:bg-dark-400'
                  : 'bg-dark-400 text-dark-800 cursor-not-allowed'
              }`}
            >
              <span>{slot.time}</span>
              <span>{slot.price}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="bg-dark-300 rounded-xl p-4 mb-6">
        <h4 className="text-white font-medium mb-4">Booking Summary</h4>
        <div className="space-y-2 text-sm">
          <div className="flex items-center justify-between text-dark-800">
            <span>Court Fee</span>
            <span className="text-white">€150</span>
          </div>
          <div className="flex items-center justify-between text-dark-800">
            <span>Equipment Rental</span>
            <span className="text-white">€30</span>
          </div>
          <div className="flex items-center justify-between text-dark-800">
            <span>Service Fee</span>
            <span className="text-white">€10</span>
          </div>
          <div className="pt-2 border-t border-dark-400">
            <div className="flex items-center justify-between">
              <span className="text-white font-medium">Total</span>
              <span className="text-gold-400 font-medium">€190</span>
            </div>
          </div>
        </div>
      </div>

      <button className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-3 rounded-xl transition-colors flex items-center justify-center space-x-2">
        <CreditCard className="h-5 w-5" />
        <span>Confirm Booking</span>
      </button>
    </>
  );

  if (isModal) {
    return (
      <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <div className="relative w-full max-w-lg bg-dark-200 rounded-2xl p-6 border border-dark-300">
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white"
              >
                <X className="h-5 w-5" />
              </button>
              
              <h2 className="text-xl font-bold text-white mb-6">Book {facility.name}</h2>
              <BookingContent />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-white">Book {facility.name}</h3>
        <button
          onClick={onClose}
          className="lg:hidden p-2 hover:bg-dark-300 rounded-lg transition-colors"
        >
          <X className="h-5 w-5 text-dark-800" />
        </button>
      </div>
      <BookingContent />
    </div>
  );
};

export default FacilityBooking;