import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Users, MapPin, MessageCircle, UserMinus, Filter, Activity, RefreshCw, X, Calendar, Clock } from 'lucide-react';
import { Input } from '../../ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import ActivityDetailsModal from "../modals/ActivityDetailsModal";
import ManageActivityModal from "../modals/ManageActivityModal";
import DeclineRequestModal from "../modals/DeclineRequestModal";
import RequestMatchModal from "../../matchmaking/RequestMatchModal";

interface ActivitiesTabProps {
  isOwnProfile: boolean;
  activities?: any[];
}

const ActivitiesTab: React.FC<ActivitiesTabProps> = ({ isOwnProfile }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [sortOrder, setSortOrder] = useState<'recent' | 'oldest'>('recent');
  const [selectedMatch, setSelectedMatch] = useState<any | null>(null);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showRematchModal, setShowRematchModal] = useState(false);

  const activities = [
    {
      id: '1',
      sport: '🎾 Tennis',
      opponent: {
        id: '51',
        name: 'Sarah Connor',
        image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-15',
      time: '14:00',
      venue: {
        name: 'Monte Carlo Tennis Club',
        city: 'Monte Carlo'
      },
      status: 'played'
    },
    {
      id: '2',
      sport: '⛳ Golf',
      opponent: {
        id: '52',
        name: 'James Wilson',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-20',
      time: '10:00',
      venue: {
        name: 'Royal Golf Club',
        city: 'Nice'
      },
      status: 'confirmed'
    },
    {
      id: '3',
      sport: '🎾 Tennis',
      opponent: {
        id: 'sg1',
        name: 'Michael Zhang',
        image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-22',
      time: '16:00',
      venue: {
        name: 'Singapore Tennis Club',
        city: 'Singapore'
      },
      status: 'pending',
      direction: 'received',
      isNew: true
    },
    {
      id: '4',
      sport: '🏊‍♂️ Swimming',
      opponent: {
        id: 'lon2',
        name: 'Victoria Palmer',
        image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-10',
      time: '09:00',
      venue: {
        name: 'London Aquatics Centre',
        city: 'London'
      },
      status: 'played'
    },
    {
      id: '5',
      sport: '🎾 Tennis',
      opponent: {
        id: 'par1',
        name: 'Antoine Dubois',
        image: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-25',
      time: '15:00',
      venue: {
        name: 'Roland Garros',
        city: 'Paris'
      },
      status: 'confirmed'
    },
    {
      id: '6',
      sport: '⛳ Golf',
      opponent: {
        id: 'dub1',
        name: 'Hassan Al-Rashid',
        image: 'https://images.unsplash.com/photo-1566492031773-4f4e44671857?auto=format&fit=crop&w=300&q=80'
      },
      date: '2024-03-18',
      time: '08:00',
      venue: {
        name: 'Emirates Golf Club',
        city: 'Dubai'
      },
      status: 'pending',
      direction: 'sent'
    }
  ];

  const filteredActivities = activities.filter(activity => {
    const matchesSearch = activity.opponent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         activity.venue.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         activity.venue.city.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesSport = selectedSport === 'all' || activity.sport === selectedSport;
    const matchesCity = selectedCity === 'all' || activity.venue.city === selectedCity;
    const matchesStatus = selectedStatus === 'all' || activity.status === selectedStatus;
    
    return matchesSearch && matchesSport && matchesCity && matchesStatus;
  }).sort((a, b) => {
    const dateA = new Date(`${a.date} ${a.time}`).getTime();
    const dateB = new Date(`${b.date} ${b.time}`).getTime();
    return sortOrder === 'recent' ? dateB - dateA : dateA - dateB;
  });

  const handleViewDetails = (match: any) => {
    setSelectedMatch(match);
  };

  const handleManageSession = (match: any) => {
    setSelectedMatch(match);
    setShowManageModal(true);
  };

  const handleRematch = (match: any) => {
    setSelectedMatch(match);
    setShowRematchModal(true);
  };

  const handleDeclineRequest = (match: any) => {
    setSelectedMatch(match);
    setShowDeclineModal(true);
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setSelectedSport('all');
    setSelectedCity('all');
    setSelectedStatus('all');
    setSortOrder('recent');
  };

  return (
    <div className="space-y-6">
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex flex-col space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <Input
              type="text"
              placeholder="Search activities..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 bg-dark-300 border-dark-400 text-white"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
            <Select value={selectedSport} onValueChange={setSelectedSport}>
              <SelectTrigger>
                <SelectValue placeholder="All Sports" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Sports</SelectItem>
                <SelectItem value="🎾 Tennis">Tennis</SelectItem>
                <SelectItem value="⛳ Golf">Golf</SelectItem>
                <SelectItem value="🏊‍♂️ Swimming">Swimming</SelectItem>
              </SelectContent>
            </Select>

            <Select value={selectedCity} onValueChange={setSelectedCity}>
              <SelectTrigger>
                <SelectValue placeholder="All Cities" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Cities</SelectItem>
                <SelectItem value="Monte Carlo">Monte Carlo</SelectItem>
                <SelectItem value="Nice">Nice</SelectItem>
                <SelectItem value="Paris">Paris</SelectItem>
              </SelectContent>
            </Select>

            <Select value={selectedStatus} onValueChange={setSelectedStatus}>
              <SelectTrigger>
                <SelectValue placeholder="All Statuses" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Statuses</SelectItem>
                <SelectItem value="played">Completed</SelectItem>
                <SelectItem value="confirmed">Confirmed</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
                <SelectItem value="cancelled">Cancelled</SelectItem>
              </SelectContent>
            </Select>

            <Select value={sortOrder} onValueChange={(value: 'recent' | 'oldest') => setSortOrder(value)}>
              <SelectTrigger>
                <SelectValue placeholder="Sort Order" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="recent">Most Recent</SelectItem>
                <SelectItem value="oldest">Oldest First</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {filteredActivities.map((activity) => (
          <div
            key={activity.id}
            className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => navigate(`/dashboard/members/${activity.opponent.id}`)}
                  className="relative group"
                >
                  <img
                    src={activity.opponent.image}
                    alt={activity.opponent.name}
                    className="w-16 h-16 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                  />
                  <div className="absolute -bottom-1 -right-1 w-8 h-8 rounded-full bg-dark-200 border border-dark-300 flex items-center justify-center text-xl">
                    {activity.sport.split(' ')[0]}
                  </div>
                  {isOwnProfile && activity.isNew && activity.direction === 'received' && (
                    <div className="absolute -top-1 -right-1 w-4 h-4 bg-gold-400 rounded-full border-2 border-dark-200" />
                  )}
                </button>
                <div className="text-left">
                  <button
                    onClick={() => navigate(`/dashboard/members/${activity.opponent.id}`)}
                    className="text-white font-medium hover:text-gold-400 transition-colors text-left"
                  >
                    {activity.sport.split(' ')[1]} with {activity.opponent.name}
                  </button>
                  <div className="flex items-center text-dark-800 text-sm">
                    <MapPin className="h-4 w-4 mr-1" />
                    {activity.venue.name}
                  </div>
                </div>
              </div>
              {isOwnProfile && (
                <div className="flex flex-col items-end">
                  <div className={`inline-flex items-center justify-center px-3 py-1 rounded-full text-sm ${
                    activity.status === 'played' ? 'bg-green-400/20 text-green-400' :
                    activity.status === 'confirmed' ? 'bg-blue-400/20 text-blue-400' :
                    activity.status === 'pending' ? 'bg-yellow-400/20 text-yellow-400' :
                    activity.status === 'disputed' ? 'bg-red-400/20 text-red-400' :
                    'bg-dark-400/20 text-dark-800'
                  }`}>
                    {activity.status === 'pending' && activity.direction
                      ? `${activity.direction === 'received' ? 'Incoming' : 'Outgoing'}`
                      : activity.status.charAt(0).toUpperCase() + activity.status.slice(1)}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-4">
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="flex items-center text-white">
                  <Calendar className="h-4 w-4 mr-2" />
                  {new Date(activity.date).toLocaleDateString()}
                </div>
                <div className="flex items-center text-white">
                  <Clock className="h-4 w-4 mr-2" />
                  {activity.time}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-4 pt-4 border-t border-dark-300 gap-3">
                <div className="flex items-center space-x-3">
                  <button
                    onClick={() => handleViewDetails(activity)}
                    className="text-white hover:text-gold-400"
                  >
                    View Details
                  </button>
                  {isOwnProfile && (
                    <>
                      {activity.status === 'played' && (
                        <button
                          onClick={() => handleRematch(activity)}
                          className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-4 py-2 rounded-lg transition-colors"
                        >
                          Rematch
                        </button>
                      )}
                      {activity.status === 'pending' && activity.direction === 'received' && (
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => handleManageSession(activity)}
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition-colors"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() => handleDeclineRequest(activity)}
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition-colors"
                          >
                            Decline
                          </button>
                        </div>
                      )}
                      {(activity.status === 'confirmed' || 
                        (activity.status === 'pending' && activity.direction === 'sent')) && (
                        <button
                          onClick={() => handleManageSession(activity)}
                          className="bg-dark-300 hover:bg-dark-400 text-white px-4 py-2 rounded-lg transition-colors"
                        >
                          Manage
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {filteredActivities.length === 0 && (
          <div className="text-center py-12 bg-dark-200 rounded-2xl border border-dark-300">
            <Activity className="h-12 w-12 text-dark-800 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-white mb-2">No Activities Found</h3>
            <p className="text-dark-800 mb-6">
              {isOwnProfile 
                ? "Try adjusting your filters or search criteria"
                : "No past activities to display"}
            </p>
            {isOwnProfile && (
              <button
                onClick={handleResetFilters}
                className="inline-flex items-center space-x-2 px-6 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
              >
                <RefreshCw className="h-4 w-4 mr-2" />
                Reset Filters
              </button>
            )}
          </div>
        )}

        {selectedMatch && !showDeclineModal && !showRematchModal && (
          <ActivityDetailsModal
            isOpen={true}
            onClose={() => setSelectedMatch(null)}
            activity={selectedMatch}
            isOwnProfile={isOwnProfile}
          />
        )}

        {selectedMatch && showManageModal && (
          <ManageActivityModal
            isOpen={true}
            onClose={() => {
              setShowManageModal(false);
              setSelectedMatch(null);
            }}
            activity={selectedMatch}
          />
        )}

        {selectedMatch && showDeclineModal && (
          <DeclineRequestModal
            isOpen={true}
            onClose={() => {
              setShowDeclineModal(false);
              setSelectedMatch(null);
            }}
            activity={selectedMatch}
          />
        )}

        {selectedMatch && showRematchModal && (
          <RequestMatchModal
            isOpen={true}
            onClose={() => {
              setShowRematchModal(false);
              setSelectedMatch(null);
            }}
            member={{
              id: selectedMatch.opponent.id,
              name: selectedMatch.opponent.name,
              city: selectedMatch.venue.city,
              sports: [selectedMatch.sport]
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ActivitiesTab;