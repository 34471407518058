import { useState, useEffect } from 'react';
import { Badge } from '../types/badge';
import { badgeCategories } from '../data/badges';

export const useBadges = (userId: string) => {
  const [userBadges, setUserBadges] = useState<Badge[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserBadges = async () => {
      try {
        // In a real app, fetch user's badge progress from API
        // For now, simulate some unlocked badges
        const simulatedBadges = badgeCategories.flatMap(category =>
          category.badges.map(badge => ({
            ...badge,
            progress: Math.floor(Math.random() * badge.requirement),
            unlocked: Math.random() > 0.7,
            unlockedAt: Math.random() > 0.7 ? new Date().toISOString() : undefined
          }))
        );

        setUserBadges(simulatedBadges);
      } catch (error) {
        console.error('Error fetching badges:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserBadges();
  }, [userId]);

  const getNextBadge = (category: string) => {
    const categoryBadges = userBadges.filter(badge => badge.category === category);
    return categoryBadges.find(badge => !badge.unlocked);
  };

  const getBadgeProgress = (badge: Badge) => {
    if (!badge.progress) return 0;
    return Math.min((badge.progress / badge.requirement) * 100, 100);
  };

  return {
    badges: userBadges,
    loading,
    getNextBadge,
    getBadgeProgress
  };
};