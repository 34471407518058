import React from 'react';
import { X, UserPlus, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { members } from '../../../data/members';

interface MutualConnectionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  connectionName: string;
  mutualCount: number;
}

const MutualConnectionsModal: React.FC<MutualConnectionsModalProps> = ({
  isOpen,
  onClose,
  connectionName,
  mutualCount
}) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  // Get random mutual connections from members
  const mutualConnections = members
    .filter(member => !member.isConnected)
    .slice(0, mutualCount)
    .map(member => ({
      ...member,
      isConnected: Math.random() > 0.5
    }));

  const handleConnect = (memberId: string) => {
    // Handle connect logic here
    console.log('Connecting with:', memberId);
  };

  const handleProfileClick = (memberId: string) => {
    navigate(`/dashboard/members/${memberId}`);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-lg sm:max-w-xl bg-dark-200 rounded-xl sm:rounded-2xl p-4 sm:p-6 border border-dark-300">
            <button
              onClick={onClose}
              className="absolute top-3 right-3 sm:top-4 sm:right-4 p-2 text-dark-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>

            <h2 className="text-lg sm:text-xl font-bold text-white mb-1 sm:mb-2 pr-8">
              Mutual Connections with {connectionName}
            </h2>
            <p className="text-dark-800 mb-4 sm:mb-6 text-sm sm:text-base">
              {mutualCount} shared connection{mutualCount !== 1 ? 's' : ''}
            </p>

            <div className="space-y-3 sm:space-y-4 max-h-[60vh] overflow-y-auto pr-2">
              {mutualConnections.map((connection) => (
                <div
                  key={connection.id}
                  className="bg-dark-300 rounded-lg sm:rounded-xl p-3 sm:p-4 flex items-start space-x-3 sm:space-x-4"
                >
                  <button
                    onClick={() => handleProfileClick(connection.id)}
                    className="flex-shrink-0"
                  >
                    <img
                      src={connection.image}
                      alt={connection.name}
                      className="w-10 h-10 sm:w-12 sm:h-12 rounded-lg sm:rounded-xl object-cover border-2 border-dark-400 hover:border-gold-400 transition-colors"
                    />
                  </button>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-start justify-between">
                      <div>
                        <button
                          onClick={() => handleProfileClick(connection.id)}
                          className="text-white font-medium hover:text-gold-400 transition-colors text-sm sm:text-base truncate"
                        >
                          {connection.name}
                        </button>
                        <div className="text-dark-800 text-xs sm:text-sm">
                          {connection.jobTitle}
                        </div>
                        <div className="text-dark-800 text-xs sm:text-sm">
                          {connection.company}
                        </div>
                      </div>
                      {connection.isConnected ? (
                        <>
                          {/* Mobile Icon Button */}
                          <button className="block md:hidden w-8 h-8 flex items-center justify-center bg-dark-400 text-white rounded-lg">
                            <Check className="h-4 w-4" />
                          </button>
                          {/* Desktop Button with Text */}
                          <button className="hidden md:flex items-center space-x-2 px-3 py-1.5 bg-dark-400 text-white rounded-lg text-sm">
                            <Check className="h-4 w-4" />
                            <span>Connected</span>
                          </button>
                        </>
                      ) : (
                        <>
                          {/* Mobile Icon Button */}
                          <button
                            onClick={() => handleConnect(connection.id)}
                            className="block md:hidden w-8 h-8 flex items-center justify-center bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors"
                          >
                            <UserPlus className="h-4 w-4" />
                          </button>
                          {/* Desktop Button with Text */}
                          <button
                            onClick={() => handleConnect(connection.id)}
                            className="hidden md:flex items-center space-x-2 px-3 py-1.5 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors text-sm"
                          >
                            <UserPlus className="h-4 w-4" />
                            <span>Connect</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MutualConnectionsModal;