import React, { useState } from 'react';
import { X, Shield, Send } from 'lucide-react';

interface JoinClubModalProps {
  isOpen: boolean;
  onClose: () => void;
  club: {
    id: string;
    name: string;
    exclusivity: string;
    rules?: string[];
  };
}

const JoinClubModal: React.FC<JoinClubModalProps> = ({ isOpen, onClose, club }) => {
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to submit request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showSuccess) {
    return (
      <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <div className="relative w-full max-w-md bg-dark-200 rounded-2xl p-6 border border-dark-300">
              <div className="text-center py-6">
                <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
                  <Send className="h-8 w-8 text-dark-50" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Request Sent!</h3>
                <p className="text-dark-800 mb-6">
                  Your request to join {club.name} has been sent. The club admins will review your application.
                </p>
                <button
                  onClick={onClose}
                  className="px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-lg bg-dark-200 rounded-2xl p-6 border border-dark-300">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            <h2 className="text-xl font-bold text-white mb-2">
              Join {club.name}
            </h2>
            <p className="text-dark-800 mb-6">
              This is a {club.exclusivity.toLowerCase()} club. Please review the rules and requirements before submitting your request.
            </p>

            {/* Club Rules */}
            {club.rules && club.rules.length > 0 && (
              <div className="bg-dark-300 rounded-xl p-4 mb-6">
                <h3 className="text-white font-medium mb-3 flex items-center">
                  <Shield className="h-5 w-5 text-gold-400 mr-2" />
                  Club Rules & Requirements
                </h3>
                <div className="space-y-2">
                  {club.rules.map((rule, index) => (
                    <div key={index} className="flex items-center text-dark-800">
                      <div className="w-1.5 h-1.5 bg-gold-400 rounded-full mr-2" />
                      {rule}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Application Message */}
            <div className="mb-6">
              <label className="block text-dark-800 mb-2">
                Why would you like to join this club?
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Tell us about yourself and why you'd like to join..."
                className="w-full h-32 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white placeholder-dark-800 resize-none"
                required
              />
            </div>

            {/* Actions */}
            <div className="flex justify-end space-x-4">
              <button
                onClick={onClose}
                className="px-6 py-2 border border-dark-400 text-white rounded-xl hover:bg-dark-300 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={isSubmitting || !message.trim()}
                className="px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
              >
                {isSubmitting ? (
                  <>
                    <div className="w-4 h-4 border-2 border-t-transparent border-dark-50 rounded-full animate-spin" />
                    <span>Sending...</span>
                  </>
                ) : (
                  <>
                    <Send className="h-5 w-5" />
                    <span>Submit Request</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinClubModal;