import React from 'react';
import { Activity, TrendingUp, Video } from 'lucide-react';
import MatchStatistics from './MatchStatistics';
import ProgressTracker from './ProgressTracker';
import VideoAnalysis from './VideoAnalysis';

const PerformanceTracker: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('statistics');
  const isPremiumMember = false; // This would normally come from your auth context or user data

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h2 className="text-xl font-bold text-white">Performance Analytics</h2>
        <p className="text-dark-800 mt-1">Track your progress and improve your game</p>

        {/* Tabs */}
        <div className="flex flex-wrap items-center gap-4 mt-6 border-t border-dark-300 pt-4">
          <button
            onClick={() => setActiveTab('statistics')}
            className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'statistics' ? 'bg-dark-300 text-gold-400' : 'text-dark-800 hover:text-white'
            }`}
          >
            <Activity className="h-5 w-5" />
            <span>Statistics</span>
          </button>
          <button
            onClick={() => setActiveTab('progress')}
            className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'progress' ? 'bg-dark-300 text-gold-400' : 'text-dark-800 hover:text-white'
            }`}
          >
            <TrendingUp className="h-5 w-5" />
            <span>Progress</span>
          </button>
          <button
            onClick={() => setActiveTab('video')}
            className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'video' ? 'bg-dark-300 text-gold-400' : 'text-dark-800 hover:text-white'
            }`}
          >
            <Video className="h-5 w-5" />
            <span>Video Analysis</span>
          </button>
        </div>
      </div>

      {/* Content */}
      <div>
        {activeTab === 'statistics' && <MatchStatistics />}
        {activeTab === 'progress' && <ProgressTracker />}
        {activeTab === 'video' && <VideoAnalysis isPremium={isPremiumMember} />}
      </div>
    </div>
  );
};

export default PerformanceTracker;