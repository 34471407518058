import React from 'react';
import { CreditCard, Plus } from 'lucide-react';
import { Button } from '../../ui/button';

const PaymentSettings: React.FC = () => {
  const paymentMethods = [
    {
      id: '1',
      type: 'visa',
      last4: '4242',
      expiry: '12/24',
      isDefault: true
    },
    {
      id: '2',
      type: 'mastercard',
      last4: '8888',
      expiry: '06/25',
      isDefault: false
    }
  ];

  return (
    <div className="space-y-6">
      {/* Payment Methods */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-white">Payment Methods</h2>
          <Button className="bg-gold-400 hover:bg-gold-500 text-dark-50">
            <Plus className="h-4 w-4 mr-2" />
            Add Payment Method
          </Button>
        </div>

        <div className="space-y-4">
          {paymentMethods.map((method) => (
            <div
              key={method.id}
              className="bg-dark-300 rounded-xl p-4 flex items-center justify-between"
            >
              <div className="flex items-center space-x-4">
                <div className="bg-dark-400 p-3 rounded-lg">
                  <CreditCard className="h-5 w-5 text-gold-400" />
                </div>
                <div>
                  <div className="text-white">
                    {method.type.charAt(0).toUpperCase() + method.type.slice(1)} ****{method.last4}
                  </div>
                  <div className="text-dark-800 text-sm">Expires {method.expiry}</div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                {method.isDefault && (
                  <span className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white">
                    Default
                  </span>
                )}
                <button className="text-dark-800 hover:text-white">Edit</button>
                <button className="text-dark-800 hover:text-red-400">Remove</button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Billing History */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <h2 className="text-xl font-semibold text-white mb-6">Billing History</h2>
        <div className="space-y-4">
          {[
            { date: '2024-02-01', amount: '$99.00', status: 'Paid' },
            { date: '2024-01-01', amount: '$99.00', status: 'Paid' }
          ].map((invoice, index) => (
            <div
              key={index}
              className="bg-dark-300 rounded-xl p-4 flex items-center justify-between"
            >
              <div>
                <div className="text-white">
                  {new Date(invoice.date).toLocaleDateString()}
                </div>
                <div className="text-dark-800 text-sm">{invoice.amount}</div>
              </div>
              <div className="flex items-center space-x-4">
                <span className="px-3 py-1 bg-green-400/20 text-green-400 rounded-full text-xs">
                  {invoice.status}
                </span>
                <button className="text-dark-800 hover:text-white">Download</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentSettings;