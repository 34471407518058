import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Users, MapPin, Star, Trophy, MessageCircle } from 'lucide-react';
import { members } from '../../data/members';
import type { Member } from '../../types/member';

interface MemberConnectionsProps {
  memberId: string;
  onClose: () => void;
  connections?: Member[];
}

const MemberConnections: React.FC<MemberConnectionsProps> = ({ memberId, onClose, connections }) => {
  const navigate = useNavigate();
  const member = members.find(m => m.id === memberId);

  // If no connections are provided, generate some from the members list
  const memberConnections = connections || members
    .filter(m => m.id !== memberId)
    .slice(0, member?.connections || 0)
    .map(m => ({
      ...m,
      connectionDate: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString()
    }));

  const handleProfileClick = (connectionId: string) => {
    onClose(); // Close the modal first
    navigate(`/dashboard/members/${connectionId}`); // Then navigate
  };

  if (!member) return null;

  return (
    <div className="fixed inset-0 z-50 bg-dark-50/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="bg-dark-200 rounded-2xl w-full max-w-3xl border border-dark-300">
            {/* Header */}
            <div className="p-6 border-b border-dark-300">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={onClose}
                    className="text-dark-800 hover:text-white transition-colors"
                  >
                    <ArrowLeft className="h-5 w-5" />
                  </button>
                  <div>
                    <h2 className="text-xl font-bold text-white">
                      {member.name}'s Connections
                    </h2>
                    <p className="text-dark-800">
                      <Users className="inline-block h-4 w-4 mr-1" />
                      {memberConnections.length} connections
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Connections List */}
            <div className="p-6 max-h-[70vh] overflow-y-auto">
              <div className="space-y-4">
                {memberConnections.map((connection) => (
                  <div
                    key={connection.id}
                    className="bg-dark-300 rounded-xl p-4 hover:bg-dark-400 transition-colors"
                  >
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={() => handleProfileClick(connection.id)}
                        className="focus:outline-none focus:ring-2 focus:ring-gold-400 rounded-full"
                      >
                        <img
                          src={connection.image}
                          alt={connection.name}
                          className="w-16 h-16 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-colors"
                        />
                      </button>
                      <div className="flex-1">
                        <div className="flex items-start justify-between">
                          <div>
                            <button
                              onClick={() => handleProfileClick(connection.id)}
                              className="text-white font-medium hover:text-gold-400 transition-colors text-left"
                            >
                              {connection.name}
                            </button>
                            <div className="flex items-center text-dark-800 mt-1">
                              <MapPin className="h-4 w-4 mr-1" />
                              {connection.city}
                            </div>
                            <div className="flex items-center text-dark-800 mt-1">
                              <Trophy className="h-4 w-4 mr-1" />
                              {connection.skillLevel}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <Star className="h-4 w-4 text-gold-400 mr-1" />
                            <span className="text-white">{connection.rating}</span>
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-3">
                          {connection.sports.map((sport, idx) => (
                            <span
                              key={idx}
                              className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white"
                            >
                              {sport}
                            </span>
                          ))}
                        </div>
                        <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-400">
                          <span className="text-gold-400 px-3 py-1 bg-dark-400/20 rounded-full text-sm">
                            Connected {new Date(connection.connectionDate || '').toLocaleDateString()}
                          </span>
                          <div className="flex items-center space-x-3">
                            <button 
                              onClick={() => handleProfileClick(connection.id)}
                              className="px-4 py-2 bg-dark-400 hover:bg-dark-500 text-white rounded-lg transition-colors"
                            >
                              View Profile
                            </button>
                            <button className="p-2 bg-dark-400 hover:bg-dark-500 rounded-lg transition-colors">
                              <MessageCircle className="h-5 w-5 text-white" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberConnections;