import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, MapPin, Star, Clock, Award, Globe, Users2, MessageCircle } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Button } from '../ui/button';
import { trainers } from '../../data/trainers';
import BookSessionModal from '../matchmaking/BookSessionModal';
import TrainerConnections from './TrainerConnections';
import SimplifiedTrainerReviewStats from '../reviews/SimplifiedTrainerReviewStats';
import TrainerReviewModal from '../reviews/TrainerReviewModal';

interface BookingTrainer {
  id: string;
  name: string;
  sports: string[];
  priceRange: string;
  location: string;
  rating: number;
  reviews: number;
}

const TrainerSearch: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [selectedLanguage, setSelectedLanguage] = useState('all');
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState<BookingTrainer | null>(null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showReviewStats, setShowReviewStats] = useState(false);
  const [selectedTrainerForReview, setSelectedTrainerForReview] = useState<BookingTrainer | null>(null);

  const filteredTrainers = useMemo(() => 
    trainers.filter(trainer => {
      const matchesSearch = searchTerm === '' || 
        trainer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        trainer.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        trainer.specialties.some(s => s.toLowerCase().includes(searchTerm.toLowerCase()));

      const matchesSport = selectedSport === 'all' || 
        trainer.sports.includes(selectedSport);

      const matchesCity = selectedCity === 'all' || 
        trainer.city === selectedCity;

      const matchesLanguage = selectedLanguage === 'all' || 
        trainer.languages.includes(selectedLanguage);

      return matchesSearch && matchesSport && matchesCity && matchesLanguage;
    }),
    [searchTerm, selectedSport, selectedCity, selectedLanguage]
  );

  const sports = useMemo(() => 
    ['all', ...new Set(trainers.flatMap(trainer => trainer.sports))],
    []
  );

  const cities = useMemo(() => 
    ['all', ...new Set(trainers.map(trainer => trainer.city))],
    []
  );

  const languages = useMemo(() => 
    ['all', ...new Set(trainers.flatMap(trainer => trainer.languages))],
    []
  );

  const handleBookSession = (trainer: BookingTrainer) => {
    setSelectedTrainer(trainer);
    setShowBookingModal(true);
  };

  const handleMessageClick = (trainerId: string, trainerName: string) => {
    const firstName = trainerName.split(' ')[0];
    navigate('/dashboard/messages', { 
      state: { 
        activeChat: `trainer-${trainerId}`,
        initialMessage: `Hi ${firstName}! I'm interested in training with you.`,
        showMobileList: false
      }
    });
  };

  const handleReviewClick = (trainer: BookingTrainer) => {
    setSelectedTrainerForReview(trainer);
    setShowReviewStats(true);
  };

  const handleSubmitReview = async (review: {
    rating: number;
    content: string;
    sport?: string;
    venue?: string;
  }) => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('Review submitted:', review);
    setShowReviewModal(false);
    setSelectedTrainerForReview(null);
  };

  return (
    <div className="space-y-6">
      {/* Search and Filters */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
              <input
                type="text"
                placeholder="Search trainers by name, location, or specialty..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-4">
            <select
              value={selectedSport}
              onChange={(e) => setSelectedSport(e.target.value)}
              className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white min-w-[150px]"
            >
              <option value="all">All Sports</option>
              {sports.filter(sport => sport !== 'all').map((sport) => (
                <option key={sport} value={sport}>{sport}</option>
              ))}
            </select>
            <select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white min-w-[150px]"
            >
              <option value="all">All Cities</option>
              {cities.filter(city => city !== 'all').map((city) => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white min-w-[150px]"
            >
              <option value="all">All Languages</option>
              {languages.filter(lang => lang !== 'all').map((lang) => (
                <option key={lang} value={lang}>{lang}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Results Count */}
      <div className="text-dark-800">
        Found {filteredTrainers.length} trainers
      </div>

      {/* Trainers Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {filteredTrainers.map((trainer) => (
          <div
            key={trainer.id}
            className="bg-dark-200 rounded-2xl p-6 border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="flex items-start space-x-4">
              <button
                onClick={() => navigate(`/dashboard/members/trainer-${trainer.id}`)}
                className="flex-shrink-0"
              >
                <img
                  src={trainer.image}
                  alt={trainer.name}
                  className="w-24 h-24 rounded-full object-cover border-2 border-gold-400 hover:border-gold-500 transition-colors"
                />
              </button>
              <div className="flex-1">
                <div className="flex items-start justify-between">
                  <div>
                    <button
                      onClick={() => navigate(`/dashboard/members/trainer-${trainer.id}`)}
                      className="text-white font-medium hover:text-gold-400 transition-colors"
                    >
                      {trainer.name}
                    </button>
                    <div className="flex items-center text-dark-800 mt-1">
                      <MapPin className="h-4 w-4 mr-1" />
                      {trainer.city}
                    </div>
                    <div className="flex items-center text-dark-800 mt-1">
                      <Award className="h-4 w-4 mr-1" />
                      {trainer.yearsExperience} years experience
                    </div>
                    <div className="flex items-center text-dark-800 mt-1">
                      <Globe className="h-4 w-4 mr-1" />
                      {trainer.languages.join(', ')}
                    </div>
                  </div>
                  <button
                    onClick={() => handleReviewClick({
                      id: trainer.id,
                      name: trainer.name,
                      sports: trainer.sports,
                      priceRange: trainer.priceRange,
                      location: trainer.city,
                      rating: trainer.rating,
                      reviews: trainer.reviews
                    })}
                    className="flex items-center hover:text-gold-400 transition-colors"
                  >
                    <Star className="h-4 w-4 text-gold-400 mr-1" />
                    <span className="text-white">{trainer.rating}</span>
                    <span className="text-dark-800 text-sm ml-1">({trainer.reviews})</span>
                  </button>
                </div>

                <div className="flex flex-wrap gap-2 mt-3">
                  {trainer.sports.map((sport, idx) => (
                    <div
                      key={idx}
                      className="flex items-center space-x-2 px-3 py-1.5 bg-dark-300 rounded-full hover:bg-dark-400 transition-colors cursor-default group"
                    >
                      <span className="text-sm text-dark-800 group-hover:text-white transition-colors">
                        {sport}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Bottom Section */}
            <div className="mt-6 pt-4 border-t border-dark-300">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <div className="flex items-center space-x-3 bg-dark-300/50 rounded-lg px-3 py-2">
                  <Award className="h-5 w-5 text-gold-400" />
                  <div>
                    <div className="text-sm text-dark-800">Session Price</div>
                    <div className="text-white font-medium">{trainer.priceRange}</div>
                  </div>
                </div>
                <div className="flex items-center space-x-3 bg-dark-300/50 rounded-lg px-3 py-2">
                  <Clock className="h-5 w-5 text-gold-400" />
                  <div>
                    <div className="text-sm text-dark-800">Next Available</div>
                    <div className="text-white font-medium">Tomorrow</div>
                  </div>
                </div>
              </div>

              <TrainerConnections trainerId={trainer.id} limit={5} />

              <div className="flex items-center gap-3 mt-4">
                <button
                  onClick={() => handleBookSession({
                    id: trainer.id,
                    name: trainer.name,
                    sports: trainer.sports,
                    priceRange: trainer.priceRange,
                    location: trainer.city,
                    rating: trainer.rating,
                    reviews: trainer.reviews
                  })}
                  className="flex-1 bg-gold-400 hover:bg-gold-500 text-dark-50 px-4 py-2.5 rounded-xl transition-colors flex items-center justify-center space-x-2 font-medium"
                >
                  <span>Book Session</span>
                </button>
                <button 
                  onClick={() => handleMessageClick(trainer.id, trainer.name)}
                  className="flex-none bg-dark-300 hover:bg-dark-400 text-white p-2.5 rounded-xl transition-colors"
                >
                  <MessageCircle className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Review Stats Modal */}
      {showReviewStats && selectedTrainerForReview && (
        <Dialog open={showReviewStats} onOpenChange={setShowReviewStats}>
          <DialogContent className="sm:max-w-md bg-white dark:bg-dark-200 border-gray-200 dark:border-dark-300">
            <DialogHeader>
              <DialogTitle className="text-gray-900 dark:text-white">
                Reviews for {selectedTrainerForReview.name}
              </DialogTitle>
            </DialogHeader>
            
            <SimplifiedTrainerReviewStats
              rating={selectedTrainerForReview.rating}
              totalReviews={selectedTrainerForReview.reviews}
              distribution={{
                5: Math.floor(selectedTrainerForReview.reviews * 0.6),
                4: Math.floor(selectedTrainerForReview.reviews * 0.25),
                3: Math.floor(selectedTrainerForReview.reviews * 0.1),
                2: Math.floor(selectedTrainerForReview.reviews * 0.03),
                1: Math.floor(selectedTrainerForReview.reviews * 0.02)
              }}
            />

            <div className="flex justify-end mt-6">
              <Button
                onClick={() => {
                  setShowReviewStats(false);
                  setShowReviewModal(true);
                }}
                className="bg-gold-400 hover:bg-gold-500 text-dark-50"
              >
                Write Review
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/* Review Modal */}
      {showReviewModal && selectedTrainerForReview && (
        <TrainerReviewModal
          isOpen={showReviewModal}
          onClose={() => {
            setShowReviewModal(false);
            setSelectedTrainerForReview(null);
          }}
          trainerId={selectedTrainerForReview.id}
          trainerName={selectedTrainerForReview.name}
          onSubmit={handleSubmitReview}
          sports={selectedTrainerForReview.sports}
        />
      )}

      {/* Booking Modal */}
      {showBookingModal && selectedTrainer && (
        <BookSessionModal
          trainer={selectedTrainer}
          isOpen={showBookingModal}
          onClose={() => {
            setShowBookingModal(false);
            setSelectedTrainer(null);
          }}
        />
      )}
    </div>
  );
};

export default TrainerSearch;