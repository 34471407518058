import React from 'react';

interface StatsProps {
  memberCount: number;
  facilityCount: number;
  cityCount: number;
  counterRef: React.RefObject<HTMLDivElement>;
}

export const Stats: React.FC<StatsProps> = ({
  memberCount,
  facilityCount,
  cityCount,
  counterRef,
}) => {
  const profilePictures = [
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/profile2-fGDqFgeuA9IALSzpfohI8DZuYyrZ4j.PNG",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/profile1-EqS1Mjf6FG2q5nnCKAcBTJvlb0shCE.PNG",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/profile3-pR6NWn8C2S6l7Y6atV0xDI5JnaNtuY.PNG",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/profile5-erqHKK5gD3Vk2vcQwrHmH5TCITXMa3.PNG",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/profile4-FJMwBqDLCg33ucEClhLEUwSsdXgou6.PNG"
  ];

  return (
    <section className="bg-dark-200 py-12 transition-colors duration-300 relative overflow-hidden">
      <div className="animated-gradient"></div>
      <div className="container mx-auto px-4 flex flex-col items-center justify-center h-full relative z-10">
        {/* Social Proof Section */}
        <div className="flex flex-col sm:flex-row items-center justify-center animate-on-scroll opacity-0 mb-8">
          <div className="flex -space-x-3 mb-4 sm:mb-0 sm:mr-4 overflow-hidden">
            {profilePictures.map((src, index) => (
              <div 
                key={index} 
                className="relative w-10 h-10 rounded-full border-2 border-white overflow-hidden transition-transform hover:scale-110"
              >
                <img
                  src={src}
                  alt={`Profile ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
          <span className="text-lg text-gray-300">Join our growing network of club members</span>
        </div>

        {/* Animated Counters */}
        <div 
          ref={counterRef} 
          className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-8 animate-on-scroll opacity-0"
        >
          <div className="text-center">
            <span className="text-4xl font-bold text-yellow-500">{memberCount.toLocaleString()}</span>
            <p className="text-sm text-gray-400">Active Members</p>
          </div>
          <div className="text-center">
            <span className="text-4xl font-bold text-yellow-500">{facilityCount.toLocaleString()}</span>
            <p className="text-sm text-gray-400">Partner Facilities</p>
          </div>
          <div className="text-center">
            <span className="text-4xl font-bold text-yellow-500">{cityCount.toLocaleString()}</span>
            <p className="text-sm text-gray-400">Active Cities</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;