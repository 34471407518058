export const trainers = [
  {
    id: '1',
    name: 'Rafael Torres',
    image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80',
    sports: ['Tennis', 'Padel'],
    rating: 4.9,
    reviews: 245,
    city: 'Madrid',
    yearsExperience: 15,
    availability: ['morning', 'afternoon', 'evening'],
    priceRange: '€180/hour',
    specialties: [
      'Professional Tennis Training',
      'Padel Technique',
      'Tournament Preparation'
    ],
    certifications: [
      'ATP Professional Coach',
      'International Padel Federation Coach',
      'Sports Science Degree'
    ],
    achievements: [
      'Davis Cup Team Coach',
      'ATP Tour Experience',
      'National Academy Director'
    ],
    languages: ['English', 'Spanish', 'Portuguese']
  },
  {
    id: '2',
    name: 'Victoria Chen',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=300&q=80',
    sports: ['Golf', 'Performance Training'],
    rating: 4.8,
    reviews: 189,
    city: 'Singapore',
    yearsExperience: 12,
    availability: ['morning', 'evening'],
    priceRange: 'S$250/hour',
    specialties: [
      'Golf Swing Analysis',
      'Mental Game Coaching',
      'Tournament Strategy'
    ],
    certifications: [
      'PGA Professional',
      'TPI Level 3 Certified',
      'Mental Game Coaching Professional'
    ],
    achievements: [
      'Asian Tour Coach',
      'National Team Trainer',
      'Golf Academy Founder'
    ],
    languages: ['English', 'Mandarin', 'Cantonese']
  },
  {
    id: '3',
    name: 'Sebastian Weber',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80',
    sports: ['Tennis', 'Fitness'],
    rating: 4.9,
    reviews: 212,
    city: 'Monte Carlo',
    yearsExperience: 18,
    availability: ['morning', 'afternoon'],
    priceRange: '€220/hour',
    specialties: [
      'Elite Tennis Training',
      'Strength & Conditioning',
      'Performance Analysis'
    ],
    certifications: [
      'ATP Master Professional',
      'Strength & Conditioning Specialist',
      'Sports Science PhD'
    ],
    achievements: [
      'Grand Slam Coach',
      'Olympic Team Trainer',
      'Performance Center Director'
    ],
    languages: ['English', 'German', 'French']
  },
  {
    id: '4',
    name: 'Sophia Rossi',
    image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80',
    sports: ['Swimming', 'Triathlon'],
    rating: 4.8,
    reviews: 156,
    city: 'Rome',
    yearsExperience: 14,
    availability: ['morning', 'evening'],
    priceRange: '€160/hour',
    specialties: [
      'Elite Swimming',
      'Triathlon Training',
      'Open Water Technique'
    ],
    certifications: [
      'FINA Coach',
      'ITU Level 3 Coach',
      'Swimming Performance Specialist'
    ],
    achievements: [
      'Olympic Swimming Coach',
      'European Championships Trainer',
      'Performance Director'
    ],
    languages: ['English', 'Italian', 'French']
  },
  {
    id: '5',
    name: 'James Mitchell',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
    sports: ['Golf', 'Performance Training'],
    rating: 4.9,
    reviews: 178,
    city: 'London',
    yearsExperience: 16,
    availability: ['morning', 'afternoon'],
    priceRange: '£200/hour',
    specialties: [
      'Golf Performance',
      'Biomechanics',
      'Mental Game'
    ],
    certifications: [
      'PGA Master Professional',
      'TPI Level 3 Medical',
      'Golf Psychology Certificate'
    ],
    achievements: [
      'European Tour Coach',
      'National Team Coach',
      'Academy Director'
    ],
    languages: ['English']
  },
  {
    id: '6',
    name: 'Yuki Tanaka',
    image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=300&q=80',
    sports: ['Tennis', 'Fitness'],
    rating: 4.8,
    reviews: 167,
    city: 'Tokyo',
    yearsExperience: 13,
    availability: ['morning', 'evening'],
    priceRange: '¥25,000/hour',
    specialties: [
      'Technical Analysis',
      'Youth Development',
      'Performance Training'
    ],
    certifications: [
      'JTA Professional Coach',
      'Strength & Conditioning Specialist',
      'Youth Development Expert'
    ],
    achievements: [
      'National Team Coach',
      'Academy Founder',
      'Professional Tour Experience'
    ],
    languages: ['English', 'Japanese']
  },
  {
    id: '7',
    name: 'Maria Gonzalez',
    image: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&fit=crop&w=300&q=80',
    sports: ['Padel', 'Fitness'],
    rating: 4.9,
    reviews: 145,
    city: 'Barcelona',
    yearsExperience: 11,
    availability: ['morning', 'afternoon', 'evening'],
    priceRange: '€150/hour',
    specialties: [
      'Professional Padel',
      'Technical Development',
      'Tournament Strategy'
    ],
    certifications: [
      'World Padel Tour Coach',
      'Fitness Specialist',
      'Sports Psychology'
    ],
    achievements: [
      'National Team Coach',
      'WPT Coach',
      'Academy Director'
    ],
    languages: ['English', 'Spanish', 'Catalan']
  },
  {
    id: '8',
    name: 'Alexander Schmidt',
    image: 'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?auto=format&fit=crop&w=300&q=80',
    sports: ['Tennis', 'Performance'],
    rating: 4.8,
    reviews: 198,
    city: 'Zurich',
    yearsExperience: 15,
    availability: ['morning', 'afternoon'],
    priceRange: 'CHF 250/hour',
    specialties: [
      'Elite Tennis',
      'Performance Analysis',
      'Technical Development'
    ],
    certifications: [
      'Swiss Tennis Professional',
      'Performance Specialist',
      'Sports Science Master'
    ],
    achievements: [
      'Davis Cup Coach',
      'National Academy Director',
      'Pro Tour Experience'
    ],
    languages: ['English', 'German', 'French']
  },
  {
    id: '9',
    name: 'Sarah Thompson',
    image: 'https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?auto=format&fit=crop&w=300&q=80',
    sports: ['Swimming', 'Performance'],
    rating: 4.9,
    reviews: 176,
    city: 'Sydney',
    yearsExperience: 14,
    availability: ['morning', 'evening'],
    priceRange: 'A$180/hour',
    specialties: [
      'Elite Swimming',
      'Performance Training',
      'Youth Development'
    ],
    certifications: [
      'Swimming Australia Elite Coach',
      'Performance Specialist',
      'Youth Development Expert'
    ],
    achievements: [
      'Olympic Team Coach',
      'National Championships Coach',
      'High Performance Director'
    ],
    languages: ['English']
  },
  {
    id: '10',
    name: 'Marcus Anderson',
    image: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?auto=format&fit=crop&w=300&q=80',
    sports: ['Golf', 'Fitness'],
    rating: 4.8,
    reviews: 189,
    city: 'Dubai',
    yearsExperience: 13,
    availability: ['morning', 'afternoon'],
    priceRange: 'AED 800/hour',
    specialties: [
      'Golf Performance',
      'Technical Analysis',
      'Mental Game'
    ],
    certifications: [
      'PGA Professional',
      'TPI Certified',
      'Mental Game Coach'
    ],
    achievements: [
      'European Tour Coach',
      'Academy Director',
      'Performance Specialist'
    ],
    languages: ['English', 'Arabic']
  }
];