import React from 'react';
import { Trophy, Users, Globe, Shield } from 'lucide-react';
import { Button } from '../../ui/button';

interface WelcomeStepProps {
  onNext: () => void;
}

export default function WelcomeStep({ onNext }: WelcomeStepProps) {
  return (
    <div className="bg-dark-200 rounded-2xl p-8 border border-dark-300">
      <h1 className="text-3xl font-bold text-white text-center mb-8">
        Ready to Play the World?
      </h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-dark-300 rounded-xl p-6 text-center">
          <div className="bg-dark-400 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
            <Users className="h-6 w-6 text-gold-400" />
          </div>
          <h3 className="text-lg font-medium text-white mb-2">Global Network</h3>
          <p className="text-dark-800">Connect with sports enthusiasts and top-tier trainers worldwide and expand your professional and social circles</p>
        </div>

        <div className="bg-dark-300 rounded-xl p-6 text-center">
          <div className="bg-dark-400 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
            <Trophy className="h-6 w-6 text-gold-400" />
          </div>
          <h3 className="text-lg font-medium text-white mb-2">Quick Matching</h3>
          <p className="text-dark-800">Find your ideal sports partner and the best facilities in no time—get right into the action</p>
        </div>

        <div className="bg-dark-300 rounded-xl p-6 text-center">
          <div className="bg-dark-400 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
            <Globe className="h-6 w-6 text-gold-400" />
          </div>
          <h3 className="text-lg font-medium text-white mb-2">Premium Experience</h3>
          <p className="text-dark-800">Enjoy exclusive entry to premier clubs, world-class trainers, and high-level tournaments</p>
        </div>

        <div className="bg-dark-300 rounded-xl p-6 text-center">
          <div className="bg-dark-400 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
            <Shield className="h-6 w-6 text-gold-400" />
          </div>
          <h3 className="text-lg font-medium text-white mb-2">Exclusive Club</h3>
          <p className="text-dark-800">Join a trusted network of like-minded accomplished professionals who share your passion for sports</p>
        </div>
      </div>

      <div className="text-center">
        <Button 
          onClick={onNext}
          className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-8 py-6 text-lg"
        >
          Join Playclub
        </Button>
      </div>
    </div>
  );
}