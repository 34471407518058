import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import FacilityManagement from '../components/facilities/FacilityManagement';

const FacilityBooking = () => {
  return (
    <DashboardLayout>
      <FacilityManagement />
    </DashboardLayout>
  );
};

export default FacilityBooking;