import React from 'react';
import { X, MapPin, Calendar, Activity, Star, Award, Globe, Navigation2 } from 'lucide-react';
import { Progress } from '../../ui/progress';
import LocationActions from '../../facilities/LocationActions';

interface ActivityDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  activity: {
    id: string;
    sport: string;
    opponent: {
      id: string;
      name: string;
      image: string;
    };
    date: string;
    time: string;
    venue: {
      name: string;
      city: string;
    };
    status: 'played' | 'confirmed' | 'disputed';
    stats?: {
      duration: string;
      intensity: string;
      performance: number;
      highlights: string[];
      achievements: string[];
    };
  };
  isOwnProfile: boolean;
}

const ActivityDetailsModal: React.FC<ActivityDetailsModalProps> = ({
  isOpen,
  onClose,
  activity,
  isOwnProfile
}) => {
  if (!isOpen) return null;

  const isJointActivity = (sport: string): boolean => {
    const jointActivities = ['🏃‍♂️ Running', '🏊‍♂️ Swimming', '🚴‍♂️ Cycling', 
                            '🧘‍♀️ Yoga', '🏋️‍♀️ Fitness', '🧘‍♂️ Pilates'];
    return jointActivities.includes(sport);
  };

  const stats = activity.stats || {
    duration: '1h 30min',
    intensity: 'High',
    performance: 85,
    highlights: ['Personal best serve speed', '12 winners', '3 aces'],
    achievements: ['Consistent Performance', 'High Intensity Maintained']
  };

  const getVenueCoordinates = (city: string) => {
    const coordinates: Record<string, { lat: number; lng: number }> = {
      'Monte Carlo': { lat: 43.7384, lng: 7.4246 },
      'Nice': { lat: 43.7102, lng: 7.2620 },
      'Paris': { lat: 48.8566, lng: 2.3522 },
      'London': { lat: 51.5074, lng: -0.1278 },
      'Singapore': { lat: 1.3521, lng: 103.8198 },
      'Dubai': { lat: 25.2048, lng: 55.2708 },
      'Rome': { lat: 41.9028, lng: 12.4964 },
      'Barcelona': { lat: 41.3851, lng: 2.1734 }
    };
    return coordinates[city] || { lat: 0, lng: 0 };
  };

  const venueCoordinates = getVenueCoordinates(activity.venue.city);

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-2xl bg-dark-200 rounded-2xl p-6 border border-dark-300">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            <div className="mb-6">
              <div className="flex items-center space-x-4 mb-4">
                <img
                  src={activity.opponent.image}
                  alt={activity.opponent.name}
                  className="w-16 h-16 rounded-full object-cover border-2 border-dark-400"
                />
                <div>
                  <h2 className="text-lg font-medium text-white">{activity.sport}</h2>
                  <p className="text-lg font-medium text-white">
                    {isJointActivity(activity.sport) ? 'with' : 'vs'} {activity.opponent.name}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center text-dark-800">
                  <Calendar className="h-4 w-4 mr-2" />
                  {new Date(`${activity.date} ${activity.time}`).toLocaleString()}
                </div>
                <div className="flex items-center text-dark-800">
                  <MapPin className="h-4 w-4 mr-2" />
                  {activity.venue.name}, {activity.venue.city}
                </div>
              </div>

              <div className="mt-4">
                <LocationActions
                  venueName={activity.venue.name}
                  address={`${activity.venue.name}, ${activity.venue.city}`}
                  coordinates={venueCoordinates}
                />
              </div>
            </div>

            <div className="space-y-6">
              <div className="bg-dark-300 rounded-xl p-4">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-2">
                    <Activity className="h-5 w-5 text-gold-400" />
                    <h3 className="text-base font-medium text-white">Performance</h3>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Star className="h-4 w-4 text-gold-400" />
                    <span className="text-white">{stats.performance}%</span>
                  </div>
                </div>
                <Progress value={stats.performance} className="h-2" />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="bg-dark-300 rounded-xl p-4">
                  <h4 className="text-dark-800 mb-2">Duration</h4>
                  <p className="text-white font-medium">{stats.duration}</p>
                </div>
                <div className="bg-dark-300 rounded-xl p-4">
                  <h4 className="text-dark-800 mb-2">Intensity</h4>
                  <p className="text-white font-medium">{stats.intensity}</p>
                </div>
              </div>

              <div className="bg-dark-300 rounded-xl p-4">
                <h4 className="text-base font-medium text-white mb-3">Highlights</h4>
                <div className="space-y-2">
                  {stats.highlights.map((highlight, index) => (
                    <div key={index} className="flex items-center text-dark-800">
                      <div className="w-1.5 h-1.5 bg-gold-400 rounded-full mr-2" />
                      {highlight}
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-dark-300 rounded-xl p-4">
                <div className="flex items-center space-x-2 mb-3">
                  <Award className="h-5 w-5 text-gold-400" />
                  <h4 className="text-base font-medium text-white">Achievements</h4>
                </div>
                <div className="space-y-2">
                  {stats.achievements.map((achievement, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-dark-400 rounded-lg p-2"
                    >
                      <Star className="h-4 w-4 text-gold-400 mr-2" />
                      <span className="text-white">{achievement}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDetailsModal;