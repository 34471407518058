import React, { useState } from 'react';
import { Calendar, Clock, MapPin, X, CreditCard, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { facilities } from '../../data/facilities';

interface BookSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  trainer: {
    id: string;
    name: string;
    sports: string[];
    priceRange: string;
    location: string;
  };
}

const BookSessionModal: React.FC<BookSessionModalProps> = ({ isOpen, onClose, trainer }) => {
  const navigate = useNavigate();
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDuration, setSelectedDuration] = useState('60');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  if (!isOpen) return null;

  const cityFacilities = facilities.filter(
    facility => facility.location === trainer.location &&
    (!selectedSport || facility.type.toLowerCase() === selectedSport.toLowerCase())
  );

  const timeSlots = [
    '09:00', '10:00', '11:00', '14:00', '15:00', '16:00', '17:00'
  ];

  const durations = [
    { value: '30', label: '30 minutes' },
    { value: '60', label: '1 hour' },
    { value: '90', label: '1.5 hours' },
    { value: '120', label: '2 hours' }
  ];

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to book session:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewBookings = () => {
    onClose();
    navigate('/dashboard/profile', { state: { activeTab: 'training' } });
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setSelectedSport('');
      setSelectedDate('');
      setSelectedTime('');
      setSelectedDuration('60');
      setShowSuccess(false);
      onClose();
    }
  };

  const minDate = new Date().toISOString().split('T')[0];
  const maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

  if (showSuccess) {
    return (
      <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <div className="relative w-full max-w-md bg-dark-200 rounded-2xl p-6 border border-dark-300">
              <div className="text-center py-6">
                <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
                  <Check className="h-8 w-8 text-dark-50" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Booking Confirmed!</h3>
                <p className="text-dark-800 mb-6">
                  Your training session with {trainer.name} has been scheduled for{' '}
                  {new Date(selectedDate).toLocaleDateString()} at {selectedTime}.
                </p>
                <div className="flex flex-col space-y-3">
                  <button
                    onClick={handleViewBookings}
                    className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-3 rounded-xl transition-colors"
                  >
                    View My Bookings
                  </button>
                  <button
                    onClick={handleClose}
                    className="w-full bg-dark-300 hover:bg-dark-400 text-white px-6 py-3 rounded-xl transition-colors"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="relative w-full max-w-md bg-dark-200 rounded-2xl p-6 border border-dark-300">
            {/* Header */}
            <div className="flex items-center justify-between mb-6">
              <div>
                <h2 className="text-xl font-semibold text-white">Book Session with {trainer.name}</h2>
                <p className="text-dark-800 mt-1">Rate: {trainer.priceRange}</p>
              </div>
              <button
                onClick={handleClose}
                className="p-2 text-dark-800 hover:text-white transition-colors"
                disabled={isSubmitting}
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {/* Booking Form */}
            <div className="space-y-6">
              {/* Sport Selection */}
              <div>
                <label className="block text-dark-800 mb-2">Sport</label>
                <select
                  value={selectedSport}
                  onChange={(e) => setSelectedSport(e.target.value)}
                  className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                  required
                  disabled={isSubmitting}
                >
                  <option value="">Select Sport</option>
                  {trainer.sports.map((sport) => (
                    <option key={sport} value={sport}>{sport}</option>
                  ))}
                </select>
              </div>

              {/* Date Selection */}
              <div>
                <label className="block text-dark-800 mb-2">Date</label>
                <div className="relative">
                  <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                  <input
                    type="date"
                    min={minDate}
                    max={maxDate}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="w-full pl-10 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                    required
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {/* Time Selection */}
              <div>
                <label className="block text-dark-800 mb-2">Time</label>
                <div className="relative">
                  <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                  <select
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    className="w-full pl-10 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                    required
                    disabled={isSubmitting}
                  >
                    <option value="">Select Time</option>
                    {timeSlots.map((time) => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Duration Selection */}
              <div>
                <label className="block text-dark-800 mb-2">Duration</label>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                  {durations.map((duration) => (
                    <button
                      key={duration.value}
                      type="button"
                      onClick={() => setSelectedDuration(duration.value)}
                      disabled={isSubmitting}
                      className={`px-3 py-2 rounded-xl text-sm transition-colors ${
                        selectedDuration === duration.value
                          ? 'bg-gold-400 text-dark-50'
                          : 'bg-dark-300 text-white hover:bg-dark-400'
                      } ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {duration.label}
                    </button>
                  ))}
                </div>
              </div>

              {/* Location */}
              {selectedSport && cityFacilities.length > 0 && (
                <div>
                  <label className="block text-dark-800 mb-2">Location</label>
                  <div className="relative">
                    <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                    <select
                      className="w-full pl-10 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                      required
                      disabled={isSubmitting}
                    >
                      {cityFacilities.map((facility) => (
                        <option key={facility.id} value={facility.id}>
                          {facility.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* Submit Button */}
              <button
                onClick={handleSubmit}
                disabled={isSubmitting || !selectedSport || !selectedDate || !selectedTime || !selectedDuration}
                className="w-full mt-6 flex items-center justify-center space-x-2 bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-3 rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <>
                    <div className="w-5 h-5 border-2 border-t-transparent border-dark-50 rounded-full animate-spin" />
                    <span>Processing...</span>
                  </>
                ) : (
                  <>
                    <CreditCard className="h-5 w-5" />
                    <span>Book Session</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookSessionModal;