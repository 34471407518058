import React, { useState, useCallback } from 'react';
import { MapPin, Globe } from 'lucide-react';
import { Command, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem } from '../ui/command';
import { searchCities } from '../../lib/api';
import type { City } from '../../types/city';
import { cities } from '../../data/cities';

interface CitySearchProps {
  selectedCity: string;
  onCitySelect: (city: string) => void;
}

const CitySearch: React.FC<CitySearchProps> = ({ selectedCity, onCitySelect }) => {
  const [searchResults, setSearchResults] = useState<City[]>(
    cities.filter(city => city.active)
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = useCallback(async (query: string) => {
    const results = await searchCities(query);
    setSearchResults(results);
  }, []);

  const handleSelect = (city: string) => {
    onCitySelect(city);
    setIsOpen(false);
  };

  return (
    <Command className="relative w-full bg-dark-300 border border-dark-400 rounded-xl overflow-visible h-[38px]">
      <div className="flex items-center h-full">
        <CommandInput
          placeholder={selectedCity || "All Cities"}
          onValueChange={handleSearch}
          onFocus={() => setIsOpen(true)}
          className="flex-1 bg-transparent border-0 outline-none text-white placeholder-dark-800 h-full px-4 py-0"
        />
      </div>
      
      {isOpen && searchResults.length > 0 && (
        <div className="absolute top-full left-0 right-0 mt-1 z-50">
          <CommandList className="bg-dark-200 border border-dark-400 rounded-xl shadow-lg max-h-[200px] overflow-y-auto">
            <CommandGroup>
              {searchResults.map((city) => (
                <CommandItem
                  key={city.name}
                  value={city.name}
                  onSelect={() => handleSelect(city.name)}
                  className="flex items-center px-3 py-2 hover:bg-dark-300 cursor-pointer"
                >
                  {city.active ? (
                    <MapPin className="h-4 w-4 mr-2 text-gold-400" />
                  ) : (
                    <Globe className="h-4 w-4 mr-2 text-dark-800" />
                  )}
                  <span className="text-white">{city.name}</span>
                  {city.active && (
                    <span className="ml-2 text-xs text-gold-400">
                      {city.venueCount} venues
                    </span>
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </div>
      )}
    </Command>
  );
};

export default CitySearch;