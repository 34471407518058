import React from 'react';
import { Play, Upload, Camera, Settings, Download } from 'lucide-react';
import PremiumFeatureOverlay from '../members/tabs/PremiumFeatureOverlay';

interface VideoAnalysisProps {
  isPremium?: boolean;
}

const VideoAnalysis: React.FC<VideoAnalysisProps> = ({ isPremium = false }) => {
  if (!isPremium) {
    return (
      <PremiumFeatureOverlay
        title="Unlock Elite Video Analysis"
        description="Get AI-powered insights, professional feedback, and advanced analysis tools to elevate your game to the next level."
      />
    );
  }

  const videos = [
    {
      id: '1',
      title: 'Serve Analysis',
      thumbnail: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=800&q=80',
      date: '2 days ago',
      duration: '4:32',
      analysis: {
        technique: 85,
        power: 90,
        accuracy: 82
      }
    },
    {
      id: '2',
      title: 'Backhand Review',
      thumbnail: 'https://images.unsplash.com/photo-1587280501635-68a0e82cd5ff?auto=format&fit=crop&w=800&q=80',
      date: '1 week ago',
      duration: '3:45',
      analysis: {
        technique: 78,
        power: 75,
        accuracy: 80
      }
    }
  ];

  return (
    <div className="space-y-6">
      {/* Upload Section */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h3 className="text-lg font-semibold text-white">Video Analysis</h3>
            <p className="text-dark-800 mt-1">Upload your performance videos for AI analysis</p>
          </div>
          <div className="flex items-center space-x-4">
            <button className="flex items-center space-x-2 px-4 py-2 bg-dark-300 rounded-xl hover:bg-dark-400 transition-colors">
              <Camera className="h-5 w-5 text-dark-800" />
              <span className="text-white">Record</span>
            </button>
            <button className="flex items-center space-x-2 px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors">
              <Upload className="h-5 w-5" />
              <span>Upload Video</span>
            </button>
          </div>
        </div>
      </div>

      {/* Video Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {videos.map((video) => (
          <div
            key={video.id}
            className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="relative group">
              <img
                src={video.thumbnail}
                alt={video.title}
                className="w-full aspect-video object-cover"
              />
              <div className="absolute inset-0 bg-dark-900/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <button className="p-4 bg-gold-400 rounded-full transform scale-75 group-hover:scale-100 transition-transform">
                  <Play className="h-6 w-6 text-dark-50" />
                </button>
              </div>
              <span className="absolute bottom-2 right-2 px-2 py-1 bg-dark-900/75 text-white text-sm rounded">
                {video.duration}
              </span>
            </div>

            <div className="p-6">
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h4 className="text-white font-medium">{video.title}</h4>
                  <p className="text-dark-800 text-sm">{video.date}</p>
                </div>
                <div className="flex items-center space-x-2">
                  <button className="p-2 text-dark-800 hover:text-gold-400">
                    <Settings className="h-5 w-5" />
                  </button>
                  <button className="p-2 text-dark-800 hover:text-gold-400">
                    <Download className="h-5 w-5" />
                  </button>
                </div>
              </div>

              <div className="space-y-4">
                {Object.entries(video.analysis).map(([key, value]) => (
                  <div key={key}>
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-dark-800 capitalize">{key}</span>
                      <span className="text-white">{value}%</span>
                    </div>
                    <div className="w-full h-2 bg-dark-400 rounded-full">
                      <div
                        className="h-2 bg-gold-400 rounded-full"
                        style={{ width: `${value}%` }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoAnalysis;