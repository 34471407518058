import React, { useState } from 'react';
import { Activity, Plus, Search, X } from 'lucide-react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

interface SportsSelectorProps {
  selectedSports: string[];
  onSportsChange: (sports: string[]) => void;
}

const availableSports = [
  '🎾 Tennis',
  '⛳ Golf',
  '🏊‍♂️ Swimming',
  '🏃‍♂️ Running',
  '🚴‍♂️ Cycling',
  '⚽ Football',
  '🏀 Basketball',
  '🏸 Badminton',
  '🏓 Table Tennis',
  '🏐 Volleyball',
  '🎱 Padel',
  '🧘‍♀️ Yoga',
  '🏋️‍♀️ Pilates',
  '🎿 Skiing',
  '🏂 Snowboarding',
  '🏄‍♂️ Surfing',
  '🚣‍♂️ Rowing',
  '🏹 Archery',
  '🤺 Fencing',
  '🥊 Boxing'
];

const SportsSelector: React.FC<SportsSelectorProps> = ({
  selectedSports,
  onSportsChange
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddSport, setShowAddSport] = useState(false);

  const filteredSports = availableSports.filter(sport => 
    !selectedSports.includes(sport) &&
    sport.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddSport = (sport: string) => {
    onSportsChange([...selectedSports, sport]);
    setShowAddSport(false);
    setSearchTerm('');
  };

  const handleRemoveSport = (sportToRemove: string) => {
    onSportsChange(selectedSports.filter(sport => sport !== sportToRemove));
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <Activity className="h-5 w-5 text-gold-400" />
          <h3 className="text-lg font-semibold text-white">Sports Preferences</h3>
        </div>
        <Button
          onClick={() => setShowAddSport(true)}
          className="bg-dark-300 hover:bg-dark-400 text-white"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Sport
        </Button>
      </div>

      {/* Add Sport Dialog */}
      {showAddSport && (
        <div className="mb-6 bg-dark-300 rounded-xl p-4">
          <div className="flex items-center justify-between mb-4">
            <h4 className="text-white font-medium">Add New Sport</h4>
            <button
              onClick={() => setShowAddSport(false)}
              className="text-dark-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
          <div className="space-y-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search sports..."
                className="pl-10 bg-dark-400 border-dark-500 text-white"
              />
            </div>
            <div className="max-h-48 overflow-y-auto space-y-2">
              {filteredSports.length > 0 ? (
                filteredSports.map((sport) => (
                  <button
                    key={sport}
                    onClick={() => handleAddSport(sport)}
                    className="w-full px-4 py-2 text-left rounded-lg hover:bg-dark-400 text-white transition-colors"
                  >
                    {sport}
                  </button>
                ))
              ) : (
                <div className="text-center text-dark-800 py-4">
                  No sports found matching your search
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Selected Sports Grid */}
      {selectedSports.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {selectedSports.map((sport) => (
            <div
              key={sport}
              className="group relative bg-dark-300 rounded-xl p-4 text-white hover:bg-dark-400 transition-colors"
            >
              <span>{sport}</span>
              <button
                onClick={() => handleRemoveSport(sport)}
                className="absolute top-2 right-2 p-1 bg-dark-400 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity hover:bg-dark-500"
              >
                <X className="h-4 w-4 text-white" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-dark-300 rounded-xl">
          <Activity className="h-12 w-12 text-dark-800 mx-auto mb-4" />
          <p className="text-dark-800">No sports selected</p>
          <Button
            onClick={() => setShowAddSport(true)}
            className="mt-4 bg-dark-400 hover:bg-dark-500 text-white"
          >
            Add Your First Sport
          </Button>
        </div>
      )}
    </div>
  );
};

export default SportsSelector;