import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface DateRangePickerProps {
  dateRange: {
    start: string;
    end: string;
  };
  onDateRangeChange: (range: { start: string; end: string }) => void;
  minDate?: string;
  maxDate?: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateRange,
  onDateRangeChange,
  minDate = new Date().toISOString().split('T')[0],
  maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [hoverDate, setHoverDate] = useState<string | null>(null);

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];
    
    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null);
    }
    
    // Add the days of the month
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(year, month, i));
    }
    
    return days;
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  const isDateInRange = (date: Date) => {
    const dateStr = formatDate(date);
    if (!dateRange.start) return false;
    if (!dateRange.end) return dateStr === dateRange.start;
    return dateStr >= dateRange.start && dateStr <= dateRange.end;
  };

  const isDateDisabled = (date: Date) => {
    return formatDate(date) < minDate || formatDate(date) > maxDate;
  };

  const isDateInHoverRange = (date: Date) => {
    if (!dateRange.start || dateRange.end || !hoverDate) return false;
    const dateStr = formatDate(date);
    return dateStr >= dateRange.start && dateStr <= hoverDate;
  };

  const handleDateClick = (date: Date) => {
    if (isDateDisabled(date)) return;
    
    const clickedDate = formatDate(date);
    
    if (!dateRange.start || (dateRange.start && dateRange.end)) {
      // Start new selection
      onDateRangeChange({ start: clickedDate, end: '' });
    } else {
      // Complete the range
      if (clickedDate < dateRange.start) {
        onDateRangeChange({ start: clickedDate, end: dateRange.start });
      } else {
        onDateRangeChange({ ...dateRange, end: clickedDate });
      }
    }
  };

  const handleDateHover = (date: Date | null) => {
    setHoverDate(date ? formatDate(date) : null);
  };

  return (
    <div className="bg-dark-300 rounded-xl p-4">
      <div className="flex items-center justify-between mb-4">
        <button
          type="button"
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1))}
          className="p-2 hover:bg-dark-400 rounded-lg transition-colors"
        >
          <ChevronLeft className="h-5 w-5 text-dark-800" />
        </button>
        <span className="text-white font-medium">
          {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </span>
        <button
          type="button"
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1))}
          className="p-2 hover:bg-dark-400 rounded-lg transition-colors"
        >
          <ChevronRight className="h-5 w-5 text-dark-800" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {weekDays.map(day => (
          <div key={day} className="text-center text-dark-800 text-sm py-2">
            {day}
          </div>
        ))}
        {getDaysInMonth(currentMonth).map((date, index) => (
          <div key={index} className="aspect-square">
            {date ? (
              <button
                type="button"
                onClick={() => handleDateClick(date)}
                onMouseEnter={() => handleDateHover(date)}
                onMouseLeave={() => handleDateHover(null)}
                disabled={isDateDisabled(date)}
                className={`w-full h-full flex items-center justify-center rounded-lg text-sm transition-colors ${
                  isDateDisabled(date)
                    ? 'text-dark-800 cursor-not-allowed'
                    : isDateInRange(date)
                    ? 'bg-gold-400 text-dark-50'
                    : isDateInHoverRange(date)
                    ? 'bg-gold-400/50 text-dark-50'
                    : 'text-white hover:bg-dark-400'
                }`}
              >
                {date.getDate()}
              </button>
            ) : (
              <div className="w-full h-full" />
            )}
          </div>
        ))}
      </div>

      {(dateRange.start || dateRange.end) && (
        <div className="mt-4 space-y-2">
          <div className="flex items-center justify-between text-sm">
            <span className="text-dark-800">Start Date:</span>
            <span className="text-white">
              {dateRange.start
                ? new Date(dateRange.start).toLocaleDateString()
                : '-'}
            </span>
          </div>
          <div className="flex items-center justify-between text-sm">
            <span className="text-dark-800">End Date:</span>
            <span className="text-white">
              {dateRange.end
                ? new Date(dateRange.end).toLocaleDateString()
                : '-'}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;