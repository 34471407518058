import React, { useState } from 'react';
import { ChevronDown, Users, Globe, Trophy, ChevronRight, Calendar, MapPin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const WelcomeMessage: React.FC = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!isCollapsed) {
    return (
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300 relative overflow-hidden">
        <button
          onClick={() => setIsCollapsed(true)}
          className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white transition-colors"
        >
          <ChevronDown className="h-5 w-5" />
        </button>

        <h2 className="text-xl font-bold text-white mb-4">Never Play Solo Again! 🤝</h2>
        <p className="text-dark-800 mb-6">
          Your elite sports network awaits. Find players, trainers, and facilities wherever your business takes you.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
          <div className="bg-dark-300 rounded-xl p-4">
            <div className="flex items-center space-x-3 mb-2">
              <div className="bg-dark-400 p-2.5 rounded-lg">
                <Users className="h-5 w-5 text-gold-400" />
              </div>
              <h3 className="text-white text-lg font-semibold tracking-tight">Network</h3>
            </div>
            <p className="text-dark-800 text-sm">
              Connect with like-minded professionals who share your passion for sports
            </p>
          </div>

          <div className="bg-dark-300 rounded-xl p-4">
            <div className="flex items-center space-x-3 mb-2">
              <div className="bg-dark-400 p-2.5 rounded-lg">
                <Globe className="h-5 w-5 text-gold-400" />
              </div>
              <h3 className="text-white text-lg font-semibold tracking-tight">Play Anywhere</h3>
            </div>
            <p className="text-dark-800 text-sm">
              Find matches and training partners in every city you visit
            </p>
          </div>

          <div className="bg-dark-300 rounded-xl p-4">
            <div className="flex items-center space-x-3 mb-2">
              <div className="bg-dark-400 p-2.5 rounded-lg">
                <Trophy className="h-5 w-5 text-gold-400" />
              </div>
              <h3 className="text-white text-lg font-semibold tracking-tight">Elite Experience</h3>
            </div>
            <p className="text-dark-800 text-sm">
              Access premium facilities and top-rated trainers worldwide
            </p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4">
          <button
            onClick={() => navigate('/dashboard/match')}
            className="flex-1 flex items-center justify-center space-x-2 px-6 py-3 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
          >
            <Users className="h-5 w-5" />
            <span>Find Players</span>
            <ChevronRight className="h-5 w-5" />
          </button>

          <button
            onClick={() => navigate('/dashboard/training')}
            className="flex-1 flex items-center justify-center space-x-2 px-6 py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
          >
            <Calendar className="h-5 w-5" />
            <span>Book a Trainer</span>
          </button>

          <button
            onClick={() => navigate('/dashboard/facilities')}
            className="flex-1 flex items-center justify-center space-x-2 px-6 py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
          >
            <MapPin className="h-5 w-5" />
            <span>Find Facilities</span>
          </button>
        </div>
      </div>
    );
  }

  // Collapsed state
  return (
    <button
      onClick={() => setIsCollapsed(false)}
      className="w-full bg-dark-200 rounded-2xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <Users className="h-5 w-5 text-gold-400" />
          <span className="text-white font-medium">Never Play Solo Again!</span>
        </div>
        <ChevronDown className="h-5 w-5 text-dark-800 transform rotate-180" />
      </div>
    </button>
  );
};

export default WelcomeMessage;