import React, { useState } from 'react';
import { Crown } from 'lucide-react';

interface PremiumFeatureOverlayProps {
  title: string;
  description: string;
}

const PremiumFeatureOverlay: React.FC<PremiumFeatureOverlayProps> = ({ title, description }) => {
  const [showFeatures, setShowFeatures] = useState(false);

  const handleUpgrade = () => {
    setShowFeatures(true);
  };

  if (showFeatures) {
    return (
      <div className="space-y-6">
        {/* Performance Analytics */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-xl font-semibold text-white mb-4">Elite Performance Analytics</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="bg-dark-300 rounded-xl p-4">
                <h4 className="text-white font-medium mb-2">Advanced Metrics</h4>
                <ul className="text-dark-800 space-y-2">
                  <li>• Detailed performance tracking</li>
                  <li>• Progress visualization</li>
                  <li>• Skill development insights</li>
                </ul>
              </div>
              <div className="bg-dark-300 rounded-xl p-4">
                <h4 className="text-white font-medium mb-2">Match Analysis</h4>
                <ul className="text-dark-800 space-y-2">
                  <li>• Game statistics</li>
                  <li>• Performance trends</li>
                  <li>• Opponent analysis</li>
                </ul>
              </div>
            </div>
            <div className="space-y-4">
              <div className="bg-dark-300 rounded-xl p-4">
                <h4 className="text-white font-medium mb-2">AI Insights</h4>
                <ul className="text-dark-800 space-y-2">
                  <li>• Personalized recommendations</li>
                  <li>• Improvement suggestions</li>
                  <li>• Training optimization</li>
                </ul>
              </div>
              <div className="bg-dark-300 rounded-xl p-4">
                <h4 className="text-white font-medium mb-2">Video Analysis</h4>
                <ul className="text-dark-800 space-y-2">
                  <li>• Technique breakdown</li>
                  <li>• Motion analysis</li>
                  <li>• Real-time feedback</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-6 text-center">
            <button className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-8 py-3 rounded-xl transition-colors">
              Upgrade Now for €99/month
            </button>
          </div>
        </div>

        {/* Video Analysis Features */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h3 className="text-xl font-semibold text-white mb-4">Elite Video Analysis</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-dark-300 rounded-xl p-4">
              <h4 className="text-white font-medium mb-2">AI-Powered Analysis</h4>
              <ul className="text-dark-800 space-y-2">
                <li>• Automated technique analysis</li>
                <li>• Form correction</li>
                <li>• Movement patterns</li>
              </ul>
            </div>
            <div className="bg-dark-300 rounded-xl p-4">
              <h4 className="text-white font-medium mb-2">Professional Feedback</h4>
              <ul className="text-dark-800 space-y-2">
                <li>• Expert reviews</li>
                <li>• Personalized tips</li>
                <li>• Progress tracking</li>
              </ul>
            </div>
            <div className="bg-dark-300 rounded-xl p-4">
              <h4 className="text-white font-medium mb-2">Advanced Tools</h4>
              <ul className="text-dark-800 space-y-2">
                <li>• Side-by-side comparison</li>
                <li>• Slow-motion analysis</li>
                <li>• Frame-by-frame review</li>
              </ul>
            </div>
          </div>
          <div className="mt-6 text-center">
            <p className="text-dark-800 mb-4">
              Get access to professional-grade video analysis tools and expert insights
            </p>
            <button className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-8 py-3 rounded-xl transition-colors">
              Start Your Elite Membership
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <div className="flex flex-col items-center justify-center text-center py-12">
        <div className="bg-gold-400/20 p-4 rounded-full mb-6">
          <Crown className="h-8 w-8 text-gold-400" />
        </div>
        <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
        <p className="text-dark-800 mb-6 max-w-md">{description}</p>
        <button 
          onClick={handleUpgrade}
          className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-xl transition-colors"
        >
          Upgrade to Elite
        </button>
      </div>
    </div>
  );
};

export default PremiumFeatureOverlay;