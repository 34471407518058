import React from 'react';
import { Activity, TrendingUp, Target } from 'lucide-react';

const ProgressTracker: React.FC = () => {
  const progressData = {
    weekly: [65, 72, 78, 75, 82, 88, 92],
    sports: [
      { name: 'Tennis', score: 92, improvement: '+15%' },
      { name: 'Golf', score: 85, improvement: '+8%' },
      { name: 'Swimming', score: 78, improvement: '+12%' }
    ],
    goals: [
      { name: 'Weekly Training', target: 5, current: 4 },
      { name: 'Monthly Matches', target: 12, current: 8 },
      { name: 'Performance Score', target: 95, current: 88 }
    ]
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* Performance Trend */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-white">Weekly Progress</h3>
          <TrendingUp className="h-5 w-5 text-gold-400" />
        </div>
        <div className="h-64 flex items-end justify-between space-x-2">
          {progressData.weekly.map((value, index) => (
            <div key={index} className="flex-1">
              <div 
                className="bg-gold-400/20 hover:bg-gold-400/30 rounded-t-lg transition-all duration-300"
                style={{ height: `${value}%` }}
              >
                <div 
                  className="bg-gold-400 rounded-t-lg transition-all duration-300" 
                  style={{ height: `${value * 0.7}%` }}
                />
              </div>
              <div className="text-dark-800 text-xs text-center mt-2">
                Day {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Sport-specific Progress */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-white">Sport Performance</h3>
          <Activity className="h-5 w-5 text-gold-400" />
        </div>
        <div className="space-y-4">
          {progressData.sports.map((sport, index) => (
            <div key={index} className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between mb-2">
                <span className="text-white">{sport.name}</span>
                <div className="flex items-center space-x-2">
                  <span className="text-white">{sport.score}</span>
                  <span className="text-green-400 text-sm">{sport.improvement}</span>
                </div>
              </div>
              <div className="w-full h-2 bg-dark-400 rounded-full">
                <div 
                  className="h-2 bg-gold-400 rounded-full transition-all duration-300"
                  style={{ width: `${sport.score}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Goals Progress */}
      <div className="lg:col-span-2 bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-white">Goals Progress</h3>
          <Target className="h-5 w-5 text-gold-400" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {progressData.goals.map((goal, index) => (
            <div key={index} className="bg-dark-300 rounded-xl p-4">
              <div className="flex items-center justify-between mb-4">
                <span className="text-white">{goal.name}</span>
                <span className="text-gold-400">
                  {goal.current}/{goal.target}
                </span>
              </div>
              <div className="w-full h-2 bg-dark-400 rounded-full">
                <div 
                  className="h-2 bg-gold-400 rounded-full transition-all duration-300"
                  style={{ width: `${(goal.current / goal.target) * 100}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressTracker;