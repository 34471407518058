import React, { useState } from 'react';
import { Image, X, Send } from 'lucide-react';

interface PostComposerProps {
  onPost: (content: string, image?: File) => void;
}

const PostComposer: React.FC<PostComposerProps> = ({ onPost }) => {
  const [content, setContent] = useState('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreview(null);
    setImageFile(null);
  };

  const handleSubmit = async () => {
    if (!content.trim()) return;

    setIsSubmitting(true);
    try {
      await onPost(content, imageFile || undefined);
      setContent('');
      setImagePreview(null);
      setImageFile(null);
    } catch (error) {
      console.error('Failed to create post:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Share your sports journey..."
        className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-3 text-white placeholder-dark-800 resize-none min-h-[100px]"
      />

      {imagePreview && (
        <div className="relative mt-4">
          <img
            src={imagePreview}
            alt="Post preview"
            className="w-full max-h-64 object-cover rounded-xl"
          />
          <button
            onClick={removeImage}
            className="absolute top-2 right-2 p-1 bg-dark-900/80 rounded-full hover:bg-dark-900 transition-colors"
          >
            <X className="h-5 w-5 text-white" />
          </button>
        </div>
      )}

      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center space-x-2">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="post-image"
          />
          <label
            htmlFor="post-image"
            className="flex items-center space-x-2 px-4 py-2 bg-dark-300 rounded-xl hover:bg-dark-400 transition-colors cursor-pointer"
          >
            <Image className="h-5 w-5 text-dark-800" />
            <span className="text-white">Add Photo</span>
          </label>
        </div>

        <button
          onClick={handleSubmit}
          disabled={!content.trim() || isSubmitting}
          className="flex items-center space-x-2 px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Send className="h-5 w-5" />
          <span>{isSubmitting ? 'Posting...' : 'Post'}</span>
        </button>
      </div>
    </div>
  );
};

export default PostComposer;