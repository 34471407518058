import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import ProfileSection from '../components/dashboard/ProfileSection';

interface LocationState {
  activeTab?: string;
}

const Profile: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [activeTab, setActiveTab] = useState(state?.activeTab || 'about');

  // Update active tab when location state changes
  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab);
    }
  }, [state?.activeTab]);

  return (
    <DashboardLayout>
      <ProfileSection defaultTab={activeTab} />
    </DashboardLayout>
  );
};

export default Profile;