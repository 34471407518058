import { Title } from '../types/points';

// Points awarded for different activities
export const POINTS_CONFIG = {
  // Match related
  MATCH_PLAYED: 100,
  MATCH_WON: 150,
  TOURNAMENT_PARTICIPATION: 200,
  TOURNAMENT_WIN: 500,

  // Training related
  TRAINING_SESSION: 75,
  TRAINING_MILESTONE: 200,
  SKILL_IMPROVEMENT: 150,

  // Social related
  NEW_CONNECTION: 50,
  PROFILE_COMPLETION: 100,
  EVENT_PARTICIPATION: 100,

  // Location related
  NEW_CITY: 200,
  NEW_COUNTRY: 500,

  // Achievement related
  ACHIEVEMENT_UNLOCK: 250,
  PERFECT_RATING: 300
};

// Titles and their perks
export const titles: Title[] = [
  {
    id: 'rookie',
    name: 'Rookie',
    icon: '🌟',
    requiredPoints: 0,
    perks: ['Basic matchmaking', 'Standard profile'],
    color: 'text-gray-400'
  },
  {
    id: 'amateur',
    name: 'Amateur',
    icon: '🥉',
    requiredPoints: 1000,
    perks: [
      'Enhanced matchmaking',
      'Profile customization',
      'Join private groups'
    ],
    color: 'text-bronze-400'
  },
  {
    id: 'intermediate',
    name: 'Intermediate',
    icon: '🥈',
    requiredPoints: 5000,
    perks: [
      'Priority matchmaking',
      'Event creation',
      'Training session discounts',
      'Custom profile badge'
    ],
    color: 'text-silver-400'
  },
  {
    id: 'pro',
    name: 'Pro',
    icon: '🥇',
    requiredPoints: 15000,
    perks: [
      'VIP matchmaking',
      'Tournament organization',
      'Premium profile features',
      'Exclusive events access',
      'Training session priority'
    ],
    color: 'text-gold-400'
  },
  {
    id: 'elite',
    name: 'Elite',
    icon: '👑',
    requiredPoints: 50000,
    perks: [
      'Global ranking visibility',
      'Personal concierge service',
      'Exclusive club access',
      'Premium event invitations',
      'Custom tournament creation',
      'Training session hosting'
    ],
    color: 'text-purple-400'
  },
  {
    id: 'legend',
    name: 'Legend',
    icon: '⚡',
    requiredPoints: 100000,
    perks: [
      'Legendary profile status',
      'Global ambassador privileges',
      'Private club creation',
      'Exclusive networking events',
      'Premium concierge service',
      'Custom achievements'
    ],
    color: 'text-red-400'
  }
];

export function calculatePoints(points: Record<string, number>): number {
  return Object.values(points).reduce((total, points) => total + points, 0);
}

export function getCurrentTitle(points: number): Title {
  return [...titles]
    .reverse()
    .find(title => points >= title.requiredPoints) || titles[0];
}

export function getNextTitle(points: number): Title | null {
  return titles.find(title => points < title.requiredPoints) || null;
}

export function getPointsToNextTitle(points: number): number {
  const nextTitle = getNextTitle(points);
  return nextTitle ? nextTitle.requiredPoints - points : 0;
}