import React from 'react';
import { Globe, LineChart, Users, Handshake } from 'lucide-react';

const features = [
  { icon: Handshake, title: 'Global Network', text: 'Connect, train, and play with like-minded sports partners globally' },
  { icon: Globe, title: 'Premium Access', text: 'Global access to premium sports facilities' },
  { icon: LineChart, title: 'Performance Tracking', text: 'AI-driven performance insights' },
  { icon: Users, title: 'Personalized Experience', text: 'Personalized trainers and networking opportunities' },
];

export const Features: React.FC = () => {
  return (
    <section className="py-24 bg-black">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12">
          <div>
            <h2 className="text-2xl font-bold mb-8">Exclusive Membership</h2>
            <div className="space-y-8">
              {features.map((feature, index) => (
                <div key={index} className="flex items-start">
                  <div className="mr-4">
                    <feature.icon className="w-6 h-6 text-gold-400" />
                  </div>
                  <div>
                    <h3 className="text-base font-medium mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center perspective">
            <div className="relative w-80 h-48 [transform-style:preserve-3d] animate-rotate-y">
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/professional_gold_card_optimized_horizontal-NgBaIbyTOCU7zJCe6fPBPM1RE78YY5.png"
                alt="Playclub.ai Professional Gold Card"
                className="absolute inset-0 w-full h-full object-contain rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};