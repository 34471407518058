import React from 'react';

export const JoinClub: React.FC = () => {
  return (
    <section className="py-16 md:py-24 lg:py-32 bg-dark-200 text-white relative overflow-hidden">
      <div className="animated-gradient"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 font-serif animate-on-scroll opacity-0">
            Join the Club
          </h2>
          <p className="text-gray-300 text-lg mb-8 animate-on-scroll opacity-0">
            Playclub is an invite-only platform for discerning business travelers who prioritize their active lifestyle. Experience the future of fitness and networking.
          </p>
          <div className="mt-8 flex justify-center">
            <div className="w-16 h-1 bg-yellow-500 rounded-full"></div>
          </div>
        </div>
      </div>
    </section>
  );
};