import React from 'react';
import { useNotificationStore } from '../../store/notificationStore';

const generateUniqueId = () => {
  return `notification_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
};

export const addDemoNotifications = () => {
  const { addNotification } = useNotificationStore.getState();

  // Match request notification
  addNotification({
    id: generateUniqueId(),
    type: 'match_request',
    title: 'New Match Request',
    message: 'Sarah Connor wants to play tennis tomorrow at 2 PM',
    timestamp: new Date().toISOString(),
    read: false,
    actionUrl: '/dashboard/activities',
    sender: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
    }
  });

  // New message notification
  addNotification({
    id: generateUniqueId(),
    type: 'new_message',
    title: 'New Message',
    message: 'James Wilson: Looking forward to our golf session!',
    timestamp: new Date(Date.now() - 1000 * 60 * 5).toISOString(), // 5 minutes ago
    read: false,
    actionUrl: '/dashboard/messages',
    sender: {
      id: '52',
      name: 'James Wilson',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
    }
  });

  // Mention notification
  addNotification({
    id: generateUniqueId(),
    type: 'mention',
    title: 'New Mention',
    message: 'Victoria Palmer mentioned you in a post',
    timestamp: new Date(Date.now() - 1000 * 60 * 30).toISOString(), // 30 minutes ago
    read: true,
    actionUrl: '/dashboard',
    sender: {
      id: 'lon2',
      name: 'Victoria Palmer',
      image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?auto=format&fit=crop&w=300&q=80'
    }
  });
};