import React from 'react';
import { Logo } from './Logo';
import type { LogoProps } from './types';

export const ThemeAwareLogo: React.FC<Omit<LogoProps, 'variant'>> = ({ colorScheme, ...props }) => {
  return (
    <Logo
      {...props}
      variant="combined"
      colorScheme={colorScheme || 'color'}
    />
  );
};

export default ThemeAwareLogo;