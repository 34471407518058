import { create } from 'zustand';
import Fuse from 'fuse.js';
import { members } from '../data/members';
import { facilities } from '../data/facilities';
import { posts } from '../data/posts';
import { SearchResults } from '../types/search';

interface SearchState {
  isOpen: boolean;
  query: string;
  isLoading: boolean;
  results: SearchResults;
  hasSearched: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setQuery: (query: string) => void;
  search: (query: string) => Promise<void>;
  clearResults: () => void;
}

const initialResults: SearchResults = {
  players: [],
  trainers: [],
  facilities: [],
  posts: []
};

const performSearch = async (query: string): Promise<SearchResults> => {
  const fuseOptions = {
    threshold: 0.3,
    keys: [
      'name',
      'description',
      'location',
      'sports',
      'content',
      'specialty',
      'city',
      'jobTitle',
      'company'
    ]
  };

  // Get trainers and players
  const trainers = members.filter(m => m.isTrainer);
  const players = members.filter(m => !m.isTrainer);

  // Add trainer- prefix to trainer IDs
  const trainersWithPrefixedIds = trainers.map(trainer => ({
    ...trainer,
    id: `trainer-${trainer.id}`
  }));

  const playersFuse = new Fuse(players, { ...fuseOptions });
  const trainersFuse = new Fuse(trainersWithPrefixedIds, { ...fuseOptions });
  const facilitiesFuse = new Fuse(facilities, { ...fuseOptions });
  const postsFuse = new Fuse(posts, { ...fuseOptions });

  const [playersResults, trainersResults, facilitiesResults, postsResults] = await Promise.all([
    playersFuse.search(query),
    trainersFuse.search(query),
    facilitiesFuse.search(query),
    postsFuse.search(query)
  ]);

  return {
    players: playersResults.map(result => result.item).slice(0, 5),
    trainers: trainersResults.map(result => result.item).slice(0, 5),
    facilities: facilitiesResults.map(result => result.item).slice(0, 5),
    posts: postsResults.map(result => result.item).slice(0, 5)
  };
};

export const useSearchStore = create<SearchState>((set) => ({
  isOpen: false,
  query: '',
  isLoading: false,
  results: initialResults,
  hasSearched: false,

  setIsOpen: (isOpen) => {
    set({ isOpen });
    if (!isOpen) {
      set({ 
        query: '',
        results: initialResults,
        hasSearched: false
      });
    }
  },
  
  setQuery: (query) => {
    set({ query });
    if (!query.trim()) {
      set({ 
        results: initialResults,
        hasSearched: false 
      });
    }
  },

  clearResults: () => set({
    query: '',
    results: initialResults,
    hasSearched: false
  }),

  search: async (query: string) => {
    const trimmedQuery = query.trim();
    
    if (!trimmedQuery) {
      set({ 
        results: initialResults,
        hasSearched: false,
        isLoading: false
      });
      return;
    }

    set({ isLoading: true });

    try {
      const searchResults = await performSearch(trimmedQuery);
      set({
        results: searchResults,
        hasSearched: true,
        isLoading: false
      });
    } catch (error) {
      console.error('Search error:', error);
      set({ 
        results: initialResults,
        hasSearched: true,
        isLoading: false
      });
    }
  }
}));